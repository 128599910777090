import { useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./DetalistInfo.scss";
import Title from "../../components/Title/Title";
import mobileHero from "./img/hero-mobile.jpg";
import desktopHero from "./img/hero-desktop.png";
import product from "./img/glo.png";
import iconPrize01 from "./img/icon-25.svg";
import iconPrize02 from "./img/icon-twopacks-flat.svg";
import titleIcon from "./img/title-icon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { useWindowDimensions } from "../../hooks";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../Faq/img/arrow.svg";

function DetalistInfo() {
  const { width } = useWindowDimensions();
  const [activeQuestion, setActiveQuestion] = useState<any[]>([]);

  const faq = [
    {
      uuid: 1,
      title: "Czy w domu będzie brzydki zapach? Czy będą śmierdzieć mi ubrania i firany?",
      answer: "Urządzenie podgrzewa wkład tytoniowy, a więc zapach będzie mniej intensywny niż w przypadku standardowych papierosów. Zapach ten nie utrzymuje się na ubraniach lub firanach."
    },
    {
      uuid: 2,
      title: "Czy palenie jednego wkładu jest porównywalne z paleniem jednego papierosa?",
      answer: "Urządzenie glo™ posiada dwa tryby pracy, aby lepiej dopasować się do preferencji użytkownika. Dzięki temu możemy sami zdecydować, czy intensywność podgrzewania ma być słabsza, czy mocniejsza."
    },
    {
      uuid: 3,
      title: "Czy znajdę wkłady neo™, które smakują jak tradycyjne papierosy?",
      answer: "W ofercie są różne warianty wkładów neo™, w tym aromaty klasycznego tytoniu, które będą najbardziej zbliżone do tradycyjnych papierosów."
    },
    {
      uuid: 4,
      title: "Czy podgrzewanie wpływa na odczucia smakowe?",
      answer: "Wkłady wykonane są ze specjalnie dobranej mieszanki tytoniu. Aromat wkładów tytoniowych neo™ może różnić się od zwykłych papierosów, ponieważ jest on podgrzewany, a nie spalany."
    },
    {
      uuid: 5,
      title: "Czy urządzenie glo™ jest wygodne w korzystaniu?",
      answer: "Urządzenie glo™ jest lekkie i poręczne, dzięki czemu korzystania z podgrzewacza nie wpływa na dyskomfort konsumenta."
    },
    {
      uuid: 51,
      title: "Czy glo™ powoduje żółknięcie zębów?",
      answer: "Przebarwienia i zżółknięcia na zębach, powstają od substancji smolistych. Podczas korzystania z urządzenia glo™, powstawanie tych substancji jest ograniczone do minimum, ponieważ tytoń zawarty we wkładach neo™ jest podgrzewany, a nie spalany."
    },
    {
      uuid: 52,
      title: "Czy z glo™ rzucę palenie? ",
      answer: "Nie. Podgrzewacz glo™ nie jest produktem, pomagającym w całkowitym rzuceniu palenia."
    },
    {
      uuid: 6,
      title: "Czy wkłady tytoniowe neo™ można kupić wszędzie?",
      answer: "Wkłady neo™ można kupić praktycznie na każdym kroku - w sieci eSmokingWorld, salonikach prasowych, stacjach paliw, wybranych marketach spożywczych i punktach sprzedaży detalicznej."
    },
    {
      uuid: 7,
      title: "Czy wkłady tytoniowe neo™ mogę kupić poza granicą Polski?",
      answer: "Tak, wkłady neo™ są dostępne w 15 krajach - Niemcy, Włochy, Hiszpania, Portugalia, Ukraina, Słowacja, Węgry, Chorwacja, Serbia, Białoruś, Grecja, Cypr Północny, Litwa. Ponadto, w niektórych krajach występują pod innymi nazwami, np. W Turcji występują pod nazwą KentSticks. Dodatkowo w poszczególnych krajach wkłady neo™  mogą różnić się wariantami."
    }
  ]

  const handleActiveQuestion = (item:any) => {
    const itemExist = activeQuestion.includes(item);
    if(activeQuestion.length === 0) {
      setActiveQuestion((prevState) => [...prevState,item])
    }else if (itemExist) {
      setActiveQuestion((prevState) => [...prevState.filter((prev) => prev !== item)])
    }else {
      setActiveQuestion((prevState) => [...prevState,item])
    }
  }

  return (
    <PageLayout>
      <section className="DetalistInfo">
        <div className="hero-section">
          <div className="hero-image">
            <picture>
              <source media="(max-width: 1019px)" srcSet={mobileHero} />
              <source media="(min-width: 1020px)" srcSet={desktopHero} />
              <img className="bg" src={desktopHero} alt="A wide image" />
            </picture>
          </div>
          <div className="hero-content">
            <Title>Jak rozmawiać o glo™ z klientami?</Title>
            <p><strong>Poznaj niezastąpioną dawkę wiedzy, aby stać się skuteczniejszym sprzedawcą.</strong> Z&nbsp;poniższymi informacjami będziesz w stanie również pomóc swoim klientom w dokonaniu właściwego wyboru oraz rozwianiu wszelkich wątpliwości.</p>
          </div>
        </div>
        <div className="what-is-glo">
          <h2>Co to jest glo™?</h2>
          <div className="product">
            <div className="image"><img src={product} /></div>
            <div className="product-infos">
              <div className="product-info"><strong>Potencjalnie mniej ryzykowna alternatywa</strong> dla tradycyjnych papierosów</div>
              <div className="product-info"><strong>Urządzenie typu „heat not burn”, co oznacza</strong> „podgrzewanie, nie spalanie”</div>
              <div className="product-info"><strong>Urządzenie do podgrzewania wkładów</strong> tytoniowych neo™</div>
            </div>
          </div>
        </div>

        <h2>Czy ten produkt jest dla&nbsp;mnie?</h2>
        <div className="product-benefits">
          <div className="product-benefit">
            <div className="image-title img1">Dla osób szukających alternatyw dla tradycyjnego palenia</div>
            <p>Urządzenie glo™ jest intuicyjne w obsłudze oraz lekkie i poręczne. <strong>Dwa tryby pracy (STANDARD I BOOST) pozwalają na kontrolowanie intensywności podgrzewania wkładów tytoniowych.</strong></p>
            <p>Dodatkowo utrzymanie czystości urządzenia z dołączoną szczoteczką jest niezwykle proste.</p>
          </div>
          <div className="product-benefit">
            <div className="image-title img2">Dla osób szukających potencjalnie mniej ryzykownej alternatywy</div>
            <p>Urządzenie glo™ ma za zadanie przypominać palenie tradycyjnego papierosa (jedna sesja trwa tyle, ile zajmuje średnio wypalenie jednego papierosa). </p>
            <p><strong>Podgrzewacz glo™ stanowi alternatywę dla tradycyjnych papierosów. Bez dymu, popiołu, przykrego zapachu, a także do 90-95% mniej szkodliwych substancji w porównaniu do tradycyjnych papierosów.*</strong></p>
            <p>* W porównaniu do tradycyjnie palonych papierosów. Nie oznacza to, że produkt ten jest mniej szkodliwy niż inne wyroby tytoniowe.</p>
          </div>
          <div className="product-benefit">
            <div className="image-title img3">Dla osób tęskniących za klikanymi papierosami</div>
            <p><strong>W ofercie wkładów tytoniowych neo™ przeznaczonych dla urządzenia glo™ znajdują się warianty, które dają aromat po kliknięciu.</strong></p>
            <p>Do wyboru są różne aromaty takie jak: jagoda, ciemne czerwone owoce, ananas, melon z dodatkiem cytrusów, mentol i mięta.</p>
          </div>
          <div className="product-benefit">
            <div className="image-title img4">Dla osób ceniących sobie smak tradycyjnego papierosa</div>
            <p><strong>W urządzeniach glo™ stosowane są wkłady z prawdziwym tytoniem,</strong> który jest podgrzewany do maksymalnie 250℃ w trybie STANDARD oraz 260℃ w trybie BOOST, aby wydobyć z niego nikotynę.</p>
            <p>W tradycyjnych papierosach dochodzi do procesu spalania, w trakcie którego tytoń osiąga temperaturę nawet do 800℃. takim wypadku powstaje dym zawierający substancje smoliste, czyli związki chemiczne. Oprócz tego efektem ubocznym jest popiół.</p>
          </div>
        </div>
        <h2>Sytuacje, w których możesz polecić glo™</h2>
        <>
          <Swiper
            slidesPerView={1.2}
            spaceBetween={16}
            centeredSlides={true}
            className="recommend-swiper"
            breakpoints={{
              720: {
                slidesPerView: 7,
                spaceBetween: 10,
                centeredSlides: false,
              }
            }}
          >
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="body">
                  <p className="desc">
                    Klient używał <strong>e-papierosów i mu nie odpowiadają</strong>
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="body">
                  <p className="desc">
                    <strong>Klient uważa, że e-papieros jest zbyt skomplikowany w obsłudze</strong> (wymiana grzałek, dolewanie liquidu)
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="body">
                  <p className="desc">
                    <strong>Klient szuka potencjalnie mniej szkodliwych opcji, ale jednocześnie</strong> oczekuje podobnego odczucia do palenia tradycyjnych papierosów
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="body">
                  <p className="desc">
                  Klientowi albo osobom w mjego otoczeniu <strong>przeszkadza dym z papierosa</strong>
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="body">
                  <p className="desc">
                  Klientowi <strong>dokucza kaszel</strong> spowodowany paleniem tradycyjnych papierosów
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="body">
                  <p className="desc">
                  Klient szuka urządzenia, którego <strong> nie będzie musiał często ładować</strong>
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="body">
                  <p className="desc">
                  Kiedy szuka ekonomicznego rozwiązania <strong>w sugerowanej cenie 49,00 zł</strong>
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </>
        <h2 style={{color: "black"}}>Poniżej znajdziesz dodatkowe odpowiedzi, które pomogą Ci w&nbsp;udzieleniu informacji i&nbsp;rozwianiu wątpliwości klienta.</h2>
        <ul className="qa-list">
          {faq.map((item:any) => {
            let fakeID = item.uuid;
              return (
                <li key={"question-" + fakeID} className={activeQuestion.includes(fakeID)  ? "active list-item" : "list-item"}>
                  <div
                    className="question"
                    onClick={() => {
                      handleActiveQuestion(fakeID);
                    }}
                  >
                    {item.title}
                    <span>
                      <ArrowIcon className="icon" />
                    </span>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: item.answer }} className="answer" />
                </li> 
            )})}
        </ul>
      </section>
    </PageLayout>
  );
}

export default DetalistInfo;
