import React, { useEffect } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import { useLocation, useNavigate } from "react-router-dom";
import Title from "../../components/Title/Title";
import "./Notifications.scss";
import BackgroundImage01 from "./img/01.png";
import { getNOTI} from "../../store/reducers/notifications";
import { closeNotification, fetchNotifications } from "../../store/actions/notifications"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { ReactComponent as Calendar } from "./img/calendar.svg";
import { hideNoti } from "../../store/actions/ui";
import { useAppDispatch } from "../../hooks";

const NotificationListItem = ({ noti }: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  return (
    <li role="link" onClick={() => {navigate(noti.content.description); dispatch(closeNotification(noti.uuid));}} className="item">
      <div className="inner">
        <div className="image">
          <img src={noti.hero_image.file.url} alt="" />
        </div>
        <div className="body">
          <div className={noti.closed ? "title" : "title color-secondary"}>{noti.content.title}</div>
          <div className="footer">
            <span className="date">
              <Calendar /> <span>{moment(noti?.publicationDate?.date).format('DD.MM.YYYY')}</span>
            </span>
            <span className="line"></span>
            <span className="copy">Zobacz więcej</span>
          </div>
        </div>
      </div>
    </li>
  );
};
function Notifications() {
  const notifications = useSelector(getNOTI);

  return (
    <PageLayout>
      <div className="Notifications page">
        <Title>Powiadomienia</Title>
        {notifications.length > 0 ? (
             <ul className="list">
             {notifications.map((noti:any) => (
               <NotificationListItem key={noti.id} noti={noti} />
             ))}
           </ul>
        ): null}
      </div>
    </PageLayout>
  );
}

export default Notifications;
