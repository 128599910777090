// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useEffect } from "react";
import PageLayout from "../../../../components/PageLayout/PageLayout";
import Title from "../../../../components/Title/Title";
import { useWindowDimensions } from "../../../../hooks";
import { ReactComponent as Correct } from "./img/correct.svg";
import { ReactComponent as Incorrect } from "./img/incorrect.svg";
import "../Quiz.scss";
import _ from "lodash";
import Bg01 from "./img/01.png";
import Bg02 from "./img/02.png";
import Bg03 from "./img/03.png";
import Bg04 from "./img/04.png";
import Bg05 from "./img/05.png";
import Bg06 from "./img/06.png";
import Bg01mobile from "./img/01-mobile.png";
import Bg02mobile from "./img/02-mobile.png";
import Bg03mobile from "./img/03-mobile.png";
import Bg04mobile from "./img/04-mobile.png";
import Bg05mobile from "./img/05-mobile.png";
import Bg06mobile from "./img/06-mobile.png";
import QuizQuestionCounter from "../../../../components/QuizQuestionCounter/QuizQuestionCounter";
import QuizCta from "../../../../components/QuizCta/QuizCta";
import Button from "../../../../components/Button/Button";
import { getToken } from "../../../../store/reducers/user";
import { API_URL, appFetch } from "../../../../helpers/fetch";
import { useAppSelector } from "../../../../hooks";
import { ADD_TOAST } from "../../../../store/actions/types";
import { useDispatch } from "react-redux";
import { errors } from "../../../../helpers/errors";
import { useNavigate } from "react-router-dom";

const ResultScreen = ({ loading, doneCorrectly }) => {
  const navigate = useNavigate();
  if (loading) return null;

  return doneCorrectly ? (
    <div className="QuizResultScreen">
      <div className="heading">
        <div className="icon">{<Correct />}</div>
        <h2 className="title">Jesteśmy pod wrażeniem!</h2>
      </div>
      <div className="body">
        <p>Wykorzystaj swoją wiedzę podczas sprzedaży.</p>
        <p>
          W nagrodę{" "}
          <strong>
            dorzucamy do Twojego konta: <br />
            10&nbsp;zł
          </strong>
        </p>
      </div>

      <button className="Button" onClick={() => navigate("/aktywnosci")}>
        Super
      </button>
    </div>
  ) : (
    <div className="QuizResultScreen">
      <div className="heading">
        <div className="icon">{<Incorrect />}</div>
        <h2 className="title">Małe potknięcie.</h2>
      </div>
      <div className="body">
        <p>
          Zapoznaj się raz jeszcze z marką KENT i{" "}
          <strong>wróć tu za 24h,</strong> aby ponownie spróbować swoich sił.
        </p>
        <p>Dyszka będzie na Ciebie czekać.</p>
      </div>

      <button className="Button" onClick={() => navigate("/kent")}>
        Poznaj KENT
      </button>
    </div>
  );
};

const questionBackgrounds = [Bg01, Bg02, Bg03, Bg04, Bg05, Bg06];
const questionBackgroundsMobile = [
  Bg01mobile,
  Bg02mobile,
  Bg03mobile,
  Bg04mobile,
  Bg05mobile,
  Bg06mobile,
];

interface QuizProps {
  questionNumber: number;
  indexActiveQuestion: number;
  handleNextQuestion: () => void;
  handleAnswer: (answer: any) => void;
  questionAnswers: any;
}

const Quiz: React.FC<QuizProps> = ({
  questionAnswers,
  questionNumber,
  indexActiveQuestion,
  handleAnswer,
  handlePickBg,
  handleNextQuestion,
}) => {
  const itsActive = (answer: string, idx: any) => {
    if (typeof idx === "number") {
      return Boolean(
        questionAnswers[indexActiveQuestion]?.answer_ident[idx] === answer
      );
    } else {
      return Boolean(
        questionAnswers[indexActiveQuestion]?.answer_ident === answer
      );
    }
  };

  return (
    <div className="quiz-wrapper kent-quiz">
      {indexActiveQuestion === 0 && (
        <div className="quiz-item">
          <h2 className="title">
            Nowość <br />- Papierosy KENT
          </h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />

          <div className="image">
            <img src={handlePickBg()} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">
                  Jakie formaty papierosów marki KENT pojawiły się w Polsce?
                </span>
              </p>
            </div>
            <div className="cta-group">
              <QuizCta
                active={itsActive("a", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q0",
                    answer_ident: "a",
                  })
                }
              >
                Tylko KENT KS
              </QuizCta>
              <QuizCta
                active={itsActive("b", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q0",
                    answer_ident: "b",
                  })
                }
              >
                Tylko KENT SSL
              </QuizCta>
              <QuizCta
                active={itsActive("c", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q0",
                    answer_ident: "c",
                  })
                }
              >
                KENT KS i KENT SSL
              </QuizCta>
              <QuizCta
                active={itsActive("d", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q0",
                    answer_ident: "d",
                  })
                }
              >
                Żadne z powyższych
              </QuizCta>
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 1 && (
        <div className="quiz-item">
          <h2 className="title">
            Nowość <br />- Papierosy KENT
          </h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />

          <div className="image">
            <img src={handlePickBg()} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">
                  Ile rodzajów papierosów marki KENT zostało wprowadzonych na
                  polski rynek?
                </span>
              </p>
            </div>
            <div className="cta-group">
              <QuizCta
                active={itsActive("a", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q1",
                    answer_ident: "a",
                  })
                }
              >
                1
              </QuizCta>
              <QuizCta
                active={itsActive("b", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q1",
                    answer_ident: "b",
                  })
                }
              >
                2
              </QuizCta>
              <QuizCta
                active={itsActive("c", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q1",
                    answer_ident: "c",
                  })
                }
              >
                3
              </QuizCta>
              <QuizCta
                active={itsActive("d", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q1",
                    answer_ident: "d",
                  })
                }
              >
                5
              </QuizCta>
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 2 && (
        <div className="quiz-item">
          <h2 className="title">
            Nowość <br />- Papierosy KENT
          </h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />

          <div className="image">
            <img src={handlePickBg()} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">
                  Czym charakteryzuje się KENT&nbsp;KS?
                </span>
              </p>
            </div>
            <div className="cta-group">
              <QuizCta
                active={itsActive("a", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q2",
                    answer_ident: "a",
                  })
                }
              >
                Dwa produkty o różnych mieszkankach tytoniu
              </QuizCta>
              <QuizCta
                active={itsActive("b", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q2",
                    answer_ident: "b",
                  })
                }
              >
                Stylowe i wyróżniające się opakowanie
              </QuizCta>
              <QuizCta
                active={itsActive("c", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q2",
                    answer_ident: "c",
                  })
                }
              >
                Nowoczesny filtr z tubą
              </QuizCta>
              <QuizCta
                active={itsActive("d", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q2",
                    answer_ident: "d",
                  })
                }
              >
                Wszystkie odpowiedzi są prawidłowe
              </QuizCta>
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 3 && (
        <div className="quiz-item">
          <h2 className="title">
            Nowość <br />- Papierosy KENT
          </h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />

          <div className="image">
            <img src={handlePickBg()} alt="photo" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">
                  Czym charakteryzuje się KENT&nbsp;SSL?
                </span>
              </p>
            </div>
            <div className="cta-group">
              <QuizCta
                active={itsActive("a", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q3",
                    answer_ident: "a",
                  })
                }
              >
                Dedykowany blend wysokiej jakości dla KENT SSL BLUE oraz blend z chłodzącym efektem dla KENT SSL BLUE MARINE
              </QuizCta>
              <QuizCta
                active={itsActive("b", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q3",
                    answer_ident: "b",
                  })
                }
              >
                Stylowe i wyróżniające się opakowanie
              </QuizCta>
              <QuizCta
                active={itsActive("c", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q3",
                    answer_ident: "c",
                  })
                }
              >
                Nowoczesny filtr z tubą
              </QuizCta>
              <QuizCta
                active={itsActive("d", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q3",
                    answer_ident: "d",
                  })
                }
              >
                Wszystkie odpowiedzi są prawidłowe
              </QuizCta>
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 4 && (
        <div className="quiz-item">
          <h2 className="title">
            Nowość <br />- Papierosy KENT
          </h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />

          <div className="image">
            <img src={handlePickBg()} alt="photo" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">
                  Jaka jest rekomendowana cena jednej paczki papierosów marki
                  KENT?
                </span>
              </p>
            </div>
            <div className="cta-group">
              <QuizCta
                active={itsActive("a", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q4",
                    answer_ident: "a",
                  })
                }
              >
                13,99 zł
              </QuizCta>
              <QuizCta
                active={itsActive("b", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q4",
                    answer_ident: "b",
                  })
                }
              >
                14,99 zł
              </QuizCta>
              <QuizCta
                active={itsActive("c", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q4",
                    answer_ident: "c",
                  })
                }
              >
                15,99 zł
              </QuizCta>
              <QuizCta
                active={itsActive("d", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q4",
                    answer_ident: "d",
                  })
                }
              >
                16,99 zł
              </QuizCta>
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 5 && (
        <div className="quiz-item">
          <h2 className="title">
            Nowość <br />- Papierosy KENT
          </h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />

          <div className="image">
            <img src={handlePickBg()} alt="photo" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">
                  Który wariant dostępny w Polsce jest najbardziej popularny na
                  Ukrainie?
                </span>
              </p>
            </div>
            <div className="cta-group">
              <QuizCta
                active={itsActive("a", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q5",
                    answer_ident: "a",
                  })
                }
              >
                Kent KS Blue
              </QuizCta>
              <QuizCta
                active={itsActive("b", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q5",
                    answer_ident: "b",
                  })
                }
              >
                Kent KS Silver
              </QuizCta>
              <QuizCta
                active={itsActive("c", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q5",
                    answer_ident: "c",
                  })
                }
              >
                Kent SSL Blue Marine
              </QuizCta>
              <QuizCta
                active={itsActive("d", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q5",
                    answer_ident: "d",
                  })
                }
              >
                Żaden z powyższych
              </QuizCta>
            </div>
          </div>
        </div>
      )}

      <Button
        onClick={handleNextQuestion}
        className={
          questionAnswers[indexActiveQuestion] === null
            ? "next-cta disabled"
            : "next-cta"
        }
      >
        Dalej
      </Button>
    </div>
  );
};

function KentQuiz() {
  const { width } = useWindowDimensions();
  const token = useAppSelector(getToken);
  const quizIdent = "quiz_kent";
  const questionQuantity = 6;
  const [questionNumber, setQuestionNumber] = useState(questionQuantity);
  const [indexActiveQuestion, setIndexActiveQuestion] = useState(0);
  const [isFinish, setIsFinish] = useState(false);
  const [doneCorrectly, setDoneCorrectly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [questionAnswers, setQuestionAnswers] = useState(
    new Array(questionQuantity).fill(null)
  );
  const dispatch = useDispatch();

  const handlePickBg = () =>
    width >= 1020
      ? questionBackgrounds[indexActiveQuestion]
      : questionBackgroundsMobile[indexActiveQuestion];

  useEffect(() => {
    function fetchQuiz() {
      const data = {
        activation: {
          ident: quizIdent,
          payload: questionAnswers,
        },
      };
      appFetch(
        "POST",
        API_URL,
        "/activation/solution/create",
        data,
        token?.uuid ? token?.uuid : false,
        (result: any) => {
          if (result.status && result.status.success) {
            if (result.data.payout != 0) {
              setDoneCorrectly(true);
            } else {
              setDoneCorrectly(false);
            }
            setLoading(false);
            setIsFinish(true);
          }
          setLoading(false);
          setIsFinish(true);
        },
        () => {
          dispatch({
            type: ADD_TOAST,
            payload: { type: "error", message: errors.generic },
          });
        }
      );
    }
    if (questionQuantity === indexActiveQuestion) {
      fetchQuiz();
    }
  }, [indexActiveQuestion]);

  const handleAnswer = (answer: any) => {
    console.log(answer);
    let arrayWithQuestion = [...questionAnswers];
    arrayWithQuestion[indexActiveQuestion] = answer;
    setQuestionAnswers(arrayWithQuestion.slice(0, questionQuantity));
  };

  const handleNextQuestion = () => {
    if (indexActiveQuestion <= questionQuantity - 1) {
      setIndexActiveQuestion((prevState) => prevState + 1);
    }
  };

  return (
    <PageLayout>
      <div className="Quiz-Wrapper">
        {console.log(questionAnswers)}
        {isFinish ? (
          <ResultScreen loading={loading} doneCorrectly={doneCorrectly} />
        ) : (
          <div className="page Quiz VeloFreeze">
            <div className="heading">
              {width <= 1020 ? (
                <Title>
                  Nowość
                  <br /> - Papierosy KENT
                </Title>
              ) : (
                <Title></Title>
              )}

              <div className="image-desktop">
                <img src={handlePickBg()} alt="" />
              </div>
            </div>
            <Quiz
              handlePickBg={handlePickBg}
              questionAnswers={questionAnswers}
              handleAnswer={handleAnswer}
              handleNextQuestion={handleNextQuestion}
              indexActiveQuestion={indexActiveQuestion}
              questionNumber={questionNumber}
            />
          </div>
        )}
      </div>
    </PageLayout>
  );
}

export default KentQuiz;
