// @ts-nocheck
import React, { useEffect, useState } from "react";
import "./TokenRefresh.scss";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppSelector, useWindowDimensions } from "../../hooks";
import { getUI } from "../../store/reducers/ui";
import { ReactComponent as IconX } from "./img/icon-x.svg";
import { ReactComponent as IconArrow } from "./img/arrow-right.svg";
import { ReactComponent as IconChevronTop } from "./img/chevron-top.svg";
import { ReactComponent as IconChevronBot } from "./img/chevron-bot.svg";
import { closeMenu } from "../../store/actions/ui";
import { ReactComponent as Logo } from "./img/logo-white.svg";
import { useClickOutside } from "../../hooks";
import { logout, refreshToken } from "../../store/actions/user";
import Countdown from "react-countdown";

function TokenRefresh() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getToken = localStorage.getItem("token");
  const [logoutWarning, setLogoutWarning] = useState(false);
  const token = getToken && JSON.parse(getToken);
  let modalTimer, tokenTimer;

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return null;
    } else {
      return <span>{seconds}</span>;
    }
  };

  const extendSesion = () => {
    dispatch(refreshToken());
    setLogoutWarning(false);
  };
  useEffect(() => {
    const tokenDate = token?.expires?.date
      ? token?.expires?.date.replace(" ", "T")
      : false;
    if (tokenDate) {
      const tokenValidTo = new Date(tokenDate).getTime();
      const now = new Date().getTime();
      const tokenExpiresIn = parseInt(tokenValidTo - now);
      //console.log("tokenExpiresIn", tokenExpiresIn);
      modalTimer = setTimeout(() => {
        setLogoutWarning(true);
      }, tokenExpiresIn - 61000);
      //console.log(tokenExpiresIn-65000)

      tokenTimer = setTimeout(() => {
        setLogoutWarning(false);
        dispatch(logout(navigate))
      }, tokenExpiresIn-1000)

    } else {
      clearTimeout(modalTimer);
      clearTimeout(tokenTimer)
    }
  }, [token?.uuid]);

  return (
    <>
      {logoutWarning ? (
        <div className="TokenRefresh">
          <div className="counter">
            Sesja wygaśnie za:&nbsp; {"   "}
            <Countdown
              date={Date.now() + 60000}
              renderer={(props) => (
                <div>{props.seconds ? props.seconds : 60} s</div>
              )}
            />
          </div>
          <div className="cta-cointainer">
            {/* <button className="Button" onClick={() => setLogoutWarning(false)}>
              zamknij
            </button> */}
            <button className="Button white" onClick={extendSesion}>
              Przedłuż sesje
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default TokenRefresh;
