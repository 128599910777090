import React ,{useState}from 'react'
import './VideoPlayer.scss'
import { ReactComponent as PlayBtn } from './img/play.svg';

interface VideoProps {
    placeholder:string,
    url?:string,
  }
    const VideoPlayer:React.FC<VideoProps> = ({placeholder,url}) => {
      const [isPlaying,setIsPlaying] = useState(false);
          const handleFullScreen = (ele: any) => {
        console.log(ele, "element");
        requestFullscreen(ele);
      };
    
      const requestFullscreen = function (ele: any) {
        if (ele.requestFullscreen) {
          ele.requestFullscreen();
        } else if (ele.webkitRequestFullscreen) {
          ele.current.webkitRequestFullscreen();
        } else if (ele.mozRequestFullScreen) {
          ele.mozRequestFullScreen();
        } else if (ele.msRequestFullscreen) {
          ele.msRequestFullscreen();
        } else {
          console.log("Fullscreen API is not supported.");
        }
      };
    return (
      <div className="Video-player">
          <div className="inner">
        
        {isPlaying ? (null) : (   <div className="play">
          <PlayBtn/>
          </div>)}
        
            <video
              className="video"
              controlsList="nodownload"
              controls
              poster={placeholder}
              onPlay={(vid) => {
                handleFullScreen(vid.target);
                setIsPlaying(true);
              }}
              onPause={() => setIsPlaying(false)}
              playsInline
            >
              <source
                src={url ? url : undefined}
                type="video/mp4"
              />
            </video>
          </div>
        </div>
    )
}

export default VideoPlayer;