//glo układanka
import gloSquare1 from "../img/puzzel01.png";
import gloSquare2 from "../img/puzzel02.png";
import gloSquare3 from "../img/puzzel03.png";
import gloSquare4 from "../img/puzzel04.png";
import gloSquare5 from "../img/puzzel05.png";
import gloSquare6 from "../img/puzzel06.png";
import gloSquare7 from "../img/puzzel07.png";
import gloSquare8 from "../img/puzzel08.png";
import gloSquare9 from "../img/puzzel09.png";

export const ukladanka_glo = {
  glo: [
    {
      ident: "q0",
      previewImg: "",
      blocks: [
        {
          part: gloSquare1,
          ident: "e75d6f7b-b1cd-413d-b2f0-fc3b3d2efe95",
        },
        {
          part: gloSquare2,
          ident: "2e8c7022-445b-4be8-bb66-4af23e66ec4b",
        },
        {
          part: gloSquare3,
          ident: "bf03f153-0c44-40ed-8f4d-ae55186cbcdb",
        },
        {
          part: gloSquare4,
          ident: "cd3f497b-528f-45ac-a940-56cd917b9e2e",
        },
        {
          part: gloSquare5,
          ident: "94c465c4-54dc-4e97-ba97-7ba8fa5488a6",
        },
        {
          part: gloSquare6,
          ident: "d6885344-90ce-4a87-bfb7-62d022cbbe03",
        },
        {
          part: gloSquare7,
          ident: "ad0c9e80-28cb-43f6-abb1-b212ebc163a0",
        },
     
        {
          part: gloSquare8,
          ident: "43d10a48-e945-4ba1-8b44-2867fba77026",
        },
     
      
      

        {
          part: gloSquare9,
          ident: "8c05a602-9aac-41f9-af2e-e820a30bc0af",
        },
      
       
      ],
    },
  ],
};
