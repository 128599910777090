import "./BankAccount.scss";
import InputMask from "react-input-mask";
import { useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import Button from "../../../components/Button/Button";
import { updateUser } from "../../../store/actions/user";
import { useAppDispatch } from "../../../hooks";
import { useEffect } from "react";

var IBAN = require("iban");

interface FormData {
  iban: string;
}

const BankAccount = ({
  ibanNumber,
  setValue,
  formStep,
  ibanLimitExceeded
}: {
  ibanNumber: string;
  setValue: any;
  formStep: number;
  ibanLimitExceeded:boolean
}) => {
  const dispatch = useAppDispatch();
  const [edit, setEdit] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    setEdit(false);
    const ibanWithoutSpaces = data.iban.replace(/\s+/g, "");
    dispatch(
      updateUser({
        user: {
          iban: "PL" + ibanWithoutSpaces,
        },
      })
    );
  };

  useEffect(() => {
    setValue("iban", "");
    setEdit(false);
  }, [formStep]);

  return (
    <div className="BankAccount">
      {ibanLimitExceeded ? (
        <>
        <div className={"prefix-input-wrapper disabled"}>
        <div className="input-item  desktop-margin-right">
          <label>Numer konta</label>
          <div className={"prefix-input-wrapper"}>
            <span className="prefix">PL</span>

        
              <InputMask
                key="default"
                value={ibanNumber}
                mask="99 9999 9999 9999 9999 9999 9999"
                maskChar={null}
                readOnly
                placeholder={ibanNumber}
              />
            
            
            
          

          </div>

          {errors?.iban && (
            <span className="error">{errors.iban?.message?.toString()}</span>
          )}
        </div>
           
          </div>
           <p className="desc">Limit zmian numeru konta bankowego został osiągnięty.</p>
           </>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input-item edit-icon desktop-margin-right">
          <label htmlFor="bank-number">Numer konta</label>
          <div className={"prefix-input-wrapper"}>
            <span className="prefix">PL</span>

            {!edit && ibanNumber && (
              <InputMask
                key="default"
                value={ibanNumber}
                mask="99 9999 9999 9999 9999 9999 9999"
                maskChar={null}
                readOnly
                placeholder={ibanNumber}
              />
            )}
               <div className={!ibanNumber || edit ? "edit active" : "edit"}>
              <InputMask
                key="edit"
                autoFocus
                {...register("iban", {
                  validate: {
                    required: (value) =>
                      value.trim().length > 0 || "To pole jest wymagane",
                    iban: (value) =>
                      IBAN.isValid("PL" + value) ||
                      "Nieprawidłowy format numeru IBAN",
                  },
                })}
                mask="99 9999 9999 9999 9999 9999 9999"
                maskChar={null}
                placeholder={"XX XXXX XXXX XXXX XXXX XXXX XXXX"}
              />
            </div>

            <button
              type="button"
              onClick={() => {
                setEdit((prev) => !prev);
                setValue("iban", "");
              }}
              className="edit-cta"
            ></button>
          </div>

          {(!ibanNumber || edit) && errors?.iban &&              <span className="error">{errors.iban?.message?.toString()}</span>}

        
        </div>

        <div className="desc">
          Pamiętej, że dany numer konta bankowego może zostać wprowadzony do
          serwisu tylko raz.
        </div>

        {(!ibanNumber || edit) && <Button disabled={false}>Zapisz</Button>}

        <p className="warning">
          Podanie numeru konta jest konieczne do wypłat środków z BAT Partners.
        </p>
      </form>
      )}
     
    </div>
  );
};

export default BankAccount;
