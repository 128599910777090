import './Disclaimer.scss';


function Disclaimer() {

  return (
    <div className="Disclaimer">
      Palenie zabija - rzuć teraz!
    </div>
  );
}

export default Disclaimer
