import React, { useEffect, useState } from "react";
import {
  SubmitHandler,
  useForm,
  FieldValues,
  FieldErrors,
} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Button from "../../components/Button/Button";
import PageLayout from "../../components/PageLayout/PageLayout";
import { registerUser } from "../../store/actions/user";
import { getRegisterState, getSubmitState } from "../../store/reducers/user";
import "../../theme/forms.scss";
import "./Register.scss";
import prefixes from "../../helpers/prefixes";
import { useAppDispatch } from "../../hooks";
import Loading from "../../components/Loading/LoadingScreen/Loading";
import loginImgMobile from "./img/mobile.jpg";
import loginImgDesktop from "./img/desktop-img.jpg";
import Modal from 'react-modal'

import { ReactComponent as IconExclamation } from './img/icon-exclamation.svg';

interface registerProps {
  firstName: string;
  lastName: string;
  salesPoint: string;
}

function RegisterPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const registerSuccess = useSelector(getRegisterState);
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();
  const {
    register: register2,
    setValue: setValue2,
    handleSubmit: handleSubmit2,
    getValues: getValues2,
    formState: { errors: errors2 },
  } = useForm();
  const [formStep, setFormStep] = useState(0);
  const [showInfo, setShowInfo] = useState(false);
  const submitting = useSelector(getSubmitState);
  const sellPointOptions = ["AM", "CK", "LA", "SH", "SP", "TA", "TT", "ESW"];

  const ESWPOINTS = [
    "F2020",
    "FS9775",
    "F0050",
    "F0051",
    "F0055",
    "F0057",
    "F0210",
    "F0002",
    "F0132",
    "F0303",
    "F0229",
    "FS0245",
    "F0044",
    "F0072",
    "F0073",
    "F0075",
    "F0109",
    "F0111",
    "F0232",
    "F0280",
    "F2191",
    "F0101",
    "F0102",
    "F0104",
    "F9107",
    "F0063",
    "F0211",
    "F308",
    "F0216",
    "F0217",
    "F0218",
    "F0222",
    "F0215",
    "F2197",
    "F0248",
    "FS9596",
    "FS9598",
    "FS9630",
    "FS9631",
    "FS9632",
    "F2013",
    "F0253",
    "FS0294",
    "F0289",
    "F0278",
    "F0307",
    "F2201",
    "F9361",
    "F9740",
    "F9763",
    "F9765",
    "F9252",
    "F2200",
    "F2003",
    "F8224",
    "FS0293",
    "F0279",
    "F10151",
    "F9764",
    "F0227",
    "F0242",
    "FS0251",
    "F0078",
    "F2186",
    "F0281",
    "F0283",
    "F0284",
    "F026",
    "F027",
    "F028",
  ];

  const onSubmit = ({
    firstName,
    lastName,
    salesPointPrefix,
    salesPointNumber,
  }: FieldValues) => {
    setFormStep(1);
  };

  const onError = (errors: FieldErrors) => {
    console.log(errors);
  };

  const onSubmit2 = ({
    emailAddress,
    mobilePhone,
    ageVerified,
    consentAgreement,
    marketingConsent,
    phoneContact,
    emailContact,
    hostessCode,
  }: FieldValues) => {
    const formPart = getValues();
    let phone = mobilePhone;
    if (mobilePhone.indexOf("+48") === -1) {
      phone = "+48" + mobilePhone;
    }
    dispatch(
      registerUser({
        firstName: formPart.firstName,
        lastName: formPart.lastName,
        salesPoint: formPart.salesPointNumber,
        emailAddress: emailAddress,
        mobilePhone: phone,
        hostessCode: hostessCode,
        ageVerified: ageVerified,
        consentAgreement: consentAgreement,
        marketingConsent: phoneContact || emailContact ? true : false,
        phoneContact: phoneContact,
        emailContact: emailContact,
      })
    );
  };

  const onError2 = (errors: FieldErrors) => {
    console.log(errors);
  };

  const validateSalesPoint = (value: string) => {
    const sellpoint = getValues("salesPointPrefix") + value;
    if (getValues("salesPointPrefix") === "ESW") {
      return ESWPOINTS.indexOf(value) >= 0;
    }
    return /^(AM|TA)(\d{3})$|^(SH)(\d{4})$|^(CK|LA)(\d{5})$|^(TT)(\d{6,14})$|^(SP)(\d{3,14})$/.test(
      sellpoint
    );
  };

  const validatePhone = (value: string) => {
    if (value.length >= 9 && value.length <= 12) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setValue("salesPointPrefix", "TT");
  }, []);

  return (
    <PageLayout>
      <div className="page Register">
        <div className="register-image">
          <picture>
            <source media="(min-width: 1020px)" srcSet={loginImgDesktop} />
            <img
              src={loginImgMobile}
              alt={"Wejdź do programu Bat"}
              loading="lazy"
            />
          </picture>
        </div>
        <div className="body-form">
          <ul className="register-page-nav">
            <li className={formStep >= 0 ? "active" : ""}></li>
            <li className={formStep >= 1 ? "active" : ""}></li>
          </ul>

          <form>
            <div
              className={
                formStep === 0 && !registerSuccess
                  ? "form-step active"
                  : "form-step"
              }
            >
              <h1>Dołącz do programu BAT&nbsp;Partners</h1>
              <div className="input-item">
                <label htmlFor="firstName">Imię</label>
                <input
                  placeholder="Jan"
                  className={errors.firstName && "error-input"}
                  type="text"
                  id="firstName"
                  {...register("firstName", {
                    required: true,
                    pattern: /^[\sa-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]*$/,
                  })}
                ></input>
                {errors.firstName && errors.firstName.type === "pattern" && (
                  <p className="error">Nieprawidłowa wartość</p>
                )}
                {errors.firstName && errors.firstName.type === "required" && (
                  <p className="error">Wpisz imię</p>
                )}
              </div>
              <div className="input-item">
                <label htmlFor="lastName">Nazwisko</label>
                <input
                  placeholder="Kowalski"
                  className={errors.lastName && "error-input"}
                  type="text"
                  id="lastName"
                  {...register("lastName", {
                    required: true,
                    pattern: /^[\sa-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]*$/,
                  })}
                ></input>
                {errors.lastName && errors.lastName.type === "pattern" && (
                  <p className="error">Nieprawidłowa wartość</p>
                )}
                {errors.lastName && errors.lastName.type === "required" && (
                  <p className="error">Wpisz nazwisko</p>
                )}
              </div>
              <div className="input-info">
                <label>Kod punktu sprzedaży (SAP ID)</label>
                <div
                  className={showInfo ? "info-btn active" : "info-btn"}
                  onClick={() => setShowInfo(!showInfo)}
                ></div>
                <div
                  className={showInfo ? "info active" : "info"}
                  onClick={() => setShowInfo(false)}
                >
                  SAP ID to unikalny numer klienta. Znajdziesz go na stronie
                  mBAT.pl. Po zalogowaniu przejdź do zakładki „Moje Konto",
                  następnie wybierz „Mój Profil". Tam znajdziesz swój
                  „Identyfikator Sklepu", czyli numer SAP ID. Możesz również
                  skontaktować się z przedstawicielem handlowym, który również
                  pomoże Ci uzyskać numer SAP ID.
                </div>
              </div>
              <div className="input-row">
                <div className="input-item">
                  <input
                    placeholder="66594123"
                    className={errors.salesPointNumber && "error-input"}
                    type="text"
                    id="salesPointNumber"
                    {...register("salesPointNumber", {
                      required: true,
                      pattern: /^([A-Z0-9]{7,8})$/,
                    })}
                  ></input>
                  {errors.salesPointNumber &&
                    errors.salesPointNumber.type === "pattern" && (
                      <p className="error">
                        Pole powinno zawierać minimum 7 znaków (duże litery
                        oraz/lub cyfry)
                      </p>
                    )}
                  {errors.salesPointNumber &&
                    errors.salesPointNumber.type === "required" && (
                      <p className="error">Pole wymagane</p>
                    )}
                </div>
              </div>
              <Button onClick={handleSubmit(onSubmit, onError)}>Dalej</Button>
              <div className="text-center margin-20">
                <Link to="/login">Powrót do logowania</Link>
              </div>

              <p className="info-bottom">
                Nie możesz znaleźć SAP ID? Potrzebujesz pomocy? <br />
                Skontaktuj się z nami:{" "}
                <a href="mailto:kontakt@batpartners.pl">
                  kontakt@batpartners.pl
                </a>
              </p>
            </div>

            <div
              className={
                formStep === 1 && !registerSuccess
                  ? "form-step active"
                  : "form-step"
              }
            >
              <h1>Dołącz do programu BAT&nbsp;Partners</h1>
              <div className="input-item">
                <label htmlFor="emailAddress">E-mail</label>
                <input
                  placeholder="jan@example.pl"
                  className={errors2.emailAddress && "error-input"}
                  type="text"
                  id="emailAddress"
                  {...register2("emailAddress", {
                    required: true,
                    pattern:
                      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                ></input>
                {errors2.emailAddress &&
                  errors2.emailAddress.type === "pattern" && (
                    <p className="error">Nieprawidłowy adres e-mail</p>
                  )}
                {errors2.emailAddress &&
                  errors2.emailAddress.type === "required" && (
                    <p className="error">Wpisz adres e-mail</p>
                  )}
              </div>
              <div className="input-item phone-input">
                <label htmlFor="mobilePhone">
                  Numer telefonu <br />
                  <span>(Ten numer będzie potrzebny do odbioru nagród)</span>
                </label>
                <input
                  placeholder="601602603"
                  className={errors2.mobilePhone && "error-input"}
                  type="text"
                  id="mobilePhone"
                  {...register2("mobilePhone", {
                    required: true,
                    pattern: /^\d{9}$/,
                  })}
                ></input>
                {errors2.mobilePhone &&
                  errors2.mobilePhone.type === "pattern" && (
                    <p className="error">Nieprawidłowy numer telefonu</p>
                  )}
                {errors2.mobilePhone &&
                  errors2.mobilePhone.type === "required" && (
                    <p className="error">Wpisz numer telefonu</p>
                  )}
              </div>
              <div className="input-item">
                <label htmlFor="hostessCode">Kod osoby polecającej</label>
                <input
                  placeholder="66594"
                  className={errors2.hostessCode && "error-input"}
                  type="text"
                  id="hostessCode"
                  {...register2("hostessCode", {
                    required: true,
                  })}
                ></input>
                {errors2.hostessCode?.type === "pattern" && (
                  <p className="error">Nieprawidłowy kod osoby polecającej</p>
                )}
                {errors2.hostessCode?.type === "required" && (
                  <p className="error">Pole wymagane</p>
                )}
              </div>
              <div className="checkbox-item">
                <input
                  type="checkbox"
                  id="ageVerified"
                  {...register2("ageVerified", { required: true })}
                />
                <label htmlFor="ageVerified">
                  Potwierdzam, że jestem osobą pełnoletnią i jestem uprawniony/a
                  do wzięcia udziału w Programie zgodnie z postanowieniami
                  Regulaminu*
                </label>
                {errors2.ageVerified &&
                  errors2.ageVerified.type === "required" && (
                    <p className="error">Zgoda wymagana</p>
                  )}
              </div>
              <div className="checkbox-item">
                <input
                  type="checkbox"
                  id="consentAgreement"
                  {...register2("consentAgreement", { required: true })}
                />
                <label htmlFor="consentAgreement">
                  Akceptuję warunki Programu określone w{" "}
                  <a
                    href="/pdf/regulamin_glowny_bat_partners.pdf"
                    target="_blank"
                  >
                    Regulaminie Programu
                  </a>
                  *
                </label>
                {errors2.consentAgreement &&
                  errors2.consentAgreement.type === "required" && (
                    <p className="error">Zgoda wymagana</p>
                  )}
              </div>
              <p className="text-11" style={{ margin: ".5em 0 1em" }}>
                Wyrażam zgodę na otrzymywanie od British American Tobacco Polska
                Trading sp. z o.o. z siedzibą w Warszawie (BAT) informacji
                marketingowych drogą elektroniczną lub telefoniczną, w tym
                informacji na temat produktów i usług oferowanych przez BAT oraz
                informacji marketingowych dotyczących programów wsparcia
                sprzedaży BAT. <br />
                <br />
                Jako formę kontaktu na potrzeby otrzymywania informacji
                marketingowych wybieram:
              </p>
              <div className="checkbox-item">
                <input
                  type="checkbox"
                  id="emailContact"
                  {...register2("emailContact")}
                />
                <label htmlFor="emailContact">
                  wiadomości przesyłane drogą elektroniczną (e-mail, SMS,
                  serwisy internetowe, okienka pop up)
                </label>
              </div>
              <div className="checkbox-item">
                <input
                  type="checkbox"
                  id="phoneContact"
                  {...register2("phoneContact")}
                />
                <label htmlFor="phoneContact">
                  połączenia głosowe (rozmowy telefoniczne, komunikaty IVR)
                </label>
              </div>
              <p className="text-11">
                W każdej chwili możesz wycofać zgodę na otrzymywanie informacji
                marketingowych ze skutkiem na przyszłość, wysyłając oświadczenie
                o wycofaniu zgody na adres e-mail{" "}
                <a href="mailto:kontakt@batpartners.pl">
                  kontakt@batpartners.pl
                </a>
                , telefonicznie pod numerem:{" "}
                <a href="tel:800610610">800 610 610</a> lub w panelu ustawień
                użytkownika serwisu www.batpartners.pl. Wycofanie zgody nie
                wpływa na zgodność z prawem wysyłania informacji marketingowych,
                którego dokonano na podstawie zgody przed jej wycofaniem. Więcej
                informacji o tym, jakie dane zbieramy, w jakim celu oraz do
                czego je wykorzystujemy znajdziesz w{" "}
                <a href="/">Klauzuli Informacyjnej</a>.
              </p>
              <div className="disclaimer">
                * Zgody konieczne do wzięcia udziału w Programie.
              </div>
              {submitting ? (
                <Button>
                  <Loading />
                </Button>
              ) : (
                <Button onClick={handleSubmit2(onSubmit2, onError2)}>
                  Dalej
                </Button>
              )}
              <Link className="link" to="/">
                Powrót do logowania
              </Link>
            </div>

            <div className={registerSuccess ? "form-step active" : "form-step"}>
              <h1>Witaj w programie BAT&nbsp;Partners</h1>
              <h2 className="h1 color-secondary" style={{ margin: "1.5em 0" }}>
                Brawo!
              </h2>
              <p className="text-center">
                Na Twój adres e-mail wysłaliśmy link potwierdzający.{" "}
                <strong>
                  Kliknij w niego aby ustawić hasło i dokończyć rejestrację.
                </strong>
              </p>
              <p className="text-center">
                Jeśli wiadomość nie dotrze <strong>w ciągu 5 minut</strong>{" "}
                sprawdź skrzynkę SPAM.
              </p>
              <div className="text-center margin-20">
                <Link to="/">Powrót do logowania</Link>
              </div>
            </div>
          </form>
        </div>
      </div>
      <p className="info-bottom">
        Administratorem danych wskazanych w trakcie rejestracji jest British
        American Tobacco Polska Trading sp. z o.o. z siedzibą w Warszawie przy
        ul. Krakowiaków 48. Skontaktuj się z nami telefonicznie pod numerem 800
        610 610 lub mailowo na adres{" "}
        <a href="mailto:daneosobowe@bat.com.pl">daneosobowe@bat.com.pl</a> Dane
        będą przez nas wykorzystywane dla celów związanych z Twoim uczestnictwem
        w programie BAT Partners, w tym utworzenia Twojego konta uczestnika. W
        związku z podaniem danych osobowych przysługuje Ci prawo dostępu do
        treści Twoich danych, prawo ich poprawiania, usunięcia lub przenoszenia,
        a także prawo do żądania ograniczenia ich przetwarzania. Przysługuje Ci
        także prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych
        Osobowych. Więcej informacji znajdziesz w informacji o przetwarzaniu
        danych na końcu{" "}
        <a href="/pdf/klauzula_informacyjna_bat_partners.pdf" target="_blank">
          Klauzuli Informacyjnej
        </a>
        .<br />
        <br />W razie pytań lub problemów technicznych napisz do nas na adres{" "}
        <a href="mailto:kontakt@batpartners.pl">kontakt@batpartners.pl</a>
      </p>

      <Modal
        isOpen={ true } 
        contentLabel="Modal" 
        className={{
          base: 'drawer',
          afterOpen: 'drawer_after-open',
          beforeClose: 'drawer_before-close'
        }}
        overlayClassName={{
          base: 'overlay',
          afterOpen: 'overlay_after-open',
          beforeClose: 'overlay_before-close'
        }} 
        onRequestClose={ () => { console.log("hello") } } 
        closeTimeoutMS={ 500 }
      >
        <div className="swipe-close">
          <div className="drawer-inner drawer-error">
            <IconExclamation />
            <h2>Rejestracja wyłączona</h2>
            <p>W związku z zakończeniem programu w dniu 31 marca 2024, nie jest już możliwe zakładanie nowych kont.</p>
            <Button path="/" >Rozumiem</Button>
          </div>
        </div>
      </Modal>


    </PageLayout>
  );
}

export default RegisterPage;
