import React, { useState, useEffect } from "react";
import PageLayout from "../../../../components/PageLayout/PageLayout";
import Title from "../../../../components/Title/Title";
import { useWindowDimensions } from "../../../../hooks";
import "../Quiz.scss";
import img02 from "./img/02.png";
import img03 from "./img/03.png";
import img04 from "./img/04.png";
import img07 from "./img/07.png";
import img09 from "./img/09.png";
import batLogoCard from "./img/bat-logo.png";
import QuizQuestionCounter from "../../../../components/QuizQuestionCounter/QuizQuestionCounter";
import Button from "../../../../components/Button/Button";
import { getToken } from "../../../../store/reducers/user";
import { API_URL, appFetch } from "../../../../helpers/fetch";
import { useAppSelector } from "../../../../hooks";
import { ADD_TOAST } from "../../../../store/actions/types";
import { useDispatch } from "react-redux";
import { errors } from "../../../../helpers/errors";
import { ReactComponent as Correct } from "./img/correct.svg";
import { useNavigate } from "react-router-dom";

const ResultScreen = ({ loading }: any) => {
  const navigate = useNavigate();
  if (loading) return null;

  return (
    <div className="QuizResultScreen">
      <div className="heading">
        <div className="icon">
          <Correct />
        </div>
        <h2 className="title">Bezbłednie!</h2>
      </div>
      <div className="body">
        Super! Otrzymujesz: <strong>5 zł</strong>
        <p>
          {" "}
          Wszystko się posypało, ale Tobie udało się dopasować opisy do
          konkretnych wariantów z pojedynczymi wkładami Vuse ePod.
        </p>
      </div>

      <button className="Button" onClick={() => navigate("/aktywnosci")}>
        Wróć do aktywności
      </button>
    </div>
  );
};

let answers = [
  {
    question_ident: "q0",
    answer_ident: [
      [
        "c8d051e0-5fdd-442a-ba09-b9e3e97126a6",
        "e0b03bcf-6107-4fbf-af48-eeec9dd0a461",
      ],
      [
        "15ea9b8f-ae29-41eb-9cc1-2bd11d8c798d",
        "206d72fc-87d8-449b-b511-3d0c3f1bcb5f",
      ],
      [
        "0cd965ea-d0c8-4b2a-966e-b8111519a70f",
        "f9bec0c0-7945-4ec3-9880-a4df87acf8f6",
      ],
      [
        "04247fae-7d1f-467f-8317-97a4a50ef5c5",
        "0eb1aed6-fc53-407b-9ef2-c7d7e12e192e",
      ],
      ["8a17ffae-7e49-4399-88b0-bfa8cd074bf5"],
    ],
  },
];

const batCards = [
  {
    id: 1,
    name: "Zbalansowana kombinacja złotego tytoniu i słodkich nut",
    image: null,
    color: { border: "2px solid #ca9212", color: "#ca9212" },
  },
  { id: 2, name: "", image: img02 },
  { id: 3, name: "", image: img03 },
  { id: 4, name: "", image: img04 },
  {
    id: 2,
    name: "Chłodny i orzeźwiający aromat mięty",
    image: null,
    color: { border: "2px solid #41797b", color: "#41797b" },
  },
  {
    id: 4,
    name: "Bogaty aromat mango zanurzony w słodkich nutach",
    image: null,
    color: { border: "2px solid #f6a704", color: "#f6a704" },
  },

  {
    id: 1,
    name: "",
    image: img07,
  },

  {
    id: 8,
    name: "Głęboki aromat ciemnej wiśni i czerwonych owoców.",
    image: null,
    color: { border: "2px solid #532a44", color: "#532a44" },
  },

  { id: 8, name: "", image: img09 },
];

const Quiz: any = ({
  matched,
  pairOfBatCards,
  openedCard,
  flipCard,
  setCurrentIndex,
  fill,
  setIsFinish,
}: any) => {
  return (
    <div className="quiz-wrapper">
      <div className="quiz-item">
        <h2 className="title">
          Warianty
          <br /> Vuse ePod
        </h2>
        <QuizQuestionCounter
          prize={true}
          value={5}
          questionNumber={1}
          indexActiveQuestion={0}
        />
        <div className="body">
          <div className="question">
            <p className="desc">
              <span className="bold subtitle">
                Znajdź, a następnie połącz w pary zdjęcia paczek wkładów Vuse do
                ich poprawnych opisów.
              </span>
            </p>
          </div>
          <div className="cards">
            {pairOfBatCards.map((bat: any, index: any): any => {
              let isFlipped = false;
              if (openedCard.includes(index)) isFlipped = true;
              if (matched.includes(bat.id)) isFlipped = true;
              return (
                <div
                  className={`bat-card ${isFlipped ? "flipped" : ""} `}
                  key={index}
                  onClick={() => {
                    flipCard(index, bat.id);
                    setCurrentIndex(index);
                  }}
                >
                  <div className="inner">
                    <div className="front" style={bat.color}>
                      {bat.name ? (
                        <span>{bat.name}</span>
                      ) : (
                        <img src={bat.image} alt="bat-name" />
                      )}
                    </div>
                    <div className="back">
                      <img src={batLogoCard} alt="bat-name" />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Button
        onClick={() => setIsFinish(true)}
        className={fill ? "next-cta" : "next-cta disabled"}
      >
        Dalej
      </Button>
    </div>
  );
};

function VuseEpodEtap2() {
  const { width } = useWindowDimensions();
  const token = useAppSelector(getToken);
  const quizIdent = "quiz_epod2_2";
  const questionQuantity = 1;
  const [openedCard, setOpenedCard] = useState<any[]>([]);
  const [matched, setMatched] = useState<any | any[] | null | undefined>([]);
  const [finish, setIsFinish] = useState(false);
  const [fill, setFill] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [quizSuccesfully, setQuizSuccessfully] = useState(false);
  const dispatch = useDispatch();
  const pairOfBatCards = [...batCards];
  const [loading, setLoading] = useState(false);

  function flipCard(index: any, id: any) {
    setTimeout(() => {
      setCurrentIndex(null);
    }, 1000);
    if (currentIndex === index) return;
    setOpenedCard((prevState: any) => [...prevState, index]);
  }

  useEffect(() => {
    if (matched.length >= 4) setFill(true);
    if (openedCard.length < 2) return;

    const firstMatched = pairOfBatCards[openedCard[0]];
    const secondMatched = pairOfBatCards[openedCard[1]];

    if (secondMatched && firstMatched.id === secondMatched.id) {
      let myArray = [...matched, firstMatched.id];
      let unique = Array.from(new Set(myArray));
      setMatched(unique);
    }

    if (openedCard.length === 2) setTimeout(() => setOpenedCard([]), 1000);
  }, [openedCard]);

  useEffect(() => {
    function fetchQuiz() {
      setLoading(true);
      const data = {
        activation: {
          ident: quizIdent,
          payload: answers,
          debug: true,
        },
      };

      appFetch(
        "POST",
        API_URL,
        "/activation/solution/create",
        data,
        token?.uuid ? token?.uuid : false,
        (result: any) => {
          if (result.status && result.status.success) {
            if (result.data.payout != 0) {
              setQuizSuccessfully(true);
            } else {
              setQuizSuccessfully(false);
            }
            setLoading(false);
          }
          setLoading(false);
        },
        () => {
          dispatch({
            type: ADD_TOAST,
            payload: { type: "error", message: errors.generic },
          });
        }
      );
    }

    if (finish) {
      fetchQuiz();
      console.log("fetch");
    }
  }, [finish, setIsFinish]);

  return (
    <PageLayout>
      <div className="Quiz-Wrapper">
        {quizSuccesfully ? (
          <ResultScreen loading={loading} />
        ) : (
          <div className="page Quiz VuseEpodStep2">
            <div className="heading">
              {width <= 1020 ? (
                <Title>
                  Warianty
                  <br /> Vuse ePod
                </Title>
              ) : (
                <Title></Title>
              )}
              <div className="card-desktop">
                <div className="cards">
                  {pairOfBatCards.map((bat: any, index: any): any => {
                    let isFlipped = false;
                    if (openedCard.includes(index)) isFlipped = true;
                    if (matched.includes(bat.id)) isFlipped = true;
                    return (
                      <div
                        className={`bat-card ${isFlipped ? "flipped" : ""} `}
                        key={index}
                        onClick={() => {
                          flipCard(index, bat.id);
                          setCurrentIndex(index);
                        }}
                      >
                        <div className="inner">
                          <div className="front" style={bat.color}>
                            {bat.name ? (
                              <span>{bat.name}</span>
                            ) : (
                              <img src={bat.image} alt="bat-name" />
                            )}
                          </div>
                          <div className="back">
                            <img src={batLogoCard} alt="bat-name" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <Quiz
              pairOfBatCards={pairOfBatCards}
              matched={matched}
              questionNumber={questionQuantity}
              openedCard={openedCard}
              flipCard={flipCard}
              setCurrentIndex={setCurrentIndex}
              setIsFinish={setIsFinish}
              finish={finish}
              fill={fill}
              setFill={setFill}
              setQuizSuccessfully={setQuizSuccessfully}
            />
          </div>
        )}
      </div>
    </PageLayout>
  );
}

export default VuseEpodEtap2;
