import React, { useState } from "react";
import "./DropDownTabs.scss";
import { ReactComponent as IconDropDown } from "./img/rank-arrow.svg";
// interface Props  {
//   children:React.ReactNode,
//   active?:boolean,
//   onClick:() => void,
// }

interface TabProps {
  name: string;
}

interface Tab {
  name: string;
}

interface DropDownProps {
  tabList: TabProps[];
  activeTab: number;
  isOpen?: boolean;
  handleChangeTab: (idx: number) => void;
}

const DropDownTabs: React.FC<DropDownProps> = ({
  tabList,
  activeTab,
  isOpen,
  handleChangeTab,
}) => {
  const [isOpenDropDown, setIsOpenDropDown] = useState(isOpen);
  const handleToggleDropDown = () => {
    setIsOpenDropDown((prevState) => !prevState);
  };

  return (
    <div className="DropDownTabs">
      <div onClick={() => handleToggleDropDown()} className="drop-down-active">
        <span className="name">{tabList[activeTab].name}</span>
        <span className={!isOpenDropDown ? "arrow" : "arrow arrow-active"}>
          <IconDropDown />
        </span>
      </div>
      <ul
        className={
          isOpenDropDown
            ? "drop-down-list drop-down-list--active"
            : "drop-down-list"
        }
      >
        {tabList.map((tab: TabProps, idx: number) => {
          return (
            <li
              key={idx}
              onClick={() => {
                handleChangeTab(idx);
                handleToggleDropDown();
              }}
              className="drop-down-list__item"
            >
              <span className="tab-name">{tab.name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default DropDownTabs;
