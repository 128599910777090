import React, { useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./VuseEpen.scss";
import Title from "../../components/Title/Title";
import mobileHero from "./img/hero.png";
import desktopHero from "./img/hero-desktop.png";
import icon01 from "./img/01.svg";
import icon02 from "./img/02.svg";
import icon03 from "./img/03.svg";
import { ReactComponent as VuseLogo } from "./img/vuse-logo.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import pack01 from "./img/pack01.png";
import pack02 from "./img/pack02.png";
import pack03 from "./img/pack03.png";
import vuseDevices from "./img/vuse-devices.png";
import vuseInfoBg from "./img/vuse-bg.png";
import vuseInfoDesktopBg from "./img/vuse-bg-desktop.png";
import vuseDevicesDesktop from "./img/vuse-devices-desktop.png";

const aromasSlider = [
  {
    title: "Dark Cherry",
    image: pack03,
  },
  {
    title: "Blended Tobacco",
    image: pack01,
  },
  {
    title: "Classic Peach",
    image: pack02,
  },
];

const featureSlider = [
  {
    title: "Pewność wyboru",
    icon: icon02,
    desc: "Marka Vuse oferuje szeroki wybór produktów oraz wysoką jakość potwierdzoną badaniami niezależnych eskspertów.",
  },
  {
    title: "Bezpieczeństwo <span>i wiarygodność</span>",
    icon: icon01,
    desc: "Urządzenia Vuse są testowane przez niezależne, certyfikowane laboratoria.",
  },
  {
    title: "Bogactwo wyboru",
    icon: icon03,
    desc: "Vuse jest liderem kategorii systemów zamkniętych oferując największy wybór aromatów w różnych stężeniach.",
  },
];

function VuseEpod() {
  const [aromaActiveIndex, setAromaActiveIndex] = useState(0);
  return (
    <PageLayout>
      <section className="VuseEpen">
        <div className="hero-section">
          <div className="hero-image">
            <picture>
              <source media="(max-width: 1019px)" srcSet={mobileHero} />
              <source media="(min-width: 1020px)" srcSet={desktopHero} />
              <img className="bg" src={desktopHero} alt="A wide image" />
            </picture>
          </div>
          <div className="hero-wrapper">
            <div className="hero-content">
              <Title>Vuse ePen</Title>
              <h2 className="sub-title">
                Jeden elektroniczny papieros. Wiele aromatów.
              </h2>
            </div>
          </div>
        </div>
        <Swiper
          pagination={{
            clickable: true,
          }}
          slidesPerView={1.2}
          spaceBetween={16}
          centeredSlides={true}
          className="vuse-swiper"
          breakpoints={{
            1020: {
              slidesPerView: 3,
              spaceBetween: 30,
              centeredSlides: false,
            },
          }}
        >
          {featureSlider.map((slide, idx) => (
            <SwiperSlide key={idx}>
              <div className="vuse-swiper-slide">
                <div className="top">
                  <img className="icon" src={slide.icon} alt="" />
                </div>
                <div className="body">
                  <h3
                    className="title"
                    dangerouslySetInnerHTML={{ __html: slide.title }}
                  />
                  <p
                    className="desc"
                    dangerouslySetInnerHTML={{ __html: slide.desc }}
                  />
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className="vuse-taste">
          <div className="content">
            <h2 className="title">Bogactwo aromatów</h2>
            <p className="desc">
              Wkłady specjalnie zaprojektowane dla Vuse ePen dostępne są w 13
              aromatach na bazie soli nikotynowych, z&nbsp;możliwością wyboru
              pomiędzy 6, 12 lub 18mg/ml stężenia nikotyny.
            </p>
          </div>

          <Swiper
            slidesPerView={1.6}
            centeredSlides={true}
            onRealIndexChange={(swiper) => {
              setAromaActiveIndex(swiper.realIndex);
            }}
            className="vuse-taste-slider"
            breakpoints={{
              500: {
                slidesPerView: 1.5,
                spaceBetween: -150,
                centeredSlides: true,
              },
              1270: {
                slidesPerView: 3,
              },
            }}
          >
            {aromasSlider.map((slide, idx) => (
              <SwiperSlide key={idx}>
                <div
                  className={idx === aromaActiveIndex ? "card active" : "card"}
                >
                  <div className="top">
                    <img className="bg" src={slide.image} alt="" />
                  </div>
                  <div className="body">
                    <h3 className="title">{slide.title}</h3>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="vuse-device">
          <div className="content">
            <h2>Vuse ePen dawniej Vype ePen3</h2>
            <p>
              Nowa nazwa i design,
              <span>to samo doświadczenie z e-palenia.</span>
            </p>
          </div>
          <picture>
            <source media="(max-width: 1019px)" srcSet={vuseDevices} />
            <source media="(min-width: 1020px)" srcSet={vuseDevicesDesktop} />
            <img
              className="vuse-bg"
              src={vuseDevicesDesktop}
              alt="vuse devices"
            />
          </picture>

          <Swiper
            slidesPerView={1.2}
            centeredSlides={true}
            spaceBetween={10}
            className="cards"
            breakpoints={{
              1020: {
                slidesPerView: 3.5,
                spaceBetween: 40,
                centeredSlides: false,
              },
              1270: {
                slidesPerView: 4,
                spaceBetween: 40,
                centeredSlides: false,
              },
            }}
          >
            <SwiperSlide>
              <div className="card">
                <div className="top">
                  <VuseLogo />
                </div>
                <div className="body">
                  <h3 className="title">
                    Vuse ePen wyróżnia ergonomiczna konstrukcja oraz bateria o
                    pojemności 650 mAh – dla całodziennego użytkowania*
                  </h3>
                  <p className="desc small-text">
                    *Na podstawie testów laboratoryjnych Vype. Żywotność baterii
                    może się różnić w zależności od użytkowania i innych
                    czynników.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <div className="top">
                  <VuseLogo />
                </div>
                <div className="body">
                  <h3 className="title">
                    Vuse ePen to e-papieros działający w prostym systemie
                    zamkniętym
                  </h3>
                  <p className="desc">
                    Zatrzaskowy system wymiany wkładów - bez rozlewania.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <div className="top">
                  <VuseLogo />
                </div>
                <div className="body">
                  <h3 className="title">Testowany 100 razy</h3>
                  <p className="desc">pod względem jakości</p>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="card">
                <div className="top">
                  <VuseLogo />
                </div>
                <div className="body">
                  <h3 className="title">
                    Do 99% mniej substancji toksycznych*
                  </h3>
                  <p className="desc small-text">
                    *W&nbsp;porównaniu do tradycyjnie palonych papierosów. Nie
                    oznacza to, że produkt ten jest mniej szkodliwy niż inne
                    wyroby tytoniowe. Potwierdzone badaniami z wyłączeniem
                    wkładów z aromatem: Crisp Mint 6mg/ml; Dark Cherry, Fresh
                    Spearmint, Just Mint, Peppermint Tobacco, Creamy Mint
                    12mg/ml & 18mg/ml.
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="vuse-info">
          <h3 className="vuse-info-title">
            Poznaj 4 filary zaufania marce Vuse
          </h3>
          <div className="image-wrapper">
            <picture>
              <source media="(max-width: 719px)" srcSet={vuseInfoBg} />
              <source media="(min-width: 720px)" srcSet={vuseInfoDesktopBg} />
              <img className="bg-info" src={vuseInfoDesktopBg} alt="" />
            </picture>

            <Swiper
              slidesPerView={1.2}
              centeredSlides={true}
              spaceBetween={15}
              className="cards"
              breakpoints={{
                1020: {
                  slidesPerView: 3.5,
                  spaceBetween: 40,
                  centeredSlides: false,
                },
                1270: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                  centeredSlides: false,
                },
              }}
            >
              <SwiperSlide>
                <div className="card">
                  <div className="top">
                    <h3 className="title">E-palenie vs Palenie</h3>
                  </div>
                  <div className="body">
                    <p className="desc">
                      Vuse zawiera o 99% mniej substancji toksycznych niż
                      papierosy. E-palenie oferuje mniejsze ryzyko i alternatywę
                      dla palenia. W tym dla osób, które nie są w stanie lub nie
                      chcą rzucić palenia. Mniejsze ryzyko e-papierosów
                      (w&nbsp;porównaniu z&nbsp;tradycyjnymi papierosami) wynika
                      w dużej mierze z&nbsp;faktu, że nie wiążą się one ze
                      spalaniem tytoniu. Alternatywy dla papierosów, które nie
                      są spalane, będą emitować potencjalnie znacznie niższe
                      poziomy substancji toksycznych.
                    </p>
                  </div>
                  <div className="footer">
                    Kluczowa różnica między e-paleniem a tradycyjnymi
                    papierosami?
                    <span className="disclaimer">Brak tytoniu</span>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card">
                  <div className="top">
                    <h3 className="title">Jakość</h3>
                  </div>
                  <div className="body">
                    <p className="desc">
                      Posiadamy certyfikat ISO 9001 Dla wielu ta liczba
                      prawdopodobnie może nic nie znaczyć, ale dla Vuse znaczy
                      wiele. Jest to międzynarodowo uznany standard zarządzania
                      jakością (system wykorzystywany w 175 krajach).
                    </p>
                  </div>
                  <div className="footer">
                    Dlaczego warto wybierać wkłady Vuse?
                  </div>
                  <p className="desc">
                    Wszystkie składniki użyte w naszych e-liquidach spełniają
                    określone wymagania jakościowe, a także przechowywanie są w
                    opakowaniach zabezpieczających przed
                    naruszeniem&nbsp;produktu.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card">
                  <div className="top">
                    <h3 className="title">Nauka</h3>
                  </div>
                  <div className="body">
                    <p className="desc">
                      Jesteśmy transparentni: publikujemy wyniki naszych badań
                      naukowych (www.bat-science.com).
                    </p>
                  </div>
                  <div className="footer">
                    Czy jakość produktów jest potwierdzona badaniami?
                  </div>
                  <p className="desc">
                    50 naszych naukowców pracuje przez 1000 godzin zanim
                    produkty trafią do konsumentów. Wobec wszystkich naszych
                    urządzeń i e-liquidów Vuse, stosujemy rygorystyczne
                    standardy jakości i bezpieczeństwa produktów. Naukowcy,
                    którzy testują i&nbsp;opracowują produkty Vuse, specjalizują
                    się w różnych dziedzinach – od toksykologii, przez
                    projektowanie i produkcję urządzeń, po nauki biologiczne,
                    badania i analizy.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="card">
                  <div className="top">
                    <h3 className="title">Społeczny dowód</h3>
                  </div>
                  <div className="body">
                    <p className="desc">
                      Vuse to wiodąca marka e-papierosów na świecie
                    </p>
                  </div>
                  <div className="footer">Gdzie znajdziesz produkty Vuse?</div>
                  <p className="desc">
                    Produkty Vuse są obecnie dostępne na 27 rynkach na całym
                    świecie oraz w wielu punktach w całej Polsce od Gdańska
                    przez Poznań i Warszawę aż po Kraków.
                  </p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
    </PageLayout>
  );
}

export default VuseEpod;
