import React from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import Title from "../../components/Title/Title";
import "./Contact.scss";
import mobileHero from "./img/hero-mobile.jpg";
import desktopHero from "./img/hero-desktop.jpg";
import { ReactComponent as TelIcon } from "./img/tel.svg";
import { ReactComponent as MailIcon } from "./img/mail.svg";
function Kontakt() {
  return (
    <PageLayout>
      <div className="page Contact">
        <Title>Kontakt</Title>

        <div className="contact-box">
          <div className="hero-image">
            <picture>
              <source media="(max-width: 1019px)" srcSet={mobileHero} />
              <source media="(min-width: 1020px)" srcSet={desktopHero} />
              <img className="bg" src={desktopHero} alt="A wide image" />
            </picture>
          </div>

          <div className="info">
            <p className="contact-desc">
              W przypadku pytań, wyślij nam wiadomość na adres{" "}
              <a href="mailto:kontakt@batpartners.pl" className="contact-link">
                kontakt@batpartners.pl
              </a>
              – pomożemy!
            </p>
            <div className="info-wrapper">
              <div className="info-box">
                <div className="info-icon">
                  <TelIcon />
                </div>
                <div className="info-box-body">
                  <span className="desc">Infolinia</span>
                  <span className="tel">
                    <a className="contact-link" href="tel:800610610">
                      800 610 610*
                    </a>
                  </span>
                </div>
              </div>
              <div className="info-box mail">
                <div className="info-icon">
                  <MailIcon />
                </div>
                <div className="info-box-body">
                  <span className="desc">
                    Zgłoszenia dotyczące danych osobowych
                  </span>
                  <span className="tel">
                    <a
                      href="mailto:daneosobowe@bat.com.pl"
                      className="contact-link"
                    >
                      daneosobowe@bat.com.pl
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <p className="info-disclaimer">
              *Bezpłatna infolinia jest czynna od poniedziałku do niedzieli w
              godzinach 8:00 – 22:00.
            </p>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Kontakt;
