import React, { useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./VuseGo.scss";
import Title from "../../components/Title/Title";
import mobileHero from "./img/hero-mobile.jpg";
import desktopHero from "./img/hero.jpg";
import Icon01 from "./img/img1.svg";
import Icon02 from "./img/img2.svg";
import Icon03 from "./img/img3.svg";
import Icon04 from "./img/img4.svg";
import Icon05 from "./img/img5.svg";
import PRODUCTS from "./img/products.png";
import APPLE from "./img/apple.png";
import BERRYBLEND from "./img/berryblend.png";
import PEPPERMINT from "./img/peppermint.png";
import GRAPE from "./img/grape.png";
import WATERMELON from "./img/watermelon.png";
import BLUEBERRY from "./img/blueberry.png";
import MANGO from "./img/mango.png";
import BERRYMELON from "./img/berrymelon.png";
import TOBACCO from "./img/tobacco.png";
import STRAWBERRY from "./img/strawberry.png";
import RASPBERRY from "./img/raspberry.png";
import PASSIONFRUIT from "./img/passionfruit.png";
import { ReactComponent as IconBack } from "./img/click-icon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { useWindowDimensions } from "../../hooks";
import { Link } from "react-router-dom";
import {Navigation} from 'swiper';
import ArrowButton from "../../components/ArrowButton/ArrowButton";
import Badge from "../../components/Badge/Badge";

function VuseGo() {
  const { width } = useWindowDimensions();

  const flavors = [
    {
      name: "Apple Sour",
      image: APPLE,
      color: "#75954C",
      text: "Aromat zielonego jabłka z chłodnymi nutami",
      nico: "20 mg/ml, 0 mg/ml"
    },
    {
      name: "Peppermint Ice",
      image: PEPPERMINT,
      color: "#4D979C",
      text: "Miętowy aromat z chłodną nutą",
      nico: "20 mg/ml"
    },
    {
      name: "Berry Blend",
      image: BERRYBLEND,
      color: "#BE6CA2",
      text: "Mieszanka aromatów truskawek, malin, jeżyn i jagód",
      nico: "20 mg/ml"
    },
    {
      name: "Grape Ice",
      image: GRAPE,
      color: "#7E10A6",
      text: "Aromat czarnych winogron",
      nico: "20 mg/ml"
    },
    {
      name: "Watermelon Ice",
      image: WATERMELON,
      color: "#F9584E",
      text: "Mieszanka aromatów arbuza z chłodnymi nutami",
      nico: "20 mg/ml"
    },
    {
      name: "Blueberry Ice",
      image: BLUEBERRY,
      color: "#476AB8",
      text: "Aromat jagód z chłodnymi nutami",
      nico: "20 mg/ml, 0 mg/ml"
    },
    {
      name: "Mango Ice",
      image: MANGO,
      color: "#BA9735",
      text: "Aromat mango",
      nico: "20 mg/ml"
    },
    {
      name: "Berry Watermelon",
      image: BERRYMELON,
      color: "#D7352B",
      text: "Mieszanka aromatów czerwonych jagód i arbuza z chłodnymi nutami",
      nico: "20 mg/ml, 10 mg/ml, 0 mg/ml"
    },
    {
      name: "Creamy Tobacco",
      image: TOBACCO,
      color: "#855835",
      text: "Mieszanka aromatu tytoniu z nutami karmelu i wanilii",
      nico: "20 mg/ml"
    },
    {
      name: "Strawberry Ice",
      image: STRAWBERRY,
      color: "#B83238",
      text: "Aromat truskawki",
      nico: "20 mg/ml"
    },
    {
      name: "Blue Raspberry",
      image: RASPBERRY,
      color: "#3B5899",
      text: "Aromat maliny z chłodnymi nutami",
      nico: "20 mg/ml"
    },
    {
      name: "Passionfruit Ice",
      image: PASSIONFRUIT,
      color: "#DA77AC",
      text: "Aromat marakui z lodową nutą",
      nico: "20 mg/ml"
    },
    {
      name: "Strawberry Kiwi",
      image: STRAWBERRY,
      color: "#D7352B",
      text: "Aromat truskawki z nutami kiwi",
      nico: "20 mg/ml"
    },
  ]

  return (
    <PageLayout>
      <section className="VuseGo">
        <div className="hero-section">
          <Badge category="Papierosy jednorazowe"/>
          <div className="hero-image">
            <picture>
              <source media="(max-width: 1019px)" srcSet={mobileHero} />
              <source media="(min-width: 1020px)" srcSet={desktopHero} />
              <img className="bg" src={desktopHero} alt="Vuse Go" />
            </picture>
          </div>
          <div className="wrapper">
            <div className="hero-content">
              <Title></Title>
              <h1 className="page-title">VUSE GO</h1>
              <p className="subtext"><strong>to nowy jednorazowy e-papieros:</strong></p>
              <p className="subtext">
                <ul>
                  <li>dostępny w różnorodnych intensywnych aromatach</li>
                  <li>posiada smukłą konstrukcję i premium design</li>
                  <li>jest łatwy w użyciu i nie wymaga ładownia</li>
                  <li>stworzony przez Vuse - wiodącą markę wśród e-papierosów na świecie</li>
                </ul>
              </p>
              <img className="products-image" src={PRODUCTS}/>
            </div>
          </div>
        </div>

        <div className="vuse-cards">
          <h2 className="color-white">Czym wyróżnia się Vuse GO?</h2>
          <Swiper
            slidesPerView={1.2}
            spaceBetween={16}
            centeredSlides={true}
            className="recommend-swiper"
            breakpoints={{
              1020: {
                slidesPerView: 5,
                spaceBetween: 16,
                centeredSlides: false,
              }
            }}
            navigation={{
              nextEl: ".vuse-btn-next",
              prevEl: ".vuse-btn-prev",
            }}
            modules={[Navigation]}
          >
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="top">
                  <img src={Icon01}/>
                </div>
                <div className="body">
                  <h3 className="title">Prosty w użyciu</h3>
                  <p className="desc">Brak przycisków i elementów wymiennych. Uruchamiany poprzez zaciągnięcie.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="top">
                <img src={Icon04}/>
                </div>
                <div className="body">
                  <h3 className="title">Smukła i lekka konstrukcja ze stali nierdzewnej</h3>
                  <p className="desc">Najsmuklejsze urządzenie tylko 12.5 mm grubości</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="top">
                <img src={Icon03}/>
                </div>
                <div className="body">
                  <h3 className="title">Bez ładowania i napełniania</h3>
                  <p className="desc">Wbudowana bateria o pojemności 370&nbsp;mAh oraz 2&nbsp;ml liquidu wystarcza aż do 500 zaciągnięć*.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="top">
                <img src={Icon05}/>
                </div>
                <div className="body">
                  <h3 className="title">Szeroka gama aromatów</h3>
                  <p className="desc">Vuse Go dostępny jest w 13 różnych wariantach (tytoniowe, owocowe oraz mentolowe) i stężeniach nikotyny (20 mg/ml, 10 mg/ml oraz 0 mg/ml)</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="top">
                <img src={Icon02}/>
                </div>
                <div className="body">
                  <h3 className="title">Szczelny ustnik</h3>
                  <p className="desc">Bez wyciekania płynu.</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="control-btns control-btns-vuse">
            <div className="vuse-btn-prev">
              <ArrowButton color="white" />
            </div>
            <div className="vuse-btn-next">
              <ArrowButton color="white" />
            </div>
          </div>
          <p className="info">*Na podstawie badań laboratoryjnych nowo wyprodukowanego produktu. <br/>Liczba zaciągnięć może różnić się w zależności od sposobu użytkowania.</p>
        </div>
        <div className="vuse-flavors">
          <h2 className="color-white">Poznaj aromaty Vuse GO</h2>
          <Swiper
            slidesPerView={1.2}
            spaceBetween={16}
            centeredSlides={true}
            className="flavor-swiper"
            breakpoints={{
              1020: {
                slidesPerView: 2,
                spaceBetween: 16,
                centeredSlides: false,
              },
            }}
            navigation={{
              nextEl: ".flavor-btn-next",
              prevEl: ".flavor-btn-prev",
            }}
            modules={[Navigation]}
          >
            {flavors.map((item)=>
              <SwiperSlide>
                <div className="flavor-swiper-slide">
                  <div className="top">
                    <img src={item.image}/>
                  </div>
                  <div className="body" style={{backgroundColor: item.color}}>
                    <h3 className="title">{item.name}</h3>
                    <p className="desc">{item.text}</p>
                    <p className="nicotine"><strong>Stężenia nikotyny:</strong><br/>{item.nico}</p>
                  </div>
                </div>
              </SwiperSlide>
            )}
          </Swiper>
          <div className="control-btns">
            <div className="flavor-btn-prev">
              <ArrowButton color="white" />
            </div>
            <div className="flavor-btn-next">
              <ArrowButton color="white" />
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
}

export default VuseGo;
