import React, { useState, useRef, useEffect } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./Activities.scss";
import Title from "../../components/Title/Title";
import BackgroundImage01 from "./img/01.jpg";
import BackgroundImage02 from "./img/02.jpg";
import ActivitiesListItem from "../../components/ActivitiesListItem/ActivitiesListItem";
import { Swiper, SwiperSlide } from "swiper/react";
import ArrowButton from "../../components/ArrowButton/ArrowButton";

// import required modules
import { Pagination, Navigation } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { listActivations } from "../../store/actions/activations";
import { getActivations } from "../../store/reducers/activations";
import { getIncomeHistory } from "../../store/reducers/account";
import { useAppDispatch } from "../../hooks";

function Activities() {
  const dispatch = useAppDispatch();
  const activations = useSelector(getActivations);
  const incomeHistory = useSelector(getIncomeHistory);

  useEffect(()=>{
    dispatch(listActivations())
  }, [])

  return (
    <PageLayout>
      <section className=" Activities">
        <div className="activities-wrapper">
          <Title>Aktywności</Title>
          <div className="activities-static mobile">
            {activations.map((activity: any) => {
              let completed = false;
              if (activity.ident === 'uzupelnij_profil') {
                if (incomeHistory.find((item: any)=>item?.solution?.activation?.ident === 'fill_profile')) {
                  completed = true;
                }
              }
              return <ActivitiesListItem key={activity?.content?.title} activity={activity} rewriteCompleted={ completed } />
            })}
          </div>
        </div>
        <div className="activities-wrapper">
          <div className="activities-static desktop">
          { activations.length > 0  &&<Swiper
              slidesPerView={3}
              spaceBetween={20}
              pagination={{
                clickable: true,
              }}
              navigation={{
                nextEl: ".swiper-cta-next",
                prevEl: ".swiper-cta-prev",
              }}
              modules={[Navigation, Pagination]}
              className="activities-slider"
              loop={false}
            >
              {activations.map((activity: any, index: number) => {
                let completed = false;
                if (activity.ident === 'uzupelnij_profil') {
                  if (incomeHistory.find((item: any)=>item?.solution?.activation?.ident === 'fill_profile')) {
                    completed = true;
                  }
                }
                return (
                  <SwiperSlide key={'activity'+index}>
                    <ActivitiesListItem activity={activity} rewriteCompleted={ completed } />
                  </SwiperSlide>
                )
              })}
            </Swiper> }
           {activations.length > 3 ? (
            <>
             <div className="swiper-cta swiper-cta-next">
              <ArrowButton color="blue" />
            </div>
            <div className="swiper-cta swiper-cta-prev">
              <ArrowButton color="blue" />
            </div></>
           ) : null}
          </div>
        </div> 
      </section>
    </PageLayout>
  );
}

export default Activities;
