import React from "react";
import ReactDOM from 'react-dom';
import Countdown from 'react-countdown';
import "./CountdownModule.scss";
import Button from "../Button/Button";
import moment from "moment";
import { getTimer } from "../../store/reducers/user";
import { useAppSelector } from "../../hooks";

const CountdownModule = ({type}) => {
  const countdownTimer = useAppSelector(getTimer);
  const cdate = new Date(countdownTimer.endDate.date.replace(' ', 'T'));
  const odmiana = (value, numerals, wovalue) => {
    var t0 = value % 10,
        t1 = value % 100,
        vo = [];
    if (wovalue !== true)
        vo.push(value);
    if (value === 1 && numerals[1])
        vo.push(numerals[1]);
    else if ((value == 0 || (t0 >= 0 && t0 <= 1) || (t0 >= 5 && t0 <= 9) || (t1 > 10 && t1 < 20)) && numerals[0])
        vo.push(numerals[0]);
    else if (((t1 < 10 || t1 > 20) && t0 >= 2 && t0 <= 4) && numerals[2])
        vo.push(numerals[2]);
    return vo.join(' ');
};

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return (
        <div className="countdown-time">
          <div className="time-part"><span>{days}</span><span className="part-name">{odmiana(days, ["Dni", "Dzień", "Dni"], true)}</span></div>
          <div className="time-part"><span>{hours}</span><span className="part-name">{odmiana(hours, ["Godzin", "Godzina", "Godziny"], true)}</span></div>
          <div className="time-part"><span>{minutes}</span><span className="part-name">{odmiana(minutes, ["Minut", "Minuta", "Minuty"], true)}</span></div>
          <div className="time-part"><span>{seconds}</span><span className="part-name">{odmiana(seconds, ["Sekund", "Sekunda", "Sekundy"], true)}</span></div>
        </div>
      );
    } else {
      // Render a countdown
      return (
        <div className="countdown-time">
          <div className="time-part"><span>{days}</span><span className="part-name">{odmiana(days, ["Dni", "Dzień", "Dni"], true)}</span></div>
          <div className="time-part"><span>{hours}</span><span className="part-name">{odmiana(hours, ["Godzin", "Godzina", "Godziny"], true)}</span></div>
          <div className="time-part"><span>{minutes}</span><span className="part-name">{odmiana(minutes, ["Minut", "Minuta", "Minuty"], true)}</span></div>
          <div className="time-part"><span>{seconds}</span><span className="part-name">{odmiana(seconds, ["Sekund", "Sekunda", "Sekundy"], true)}</span></div>
        </div>
      );
    }
  }

  return (
    <div className={`CountdownModule ${type}`} style={{backgroundImage: "url("+countdownTimer.content.modules[1].file.url+")"}}>
      <span className="countdown-title">{ countdownTimer.content.title }</span>
      <Countdown
        date={cdate}
        renderer={renderer}
      />
      {/*<Button color="white" path="/news/bonusowa-gra-z-nagrodami" >Sprawdź teraz</Button>*/}
    </div>
  )
};

export default CountdownModule;
