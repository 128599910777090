import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import './index.scss';
import App from './App';
import { store } from './app/store/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

const container = document.getElementById('root')!;
const root = createRoot(container);

const savePrompt = () => {
  window.addEventListener('beforeinstallprompt', (e) => {
    e.preventDefault();
    //@ts-ignore
    window.deferredPrompt = e;
  });
}

savePrompt();

root.render(
    <Provider store={store}>
      <App />
    </Provider>
);

serviceWorker.register();
