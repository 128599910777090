import React, { useEffect, useState } from "react";
import Bg from "./img/uzupelnij-profil.jpg";
import { useWindowDimensions } from "../../../hooks";
import { ReactComponent as Calendar } from "../ActivitiesDetails/img/calendar.svg";
import { ReactComponent as Play } from "./img/play.svg";
import "../ActivitiesDetails/ActivitiesDetails.scss";
import { useOnScreen } from "../../../hooks";
import Title from "../../../components/Title/Title";
import { API_URL, appFetch } from "../../../helpers/fetch";
import { useSelector } from "react-redux";
import { getToken } from "../../../store/reducers/user";
import { ReactComponent as OpenIcon } from "./img/open.svg";
import { ReactComponent as CloseIcon } from "./img/close.svg";
import moment from "moment";
import { ReactComponent as Gift } from "../ActivitiesDetails/img/gift.svg";
import { Link, useLocation, useParams } from "react-router-dom";
import Button from "../../../components/Button/Button";
import PageLayout from "../../../components/PageLayout/PageLayout";
import { getActivations } from "../../../store/reducers/activations";

const Activity = ({ activity }: any) => {
  const [ref, visible] = useOnScreen({ rootMargin: "-60px" });
  const [showToolTip, setShowToolTip] = useState(false);
  const { width } = useWindowDimensions();

  const handleToggleTooltip = () => {
    setShowToolTip((prevState) => !prevState);
  };

  return (
    <>
      <div className="heading">
        {width <= 1020 ? <Title>{ activity?.content?.title}</Title> : <Title></Title>}
        <div className="prize">
          <div className="value">
            { activity?.payout } zł{" "}
            <span className="icon">
              <Gift />
            </span>
          </div>
        </div>
        <div className="calendar">
          <span className="date">
            <Calendar /> {moment(activity?.publicationDate?.date).format('DD-MM-YYYY')}
          </span>
          <span className="line"></span>
        </div>
      </div>
      <div ref={ref}></div>
      <div className={visible ? "activity-item" : "activity-item fixed"}>
        <div className="activity-image">
          <img src={Bg} alt="" />
        </div>
        <div className="activity-body mobile">
          <h2>Poznajmy się lepiej!</h2>
          <p>Uzupełnij wszystkie pola w swoim profilu, aby móc wypłacać nagrody. Dane rozliczeniowe są niezbędne do prawidłowego wystawienia PIT-11 (albo, w przypadku prowadzenia jednoosobowej działalności gospodarczej, stanowią potwierdzenie, że wszystkie uzyskane nagrody zostaną rozliczone jako przychód z działalności gospodarczej).</p>
          <Button className="cta" path={'/profil'}>Uzupełnij dane</Button>
        </div>
      </div>

      <div className="desktop-activities">
      <div className="heading">
         <h2 className="title">{ activity?.content?.title}</h2>
          <div className="prize">
            <div className="value">
            { activity?.payout } zł{" "}
              <span className="icon">
                <Gift />
              </span>
            </div>
          </div>
          <div className="calendar">
            <span className="date">
              <Calendar /> {moment(activity?.publicationDate?.date).format('DD-MM-YYYY')}
            </span>
            <span className="line"></span>
          </div>
        </div>

        <div className="activity-body">
          <h2>Poznajmy się lepiej!</h2>
          <p>Uzupełnij wszystkie pola w swoim profilu, aby móc wypłacać nagrody. Dane rozliczeniowe są niezbędne do prawidłowego wystawienia PIT-11 (albo, w przypadku prowadzenia jednoosobowej działalności gospodarczej, stanowią potwierdzenie, że wszystkie uzyskane nagrody zostaną rozliczone jako przychód z działalności gospodarczej).</p>
          <Button className="cta" path={'/profil'}>Uzupełnij dane</Button>
        </div>
      </div>
    </>
  );
};

function ActivitiesDetails() {
  const token = useSelector(getToken);
  const { slug } = useParams();
  const activityItem = useSelector(getActivations).find((item: any)=>item.ident === 'uzupelnij_profil')

  return (
    <PageLayout>
      <div className="page ActivitiesDetails">
        { activityItem && <Activity activity={activityItem} /> }
      </div>
    </PageLayout>
  );
}

export default ActivitiesDetails;
