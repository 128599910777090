import React from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import Button from "../../components/Button/Button";
import "./TechBreak.scss";
import { ReactComponent as Icon } from "./img/icon.svg";

function TechBreak() {
  return (
    <PageLayout clsName="logout">
      <div className="TechBreak">
       <div className="content">
        <div className="icon">
          <Icon/>
        </div>
       <h2 className="title">
        Przerwa techniczna
       </h2>
       <p className="desc">
        W związku z koniecznymi pracami technicznymi, strona BAT Partners jest chwilowo niedostępna.<br/><br/>
        <strong>Do zobaczenia jutro, 2 stycznia.</strong><br/>
        Przepraszamy za utrudnienia.
        </p>
      </div>
      </div>
    </PageLayout>
  );
}

export default TechBreak;
