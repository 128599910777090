import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm, FieldValues, FieldErrors } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import Button from '../../components/Button/Button';
import PageLayout from '../../components/PageLayout/PageLayout';
import { registerGlo, otpGlo } from '../../store/actions/user';
import { getRegisterGloState, getRegisterState } from '../../store/reducers/user';
import '../../theme/forms.scss';
import './RegisterGlo.scss';
import { useAppDispatch } from '../../hooks';
import ButtonBack from '../../components/Button/ButtonBack/ButtonBack';
import { ReactComponent as IconBack } from '../../components/Button/ButtonBack/img/icon-back.svg';
import AgeGate from './AgeGate';
import Countdown, {zeroPad} from 'react-countdown';

interface registerProps {
  firstName: string;
  lastName: string;
  salesPoint: string;
}

function RegisterGlo() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const registerGloSuccess = useSelector(getRegisterGloState);
  const { register, setValue, handleSubmit, getValues, formState: { errors } } = useForm();
  const { register: register2, setValue: setValue2, handleSubmit: handleSubmit2, getValues: getValues2, formState: { errors: errors2 } } = useForm();
  const [formStep, setFormStep] = useState(0);
  const [showPhoneInfo, setShowPhoneInfo] = useState(false);
  const [showNumberInfo, setShowNumberInfo] = useState(false);
  const [age, setAge] = useState(false)
  const [otpButton, setOtpButton] = useState(false)
  const [timer, setTimer] = useState(Date.now() + 300000)

  const onSubmit = ({mobilePhone}: FieldValues) => {
    setFormStep(1);
    sendOtp()
  }

  const onError = (errors: FieldErrors) => {
    console.log(errors)
  }
  const onError2 = (errors: FieldErrors) => {
    console.log(errors)
  }

  const onSubmit2 = () => {
    let phone = getValues().mobilePhone;
    if ( getValues().mobilePhone.indexOf('+48') === -1 ) {
      phone = '+48'+getValues().mobilePhone
    }

    dispatch(registerGlo(
      {
        "consumer": {
            "mobilePhone": phone,
            "otp": getValues2().otp,
            "termsConsent": true
        },
        "device": {
            "serialNumber": getValues().deviceNumber
        }
    }))
  }

  const sendOtp = () => {
    setOtpButton(false)
    setTimer(Date.now() + 120000)
    let phone = getValues().mobilePhone;
    if ( getValues().mobilePhone.indexOf('+48') === -1 ) {
      phone = '+48'+getValues().mobilePhone
    }
    dispatch(otpGlo(
      {
        "consumer": {
            "mobilePhone": phone,
            "termsConsent": true
        }
    }))
  }

  const validatePhone = (value: string) => {
    if (value.length >=9 && value.length <= 12) {
      return true
    } 
    return false;
  }

  const renderer = ({ minutes, seconds, completed } :any) => {
    if (completed) {
      setOtpButton(true)
      return <p className="timer disabled">00:00</p>;
    } else {
      return <p className="timer">{zeroPad(minutes)}:{zeroPad(seconds)}</p>;
    }
  };

  return (
    <PageLayout>
      <div className="page RegisterGlo">
        { formStep === 1 && 
          <div className="ButtonBack" onClick={ ()=>setFormStep(0)}>
            <IconBack />
          </div>  
        }
        <div className="bg-image"></div>
        <form className="dropshadow-box">
          <div className={formStep === 0 && !registerGloSuccess ? "form-step active": "form-step"}>
            <h1>Zarejestruj glo i&nbsp;odbierz kod na ofertę 1+1&nbsp;NEO™</h1>
            <p className="text-center">Wpisz swój telefon i numer urządzenia glo™, aby otrzymać kupon na <strong>2 paczki neo™ w&nbsp;cenie jednej</strong></p>
            <div className="input-item input-info phone-input">
              <label htmlFor="mobilePhone">Numer telefonu</label>
              <div className={showPhoneInfo ? "info-btn active" : "info-btn"} onClick={()=>setShowPhoneInfo(!showPhoneInfo)}></div>
              <div className={showPhoneInfo ? "info active" : "info"} onClick={()=>setShowPhoneInfo(false)}>
                Numer telefonu jest potrzebny do odbioru kodu promocyjnego.
              </div>
              <input placeholder='601602603' className={ errors.mobilePhone && 'error-input' } type="text" id="mobilePhone" {...register("mobilePhone", {required: true, pattern: /^\d{9}$/})} ></input>
              {errors.mobilePhone && errors.mobilePhone.type === "pattern" && (
                <p className="error">Nieprawidłowy numer telefonu</p>
              )}
              {errors.mobilePhone && errors.mobilePhone.type === "required" && (
                <p className="error">Wpisz numer telefonu</p>
              )}
            </div>
            <div className="input-item input-info">
              <label htmlFor="deviceNumber">Numer urządzenia</label>
              <div className={showNumberInfo ? "info-btn active" : "info-btn"} onClick={()=>setShowNumberInfo(!showNumberInfo)}></div>
              <div className={showNumberInfo ? "info active" : "info"} onClick={()=>setShowNumberInfo(false)}>
                Numer urządzenia jest umieszczony z tyłu opakowania, oznaczony jako my glo™ number.
              </div>
              <input placeholder='np. S/N123436' className={ errors.deviceNumber && 'error-input' } type="text" id="deviceNumber" {...register("deviceNumber", {pattern:/^[\sa-zA-Z0-9\/]*$/})} ></input>
              {errors.deviceNumber && errors.deviceNumber.type === "pattern" && (
                <p className="error">Nieprawidłowy numer urządzenia</p>
              )}
            </div>
            <div className="checkbox-item">
              <input type="checkbox" id="consentAgreement" {...register("consentAgreement", { required: true })}  />
              <label htmlFor="consentAgreement">Oświadczam, że znam i akceptuję <a href="/pdf/regulamin_niepublicznej_akcji_wklady_neo_w_ofercie_2_za_1.pdf" target="_blank">Regulamin Akcji</a> i <a href="https://www.discoverglo.com/pl/pl/polityka-prywatnosci" target="_blank">Politykę Prywatności</a>*</label>
              {errors2.consentAgreement && errors2.consentAgreement.type === "required" && (
                <p className="error">Zgoda wymagana</p>
              )}
            </div>
            <div className="checkbox-item">
              <input type="checkbox" id="ageVerified" {...register("ageVerified", { required: true })}  />
              <label htmlFor="ageVerified">Oświadczam, że jestem pełnoletnim użytkownikiem wyrobów tytoniowych i/lub wyrobów powiązanych.*</label>
              {errors2.ageVerified && errors2.ageVerified.type === "required" && (
                <p className="error">Zgoda wymagana</p>
              )}
            </div>
            <p className="text-11">
              * Zgoda wymagana
            </p>
            <div className="checkbox-item">
              <input type="checkbox" id="marketingConsent" {...register("marketingConsent")}  />
              <label htmlFor="marketingConsent">Wyrażam zgodę na przetwarzanie podanych przeze mnie danych osobowych przez British American Tobacco Polska Trading sp. z o.o. z siedzibą w Warszawie oraz CHIC spółkę z ograniczoną odpowiedzialnością z siedzibą w Ostrzeszowie jako współadministratorów, w celu prowadzenia przez te podmioty niepublicznych działań o charakterze marketingu bezpośredniego, działania te będą obejmowały jednorazową informację o niepublicznej akcji marketingowej „Bat Partners Konsument Test”. za pośrednictwem środków komunikacji elektronicznej, tj.: wiadomości tekstowej SMS.</label>
            </div>
            <p className="text-11">Pamiętaj, że zawsze możesz wycofać powyższe zgody na przetwarzanie Twoich danych osobowych. Wycofanie zgody nie będzie miało jednak wpływu na zgodność z prawem naszych działań podjętych przed jej wycofaniem. Aby wycofać swoją zgodę skontaktuj się z nami pod adresem: <a href="mailto:daneosobowe@bat.com.pl">daneosobowe@bat.com.pl</a>. Masz także prawo do uzyskania dostępu do treści Twoich danych, prawo ich poprawiania, prawo do ich przenoszenia, prawo do żądania ich usunięcia oraz prawo ograniczenia ich przetwarzania. Jeśli uznasz, że przetwarzamy Twoje dane osobowe niezgodnie z prawem, możesz wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych. Więcej informacji w <a href="https://www.discoverglo.com/pl/pl/polityka-prywatnosci" target="_blank">Polityce Prywatności</a>.</p>
            <Button onClick={ handleSubmit(onSubmit, onError) }>Zarejestruj</Button>
          </div>

          <div className={formStep === 1 && !registerGloSuccess ? "form-step active": "form-step"}>
            <h1>Potwierdź swój numer telefonu</h1>
            <p className="text-center">{`Na numer +48 ${getValues().mobilePhone}`} wysłaliśmy SMS z&nbsp;kodem.<br/>Wpisz go tutaj, aby odebrać kupon.</p>
            <div className="input-item">
              <label htmlFor="otp">Kod SMS</label>
              <input placeholder='Podaj kod' className={ errors2.otp && 'error-input' } type="text" id="otp" {...register2("otp", {pattern:/^[a-z0-9]{4}$/})} ></input>
              {errors2.otp && errors2.otp.type === "pattern" && (
                <p className="error">Nieprawidłowy kod</p>
              )}
            </div>
            <div className="margin-20">
              <p>Nie dostałeś sms?</p>
              <div className="renew-wrap">
                <p>{ otpButton ? 
                    <div className="renew-otp" onClick={()=> sendOtp()}>Wyślij kod ponownie</div> :
                    <div className="renew-otp disabled">Wyślij kod ponownie</div> }
                </p>
                  <Countdown
                    date={timer}
                    key={timer}
                    zeroPadTime={2}
                    renderer={renderer}
                  />
              </div>
            </div>
            <Button onClick={ handleSubmit2(onSubmit2, onError2) }>Odbierz kupon</Button>
          </div>

          <div className={registerGloSuccess ? "form-step active": "form-step"}>
            <h1>Super!</h1>
            <p className="text-center">Twoje urządzenie glo™ zostało zarejestrowane, <strong>a Twój kod na 2&nbsp;paczki&nbsp;neo™ w&nbsp;cenie jednej jest w drodze na telefon.</strong></p>
            <p className="text-center">Kod możesz wykorzystać w sklepie, w którym kupiłeś(-aś) swoje glo™ o numerze:</p>
            <p className="text-center glonumber"><strong>{getValues().deviceNumber}</strong></p>
            <Button onClick={ ()=> {
              window.location.reload()
            }}>Dzięki</Button>
          </div>
        </form>
      </div>
      <AgeGate setAge={age}/>
    </PageLayout>
  );
}

export default RegisterGlo