import React, { useState, useEffect, useRef } from "react";
import Content from "../../components/PageLayout/Content/Content";
import PageLayout from "../../components/PageLayout/PageLayout";
import { romanize, useAppDispatch, useAppSelector } from "../../hooks";
import { getUser } from "../../store/reducers/user";
import "./Rankings.scss";
import Title from "../../components/Title/Title";
import ButtonTab from "../../components/ButtonTab/ButtonTab";
import DropDownTabs from "../../components/DropDownTabs/DropDownTabs";
import UserRanking from "../../components/UserRanking/UserRanking";
import { listRanking } from "../../store/actions/rankings";
import { useDispatch, useSelector } from "react-redux";
import { getRanking, getRankingLoading } from "../../store/reducers/rankings";
import { getSeasons, getSeasonsInfo } from "../../store/reducers/account";
import { accountSeasons, balanceSummary } from "../../store/actions/account";
import { Link, NavLink, useLocation } from "react-router-dom";
import Loading from "../../components/Loading/LoadingScreen/Loading";
import Button from "../../components/Button/Button";
import edition1 from "./img/edition1.jpg";
import edition2 from "./img/edition2.jpg";
import edition3 from "./img/edition3.jpg";

const SellRankingHeader = () => {
  return (
    <div className="rankings-header dropshadow-box">
      <div className="rankings-header-title">Do zdobycia przez cały sezon:</div>
      <div className="rankings-header-prize">
        <div className="rankings-header-prize-item">
          <div className="rankings-header-prize-value">
            500 <span className="rankings-header-prize-value-currency">zł</span>
          </div>
          <p className="rankings-header-prize-desc">
            dla miejsc 1-5
            <span className="rankings-header-prize-desc rankings-header-prize-desc--under">
              (przy co najmniej 20 sprzedażach)
            </span>
          </p>
        </div>
        <div className="rankings-header-prize-item">
          <div className="rankings-header-prize-value">
            250 <span className="rankings-header-prize-value-currency">zł</span>
          </div>
          <p className="rankings-header-prize-desc">
            dla miejsc 6-20
            <span className="rankings-header-prize-desc rankings-header-prize-desc--under">
              (przy co najmniej 10 sprzedażach)
            </span>
          </p>
        </div>
        <div className="rankings-header-prize-item">
          <div className="rankings-header-prize-value">
            100 <span className="rankings-header-prize-value-currency">zł</span>
          </div>
          <p className="rankings-header-prize-desc">
            dla miejsc 21-50
            <span className="rankings-header-prize-desc rankings-header-prize-desc--under">
              (przy co najmniej 6 sprzedażach)
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

const RefRankingHeader = () => {
  return (
    <div className="rankings-header dropshadow-box ref-ranking">
      <div className="rankings-header-title">Do zdobycia przez cały sezon:</div>
      <div className="rankings-header-prize">
        <div className="rankings-header-prize-item">
          <div className="rankings-header-prize-value">
            500 <span className="rankings-header-prize-value-currency">zł</span>
          </div>
          <p className="rankings-header-prize-desc">
            dla miejsc 1-5
            {/* <span className="rankings-header-prize-desc rankings-header-prize-desc--under">
         (przy co najmniej 20 sprzedażach)
          </span> */}
          </p>
        </div>
        <div className="rankings-header-prize-item">
          <div className="rankings-header-prize-value">
            250 <span className="rankings-header-prize-value-currency">zł</span>
          </div>
          <p className="rankings-header-prize-desc">
            dla miejsc 6-20
            {/* <span className="rankings-header-prize-desc rankings-header-prize-desc--under">
          (przy co najmniej 10 sprzedażach)
          </span> */}
          </p>
        </div>
        <div className="rankings-header-prize-item">
          <div className="rankings-header-prize-value">
            100 <span className="rankings-header-prize-value-currency">zł</span>
          </div>
          <p className="rankings-header-prize-desc">
            dla miejsc 21-50
            {/* <span className="rankings-header-prize-desc rankings-header-prize-desc--under">
          (przy co najmniej 6 sprzedażach)
          </span> */}
          </p>
        </div>
      </div>
    </div>
  );
};

const ContestRankingHeader = () => {
  const user = useAppSelector(getUser);

  return (
    <div className="contest-header">
      <div className="body">
        <h3 className="title">
          Świąteczna uczta - Grupa{" "}
          <span className="group">{user?.swiatecznaUcztaGroup}</span>
        </h3>
        <p>Sprzedawaj i rejestruj urządzenia glo™. Wygraj jedną z nagród:</p>
        <div className="prizes">
          <div className="prize">
            <div className="box">
              <span>1000 zł</span> <br />
              Karta podarunkowa Media Expert
            </div>
            <div className="name">
              <strong>1 - 5</strong> <br />
              miejsce
            </div>
          </div>
          <div className="prize">
            <div className="box">
              <span>500 zł</span> <br />
              Karta podarunkowa na paliwo
            </div>
            <div className="name">
              <strong>6 - 10</strong> <br />
              miejsce
            </div>
          </div>
          <div className="prize">
            <div className="box">
              <span>200 zł</span> <br />
              Karta podarunkowa Media Expert
            </div>
            <div className="name">
              <strong>11 - 20</strong> <br />
              miejsce
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface HeaderProps {
  scrollToRanking: any;
  setContestStage: any;
}

const TravelRankingHeader: React.FC<HeaderProps> = ({
  scrollToRanking,
  setContestStage,
}) => {
  const user = useAppSelector(getUser);

  return (
    <div className="time-travel-contest-header contest-header">
      <div className="top">
        <h2>
          W BAT Partners uruchomiliśmy wehikuł <br />
          i&nbsp;zapraszamy Cię do podróży w czasie.
        </h2>
        <p>
          Energii starczy nam <strong>tylko na trzy skoki</strong>. <br />Z
          każdej podróży możesz wrócić bogatszy o nowe doświadczenia, a przede
          wszystkim <strong>NAGRODY!</strong>
        </p>
        <p>
          <strong>Zobacz, gdzie się przeniesiemy:</strong>
        </p>

        <div className="timetravel-editions">
          <div className="edition active completed">
            <div className="image">
              <img src={edition1} />
            </div>
            <div className="box-wrap">
              <div className="box">
                <p>Etap I (01.03-08.03)</p>
                <h3>Szalone lata 70</h3>
                <p>
                  <strong>Nagrody:</strong>
                  <br />
                  <strong>Miejsce 1</strong>: Karta podarunkowa
                  Media&nbsp;Expert&nbsp;-&nbsp;500&nbsp;zł
                  <br />
                  <strong>Miejsce 2-5</strong>: Karta podarunkowa
                  Media&nbsp;Expert&nbsp;-&nbsp;200&nbsp;zł
                </p>
                <Button
                  color="white"
                  onClick={() => {
                    scrollToRanking();
                    setContestStage(0);
                  }}
                >
                  Sprawdź ranking
                </Button>
              </div>
            </div>
          </div>
          <div className="edition active completed">
            <div className="image">
              <img src={edition2} />
            </div>
            <div className="box-wrap">
              <div className="box">
                <p>Etap II (09.03-16.03)</p>
                <h3>Kultowe lata 90</h3>
                <p>
                  <strong>Nagrody:</strong>
                  <br />
                  <strong>Miejsce 1</strong>: Karta podarunkowa
                  Media&nbsp;Expert&nbsp;-&nbsp;500&nbsp;zł
                  <br />
                  <strong>Miejsce 2-5</strong>: Karta podarunkowa
                  Media&nbsp;Expert&nbsp;-&nbsp;200&nbsp;zł
                </p>
                <Button
                  color="white"
                  onClick={() => {
                    scrollToRanking();
                    setContestStage(1);
                  }}
                >
                  Sprawdź ranking
                </Button>
              </div>
            </div>
          </div>
          <div className="edition active completed">
            <div className="image">
              <img src={edition3} />
            </div>
            <div className="box-wrap">
              <div className="box">
                <p>Etap III (17.03-07.04)</p>
                <h3>Futurystyczny 2050</h3>
                <p>
                  <strong>Nagrody:</strong>
                  <br />
                  <strong>Miejsce 1-3</strong>: Robot kuchenny Bosch MUM58259
                  <br />
                  <strong>Miejsce 4-6</strong>: Soundbar Samsung&nbsp;HW-B550
                  <br />
                  <strong>Miejsce 7-10</strong>: Oczyszczacz powietrza Xiaomi Mi
                  Air Purifier 3C
                </p>
                <Button
                  color="white"
                  onClick={() => {
                    scrollToRanking();
                    setContestStage(2);
                  }}
                >
                  Sprawdź ranking
                </Button>
              </div>
            </div>
          </div>
        </div>

        <p className="h2">
          Rozmawiaj, polecaj i sprzedawaj innym urządzenie glo™ hyper X2 lub
          glo™ hyper+ UNIQ. <br />
          <strong>Napisz własną historię i niech Cię zapamiętają!</strong>
        </p>
      </div>
      <div className="body">
        <h3 className="title">
          Podróż w czasie - Grupa{" "}
          <span className="group">{user?.podrozGroup}</span>
        </h3>
        <p>Sprzedawaj i rejestruj urządzenia glo™. Wygraj jedną z nagród:</p>
        <div className="prizes">
          <div className="prize">
            <div className="box">
              <span>Robot kuchenny</span> <br />
              Bosch MUM58259
            </div>
            <div className="name">
              <strong>1-3</strong> <br />
              miejsce
            </div>
          </div>
          <div className="prize">
            <div className="box">
              <span>Soundbar</span> <br />
              Samsung&nbsp;HW-B550
            </div>
            <div className="name">
              <strong>4 - 6</strong> <br />
              miejsce
            </div>
          </div>
          <div className="prize">
            <div className="box">
              <span>Oczyszczacz powietrza</span> <br />
              Xiaomi Mi Air Purifier 3C
            </div>
            <div className="name">
              <strong>7 - 10</strong> <br />
              miejsce
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SalepointRankingHeader = () => {
  return (
    <div className="consumer-header">
      <div className="body">
        <p className="desc">
          Śledź emocjonujące zmagania sprzedawców w Twojej sieci handlowej.
          Rywalizuj o miano najlepszego sprzedawcy. Zyskaj sławę, uznanie oraz
          nagrodę dodatkową.
        </p>
        <p>
          <strong>Do zdobycia przez cały sezon:</strong>
        </p>
        <ul className="ranking-list">
          <li className="list-item">
            <strong>450 zł</strong> dla miejsc 1–10
          </li>
          <li className="list-item">
            <strong>300 zł</strong> dla miejsc 11–20
          </li>
          <li className="list-item">
            <strong>200 zł</strong> dla miejsc 21–30
          </li>
          <li className="list-item">
            <strong>100 zł</strong> dla miejsc 31–50
          </li>
          <li className="list-item">
            <strong>50 zł</strong> dla miejsc 51–80
          </li>
        </ul>
      </div>
    </div>
  );
};

export const RANKING_TYPE = {
  SELL: "SELL",
  REF: "REF",
  MARZEC: "MARZEC",
  KONKURS: "KONKURS",
  SALESPOINT: "SALESPOINT",
  TIMETRAVEL: "TIMETRAVEL",
};

interface RankingProps {
  rankingType: string;
}

const Rankings: React.FC<RankingProps> = ({ rankingType }) => {
  const dispatch = useAppDispatch();
  const ranking = useSelector(getRanking);
  const loading = useSelector(getRankingLoading);
  const [season, setSeason] = useState(0);
  const seasons = useSelector(getSeasons);
  const seasonsInfo = useSelector(getSeasonsInfo);
  const location = useLocation();
  const user = useAppSelector(getUser);
  const [contestStage, setContestStage] = useState(2);
  const rankingRef = useRef(null) as any;
  const reverseSeasons = [...seasons].reverse();

  useEffect(() => {
    fetchRankings(rankingType, reverseSeasons[season]?.name);
    if (seasonsInfo.current === "") {
      dispatch(accountSeasons());
      dispatch(balanceSummary());
    }
  }, []);

  useEffect(() => {
    fetchRankingsByType(rankingType);
    if (seasonsInfo.current === "") {
      dispatch(accountSeasons());
      dispatch(balanceSummary());
    }
  }, [location.pathname]);

  useEffect(() => {
    //dispatch(listRanking(contestStage + 1, "ranking-podroz"));
  }, [contestStage]);

  const scrollToRanking = () => {
    rankingRef.current.scrollIntoView({ behavior: "smooth" });
  };

  /*const handleChangeRankingType = (type: string) => {
    setRankingType(type);
    fetchRankingsByType(type)
  };*/

  const fetchRankings = (type: string, sName: any) => {
    if (type === "REF") {
      dispatch(listRanking(sName, "ranking-consumer"));
    } else if (type === "MARZEC") {
      dispatch(listRanking(sName, "ranking-marzec"));
    } else if (type === "KONKURS") {
      dispatch(listRanking(sName + 1, "ranking-swiateczna-uczta"));
    } else if (type === "SALESPOINT") {
      dispatch(listRanking("", "ranking-salespoint"));
    } else if (type === "TIMETRAVEL") {
      dispatch(listRanking(contestStage + 1, "ranking-podroz"));
    } else {
      dispatch(listRanking(sName, "ranking"));
    }
  };

  const fetchRankingsByType = (type: string) => {
    fetchRankings(type, reverseSeasons[season]?.name);
  };

  const fetchRankingsBySeason = (sName: any) => {
    fetchRankings(rankingType, sName);
  };

  const handleChangeSeason = (s: number) => {
    setSeason(s);
    fetchRankingsBySeason(reverseSeasons[s].name);
  };

  const handleChangeStage = (s: number) => {
    setContestStage(s);
    fetchRankingsBySeason(s);
  };

  const seasonNames = () => {
    const seasonKeys = Object.keys(seasonsInfo.list);
    const names = seasonKeys.map((item) => {
      if (item === "q1_21") {
        return { name: "2021 - Wiosna" };
      } else if (item === "q2_21") {
        return { name: "2021 - Lato" };
      } else if (item === "q3_21") {
        return { name: "2021 - Jesień" };
      } else if (item === "q4_21") {
        return { name: "2021 - Zima" };
      } else {
        return {
          name: `20${item.substring(3, 5)} - Sezon ${romanize(
            parseInt(item.substring(1, 2))
          )}`,
        };
      }
    });
    return names.reverse();
  };

  const handleRankingHeader = () => {
    if (rankingType === RANKING_TYPE.KONKURS) {
      return <ContestRankingHeader />;
    } else if (rankingType === RANKING_TYPE.REF) {
      return <RefRankingHeader />;
    } else if (rankingType === RANKING_TYPE.SALESPOINT) {
      return <SalepointRankingHeader />;
    } else if (rankingType === RANKING_TYPE.TIMETRAVEL) {
      return (
        <TravelRankingHeader
          scrollToRanking={() => scrollToRanking()}
          setContestStage={(x: number) => setContestStage(x)}
        />
      );
    } else {
      return <SellRankingHeader />;
    }
  };

  const handleSalesPointButton = (salesName: string) => {
    if (salesName.startsWith("CK")) {
      return (
        <NavLink className="ButtonTab" to="/rankingi/salespoint">
          Circle K
        </NavLink>
      );
    } else if (salesName.startsWith("AM")) {
      return (
        <NavLink className="ButtonTab" to="/rankingi/salespoint">
          Amic
        </NavLink>
      );
    } else if (salesName.startsWith("TA")) {
      return (
        <NavLink className="ButtonTab" to="/rankingi/salespoint">
          Tabak
        </NavLink>
      );
    } else if (salesName.startsWith("SH")) {
      return (
        <NavLink className="ButtonTab" to="/rankingi/salespoint">
          Shell
        </NavLink>
      );
    } else if (salesName.startsWith("SP")) {
      return (
        <NavLink className="ButtonTab" to="/rankingi/salespoint">
          Świat Prasy
        </NavLink>
      );
    } else if (salesName.startsWith("LA")) {
      return (
        <NavLink className="ButtonTab" to="/rankingi/salespoint">
          Inmedio
        </NavLink>
      );
    }
    return null;
  };

  return (
    <PageLayout>
      <div className={"page rankings " + rankingType.toLowerCase()}>
        <Title>
          {rankingType === RANKING_TYPE.TIMETRAVEL ? "" : "Rankingi"}
        </Title>
        {handleRankingHeader()}

        <div className="rakings-user-raking">
          <div className="rakings-user-raking-header">
            {rankingType !== "TIMETRAVEL" && (
              <div className="rakings-user-raking-cta-group">
                <NavLink
                  className={
                    rankingType === "SELL" ? "ButtonTab active" : "ButtonTab"
                  }
                  to="/rankingi/sprzedaz"
                >
                  Sprzedaż
                </NavLink>
                {/* <NavLink className="ButtonTab" to="/rankingi/polecenia">
                  Polecenia
                </NavLink> */}
                {/*handleSalesPointButton(user.salesPoint ? user.salesPoint : "")*/}
              </div>
            )}
            {seasonsInfo.current != "" &&
            (rankingType === "REF" || rankingType === "SELL") ? (
              <DropDownTabs
                handleChangeTab={handleChangeSeason}
                activeTab={season}
                tabList={seasonNames()}
              />
            ) : null}
            {rankingType === RANKING_TYPE.TIMETRAVEL && (
              /*<DropDownTabs
                  handleChangeTab={handleChangeStage}
                  activeTab={contestStage}
                  tabList={[
                    {name: "Etap I (03.01 - 03.08)"},
                    {name: "Etap II (03.09 - 03.19)"},
                    {name: "Etap III (03.17 - 04.07)"},
                  ]}
                  />*/
              <div className="timetravel-editions-nav">
                <strong>Obecny:</strong>
                <div
                  className={
                    contestStage === 2
                      ? "ButtonTab tertiary active"
                      : "ButtonTab tertiary"
                  }
                  onClick={() => setContestStage(2)}
                >
                  Etap III (17.03-07.04)
                </div>
                <strong>Poprzednie:</strong>
                <div
                  className={
                    contestStage === 1
                      ? "ButtonTab secondary active"
                      : "ButtonTab secondary"
                  }
                  onClick={() => setContestStage(1)}
                >
                  Etap II (09.03 - 16.03)
                </div>
                <div
                  className={
                    contestStage === 0
                      ? "ButtonTab primary active"
                      : "ButtonTab primary"
                  }
                  onClick={() => setContestStage(0)}
                >
                  Etap I (01.03 - 08.03)
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div id="scroll-point" ref={rankingRef}></div>
      {loading ? (
        <div className="rankings">
          <Loading color />
        </div>
      ) : ranking?.length > 0 ? (
        <UserRanking rankingType={rankingType} rankingList={ranking} />
      ) : null}
      {rankingType === RANKING_TYPE.TIMETRAVEL && (
        <div className="contest-footer page">
          <h3>
            Osiągnij najlepszy wynik w danym etapie i&nbsp;wygraj jedną
            z&nbsp;nagród. <br />
            Czas leci! Powodzenia!
          </h3>
          <Button path="/nowa-sprzedaz">Sprzedawaj</Button>
          <h3 className="footer-h3">
            <span>O konkursie</span>
          </h3>
          <p>
            <strong>
              Komu przypadnie nagroda, jeśli posiadasz tyle samo urządzeń, co
              inni Uczestnicy?
            </strong>
          </p>
          <p>
            Na pozycję Uczestników w rankingu z tą samą ilością sprzedaży ma
            wpływ najszybszy czas rejestracji pierwszego sprzedanego urządzenia
            glo™ w Konkursie przez danego Uczestnika, a w przypadku gdy czas
            rejestracji urządzeń przez Uczestników będzie identyczny wówczas
            decydować będzie całkowita liczba sprzedanych urządzeń glo™ od
            momentu zarejestrowania się przez Uczestnika w Programie BAT
            Partners.
          </p>
          <p>
            <strong>
              Czy mogę wygrać nagrodę we wszystkich 3 etapach konkursu? 
            </strong>
          </p>
          <p>
            Laureat z I etapu nie może zdobyć nagrody w II etapie Konkursu.{" "}
            <br />
            Laureaci z I i II etapu mogą zdobyć nagrody w III etapie (Finał)
            Konkursu.
          </p>
        </div>
      )}
    </PageLayout>
  );
};

export default Rankings;
