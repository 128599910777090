import React, { SyntheticEvent, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useWindowDimensions } from "../../hooks";
import { otp, updateUser } from "../../store/actions/user";
import moment from "moment";
import { URZEDY_SKARBOWE } from "../../data/urzedy_skarbowe";
import {
  useForm,
  SubmitHandler,
  FieldValues,
  FieldErrors,
  Controller,
} from "react-hook-form";
import { getUser } from "../../store/reducers/user";
import { useDispatch, useSelector } from "react-redux";
import Title from "../../components/Title/Title";
import PageLayout from "../../components/PageLayout/PageLayout";
import Button from "../../components/Button/Button";
import "./Profile.scss";
import BankAccount from "./BankAccount/BankAccount";
import { showDrawer } from "../../store/actions/ui";
import cl from "classnames";
import { ReactComponent as ArrowDown } from "./img/arrow-down.svg";
import { ReactComponent as ArrowUp } from "./img/arrow-up.svg";
import { ReactComponent as Warning } from "./img/warning.svg";
import { ReactComponent as Checked } from "./img/checked.svg";
import { region } from "./region";
import Select from "react-select";
import TaxReportStep1 from "./TaxReportStep1/TaxReportStep1";
import TaxReportStep2 from "./TaxReportStep2/TaxReportStep2";
import TaxReportStep3 from "./TaxReportStep3/TaxReportStep3";
import Countdown, { zeroPad } from "react-countdown";
import Consents from "./Consents";

function Profile() {
  const dispatch = useAppDispatch();
  const { height, width } = useWindowDimensions();
  const user = useSelector(getUser);
  const {
    register,
    setValue,
    handleSubmit,
    control,
    getValues,
    reset,
    formState: { errors },
  } = useForm();
  const formValues = getValues();
  const [sectionIndex, setSectionIndex] = useState<number>(0);
  const [otpSendedAtLeastOnce, setOtpSendedAtLeastOnce] = useState(false);
  const [otpButton, setOtpButton] = useState(false);
  const [timer, setTimer] = useState(Date.now());

  const [formStep, setFormStep] = useState(1);
  const [formDataValue, setFormDataValue] = useState<any>({
    streetAddress: user?.streetAddress ? user?.streetAddress : "",
    streetNumber: user?.streetNumber ? user?.streetNumber : "",
    taxOfficeName: user?.taxOfficeName ? user?.taxOfficeName : "",
    stateName: user?.stateName ? user?.stateName : "",
    businessCode: user.businessCode ? user?.businessCode : "",
    dob: user?.dob ? user?.dob : "",
    postalCode: user?.postalCode ? user?.postalCode : "",
    cityName: user?.cityName ? user?.cityName : "",
    apartmentNumber: user?.apartmentNumber ? user?.apartmentNumber.user : "",
    businessOwnership: user?.businessOwnership
      ? user?.businessOwnership.user
      : false,
  });

  const handleNextStep = () => {
    setFormStep((prevState) => prevState + 1);
  };

  const handlePrevStep = () => {
    if (formStep > 1) {
      setFormStep((prevState) => prevState - 1);
    }
  };

  const handleUpdateFormData = (fields: any) => {
    setFormDataValue((prevState: any) => ({
      ...prevState,
      ...fields,
    }));
  };

  const onSubmit = (data: FieldValues) => {
    //if (!userValues.businessOwnership) userValues.taxNumber = getValues('pesel')
    let formData = { ...formDataValue };
    if (formData.businessOwnership) {
      delete formData.pesel;
    }
    dispatch(updateUser({ otp: data.otp, user: formData }));
    setTimer(0);
    setOtpButton(true);
    reset({ otp: "" });
    setOtpSendedAtLeastOnce(false);
  };

  useEffect(() => {
    console.log("formDataValue ❗", formDataValue);
  }, [formDataValue]);

  useEffect(() => {
    setFormDataValue((state: any) => ({
      ...state,
      streetAddress: user?.streetAddress ? user?.streetAddress : "",
      streetNumber: user?.streetNumber ? user?.streetNumber : "",
      taxOfficeName: user?.taxOfficeName ? user?.taxOfficeName : "",
      stateName: user?.stateName ? user?.stateName : "",
      businessCode: user.businessCode ? user?.businessCode : "",
      dob: user?.dob ? user?.dob : "",
      postalCode: user?.postalCode ? user?.postalCode : "",
      cityName: user?.cityName ? user?.cityName : "",
      pesel: user?.pesel ? user?.pesel : "",
      nip: user?.nip ? user?.nip : "",
      apartmentNumber: user?.apartmentNumber ? user?.apartmentNumber : "",
      businessOwnership: user?.businessOwnership
        ? user?.businessOwnership
        : false,
    }));
  }, [user.firstName]);

  const onError = (errors: FieldErrors) => {
    //console.log(errors)
  };

  const renderer = ({ minutes, seconds, completed }: any) => {
    if (completed) {
      setOtpButton(true);
      return <p className="timer disabled">00:00</p>;
    } else {
      return (
        <p className="timer">
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </p>
      );
    }
  };

  return (
    <PageLayout withBottom={true}>
      <div className="page Profile">
        <Title>Mój profil</Title>
        <div className="profile-batcode">
          <div className="batcode-title">Twój numer BAT Partnera:</div>
          <div className="batcode-number">{user?.referralCode}</div>
        </div>
        <div className="profile-columns">
          <div className="profile-column nav-column">
            <div className="profile-nav">
              <div
                className={cl("profile-nav-item", {
                  active: sectionIndex === 0,
                })}
                onClick={() => setSectionIndex(0)}
              >
                Dane logowania
              </div>
              <div
                className={cl("profile-nav-item", {
                  active: sectionIndex === 1,
                })}
                onClick={() => setSectionIndex(1)}
              >
                Dane osobowe
              </div>
              <div
                className={cl("profile-nav-item", {
                  active: sectionIndex === 4,
                })}
                onClick={() => setSectionIndex(4)}
              >
                Dane do wypłat
              </div>
              <div
                className={cl("profile-nav-item", {
                  active: sectionIndex === 2,
                })}
                onClick={() => setSectionIndex(2)}
              >
                Dane PIT
              </div>
              <div
                className={cl("profile-nav-item", {
                  active: sectionIndex === 3,
                })}
                onClick={() => setSectionIndex(3)}
              >
                Zgody
              </div>
            </div>
          </div>
          <div className="profile-column edit-column">
            <section
              className={cl("login-section", {
                "active-section": sectionIndex === 0,
              })}
            >
              <h2>Dane logowania</h2>
              <div className="input-item">
                <label htmlFor="email">E-mail</label>
                <input
                  name="email"
                  type="email"
                  id="email"
                  value={user.emailAddress}
                  disabled
                />
              </div>
              <div className="password-item">
                <div className="label">Hasło</div>
                <p>
                  Chcesz zmienić hasło? Kliknij w link poniżej i postępuj
                  zgodnie z instrukcją, którą otrzymasz mailowo.
                </p>
                <NavLink to="/przypomnienie-hasla">
                  Chcę zresetować hasło
                </NavLink>
              </div>
              <p>
                Ze względów bezpieczeństwa niektórych danych pozwalających na
                identyfikację uczestnika programu nie można zmienić w zakładce
                Profil. Jeżeli któraś z tych danych uległa zmianie, wyślij nam
                wiadomość na adres{" "}
                <a href="mailto:kontakt@batpartners.pl">
                  kontakt@batpartners.pl
                </a>{" "}
                i wskaż dane do zmiany - pomożemy!
              </p>
            </section>
            <section
              className={cl("user-data-section", {
                "active-section": sectionIndex === 1,
              })}
            >
              <h2>Dane osobowe</h2>
              <div className="input-item">
                <label htmlFor="firstName">Imię</label>
                <input
                  name="firstName"
                  type="text"
                  id="firstName"
                  value={user.firstName}
                  disabled
                ></input>
              </div>
              <div className="input-item">
                <label htmlFor="lastName">Nazwisko</label>
                <input
                  name="lastName"
                  type="text"
                  id="lastName"
                  value={user.lastName}
                  disabled
                ></input>
              </div>
              <div className="input-item">
                <label htmlFor="mobilePhone">Numer telefonu</label>
                <input
                  name="mobilePhone"
                  type="text"
                  id="mobilePhone"
                  value={user.mobilePhone}
                  disabled
                />
              </div>
              <div className="input-item">
                <label htmlFor="salesPoint">
                  Kod punktu sprzedaży (SAP ID)
                </label>
                <input
                  name="salesPoint"
                  type="text"
                  id="salesPoint"
                  value={user.salesPoint}
                  disabled
                />
              </div>

              <p className="disclaimer">
                Ze względów bezpieczeństwa niektórych danych pozwalających na
                identyfikację uczestnika programu nie można zmienić w zakładce
                Profil. Jeżeli któraś z tych danych uległa zmianie, wyślij nam
                wiadomość na adres{" "}
                <a href="mailto:kontakt@batpartners.pl">
                  kontakt@batpartners.pl
                </a>{" "}
                i wskaż dane do zmiany - pomożemy!
              </p>
            </section>
            <section
              className={cl("user-data-section", {
                "active-section": sectionIndex === 2,
              })}
            >
              <div className="pit-header">
                <h2>Dane PIT</h2>
                {!user?.dob && (
                  <div className="info-warning-group">
                    <div style={{ width: "24px", height: "24px" }}>
                      <Warning width="24px" />
                    </div>
                    <strong className="user-data-text">
                      Uzupełnij wszystkie pola w celu poprawnego rozliczenia
                      PIT. <br />
                      Dane muszą należeć do Ciebie. W innym przypadku Twoja
                      wypłata może zostać odrzucona.
                    </strong>
                  </div>
                )}
              </div>
              {user.firstName != "" ? (
                <>
                  {/* ADRES PODATNIKA  */}
                  <h3
                    style={
                      formStep > 1
                        ? {
                            color: "#FF8D03",
                            borderBottom: "2px solid #FF8D03",
                          }
                        : {}
                    }
                    className="tax-header"
                  >
                    Adres Podatnika {formStep > 1 ? <Checked /> : null}
                  </h3>
                  <div
                    aria-label="adres-podatnika"
                    className={formStep === 1 ? "" : "?"}
                  >
                    <div>
                      {formStep === 1 && (
                        <TaxReportStep1
                          formDataValue={formDataValue}
                          handleUpdateFormData={handleUpdateFormData}
                          handlePrevStep={handlePrevStep}
                          handleNextStep={handleNextStep}
                          streetAddress={user.streetAddress}
                          streetNumber={user.streetNumber}
                          apartmentNumber={user.apartmentNumber}
                          cityName={user.cityName}
                          postalCode={user.postalCode}
                        />
                      )}
                    </div>
                  </div>
                  {/* DANE PODATNIKA  */}
                  <h3
                    style={
                      formStep > 2
                        ? {
                            color: "#FF8D03",
                            borderBottom: "2px solid #FF8D03",
                          }
                        : {}
                    }
                    className="tax-header"
                  >
                    Dane Podatnika {formStep > 2 ? <Checked /> : null}
                  </h3>
                  <div
                    aria-label="dane-podatnika"
                    className={formStep === 2 ? "" : "?"}
                  >
                    {formStep === 2 && (
                      <TaxReportStep2
                        formDataValue={formDataValue}
                        handleUpdateFormData={handleUpdateFormData}
                        handlePrevStep={handlePrevStep}
                        handleNextStep={handleNextStep}
                        dob={user.dob}
                        businessCode={user.businessCode}
                      />
                    )}
                  </div>
                </>
              ) : null}
              <h3
                style={
                  formStep > 3
                    ? { color: "#FF8D03", borderBottom: "2px solid #FF8D03" }
                    : {}
                }
                className="tax-header"
              >
                Urząd Skarbowy {formStep > 3 ? <Checked /> : null}
              </h3>
              {formStep === 3 && (
                <TaxReportStep3
                  formDataValue={formDataValue}
                  handleUpdateFormData={handleUpdateFormData}
                  handlePrevStep={handlePrevStep}
                  handleNextStep={handleNextStep}
                  user={user}
                  taxOfficeName={user.taxOfficeName}
                />
              )}

              <h3 className="tax-header">
                Weryfikacja SMS {formStep > 4 ? <Checked /> : null}
              </h3>
              {formStep === 4 && (
                <div className="bottom-section-inner">
                  <p>
                    <strong>
                      Na numer telefonu {user?.mobilePhone} wyślemy kod
                      autoryzacyjny.{" "}
                    </strong>
                  </p>
                  <p>Wprowadź kod, aby zapisać zmiany w profilu.</p>
                  <div className="input-item send-code-group">
                    <div>
                      <label htmlFor="otp">Kod autoryzacyjny</label>
                      <input
                        disabled={!otpSendedAtLeastOnce}
                        className={errors?.otp && "error-input"}
                        {...register("otp", { required: true })}
                        defaultValue={user.otp}
                      />
                      {errors.otp && errors.otp.type === "required" && (
                        <p className="error">Uzupełnij pole</p>
                      )}
                    </div>
                    {!otpSendedAtLeastOnce && (
                      <Button
                        className="send-code"
                        onClick={() => {
                          dispatch(otp());
                          setOtpSendedAtLeastOnce(true);
                          setTimer(Date.now() + 300000);
                          setOtpButton(false);
                        }}
                      >
                        Wyślij SMS
                      </Button>
                    )}
                  </div>
                  {otpSendedAtLeastOnce && (
                    <div className="sended-sms-group">
                      <p>SMS nie przyszedł?</p>
                      {otpButton ? (
                        <p
                          className="send-again"
                          onClick={() => {
                            setTimer(Date.now() + 300000);
                            setOtpButton(false);
                            dispatch(otp());
                          }}
                        >
                          Wyślij ponowienie
                        </p>
                      ) : (
                        <div className="send-again disabled">
                          Wyślij kod ponownie
                        </div>
                      )}
                      <Countdown
                        date={timer}
                        key={timer}
                        zeroPadTime={0}
                        renderer={renderer}
                      />
                    </div>
                  )}
                  <div className="btn-group">
                    <Button
                      className={
                        otpSendedAtLeastOnce ? "back-disabled" : "back"
                      }
                      disabled={otpSendedAtLeastOnce}
                      onClick={() => handlePrevStep()}
                    >
                      Cofnij
                    </Button>
                    <Button
                      className={
                        !otpSendedAtLeastOnce ? "next disabled" : "next"
                      }
                      disabled={!otpSendedAtLeastOnce}
                      onClick={handleSubmit(onSubmit, onError)}
                    >
                      Zapisz dane
                    </Button>
                  </div>
                  {/* {!otpSendedAtLeastOnce ? 
                  <Button className="back" onClick={() => handlePrevStep()}>Cofnij</Button> 
                  :
                  <Button className='next' onClick={ handleSubmit(onSubmit, onError) }>Zapisz dane</Button>
                } */}
                  <p className="send-disclaimer">
                    Po kliknięciu <strong>„Zapisz dane”</strong> nie będzie
                    można zmienić danych rozliczeniowych własnoręcznie. <br />{" "}
                    Dane będzie można edytować kontaktując się pod adresem:{" "}
                    <a href="mailto:kontakt@batpartners.pl">
                      kontakt@batpartners.pl
                    </a>
                  </p>
                </div>
              )}
            </section>
            <section
              className={cl("user-data-section", {
                "active-section": sectionIndex === 3,
              })}
            >
              <h2>Zgody</h2>
              <h3>Wyrażone zgody marketingowe</h3>

              <Consents />

              <p>
                W każdej chwili możesz wycofać zgodę na otrzymywanie informacji
                marketingowych ze skutkiem na przyszłość, wysyłając oświadczenie
                o wycofaniu zgody na adres e-mail{" "}
                <a href="mailto:kontakt@batpartners.pl">
                  kontakt@batpartners.pl
                </a>
                , telefonicznie pod numerem:{" "}
                <a href="tel:800 610 610">800 610 610</a> lub w panelu ustawień
                użytkownika serwisu www.batpartners.pl. Wycofanie zgody nie
                wpływa na zgodność z prawem wysyłania informacji marketingowych,
                którego dokonano na podstawie zgody przed jej wycofaniem.
              </p>
              <p>
                Więcej informacji o tym, jakie dane zbieramy, w jakim celu oraz
                do czego je wykorzystujemy znajdziesz w{" "}
                <a
                  href="/pdf/klauzula_informacyjna_bat_partners.pdf"
                  target="_blank"
                >
                  Klauzuli Informacyjnej.
                </a>
              </p>
            </section>
            <section
              className={cl("user-bank-account-section", {
                "active-section": sectionIndex === 4,
              })}
            >
              <h2>Dane do wypłat</h2>

              <BankAccount
                setValue={setValue}
                ibanNumber={user?.iban}
                ibanLimitExceeded={user?.ibanLimitExceeded}
                formStep={sectionIndex}
              />
            </section>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default Profile;
