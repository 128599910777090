import React, { useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./Velo.scss";
import Title from "../../components/Title/Title";
import mobileHero from "./img/hero-image-mobile.png";
import desktopHero from "./img/hero-image.png";
import veloSectionBg from "./img/velo-section-bg.png";
import veloSectionBgDesktop from "./img/velo-section-bg-desktop.png";
import icon01 from "./img/velo_ic_1.png";
import icon02 from "./img/velo_ic_2.png";
import icon03 from "./img/velo_ic_3.png";
import icon04 from "./img/velo_ic_4.png";
import pIcon01 from "./img/no-smoke-no-smell-no-tobacco.png";
import pIcon02 from "./img/no-colour-no-mess.png";
import pIcon03 from "./img/quality-ingredients.png";
import pIcon04 from "./img/smaki.png";
import pIcon05 from "./img/prostota.png";
import pIcon06 from "./img/no-device.png";
import pIcon07 from "./img/feel-it.png";
import pIcon08 from "./img/recycling.png";
import packOpen from "./img/pack-open.png";
import miniMint1 from "./img/mini-mint-1.png"
import miniMint2 from "./img/mini-mint-2.png"
import miniBerry1 from "./img/mini-berry-1.png"
import miniBerry2 from "./img/mini-berry-2.png"
import slimMint from "./img/slim-mint.png"
import slimBerry from "./img/slim-berry.png"
import slimFrost from "./img/slim-frost.png"
import slimFreeze from "./img/slim-freeze.png"
import ultraFreeze1 from "./img/ultra-freeze-1.png"
import ultraFreeze2 from "./img/ultra-freeze-2.png"
import Button from "../../components/Button/Button";
import Badge from "../../components/Badge/Badge";

const stepSlider = [
  {
    title: "Krok 1",
    icon: icon01,
    desc: <span><strong>WYJMIJ</strong> jedną saszetkę z opakowania i umieść w ustach pod górną wargą lub tak jak jest Ci wygodnie.</span>,
  },
  {
    title: "Krok 2",
    icon: icon02,
    desc: <span><strong>POCZUJESZ</strong> mrowienie - to oznacza, że nikotyna się wchłania.</span>,
  },
  {
    title: "Krok 3",
    icon: icon03,
    desc: <span><strong>POZOSTAW</strong> saszetkę w ustach i ciesz się działaniem Velo nawet do 30 minut.</span>,
  },
  {
    title: "Krok 4",
    icon: icon04,
    desc: <span><strong>ZUŻYTĄ</strong> saszetkę możesz umieścić w górnej części opakowania albo wyrzucić do kosza.</span>,
  },
];

const profitSlider = [
  {
    title: "Bez dymu i bez popiołu",
    desc: "VELO zapewnia pełną satysfakcję nikotynową bez wpływu na innych.",
    image: pIcon01
  },
  {
    title: "Pełna dyskrecja użytkowania od początku do końca",
    desc: "Saszetki VELO są niezauważalne podczas korzystania, oraz pozostają białe w trakcie i po użytkowaniu.",
    image: pIcon02
  },
  {
    title: "Wysoka jakość składników",
    desc: "Saszetki VELO są produkowane z wysokiej jakości składników.",
    image: pIcon03
  },
  {
    title: "Szeroki wybór wariantów smakowych i stężeń nikotynowych",
    desc: "Bogata oferta wariantów smakowych i stężeń nikotyny VELO umożliwia dopasowanie do potrzeb każdego użytkownika.",
    image: pIcon04
  },
  {
    title: "",
    desc: "",
    image: packOpen
  },
  {
    title: "Kompaktowne i funkcjonalne opakowanie",
    desc: "Funkcjonalne opakowanie VELO umożliwia przechowywanie zużytych saszetek w górnym wieczku.",
    image: pIcon05
  },
  {
    title: "Żadne urządzenie nie jest Ci potrzebne",
    desc: "Zapomnij o jakichkolwiek dodatkach. Wystarczy Ci puszka saszetek nikotynowych VELO.",
    image: pIcon06
  },
  {
    title: "Gdziekolwiek, kiedykolwiek",
    desc: "VELO możesz zabrać ze sobą w dowolne miejsce. Pasuje tam, gdzie jesteś.",
    image: pIcon07
  },
  {
    title: "Recykling",
    desc: "Z myślą o środowisku naturalnym, opakowania VELO wykonaliśmy z plastiku, który podlega recyklingowi.",
    image: pIcon08
  },
];

function Velo() {
  const [format, setFormat] = useState(0);

  return (
    <PageLayout>
      <section className="Velo">
        <div className="hero-section">
          <Badge category="Saszetki nikotynowe"/>
          <div className="velo-hero-content">
            <Title><span><span className="color-blue">Poznaj innowacyjne</span>{" saszetki nikotynowe VELO"}</span></Title>
          </div>
          <div className="hero-image">
            <picture>
              <source media="(max-width: 1019px)" srcSet={mobileHero} />
              <source media="(min-width: 1020px)" srcSet={desktopHero} />
              <img className="bg" src={desktopHero} alt="Velo" />
            </picture>
          </div>
        </div>

        <div className="velo-formats">
          <div className="wrapper">
            <h2><span className="color-blue">Trzy</span> Formaty</h2>
            <div className="formats-holder">
              <div className="selection">
                <h4>Saszetki nikotynowe VELO zapewniają różnorodność, od łagodnych wariantów smakowych, po <span className="velo-red">mocniejsze odczucia</span> i&nbsp;występują w&nbsp;różnych formatach.</h4>
                <div className="select-buttons">
                  <div className={format === 0 ? "select-button active" : "select-button"} onClick={ ()=>{setFormat(0)} }>Dla poczatkujących</div>
                  <div className={format === 1 ? "select-button active" : "select-button"} onClick={ ()=>{setFormat(1)} }>Dla zaawansowanych</div>
                  <div className={format === 2 ? "select-button active" : "select-button"} onClick={ ()=>{setFormat(2)} }>Dla ekspertów</div>
                </div>
              </div>
              { format === 0 &&
                <div className="presentation presentation-velo-mini velo-box">
                  <div className="head">
                    <h3>Velo Mini</h3>
                    <p className="small">(18 saszetek w opakowaniu)</p>
                    <h4>Dla zaczynających przygodę z saszetkami nikotynowymi.</h4>
                  </div>
                  <div className="content">
                    <h4>Różne warianty smakowe. Różne stężenia nikotyny.</h4>
                    <div className="flavours">
                      <div className="flavour">
                        <h4>Polar Mint</h4>
                        <p>Słodki miętowy wariant smakowy z&nbsp;dodatkiem mentolu</p>
                        <div className="packs">
                          <div className="pack">
                            <img src={miniMint1} />
                            <span className="intensity-name">Intensywność: <strong>Mild</strong></span>
                            <div className="intensity mint">
                              <span>4 mg</span>
                              <div className="intensity-dots mint">
                                <div className="full"></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                          <div className="pack">
                            <img src={miniMint2} />
                            <span className="intensity-name">Intensywność: <strong>Medium</strong></span>
                            <div className="intensity mint">
                              <span>6 mg</span>
                              <div className="intensity-dots mint">
                                <div className="full"></div>
                                <div className="full"></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flavour">
                        <h4>Berry Frost</h4>
                        <p>Jagodowy wariant smakowy z&nbsp;dodatkiem mięty</p>
                        <div className="packs">
                          <div className="pack">
                            <img src={miniBerry1} />
                            <span className="intensity-name">Intensywność: <strong>Mild</strong></span>
                            <div className="intensity berry">
                              <span>4 mg</span>
                              <div className="intensity-dots berry">
                                <div className="full"></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                          <div className="pack">
                            <img src={miniBerry2} />
                            <span className="intensity-name">Intensywność: <strong>Medium</strong></span>
                            <div className="intensity berry">
                              <span>6 mg</span>
                              <div className="intensity-dots berry">
                                <div className="full"></div>
                                <div className="full"></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              { format === 1 &&
                <div className="presentation presentation-velo-slim velo-box">
                  <div className="head">
                    <h3>Velo Slim</h3>
                    <p className="small">(20 saszetek w opakowaniu)</p>
                    <h4>Dla doświadczonych szukających większej satysfakcji i&nbsp;mocniejszego doświadczenia nikotyny.</h4>
                  </div>
                  <div className="content">
                  <h4>Różne warianty smakowe. Różne stężenia nikotyny.</h4>
                    <div className="flavours">
                      <div className="flavour">
                        <h4>Polar Mint</h4>
                        <p>Słodki miętowy wariant smakowy z&nbsp;dodatkiem mentolu</p>
                        <div className="pack">
                          <img src={slimMint} />
                          <span className="intensity-name">Intensywność: <strong>Strong</strong></span>
                          <div className="intensity mint">
                            <span>10 mg</span>
                            <div className="intensity-dots mint">
                              <div className="full"></div>
                              <div className="full"></div>
                              <div className="full"></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flavour">
                        <h4>Berry Frost</h4>
                        <p>Jagodowy wariant smakowy z&nbsp;dodatkiem mięty</p>
                        <div className="pack">
                          <img src={slimBerry} />
                          <span className="intensity-name">Intensywność: <strong>Strong</strong></span>
                          <div className="intensity berry">
                            <span>10 mg</span>
                            <div className="intensity-dots berry">
                              <div className="full"></div>
                              <div className="full"></div>
                              <div className="full"></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flavour">
                        <h4>Ice Cool</h4>
                        <p>Intensywny miętowy wariant smakowy</p>
                        <div className="pack">
                          <img src={slimFrost} />
                          <span className="intensity-name">Intensywność: <strong>Strong</strong></span>
                          <div className="intensity frost">
                            <span>10 mg</span>
                            <div className="intensity-dots frost">
                              <div className="full"></div>
                              <div className="full"></div>
                              <div className="full"></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flavour">
                        <h4>Freeze</h4>
                        <p>Intensywny miętowy wariant smakowy</p>
                        <div className="pack">
                          <img src={slimFreeze} />
                          <span className="intensity-name">Intensywność: <strong>X-Strong</strong></span>
                          <div className="intensity darkred">
                            <span>10 mg</span>
                            <div className="intensity-dots darkred">
                              <div className="full"></div>
                              <div className="full"></div>
                              <div className="full"></div>
                              <div className="full"></div>
                              <div></div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
              { format === 2 &&
                <div className="presentation presentation-velo-ultra velo-box">
                  <div className="head">
                    <h3>Velo X-Freeze Ultra i Max</h3>
                    <p className="small">(18 saszetek w opakowaniu)</p>
                    <h4>Dla poszukujących intensywnego i&nbsp;najsilniejszego doświadczenia nikotyny w portfolio Velo.</h4>
                  </div>
                  <div className="content">
                  <h4>Różne warianty smakowe. Różne stężenia nikotyny.</h4>
                    <div className="flavours">
                      <div className="flavour">
                        <h4>X-Freeze Ultra</h4>
                        <p>Intensywna mięta pieprzowa w&nbsp;połączeniu z&nbsp;orzeźwiającym mentolem </p>
                        <div className="pack">
                          <img src={ultraFreeze1} />
                          <span className="intensity-name">Intensywność: <strong>Ultra</strong></span>
                          <div className="intensity frost">
                            <span>15 mg</span>
                            <div className="intensity-dots frost">
                              <div className="full"></div>
                              <div className="full"></div>
                              <div className="full"></div>
                              <div className="full"></div>
                              <div className="full"></div>
                              <div></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flavour">
                        <h4>X-Freeze Max</h4>
                        <p>Intensywna mięta pieprzowa w&nbsp;połączeniu z&nbsp;orzeźwiającym mentolem </p>
                        <div className="pack">
                          <img src={ultraFreeze2} />
                          <span className="intensity-name">Intensywność: <strong>Max</strong></span>
                          <div className="intensity darkred">
                            <span>20 mg</span>
                            <div className="intensity-dots darkred">
                              <div className="full"></div>
                              <div className="full"></div>
                              <div className="full"></div>
                              <div className="full"></div>
                              <div className="full"></div>
                              <div className="full"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>

        <div className="velo-why">
          <div className="wrapper">
            <h2>Dlaczego <span className="color-blue">Velo?</span></h2>
            <div className="profits">
              { profitSlider.map((item,key)=>{
                return (
                  <div className="profit velo-box">
                    <div className="icon"><img src={item?.image} /></div>
                    <h4>{ item.title }</h4>
                    <p>{ item.desc }</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className="velo-steps">
          <div className="wrapper">
            <h2>Jak używać <span className="color-blue">Velo?</span></h2>
            <h4 className="subtitle">4 proste kroki</h4>
            <div className="steps">
              { stepSlider.map((item,key)=>{
                return (
                  <div className="step velo-box">
                    <div className="icon"><img src={item?.icon} /></div>
                    <h4>{ item.title }</h4>
                    <p>{ item.desc }</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className="for-more">
          <div className="wrapper">
            <div className="heading">
              <h3 className="title">chcesz dowiedzieć się więcej? <br/>odwiedź <a href="https://velo.com/" target="_blank">www.velo.com</a></h3>
              <a className="Button" href="https://velo.com/" target="_blank">Odwiedź</a>
            </div>
            <div className="footer-image">
              <picture>
                <source media="(max-width: 1019px)" srcSet={mobileHero} />
                <source media="(min-width: 1020px)" srcSet={desktopHero} />
                <img src={desktopHero} alt="Velo" />
              </picture>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
}

export default Velo;
