import {
  useForm,
  SubmitHandler,
  FieldValues,
  FieldErrors,
} from "react-hook-form"
import Button from '../../../components/Button/Button';

function TaxReportStep1({
  setOpenTab,
  formDataValue,
  streetAddress,
  streetNumber,
  apartmentNumber,
  cityName,
  postalCode,
  handleNextStep,
  handleUpdateFormData
}: any) {
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm()
  const onSubmit = (data: FieldValues, event: any) => {
    event.preventDefault()
    handleUpdateFormData(data);
    handleNextStep();
    // console.log(data,'F')
  }
  console.log(formDataValue,'pierwszy render')
  return (
    <div>
      <div className="desktop-row">
        <div className="input-item edit-icon desktop-margin-right">
          <label htmlFor="streetAddress">Ulica</label>
          <input
            className={errors.streetAddress && "error-input"}
            type="text"
            id="streetAddress"
            {...register("streetAddress", {
              required: true,
              pattern: /^[\s0-9a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]*$/,
            })}
            defaultValue={formDataValue.streetAddress}
          />
          {errors.streetAddress && errors.streetAddress.type === "required" && (
            <p className="error">Uzupełnij pole</p>
          )}
          {errors.streetAddress && errors.streetAddress.type === "pattern" && (
            <p className="error">Nieprawidłowa wartość</p>
          )}
        </div>
        <div className="input-group">
          <div className="input-item edit-icon">
            <label htmlFor="streetNumber">Nr budynku</label>
            <input
              className={errors.streetNumber && "error-input"}
              type="text"
              id="streetNumber"
              {...register("streetNumber", {
                required: true,
                pattern: /^[0-9A-Za-z,-\/]*$/,
              })}
              defaultValue={formDataValue.streetNumber}
            />
            {errors.streetNumber && errors.streetNumber.type === "required" && (
              <p className="error">Uzupełnij pole</p>
            )}
            {errors.streetNumber && errors.streetNumber.type === "pattern" && (
              <p className="error">Nieprawidłowa wartość</p>
            )}
          </div>
          <div className="input-item edit-icon desktop-margin-left">
            <label htmlFor="apartmentNumber">Nr mieszkania</label>
            <input
              className={errors.apartmentNumber && "error-input"}
              type="text"
              id="apartmentNumber"
              {...register("apartmentNumber", {
                required: false,
                pattern: /^[0-9A-Z\/]*$/,
              })}
              defaultValue={formDataValue.apartmentNumber}
            />
            {errors.apartmentNumber &&
              errors.apartmentNumber.type === "pattern" && (
                <p className="error">Nieprawidłowa wartość</p>
              )}
          </div>
        </div>
      </div>
      <div className="">
        <div className="input-group desktop-row">
          <div className="input-item edit-icon desktop-margin-right">
            <label htmlFor="cityName">Miejscowość</label>
            <input
              className={errors.cityName && "error-input"}
              type="text"
              id="cityName"
              {...register("cityName", {
                required: true,
                pattern: /^[\sa-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ-]*$/,
              })}
              defaultValue={formDataValue.cityName}
            />
            {errors.cityName && errors.cityName.type === "required" && (
              <p className="error">Uzupełnij pole</p>
            )}
            {errors.cityName && errors.cityName.type === "pattern" && (
              <p className="error">Nieprawidłowa wartość</p>
            )}
          </div>
          <div className="input-item edit-icon">
            <label htmlFor="postalCode">Kod pocztowy</label>
            <input
              className={errors.postalCode && "error-input"}
              {...register("postalCode", {
                required: true,
                pattern: /^[0-9]{2}-[0-9]{3}$/,
              })}
              defaultValue={formDataValue.postalCode}
            />
            {errors.postalCode && errors.postalCode.type === "required" && (
              <p className="error">Uzupełnij pole</p>
            )}
            {errors.postalCode && errors.postalCode.type === "pattern" && (
              <p className="error">
                Wpisz prawidłowy kod pocztowy w formacie 00-000
              </p>
            )}
          </div>
        </div>
      </div>
      <Button className="next" onClick={handleSubmit(onSubmit)}>Dalej</Button>
    </div>
  )
}

export default TaxReportStep1
