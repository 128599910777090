import { 
  GET_NOTIFICATIONS, 
  CLOSE_NOTIFICATION 
} from "../actions/types";

export function notificationReducer(state = {notifications:[]}, action) {
  switch (action.type) {

    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload.reverse(),
      };
      break;
      case CLOSE_NOTIFICATION:
        return {
          ...state,
          notifications: state.notifications.map((noti) => noti.uuid === action.payload.uuid ? action.payload : noti),
        }
        break;
    default:
      return state;
  }
}

// selectors
export const getNOTI = (state) => state.notifications.notifications;
export const getBellNoti = (state) => state.notifications.notifications.filter((noti) => (!noti.closed));