import { 
  ACCOUNT_BALANCE,
  ACCOUNT_PAYMENT_CREATE,
  ACCOUNT_PAYMENT_REQUEST,
  ACCOUNT_PAYMENT_FAILURE,
  ADD_TOAST,
  REGISTER_SUCCESS_CLEAR,
  ACCOUNT_PAYMENT_HISTORY,
  ACCOUNT_BALANCE_HISTORY,
  ACCOUNT_BALANCE_SUMMARY,
  GET_SEASONS_SUCCESS
} from './types';
import { API_URL, appFetch } from '../../helpers/fetch';
import { errors } from '../../helpers/errors';
import { showDrawer } from './ui';

export function accountBalance(seasonData) {
  return (dispatch, getState) => { 
    const currentState = getState();
    dispatch({ type: REGISTER_SUCCESS_CLEAR, payload: {  } });
    appFetch("POST", API_URL, "/user/account/balance", false, currentState.user?.token?.uuid ? currentState.user.token?.uuid : false, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: ACCOUNT_BALANCE, payload: { balance: result.data.balance, seasons: seasonData } });
        } else {
            //ispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function accountSeasons() {
  return (dispatch, getState) => { 
    const currentState = getState();
    appFetch("POST", API_URL, "/season/list", false, currentState.user?.token?.uuid ? currentState.user.token?.uuid : false, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: GET_SEASONS_SUCCESS, payload: { seasons: result.data.seasons, currentSeason: result.data.current } });
        } else {
            //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function accountPaymentHistory() {
  return (dispatch, getState) => { 
    const currentState = getState();
    dispatch({ type: REGISTER_SUCCESS_CLEAR, payload: {  } });
    appFetch("POST", API_URL, "/user/account/payment-history", false, currentState.user?.token?.uuid ? currentState.user?.token?.uuid : false, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: ACCOUNT_PAYMENT_HISTORY, payload: { balance: result.data.balance } });
        } else {
            //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}


export function balanceHistory(offset, quarter) {
  return (dispatch, getState) => { 
    const data = {
      limit: 100,
      offset: offset || 0,
      quarter: quarter || null
    }
    const currentState = getState();
    dispatch({ type: REGISTER_SUCCESS_CLEAR, payload: {  } });
    appFetch("POST", API_URL, "/user/account/balance-history", data, currentState.user?.token?.uuid ? currentState.user?.token?.uuid : false, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: ACCOUNT_BALANCE_HISTORY, payload: { balance: result.data.balance } });
        } else {
            //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function balanceSummary() {
  return (dispatch, getState) => { 
    const currentState = getState();
    dispatch({ type: REGISTER_SUCCESS_CLEAR, payload: {  } });
    appFetch("POST", API_URL, "/user/account/balance-summary", false, currentState.user?.token?.uuid ? currentState.user?.token?.uuid : false, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: ACCOUNT_BALANCE_SUMMARY, payload: { balance: result.data.balance, referral: result.data.referral } });
        } else {
            //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function payout() {
  return (dispatch, getState) => { 
    const currentState = getState();
    dispatch({ type: ACCOUNT_PAYMENT_REQUEST, payload: {  }});
    appFetch("POST", API_URL, "/user/account/payment-create", false, currentState.user?.token?.uuid ? currentState.user?.token?.uuid : false, (result) => {
      dispatch({ type: ACCOUNT_PAYMENT_CREATE, payload: {paymentStatus: result.data } });
      if(result.status && result.status.success) {
          dispatch(accountBalance());
          dispatch(showDrawer('info', { name:'payout' }))
          //dispatch({ type: ADD_TOAST, payload: { type: "normal", message: "Twoja wypłata jest procesowana" } });
      } else {
        dispatch({ type: ACCOUNT_PAYMENT_FAILURE, payload: {  }});
        dispatch(showDrawer('error', {name: result.data.error, errorCode: result.meta.ts }))
        dispatch({ type: ACCOUNT_PAYMENT_FAILURE, payload: {  }});
      }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}