
export const region = [
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Dolnośląski Urząd Skarbowy we Wrocławiu",
      "adres": "ul. Żmigrodzka 141, 51-130 Wrocław"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Drugi Mazowiecki Urząd Skarbowy w Warszawie",
      "adres": "ul. Stawki 2, 00-193 Warszawa"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Drugi Śląski Urząd Skarbowy w Bielsku-Białej",
      "adres": "ul. Warszawska 45, 43-300 Bielsko-Biała"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Drugi Urząd Skarbowy Kraków",
      "adres": "ul. Krowoderskich Zuchów 2, 31-272 Kraków"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Drugi Urząd Skarbowy Łódź - Bałuty",
      "adres": "ul. Św. Teresy od Dzieciątka Jezus 105, 91-222 Łódź"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Drugi Urząd Skarbowy Łódź - Górna",
      "adres": "ul. Walerego Wróblewskiego 10A, 93-578 Łódź"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Drugi Urząd Skarbowy Warszawa - Śródmieście",
      "adres": "ul. Jagiellońska 15, 03-719 Warszawa"
    },
    {
      "wojewodztwo": "podlaskie",
      "title": "Drugi Urząd Skarbowy w Białymstoku",
      "adres": "ul. Plażowa 17, 15-502 Białystok"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Drugi Urząd Skarbowy w Bielsku-Białej",
      "adres": "ul. Gen. Stanisława Maczka 73, 43-300 Bielsko-Biała"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Drugi Urząd Skarbowy w Bydgoszczy",
      "adres": "ul. Wojska Polskiego 20B, 85-822 Bydgoszcz"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Drugi Urząd Skarbowy w Częstochowie",
      "adres": "ul. Tkacka 3, 42-200 Częstochowa"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Drugi Urząd Skarbowy w Gdańsku",
      "adres": "ul. Kołobrzeska 43, 80-391 Gdańsk"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Drugi Urząd Skarbowy w Gdyni",
      "adres": "ul. Hutnicza 25, 81-061 Gdynia"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Drugi Urząd Skarbowy w Gliwicach",
      "adres": "ul. Młodego Hutnika 2, 44-100 Gliwice"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Drugi Urząd Skarbowy w Kaliszu",
      "adres": "ul. Fabryczna 1a, 62-800 Kalisz"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Drugi Urząd Skarbowy w Katowicach",
      "adres": "ul. Ignacego Paderewskiego 32B, 40-282 Katowice"
    },
    {
      "wojewodztwo": "świętokrzyskie",
      "title": "Drugi Urząd Skarbowy w Kielcach",
      "adres": "ul. Częstochowska 20, 25-647 Kielce"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Drugi Urząd Skarbowy w Koszalinie",
      "adres": "ul. Stanisława Moniuszki 15, 75-549 Koszalin"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Drugi Urząd Skarbowy w Lublinie",
      "adres": "ul. Tadeusza Szeligowskiego 24, 20-883 Lublin"
    },
    {
      "wojewodztwo": "opolskie",
      "title": "Drugi Urząd Skarbowy w Opolu",
      "adres": "ul. płk. Witolda Pileckiego 2, 45-331 Opole"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Drugi Urząd Skarbowy w Radomiu",
      "adres": "ul. Toruńska 3, 26-600 Radom"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Drugi Urząd Skarbowy w Rzeszowie",
      "adres": "ul. Lucjana Siemieńskiego 18, 35-234 Rzeszów"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Drugi Urząd Skarbowy w Szczecinie",
      "adres": "ul. Wacława Felczaka 19, 71-417 Szczecin"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Drugi Urząd Skarbowy w Tarnowie",
      "adres": "ul. Al.Solidarności 5-9B, 33-100 Tarnów"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Drugi Urząd Skarbowy w Toruniu",
      "adres": "ul. Mazowiecka 63, 87-100 Toruń"
    },
    {
      "wojewodztwo": "lubuskie",
      "title": "Drugi Urząd Skarbowy w Zielonej Górze",
      "adres": "ul. Dr. Pieniężnego 24, 65-054 Zielona Góra"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Drugi Wielkopolski Urząd Skarbowy w Kaliszu",
      "adres": "ul. Fabryczna 1A, 62-800 Kalisz"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Kujawsko-Pomorski Urząd Skarbowy w Bydgoszczy",
      "adres": "ul. Grunwaldzka 50, 85-236 Bydgoszcz"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Lubelski Urząd Skarbowy w Lublinie",
      "adres": "ul. Tomasza Zana 38, 20-601 Lublin"
    },
    {
      "wojewodztwo": "lubuskie",
      "title": "Lubuski Urząd Skarbowy w Zielonej Górze",
      "adres": "ul. Dr. Pieniężnego 24, 65-054 Zielona Góra"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Łódzki Urząd Skarbowy w Łodzi",
      "adres": "ul. aleja T. Kościuszki 85, 90-436 Łódź"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Małopolski Urząd Skarbowy w Krakowie",
      "adres": "ul. os. Bohaterów Września 80, 31-621 Kraków"
    },
    {
      "wojewodztwo": "opolskie",
      "title": "Opolski Urząd Skarbowy w Opolu",
      "adres": "ul. Tadeusza Rejtana 3A, 45-331 Opole"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Pierwszy Mazowiecki Urząd Skarbowy w Warszawie",
      "adres": "ul. Alojzego Felińskiego 2A, 01-513 Warszawa"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Pierwszy Śląski Urząd Skarbowy w Sosnowcu",
      "adres": "ul. Braci Mieroszewskich 97, 41-219 Sosnowiec"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Pierwszy Urząd Skarbowy Kraków",
      "adres": "ul. Rzemieślnicza 20, 30-363 Kraków"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Pierwszy Urząd Skarbowy Łódź - Bałuty",
      "adres": "ul. Zachodnia 47, 91-066 Łódź"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Pierwszy Urząd Skarbowy Łódź - Górna",
      "adres": "ul. Walerego Wróblewskiego 10, 93-578 Łódź"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Pierwszy Urząd Skarbowy Warszawa - Śródmieście",
      "adres": "ul. Williama Lindleya 14, 02-013 Warszawa"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Pierwszy Urząd Skarbowy we Wrocławiu",
      "adres": "ul. Ks. Czesława Klimasa 34, 50-515 Wrocław"
    },
    {
      "wojewodztwo": "podlaskie",
      "title": "Pierwszy Urząd Skarbowy w Białymstoku",
      "adres": "ul. Świętojańska 13, 15-219 Białystok"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Pierwszy Urząd Skarbowy w Bielsku-Białej",
      "adres": "ul. Teodora Sixta 17, 43-300 Bielsko-Biała"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Pierwszy Urząd Skarbowy w Bydgoszczy",
      "adres": "ul. Fordońska 77, 85-950 Bydgoszcz"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Pierwszy Urząd Skarbowy w Częstochowie",
      "adres": "ul. Filomatów 18/20, 42-217 Częstochowa"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Pierwszy Urząd Skarbowy w Gdańsku",
      "adres": "ul. Rzeźnicka 54/56, 80-822 Gdańsk"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Pierwszy Urząd Skarbowy w Gdyni",
      "adres": "ul. Władysława IV 2/4, 81-353 Gdynia"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Pierwszy Urząd Skarbowy w Gliwicach",
      "adres": "ul. Góry Chełmskiej 15, 44-100 Gliwice"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Pierwszy Urząd Skarbowy w Kaliszu",
      "adres": "ul. Wrocławska 12, 62-800 Kalisz"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Pierwszy Urząd Skarbowy w Katowicach",
      "adres": "ul. Żwirki i Wigury 17, 40-063 Katowice"
    },
    {
      "wojewodztwo": "świętokrzyskie",
      "title": "Pierwszy Urząd Skarbowy w Kielcach",
      "adres": "ul. Wróbla 17, 25-661 Kielce"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Pierwszy Urząd Skarbowy w Koszalinie",
      "adres": "ul. Przemysłowa 3, 75-216 Koszalin"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Pierwszy Urząd Skarbowy w Lublinie",
      "adres": "ul. Sądowa 5, 20-027 Lublin"
    },
    {
      "wojewodztwo": "opolskie",
      "title": "Pierwszy Urząd Skarbowy w Opolu",
      "adres": "ul. Tadeusza Rejtana 3B, 45-331 Opole"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Pierwszy Urząd Skarbowy w Poznaniu",
      "adres": "ul. Dolna Wilda 80, 61-501 Poznań"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Pierwszy Urząd Skarbowy w Radomiu",
      "adres": "ul. Stanisława Zbrowskiego 106, 26-615 Radom"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Pierwszy Urząd Skarbowy w Rzeszowie",
      "adres": "ul. Podwisłocze 42, 35-309 Rzeszów"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Pierwszy Urząd Skarbowy w Szczecinie",
      "adres": "ul. Michała Drzymały 5, 70-217 Szczecin"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Pierwszy Urząd Skarbowy w Tarnowie",
      "adres": "ul. Lwowska 72-96A, 33-100 Tarnów"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Pierwszy Urząd Skarbowy w Toruniu",
      "adres": "ul. Szosa Chełmińska 34/36, 87-100 Toruń"
    },
    {
      "wojewodztwo": "lubuskie",
      "title": "Pierwszy Urząd Skarbowy w Zielonej Górze",
      "adres": "ul. Dr. Pieniężnego 24, 65-054 Zielona Góra"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Pierwszy Wielkopolski Urząd Skarbowy w Poznaniu",
      "adres": "ul. pl. Cyryla Ratajskiego 5, 61-726 Poznań"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Podkarpacki Urząd Skarbowy w Rzeszowie",
      "adres": "ul. Geodetów 1, 35-959 Rzeszów"
    },
    {
      "wojewodztwo": "podlaskie",
      "title": "Podlaski Urząd Skarbowy w Białymstoku",
      "adres": "ul. Młynowa 21A, 15-404 Białystok"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Pomorski Urząd Skarbowy w Gdańsku",
      "adres": "ul. Żytnia 4/6, 80-749 Gdańsk"
    },
    {
      "wojewodztwo": "świętokrzyskie",
      "title": "Świętokrzyski Urząd Skarbowy w Kielcach",
      "adres": "ul. Częstochowska 20, 25-647 Kielce"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Trzeci Mazowiecki Urząd Skarbowy w Radomiu",
      "adres": "ul. Andrzeja Struga 26/28, 26-610 Radom"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Trzeci Urząd Skarbowy Warszawa - Śródmieście",
      "adres": "ul. Williama Lindleya 14, 02-013 Warszawa"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Trzeci Urząd Skarbowy w Bydgoszczy",
      "adres": "ul. Tadeusza Rejtana 5, 85-032 Bydgoszcz"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Trzeci Urząd Skarbowy w Gdańsku",
      "adres": "ul. Chłopska 3, 80-362 Gdańsk"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Trzeci Urząd Skarbowy w Lublinie",
      "adres": "ul. Prezydenta Gabriela Narutowicza 56, 20-016 Lublin"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Trzeci Urząd Skarbowy w Szczecinie",
      "adres": "ul. Lucjana Rydla 65, 70-783 Szczecin"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy Kraków - Krowodrza",
      "adres": "ul. Krowoderskich Zuchów 2, 31-272 Kraków"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy Kraków - Nowa Huta",
      "adres": "ul. os. Bohaterów Września 80, 31-621 Kraków"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy Kraków - Podgórze",
      "adres": "ul. Wadowicka 10, 30-415 Kraków"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy Kraków - Prądnik",
      "adres": "ul. Krowoderskich Zuchów 2, 31-272 Kraków"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy Kraków - Stare Miasto",
      "adres": "ul. Grodzka 65, 31-001 Kraków"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy Kraków - Śródmieście",
      "adres": "ul. Krowoderskich Zuchów 2, 31-272 Kraków"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy Łódź - Polesie",
      "adres": "ul. 6 Sierpnia 86A, 90-646 Łódź"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy Łódź - Śródmieście",
      "adres": "ul. Dowborczyków 9/11, 90-019 Łódź"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy Łódź - Widzew",
      "adres": "ul. Papiernicza 7, 92-312 Łódź"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy Poznań - Grunwald",
      "adres": "ul. Mariana Smoluchowskiego 1, 60-179 Poznań"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy Poznań - Jeżyce",
      "adres": "ul. Strzelecka 2/6, 61-845 Poznań"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy Poznań - Nowe Miasto",
      "adres": "ul. Dezyderego Chłapowskiego 17/18, 60-965 Poznań"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy Poznań - Wilda",
      "adres": "ul. Dolna Wilda 80B, 61-501 Poznań"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy Poznań - Winogrady",
      "adres": "ul. Zygmunta Wojciechowskiego 3/5, 60-685 Poznań"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy Warszawa - Bemowo",
      "adres": "ul. Białobrzeska 53A, 02-325 Warszawa"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy Warszawa - Bielany",
      "adres": "ul. Skalbmierska 5, 01-844 Warszawa"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy Warszawa - Mokotów",
      "adres": "ul. Postępu 16A, 02-676 Warszawa"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy Warszawa - Praga",
      "adres": "ul. Jagiellońska 15, 03-719 Warszawa"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy Warszawa - Targówek",
      "adres": "ul. Dąbrowszczaków 14, 03-484 Warszawa"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy Warszawa - Ursynów",
      "adres": "ul. Wynalazek 3, 02-677 Warszawa"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy Warszawa - Wawer",
      "adres": "ul. Ludwika Mycielskiego 21, 04-379 Warszawa"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy Warszawa - Wola",
      "adres": "ul. Powstańców Śląskich 2D, 01-381 Warszawa"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Urząd Skarbowy we Włocławku",
      "adres": "ul. Stefana Okrzei 72B, 87-800 Włocławek"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Urząd Skarbowy we Włodawie",
      "adres": "ul. Rynek 9, 22-200 Włodawa"
    },
    {
      "wojewodztwo": "świętokrzyskie",
      "title": "Urząd Skarbowy we Włoszczowie",
      "adres": "ul. Wiśniowa 10, 29-100 Włoszczowa"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy we Wrześni",
      "adres": "ul. Warszawska 26, 62-300 Września"
    },
    {
      "wojewodztwo": "lubuskie",
      "title": "Urząd Skarbowy we Wschowie",
      "adres": "ul. Zielony Rynek 7, 67-400 Wschowa"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy Wrocław - Fabryczna",
      "adres": "ul. Aleksandra Ostrowskiego 5, 53-238 Wrocław"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy Wrocław - Krzyki",
      "adres": "ul. Sztabowa 100, 53-310 Wrocław"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy Wrocław - Psie Pole",
      "adres": "ul. Trzebnicka 33, 50-231 Wrocław"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy Wrocław - Stare Miasto",
      "adres": "ul. Inowrocławska 4, 53-654 Wrocław"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy Wrocław - Śródmieście",
      "adres": "ul. marsz. Józefa Piłsudskiego 27-29, 50-044 Wrocław"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Urząd Skarbowy w Aleksandrowie Kujawskim",
      "adres": "ul. Kościelna 18, 87-700 Aleksandrów Kujawski"
    },
    {
      "wojewodztwo": "podlaskie",
      "title": "Urząd Skarbowy w Augustowie",
      "adres": "ul. Żabia 7, 16-300 Augustów"
    },
    {
      "wojewodztwo": "warmińsko-mazurskie",
      "title": "Urząd Skarbowy w Bartoszycach",
      "adres": "ul. Boh. Warszawy 5, 11-200 Bartoszyce"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Bełchatowie",
      "adres": "ul. Józefa Piłsudskiego 18, 97-400 Bełchatów"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Będzinie",
      "adres": "ul. Józefa Retingera 1, 42-500 Będzin"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Urząd Skarbowy w Białej Podlaskiej",
      "adres": "ul. Prosta 19, 21-500 Biała Podlaska"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Białobrzegach",
      "adres": "ul. Władysława Stanisława Reymonta 46, 26-800 Białobrzegi"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Urząd Skarbowy w Białogardzie",
      "adres": "ul. Mickiewicza 3, 78-200 Białogard"
    },
    {
      "wojewodztwo": "podlaskie",
      "title": "Urząd Skarbowy w Bielsku Podlaskim",
      "adres": "ul. Adama Mickiewicza 33, 17-100 Bielsk Podlaski"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Urząd Skarbowy w Biłgoraju",
      "adres": "ul. Tadeusza Kościuszki 78, 23-400 Biłgoraj"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy w Bochni",
      "adres": "ul. Gołębia 3, 32-700 Bochnia"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Bolesławcu",
      "adres": "ul. Garncarska 10, 59-700 Bolesławiec"
    },
    {
      "wojewodztwo": "warmińsko-mazurskie",
      "title": "Urząd Skarbowy w Braniewie",
      "adres": "ul. Jana Matejki 6, 14-500 Braniewo"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Urząd Skarbowy w Brodnicy",
      "adres": "ul. Gen. Władysława Sikorskiego 19, 87-300 Brodnica"
    },
    {
      "wojewodztwo": "opolskie",
      "title": "Urząd Skarbowy w Brzegu",
      "adres": "ul. Składowa 2, 49-305 Brzeg"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy w Brzesku",
      "adres": "ul. Królowej Jadwigi 16, 32-800 Brzesko"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Brzezinach",
      "adres": "ul. Henryka Sienkiewicza 16, 95-060 Brzeziny"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Brzozowie",
      "adres": "ul. Kazimierzowska 1, 36-200 Brzozów"
    },
    {
      "wojewodztwo": "świętokrzyskie",
      "title": "Urząd Skarbowy w Busku-Zdroju",
      "adres": "ul. Parkowa 17, 28-100 Busko-Zdrój"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Bystrzycy Kłodzkiej",
      "adres": "ul. Adama Mickiewicza 5, 57-500 Bystrzyca Kłodzka"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Bytomiu",
      "adres": "ul. Wrocławska 92, 41-902 Bytom"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Urząd Skarbowy w Bytowie",
      "adres": "ul. 1 Maja 16, 77-100 Bytów"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Urząd Skarbowy w Chełmie",
      "adres": "ul. Obłońska 20A, 22-100 Chełm"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Urząd Skarbowy w Chełmnie",
      "adres": "ul. Grudziądzka 6, 86-200 Chełmno"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Chodzieży",
      "adres": "ul. Ofiar Gór Morzewskich 1, 64-800 Chodzież"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Urząd Skarbowy w Chojnicach",
      "adres": "ul. Młyńska 22, 89-600 Chojnice"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Chorzowie",
      "adres": "ul. Armii Krajowej 5, 41-506 Chorzów"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Urząd Skarbowy w Choszcznie",
      "adres": "ul. Lipcowa 16, 73-200 Choszczno"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy w Chrzanowie",
      "adres": "ul. Garncarska 9, 32-500 Chrzanów"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Ciechanowie",
      "adres": "ul. Warszawska 58, 06-400 Ciechanów"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Cieszynie",
      "adres": "ul. Ignacego Kraszewskiego 4, 43-400 Cieszyn"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Czarnkowie",
      "adres": "ul. Wodna 8, 64-700 Czarnków"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Czechowicach-Dziedzicach",
      "adres": "ul. Nad Białką 1A, 43-502 Czechowice-Dziedzice"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Urząd Skarbowy w Człuchowie",
      "adres": "ul. Jerzego z Dąbrowy 5E, 77-300 Człuchów"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Dąbrowie Górniczej",
      "adres": "ul. Zygmunta Krasińskiego 33A, 41-300 Dąbrowa Górnicza"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy w Dąbrowie Tarnowskiej",
      "adres": "ul. Berka Joselewicza 5, 33-200 Dąbrowa Tarnowska"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Dębicy",
      "adres": "ul. Kolejowa 21, 39-200 Dębica"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Urząd Skarbowy w Drawsku Pomorskim",
      "adres": "ul. Józefa Piłsudskiego 35-37, 78-500 Drawsko Pomorskie"
    },
    {
      "wojewodztwo": "lubuskie",
      "title": "Urząd Skarbowy w Drezdenku",
      "adres": "ul. Pierwszej Brygady 21, 66-530 Drezdenko"
    },
    {
      "wojewodztwo": "warmińsko-mazurskie",
      "title": "Urząd Skarbowy w Działdowie",
      "adres": "ul. Marii Skłodowskiej-Curie 35A, 13-200 Działdowo"
    },
    {
      "wojewodztwo": "warmińsko-mazurskie",
      "title": "Urząd Skarbowy w Elblągu",
      "adres": "ul. Adama Mickiewicza 43, 82-300 Elbląg"
    },
    {
      "wojewodztwo": "warmińsko-mazurskie",
      "title": "Urząd Skarbowy w Ełku",
      "adres": "ul. Wojska Polskiego 67, 19-300 Ełk"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Garwolinie",
      "adres": "ul. Senatorska 30, 08-400 Garwolin"
    },
    {
      "wojewodztwo": "warmińsko-mazurskie",
      "title": "Urząd Skarbowy w Giżycku",
      "adres": "ul. Tadeusza Kościuszki 15, 11-500 Giżycko"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Głogowie",
      "adres": "ul. Adama Mickiewicza 53, 67-200 Głogów"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Głownie",
      "adres": "ul. Ludwika Norblina 2, 95-015 Głowno"
    },
    {
      "wojewodztwo": "opolskie",
      "title": "Urząd Skarbowy w Głubczycach",
      "adres": "ul. Fabryczna 2, 48-100 Głubczyce"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Gnieźnie",
      "adres": "ul. Spichrzowa 4, 62-200 Gniezno"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Urząd Skarbowy w Goleniowie",
      "adres": "Plac Lotników 1, 72-100 Goleniów"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Urząd Skarbowy w Golubiu-Dobrzyniu",
      "adres": "ul. Dr. Jerzego Gerarda Koppa 1A, 87-400 Golub-Dobrzyń"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy w Gorlicach",
      "adres": "ul. Bardiowska 9, 38-300 Gorlice"
    },
    {
      "wojewodztwo": "lubuskie",
      "title": "Urząd Skarbowy w Gorzowie Wielkopolskim",
      "adres": "ul. Kazimierza Jagiellończyka 10, 66-400 Gorzów Wielkopolski"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Gostyninie",
      "adres": "ul. Płocka 10, 09-500 Gostynin"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Gostyniu",
      "adres": "ul. Lipowa 2, 63-800 Gostyń"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd skarbowy w Górze",
      "adres": "ul. Poznańska 4, 56-200 Góra"
    },
    {
      "wojewodztwo": "podlaskie",
      "title": "Urząd Skarbowy w Grajewie",
      "adres": "ul. Elewatorska 8, 19-200 Grajewo"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Grodzisku Mazowieckim",
      "adres": "ul. Daleka 22, 05-825 Grodzisk Mazowiecki"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Grodzisku Wielkopolskim",
      "adres": "ul. Chopina 7, 62-065 Grodzisk Wielkopolski"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Grójcu",
      "adres": "ul. Ks. Piotra Skargi 35, 05-600 Grójec"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Urząd Skarbowy w Grudziądzu",
      "adres": "ul. Droga Łąkowa 23, 86-300 Grudziądz"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Urząd Skarbowy w Gryficach",
      "adres": "ul. Niepodległości 54A, 72-300 Gryfice"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Urząd Skarbowy w Gryfinie",
      "adres": "ul. Szczecińska 24, 74-100 Gryfino"
    },
    {
      "wojewodztwo": "podlaskie",
      "title": "Urząd Skarbowy w Hajnówce",
      "adres": "ul. 3 Maja 34, 17-200 Hajnówka"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Urząd Skarbowy w Hrubieszowie",
      "adres": "ul. 27 Wołyńskiej Dywizji Piechoty AK 11, 22-500 Hrubieszów"
    },
    {
      "wojewodztwo": "warmińsko-mazurskie",
      "title": "Urząd Skarbowy w Iławie",
      "adres": "ul. Wojska Polskiego 26, 14-200 Iława"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Urząd Skarbowy w Inowrocławiu",
      "adres": "al. Niepodległości 5, 88-100 Inowrocław"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Urząd Skarbowy w Janowie Lubelskim",
      "adres": "ul. Wojska Polskiego 32, 23-300 Janów Lubelski"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Jarocinie",
      "adres": "ul. Tadeusza Kościuszki 21, 63-200 Jarocin"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Jarosławiu",
      "adres": "ul. Przemysłowa 2A, 37-500 Jarosław"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Jastrzębiu-Zdroju",
      "adres": "ul. 11 Listopada 13, 44-335 Jastrzębie-Zdrój"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Jaśle",
      "adres": "ul. Stanisława Staszica 3, 38-200 Jasło"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Jaworze",
      "adres": "ul. Jurija Gagarina 5, 59-400 Jawor"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Jaworznie",
      "adres": "ul. Grunwaldzka 274A, 43-600 Jaworzno"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Jeleniej Górze",
      "adres": "ul. Adama Thebesiusa 1, 58-506 Jelenia Góra"
    },
    {
      "wojewodztwo": "świętokrzyskie",
      "title": "Urząd Skarbowy w Jędrzejowie",
      "adres": "ul. 11 Listopada 33, 28-300 Jędrzejów"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Urząd Skarbowy w Kamieniu Pomorskim",
      "adres": "ul. Mieszka I 5B, 72-400 Kamień Pomorski"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Kamiennej Górze",
      "adres": "ul. Papieża Jana Pawła II 18, 58-400 Kamienna Góra"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Urząd Skarbowy w Kartuzach",
      "adres": "ul. Kościerska 13, 83-300 Kartuzy"
    },
    {
      "wojewodztwo": "świętokrzyskie",
      "title": "Urząd Skarbowy w Kazimierzy Wielkiej",
      "adres": "ul. Tadeusza Kościuszki 16, 28-500 Kazimierza Wielka"
    },
    {
      "wojewodztwo": "opolskie",
      "title": "Urząd Skarbowy w Kędzierzynie-Koźlu",
      "adres": "ul. Piotra Skargi 19, 47-224 Kędzierzyn-Koźle"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Kępnie",
      "adres": "ul. Rynek 4, 63-600 Kępno"
    },
    {
      "wojewodztwo": "warmińsko-mazurskie",
      "title": "Urząd Skarbowy w Kętrzynie",
      "adres": "ul. Powstańców Warszawy 13, 11-400 Kętrzyn"
    },
    {
      "wojewodztwo": "opolskie",
      "title": "Urząd Skarbowy w Kluczborku",
      "adres": "ul. Sienkiewicza 22A, 46-200 Kluczbork"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Kłobucku",
      "adres": "ul. Rynek im. Jana Pawła II 13, 42-100 Kłobuck"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Kłodzku",
      "adres": "ul. Stanisławy Walasiewiczówny 1, 57-300 Kłodzko"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Kolbuszowej",
      "adres": "ul. Tadeusza Kościuszki 20, 36-100 Kolbuszowa"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Kole",
      "adres": "ul. Bolesława Prusa 10, 62-600 Koło"
    },
    {
      "wojewodztwo": "podlaskie",
      "title": "Urząd Skarbowy w Kolnie",
      "adres": "ul. Wojska Polskiego 20, 18-500 Kolno"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Urząd Skarbowy w Kołobrzegu",
      "adres": "ul. Armii Krajowej 2, 78-100 Kołobrzeg"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Koninie",
      "adres": "ul. Zakładowa 7A, 62-510 Konin"
    },
    {
      "wojewodztwo": "świętokrzyskie",
      "title": "Urząd Skarbowy w Końskich",
      "adres": "ul. Marszałka Piłsudskiego 156B, 26-200 Końskie"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Kościanie",
      "adres": "ul. Młyńska 5, 64-000 Kościan"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Urząd Skarbowy w Kościerzynie",
      "adres": "ul. Staszica 6, 83-400 Kościerzyna"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Kozienicach",
      "adres": "ul. Parkowa 5, 26-900 Kozienice"
    },
    {
      "wojewodztwo": "opolskie",
      "title": "Urząd Skarbowy w Krapkowicach",
      "adres": "ul. Opolska 96A, 47-300 Krapkowice"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Urząd Skarbowy w Krasnymstawie",
      "adres": "ul. Rzeczna 5, 22-300 Krasnystaw"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Urząd Skarbowy w Kraśniku",
      "adres": "ul. Tadeusza Kościuszki 5, 23-200 Kraśnik"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Krośnie",
      "adres": "ul. Składowa 5, 38-400 Krosno"
    },
    {
      "wojewodztwo": "lubuskie",
      "title": "Urząd Skarbowy w Krośnie Odrzańskim",
      "adres": "ul. Słubicka 3, 66-600 Krosno Odrzańskie"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Krotoszynie",
      "adres": "ul. Polna 32, 63-700 Krotoszyn"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Kutnie",
      "adres": "ul. Dr. Antoniego Troczewskiego 12, 99-300 Kutno"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Urząd Skarbowy w Kwidzynie",
      "adres": "ul. 3 Maja 6, 82-500 Kwidzyn"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Legionowie",
      "adres": "ul. Marszałka Józefa Piłsudskiego 43C, 05-120 Legionowo"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Legnicy",
      "adres": "ul. Wrocławska 37, 59-220 Legnica"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Lesku",
      "adres": "ul. Rynek 1, 38-600 Lesko"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Lesznie",
      "adres": "ul. Adama Mickiewicza 7, 64-100 Leszno"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Leżajsku",
      "adres": "Plac Targowy 3, 37-300 Leżajsk"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Urząd Skarbowy w Lęborku",
      "adres": "ul. Słupska 23, 84-300 Lębork"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy w Limanowej",
      "adres": "ul. Matki Boskiej Bolesnej 9, 34-600 Limanowa"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Urząd Skarbowy w Lipnie",
      "adres": "ul. Stanisława Staszica 4, 87-600 Lipno"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Lipsku",
      "adres": "ul. Solecka 88, 27-300 Lipsko"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Lubaczowie",
      "adres": "ul. Jana III Sobieskiego 6, 37-600 Lubaczów"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Lubaniu",
      "adres": "ul. Stara 1, 59-800 Lubań"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Urząd Skarbowy w Lubartowie",
      "adres": "ul. Legionów 55, 21-100 Lubartów"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Lubinie",
      "adres": "ul. Marii Skłodowskiej-Curie 94, 59-300 Lubin"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Lublińcu",
      "adres": "ul. Ignacego Paderewskiego 7B, 42-700 Lubliniec"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Lwówku Śląskim",
      "adres": "ul. Budowlanych 1, 59-600 Lwówek Śląski"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Łańcucie",
      "adres": "ul. Józefa Piłsudskiego 11, 37-100 Łańcut"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Łasku",
      "adres": "ul. 9 Maja 31, 98-100 Łask"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Urząd Skarbowy w Łęcznej",
      "adres": "ul. Aleja Jana Pawła II 95, 21-010 Łęczna"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Łęczycy",
      "adres": "al. Jana Pawła II 17, 99-100 Łęczyca"
    },
    {
      "wojewodztwo": "podlaskie",
      "title": "Urząd Skarbowy w Łomży",
      "adres": "ul. Polowa 47, 18-400 Łomża"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Łosicach",
      "adres": "ul. 11 Listopada 1, 08-200 Łosice"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Łowiczu",
      "adres": "ul. Józefa Chełmońskiego 2, 99-400 Łowicz"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Urząd Skarbowy w Łukowie",
      "adres": "ul. Międzyrzecka 72A, 21-400 Łuków"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Makowie Mazowieckim",
      "adres": "ul. Kopernika 6C, 06-200 Maków Mazowiecki"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Urząd Skarbowy w Malborku",
      "adres": "ul. Mikołaja Kopernika 10, 82-200 Malbork"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy w Miechowie",
      "adres": "ul. Stanisławy Daneckiej 1, 32-200 Miechów"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Mielcu",
      "adres": "ul. Żeromskiego 13, 39-300 Mielec"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Międzychodzie",
      "adres": "ul. Marszałka Piłsudskiego 2, 64-400 Międzychód"
    },
    {
      "wojewodztwo": "lubuskie",
      "title": "Urząd Skarbowy w Międzyrzeczu",
      "adres": "ul. Rynek 3, 66-300 Międzyrzecz"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Mikołowie",
      "adres": "ul. prof. Maksymiliana Hubera 4, 43-190 Mikołów"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Miliczu",
      "adres": "ul. Składowa 2, 56-300 Milicz"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Mińsku Mazowieckim",
      "adres": "ul. Szczecińska 2, 05-300 Mińsk Mazowiecki"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Mławie",
      "adres": "ul. Gabriela Narutowicza 19/7, 06-500 Mława"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Urząd Skarbowy w Mogilnie",
      "adres": "ul. 900-lecia 20, 88-300 Mogilno"
    },
    {
      "wojewodztwo": "podlaskie",
      "title": "Urząd Skarbowy w Mońkach",
      "adres": "ul. Ełcka 41A, 19-100 Mońki"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Mysłowicach",
      "adres": "ul. Adama Mickiewicza 4, 41-400 Mysłowice"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Myszkowie",
      "adres": "ul. Kazimierza Pułaskiego 68, 42-300 Myszków"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy w Myślenicach",
      "adres": "ul. Hipolita Cegielskiego 2, 32-400 Myślenice"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Urząd Skarbowy w Myśliborzu",
      "adres": "ul. Witolda Pileckiego 18, 74-300 Myślibórz"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Urząd Skarbowy w Nakle nad Notecią",
      "adres": "ul. Sądowa 8, 89-100 Nakło nad Notecią"
    },
    {
      "wojewodztwo": "opolskie",
      "title": "Urząd Skarbowy w Namysłowie",
      "adres": "ul. Marii Skłodowskiej-Curie 12, 46-100 Namysłów"
    },
    {
      "wojewodztwo": "warmińsko-mazurskie",
      "title": "Urząd Skarbowy w Nidzicy",
      "adres": "ul. Żeromskiego 11, 13-100 Nidzica"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Nisku",
      "adres": "ul. 3 Maja 32B, 37-400 Nisko"
    },
    {
      "wojewodztwo": "lubuskie",
      "title": "Urząd Skarbowy w Nowej Soli",
      "adres": "ul. Staszica 1, 67-100 Nowa Sól"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Nowym Dworze Mazowieckim",
      "adres": "ul. Legionów 7, 05-100 Nowy Dwór Mazowiecki"
    },
    {
      "wojewodztwo": "warmińsko-mazurskie",
      "title": "Urząd Skarbowy w Nowym Mieście Lubawskim",
      "adres": "ul. Działyńskich 3, 13-300 Nowe Miasto Lubawskie"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy w Nowym Sączu",
      "adres": "ul. Władysława Barbackiego 10, 33-300 Nowy Sącz"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy w Nowym Targu",
      "adres": "ul. Parkowa 13, 34-400 Nowy Targ"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Nowym Tomyślu",
      "adres": "ul. Kolejowa 38, 64-300 Nowy Tomyśl"
    },
    {
      "wojewodztwo": "opolskie",
      "title": "Urząd Skarbowy w Nysie",
      "adres": "ul. Bolesława Krzywoustego 23, 48-300 Nysa"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Obornikach",
      "adres": "ul. Rynek 4, 64-600 Oborniki"
    },
    {
      "wojewodztwo": "warmińsko-mazurskie",
      "title": "Urząd Skarbowy w Olecku",
      "adres": "ul. Wojska Polskiego 7, 19-400 Olecko"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Oleśnicy",
      "adres": "ul. Lwowska 34-38, 56-400 Oleśnica"
    },
    {
      "wojewodztwo": "opolskie",
      "title": "Urząd Skarbowy w Oleśnie",
      "adres": "ul. Jana Pieloka 21, 46-300 Olesno"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy w Olkuszu",
      "adres": "ul. Budowlanych 2, 32-300 Olkusz"
    },
    {
      "wojewodztwo": "warmińsko-mazurskie",
      "title": "Urząd Skarbowy w Olsztynie",
      "adres": "ul. Aleja Marszałka Józefa Piłsudskiego 59, 10-950 Olsztyn"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Oławie",
      "adres": "ul. Lwowska 1, 55-200 Oława"
    },
    {
      "wojewodztwo": "świętokrzyskie",
      "title": "Urząd Skarbowy w Opatowie",
      "adres": "ul. Jana Kilińskiego 9, 27-500 Opatów"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Opocznie",
      "adres": "ul. Piotrkowska 14, 26-300 Opoczno"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Urząd Skarbowy w Opolu Lubelskim",
      "adres": "ul. Józefa Piłsudskiego 12, 24-300 Opole Lubelskie"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Ostrołęce",
      "adres": "ul. Przechodnia 6, 07-410 Ostrołęka"
    },
    {
      "wojewodztwo": "świętokrzyskie",
      "title": "Urząd Skarbowy w Ostrowcu Świętokrzyskim",
      "adres": "ul. Polna 11, 27-400 Ostrowiec Świętokrzyski"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Ostrowie Wielkopolskim",
      "adres": "ul. Chłapowskiego 45, 63-400 Ostrów Wielkopolski"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Ostrowi Mazowieckiej",
      "adres": "ul. Stanisława Duboisa 1, 07-300 Ostrów Mazowiecka"
    },
    {
      "wojewodztwo": "warmińsko-mazurskie",
      "title": "Urząd Skarbowy w Ostródzie",
      "adres": "ul. Olsztyńska 5B, 14-100 Ostróda"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Ostrzeszowie",
      "adres": "ul. Władysława Grabskiego 1, 63-500 Ostrzeszów"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy w Oświęcimiu",
      "adres": "ul. Plebańska 2, 32-600 Oświęcim"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Otwocku",
      "adres": "ul. Jana Matejki 4, 05-400 Otwock"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Pabianicach",
      "adres": "ul. Zamkowa 26, 95-200 Pabianice"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Pajęcznie",
      "adres": "ul. 1 Maja 9, 98-330 Pajęczno"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Urząd Skarbowy w Parczewie",
      "adres": "ul. Żabia 2a, 21-200 Parczew"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Piasecznie",
      "adres": "ul. Energetyczna 5, 05-500 Piaseczno"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Piekarach Śląskich",
      "adres": "ul. Bytomska 92, 41-940 Piekary Śląskie"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Pile",
      "adres": "ul. Kossaka 106, 64-920 Piła"
    },
    {
      "wojewodztwo": "świętokrzyskie",
      "title": "Urząd Skarbowy w Pińczowie",
      "adres": "ul. Grodziskowa 1, 28-400 Pińczów"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Piotrkowie Trybunalskim",
      "adres": "ul. Wronia 65, 97-300 Piotrków Trybunalski"
    },
    {
      "wojewodztwo": "warmińsko-mazurskie",
      "title": "Urząd Skarbowy w Piszu",
      "adres": "ul. Okopowa 2, 12-200 Pisz"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Pleszewie",
      "adres": "ul. M. Bogusza 6, 63-300 Pleszew"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Płocku",
      "adres": "ul. 1 Maja 10, 09-402 Płock"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Płońsku",
      "adres": "ul. Spółdzielcza 2, 09-100 Płońsk"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Poddębicach",
      "adres": "ul. Narutowicza 12, 99-200 Poddębice"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Polkowicach",
      "adres": "ul. Zachodnia 10, 59-100 Polkowice"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy w Proszowicach",
      "adres": "ul. Mikołaja Reja 1, 32-100 Proszowice"
    },
    {
      "wojewodztwo": "opolskie",
      "title": "Urząd Skarbowy w Prudniku",
      "adres": "ul. Mikołaja Kopernika 1A, 48-200 Prudnik"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Urząd Skarbowy w Pruszczu Gdańskim",
      "adres": "ul. Ignacego Łukasiewicza 2, 83-000 Pruszcz Gdański"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Pruszkowie",
      "adres": "ul. Stanisława Staszica 1, 05-800 Pruszków"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Przasnyszu",
      "adres": "ul. Świerkowa 3, 06-300 Przasnysz"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Przemyślu",
      "adres": "ul. Lwowska 9A, 37-700 Przemyśl"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Przeworsku",
      "adres": "ul. Tysiąclecia 1, 37-200 Przeworsk"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Przysusze",
      "adres": "ul. Szkolna 7, 26-400 Przysucha"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Pszczynie",
      "adres": "ul. 3 Maja 4, 43-200 Pszczyna"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Urząd Skarbowy w Pucku",
      "adres": "ul. Kmdr. E. Szystowskiego 18, 84-100 Puck"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Urząd Skarbowy w Puławach",
      "adres": "ul. Władysława Grabskiego 4, 24-100 Puławy"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Pułtusku",
      "adres": "ul. Żwirki i Wigury 7, 06-100 Pułtusk"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Urząd Skarbowy w Pyrzycach",
      "adres": "ul. 1 Maja 16, 74-200 Pyrzyce"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Raciborzu",
      "adres": "ul. Michała Drzymały 32, 47-400 Racibórz"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Radomsku",
      "adres": "ul. Mickiewicza 4, 97-500 Radomsko"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Urząd Skarbowy w Radziejowie",
      "adres": "ul. Rolnicza 6, 88-200 Radziejów"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Urząd Skarbowy w Radzyniu Podlaskim",
      "adres": "ul. Lubelska 1/2, 21-300 Radzyń Podlaski"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Rawiczu",
      "adres": "ul. Stanisława Staszica 4, 63-900 Rawicz"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Rawie Mazowieckiej",
      "adres": "ul. Juliusza Słowackiego 4, 96-200 Rawa Mazowiecka"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Ropczycach",
      "adres": "ul. Świętej Barbary 12, 39-100 Ropczyce"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Rudzie Śląskiej",
      "adres": "ul. Kokotek 6, 41-700 Ruda Śląska"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Rybniku",
      "adres": "ul. Pl. Armii Krajowej 3, 44-200 Rybnik"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Urząd Skarbowy w Rykach",
      "adres": "ul. Leona Wyczółkowskiego 10A, 08-500 Ryki"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Urząd Skarbowy w Rypinie",
      "adres": "ul. Dojazdowa 10, 87-500 Rypin"
    },
    {
      "wojewodztwo": "świętokrzyskie",
      "title": "Urząd Skarbowy w Sandomierzu",
      "adres": "ul. Żydowska 1, 27-600 Sandomierz"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Sanoku",
      "adres": "ul. Henryka Sienkiewicza 1, 38-500 Sanok"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Urząd Skarbowy w Sępólnie Krajeńskim",
      "adres": "ul. Tadeusza Kościuszki 22, 89-400 Sępólno Krajeńskie"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Siedlcach",
      "adres": "ul. Bp. Ignacego Świrskiego 45, 08-110 Siedlce"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Siemianowicach Śląskich",
      "adres": "ul. Śląska 84, 41-100 Siemianowice Śląskie"
    },
    {
      "wojewodztwo": "podlaskie",
      "title": "Urząd Skarbowy w Siemiatyczach",
      "adres": "ul. Pałacowa 18, 17-300 Siemiatycze"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Sieradzu",
      "adres": "ul. Spółdzielcza 3, 98-200 Sieradz"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Sierpcu",
      "adres": "ul. Piastowska 48, 09-200 Sierpc"
    },
    {
      "wojewodztwo": "świętokrzyskie",
      "title": "Urząd Skarbowy w Skarżysku-Kamiennej",
      "adres": "ul. 1 Maja 56, 26-110 Skarżysko-Kamienna"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Skierniewicach",
      "adres": "ul. Czerwona 22, 96-100 Skierniewice"
    },
    {
      "wojewodztwo": "lubuskie",
      "title": "Urząd Skarbowy w Słubicach",
      "adres": "ul. Wojska Polskiego 155, 69-100 Słubice"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Słupcy",
      "adres": "ul. Wspólna 1, 62-400 Słupca"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Urząd Skarbowy w Słupsku",
      "adres": "ul. Szczecińska 59, 76-200 Słupsk"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Sochaczewie",
      "adres": "ul. Władysława Grabskiego 4, 96-500 Sochaczew"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Sokołowie Podlaskim",
      "adres": "ul. Węgrowska 5, 08-300 Sokołów Podlaski"
    },
    {
      "wojewodztwo": "podlaskie",
      "title": "Urząd Skarbowy w Sokółce",
      "adres": "ul. Białostocka 47, 16-100 Sokółka"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Urząd Skarbowy w Sopocie",
      "adres": "ul. 23 Marca 9/11, 81-808 Sopot"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Sosnowcu",
      "adres": "ul. 3 Maja 20, 41-200 Sosnowiec"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Stalowej Woli",
      "adres": "ul. Metalowców 6, 37-450 Stalowa Wola"
    },
    {
      "wojewodztwo": "świętokrzyskie",
      "title": "Urząd Skarbowy w Starachowicach",
      "adres": "ul. Składowa 33, 27-200 Starachowice"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Urząd Skarbowy w Stargardzie",
      "adres": "ul. Towarowa 15, 73-110 Stargard"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Urząd Skarbowy w Starogardzie Gdańskim",
      "adres": "ul. Lubichowska 4, 83-200 Starogard Gdański"
    },
    {
      "wojewodztwo": "świętokrzyskie",
      "title": "Urząd Skarbowy w Staszowie",
      "adres": "ul. Oględowska 12, 28-200 Staszów"
    },
    {
      "wojewodztwo": "opolskie",
      "title": "Urząd Skarbowy w Strzelcach Opolskich",
      "adres": "ul. Opolska 13, 47-100 Strzelce Opolskie"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Strzelinie",
      "adres": "ul. Bolka I Świdnickiego 14, 57-100 Strzelin"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Strzyżowie",
      "adres": "ul. Daszyńskiego 6, 38-100 Strzyżów"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy w Suchej Beskidzkiej",
      "adres": "ul. Adama Mickiewicza 36, 34-200 Sucha Beskidzka"
    },
    {
      "wojewodztwo": "lubuskie",
      "title": "Urząd Skarbowy w Sulęcinie",
      "adres": "ul. Daszyńskiego 47, 69-200 Sulęcin"
    },
    {
      "wojewodztwo": "podlaskie",
      "title": "Urząd Skarbowy w Suwałkach",
      "adres": "ul. 1 Maja 2A, 16-400 Suwałki"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Szamotułach",
      "adres": "ul. Bolesława Chrobrego 17A, 64-500 Szamotuły"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Urząd Skarbowy w Szczecinku",
      "adres": "ul. Mickiewicza 13,14, 78-400 Szczecinek"
    },
    {
      "wojewodztwo": "warmińsko-mazurskie",
      "title": "Urząd Skarbowy w Szczytnie",
      "adres": "ul. Warszawska 5, 12-100 Szczytno"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Szydłowcu",
      "adres": "ul. Widok 6, 26-500 Szydłowiec"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Śremie",
      "adres": "ul. Wojska Polskiego 12, 63-100 Śrem"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Środzie Śląskiej",
      "adres": "ul. Oławska 5A, 55-300 Środa Śląska"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Środzie Wielkopolskiej",
      "adres": "ul. Harcerska 2, 63-000 Środa Wielkopolska"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Świdnicy",
      "adres": "ul. Marii Skłodowskiej-Curie 1-3, 58-100 Świdnica"
    },
    {
      "wojewodztwo": "lubuskie",
      "title": "Urząd Skarbowy w Świebodzinie",
      "adres": "ul. Sobieskiego 6, 66-200 Świebodzin"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Urząd Skarbowy w Świeciu",
      "adres": "ul. 10 lutego 16, 86-100 Świecie"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Urząd Skarbowy w Świnoujściu",
      "adres": "ul. Kazimierza Pułaskiego 7, 72-600 Świnoujście"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Tarnobrzegu",
      "adres": "ul. Stanisława Wyspiańskiego 12, 39-400 Tarnobrzeg"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Tarnowskich Górach",
      "adres": "ul. Opolska 23, 42-600 Tarnowskie Góry"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Urząd Skarbowy w Tczewie",
      "adres": "ul. Gdańska 33, 83-110 Tczew"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Urząd Skarbowy w Tomaszowie Lubelskim",
      "adres": "ul. Rolnicza 17, 22-600 Tomaszów Lubelski"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Tomaszowie Mazowieckim",
      "adres": "ul. Mireckiego 37, 97-200 Tomaszów Mazowiecki"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Trzebnicy",
      "adres": "ul. Prusicka 2, 55-100 Trzebnica"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Urząd Skarbowy w Tucholi",
      "adres": "ul. Pl. Zamkowy 4, 89-500 Tuchola"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Turku",
      "adres": "ul. Konińska 1, 62-700 Turek"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Tychach",
      "adres": "ul. Al. Niepodległości 60, 43-100 Tychy"
    },
    {
      "wojewodztwo": "podkarpackie",
      "title": "Urząd Skarbowy w Ustrzykach Dolnych",
      "adres": "ul. Mikołaja Kopernika 1, 38-700 Ustrzyki Dolne"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy w Wadowicach",
      "adres": "ul. Legionów 22, 34-100 Wadowice"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Urząd Skarbowy w Wałczu",
      "adres": "ul. T. Kościuszki 4, 78-600 Wałcz"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Urząd Skarbowy w Wąbrzeźnie",
      "adres": "ul. Macieja Rataja 2, 87-200 Wąbrzeźno"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Wągrowcu",
      "adres": "ul. Kościuszki 19A, 62-100 Wągrowiec"
    },
    {
      "wojewodztwo": "pomorskie",
      "title": "Urząd Skarbowy w Wejherowie",
      "adres": "ul. Jana III Sobieskiego 346, 84-200 Wejherowo"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Węgrowie",
      "adres": "ul. Obrębowa 17, 07-100 Węgrów"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy w Wieliczce",
      "adres": "ul. Zamkowa 2, 32-020 Wieliczka"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Wieluniu",
      "adres": "ul. Krakowskie Przedmieście 34, 98-300 Wieluń"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Wieruszowie",
      "adres": "ul. Rynek 12/13, 98-400 Wieruszów"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Wodzisławiu Śląskim",
      "adres": "ul. Bartosza Głowackiego 4, 44-300 Wodzisław Śląski"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Wolsztynie",
      "adres": "ul. Dworcowa 15, 64-200 Wolsztyn"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Wołominie",
      "adres": "ul. Przejazd 2, 05-200 Wołomin"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Wołowie",
      "adres": "ul. Tadeusza Kościuszki 17, 56-100 Wołów"
    },
    {
      "wojewodztwo": "podlaskie",
      "title": "Urząd Skarbowy w Wysokiem Mazowieckiem",
      "adres": "ul. Ludowa 21, 18-200 Wysokie Mazowieckie"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Wyszkowie",
      "adres": "ul. Gen. Józefa Sowińskiego 82, 07-200 Wyszków"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Zabrzu",
      "adres": "ul. Bytomska 2, 41-800 Zabrze"
    },
    {
      "wojewodztwo": "małopolskie",
      "title": "Urząd Skarbowy w Zakopanem",
      "adres": "ul. Szymony 14, 34-500 Zakopane"
    },
    {
      "wojewodztwo": "podlaskie",
      "title": "Urząd Skarbowy w Zambrowie",
      "adres": "ul. Jantarowa 16, 18-300 Zambrów"
    },
    {
      "wojewodztwo": "lubelskie",
      "title": "Urząd Skarbowy w Zamościu",
      "adres": "ul. Jana Kilińskiego 82, 22-400 Zamość"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Zawierciu",
      "adres": "ul. Leśna 8, 42-400 Zawiercie"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Ząbkowicach Śląskich",
      "adres": "ul. Ludwika Waryńskiego 2A, 57-200 Ząbkowice Śląskie"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Zduńskiej Woli",
      "adres": "ul. Zielona 15, 98-220 Zduńska Wola"
    },
    {
      "wojewodztwo": "łódzkie",
      "title": "Urząd Skarbowy w Zgierzu",
      "adres": "ul. ks. Jerzego Popiełuszki 8, 95-100 Zgierz"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Zgorzelcu",
      "adres": "ul. Bohaterów II Armii Wojska Polskiego 8B, 59-900 Zgorzelec"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Złotoryi",
      "adres": "ul. Rynek 42, 59-500 Złotoryja"
    },
    {
      "wojewodztwo": "wielkopolskie",
      "title": "Urząd Skarbowy w Złotowie",
      "adres": "ul. Al. Piasta 25, 77-400 Złotów"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Zwoleniu",
      "adres": "ul. Wojska Polskiego 39, 26-700 Zwoleń"
    },
    {
      "wojewodztwo": "lubuskie",
      "title": "Urząd Skarbowy w Żaganiu",
      "adres": "ul. Skarbowa 26, 68-100 Żagań"
    },
    {
      "wojewodztwo": "lubuskie",
      "title": "Urząd Skarbowy w Żarach",
      "adres": "ul. Osadników Wojskowych 3-4, 68-200 Żary"
    },
    {
      "wojewodztwo": "kujawsko-pomorskie",
      "title": "Urząd Skarbowy w Żninie",
      "adres": "ul. Klemensa Janickiego 18, 88-400 Żnin"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Żorach",
      "adres": "ul. Wodzisławska 1, 44-240 Żory"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Żurominie",
      "adres": "Plac Józefa Piłsudskiego 4, 09-300 Żuromin"
    },
    {
      "wojewodztwo": "mazowieckie",
      "title": "Urząd Skarbowy w Żyrardowie",
      "adres": "ul. 1 Maja 49A, 96-300 Żyrardów"
    },
    {
      "wojewodztwo": "śląskie",
      "title": "Urząd Skarbowy w Żywcu",
      "adres": "ul. Zygmunta Krasińskiego 11, 34-300 Żywiec"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Dzierżoniowie",
      "adres": "ul. Pocztowa 14, 58-200 Dzierżoniów"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Nowej Rudzie",
      "adres": "ul. Kolejowa 23, 57-400 Nowa Ruda"
    },
    {
      "wojewodztwo": "dolnośląskie",
      "title": "Urząd Skarbowy w Wałbrzychu",
      "adres": "ul. Uczniowska 21, 58-306 Wałbrzych"
    },
    {
      "wojewodztwo": "warmińsko-mazurskie",
      "title": "Warmińsko-Mazurski Urząd Skarbowy w Olsztynie",
      "adres": "ul. Lubelska 37, 10-408 Olsztyn"
    },
    {
      "wojewodztwo": "zachodniopomorskie",
      "title": "Zachodniopomorski Urząd Skarbowy w Szczecinie",
      "adres": "ul. Żołnierska 45, 71-210 Szczecin"
    }
   ]