import React from "react";
import { ReactComponent as Calendar } from "./img/calendar.svg";
import "./NewsBigListItem.scss";
import ArrowButton from "../ArrowButton/ArrowButton";

import { useNavigate } from "react-router-dom";
import { useColorPicker } from "../../hooks";
import moment from "moment";

const NewsBigListItem = ({ news, visable }: any) => {
  const navigate = useNavigate();

  return (
    <li
      role="link"
      onClick={() => navigate("/news/" + news?.slug)}
      className="news-list-big-item"
    >
      <span
        style={{ backgroundColor: `${useColorPicker(news?.category)}` }}
        className="news-list-item-category"
      >
        {news?.categoryName}
      </span>

      <div className="news-list-image">
        <img src={news?.hero_image?.file?.url} alt="" />
      </div>
      <div className="news-list-body dropshadow-box">
        <h2
          className="news-list-title"
          dangerouslySetInnerHTML={{ __html: news?.content?.title }}
        ></h2>
        <div className="news-item-footer">
          {news.startDate && news.endDate ? (
            <span className="news-item-date">
              <Calendar />{" "}
              <span>
                Czas trwania:
                <strong>
                  {" "}
                  {moment(news?.startDate?.date).format("DD.MM")}
                </strong>
                <strong>
                  {"-"}
                  {moment(news?.endDate?.date).format("DD.MM.YY")}
                </strong>
              </span>
            </span>
          ) : (
            <span className="news-item-date">
              <Calendar />{" "}
              <span>{moment(news?.created?.date).format("DD-MM-YYYY")}</span>
            </span>
          )}

          <span className="news-item-line"></span>

          <span className="news-item-arrow">
            <ArrowButton path={"/news/" + news?.slug} color={"white"} />
          </span>
        </div>
      </div>
    </li>
  );
};

export default NewsBigListItem;
