import {
  SHOW_MENU,
  SHOW_DRAWER,
  SHOW_NOTI,
  CONTEST_INFO
 } from "../actions/types";
  
export function uiReducer(state = {}, action) {
  switch (action.type) {

    case SHOW_MENU:
      return {
        ...state,
        showMenu: action.payload
      }
      break;
    
    case SHOW_NOTI:
      return {
        ...state,
        showNoti: action.payload
      }
      break;
    
    case SHOW_DRAWER:
      return {
        ...state,
        showDrawer: action.payload.show,
        drawerType: action.payload.type ? action.payload.type : state.drawerType,
        drawerData: action.payload.data ? action.payload.data : state.drawerData
      }
      break;
    
    case CONTEST_INFO:
      return {
        ...state,
        contestInfo: action.payload
      }
      break;
      
    default:
      return state;
  }
}

// selectors
export const getUI = (state) => state.ui;