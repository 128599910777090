import { getUI } from "../../store/reducers/ui";
import { getUser } from "../../store/reducers/user";
import "./Onboarding.scss";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import img1_d from "./img/desktop_co_nowego.jpg";
import img2_d from "./img/desktop_dash.jpg";
import img3_d from "./img/desktop_news.jpg";
import img4_d from "./img/desktop_sprzedaz.jpg";
import img5_d from "./img/desktop_aktywnosci.jpg";
import img6_d from "./img/desktop_wyplac.jpg";
import img7_d from "./img/desktop_menu.jpg";
import img8_d from "./img/desktop_skrot.jpg";
import img9_d from "./img/desktop_end.jpg";
import { useState } from "react";
import ArrowButton from "../ArrowButton/ArrowButton";

declare global {
  interface Window {
    defferedPrompt: any;
  }
}

export default function Onboarding() {
  const dispatch = useAppDispatch();
  const ui = useAppSelector(getUI);
  const user = useAppSelector(getUser);
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [activeSlide, setActiveSlide] = useState(0);
  const onboarding = sessionStorage.getItem("onboarding");

  const slidesList = [
    {
      image: img1_d,
      image_mobile: img1_d,
      title: "Witaj w BAT Partners!",
      text: "W kilku krokach nauczymy Cię jak zwinnie poruszać się po programie. Poznasz najważniejsze elementy i funkcjonalności.",
    },
    {
      image: img2_d,
      image_mobile: img2_d,
      title: "Dashboard - Twoje centrum dowodzenia",
      text: "To główna strona programu, w której szybko podejrzysz Twoje saldo i je wypłacisz. Zapoznasz się z najnowszym newsem oraz sprawdzisz, jak Ci idzie sprzedaż oraz polecanie swoim klientom rejestracji zakupionego urządzenia w tym sezonie.",
    },
    {
      image: img3_d,
      image_mobile: img3_d,
      title: "News - nie przegap dodatkowych nagród",
      text: "Każda informacja o najnowszych aktywnościach, akcjach, bonusach do sprzedaży lub konkursach pojawia się właśnie tutaj. Z newsami będziesz na bieżąco.",
    },
    {
      image: img4_d,
      image_mobile: img4_d,
      title: "Nowa sprzedaż - główny punkt programu",
      text: "Warto zaprzyjaźnić się z tym miejscem, bo to właśnie tu rejestrujesz sprzedane urządzenia swoim klientom. Pieniądze wpadną na Twoje konto tylko wtedy, kiedy  zeskanujesz numer seryjny lub po prostu go wpiszesz.",
    },
    {
      image: img5_d,
      image_mobile: img5_d,
      title: "Aktywności - bierz udział i zarabiaj",
      text: "Znajdziesz tu aktywne i najnowsze akcje bonusowe. Są one idealną okazją do powiększenia swoich nagród. Od czasu do czasu wleci też quiz lub inne zadania sprawdzające Twoją wiedzę z produktów BAT. ",
    },
    {
      image: img6_d,
      image_mobile: img6_d,
      title: "Wypłać - nagrody na Twoje konto",
      text: "Zajrzyj tu, gdy chcesz podejrzeć Twoje saldo lub historię wypłat, albo po prostu zlecić transakcję wprost na swoje konto. Minimalna kwota wypłaty to 20 zł.",
    },
    {
      image: img7_d,
      image_mobile: img7_d,
      title: "Menu - pomocna strona programu",
      text: "Zajrzyj tu, aby dostać się do swojego profilu, sprawdzić statystyki, ranking oraz cele. Po rozwinięciu menu masz dostęp również do materiałów edukacyjnych, które mogą być pomocne w sprzedaży.",
    },
    {
      image: img8_d,
      image_mobile: img8_d,
      title: "Aplikacja internetowa - łatwiejszy dostęp",
      text: "BAT Partners działa świetnie jako aplikacja webowa. Łatwy dostęp i korzystanie z programu niezależnie od przeglądarki. Wypróbuj i dodaj skrót BAT Partners z poziomu dashboardu do głównego ekranu w Twoim smartfonie.",
    },
    {
      image: img9_d,
      image_mobile: img9_d,
      title: "Nasza wycieczka dobiegła końca",
      text: (
        <>
          Pierwsze kroki w BAT Partners na drodze do wielkich nagród pieniężnych
          za Tobą. <br />
          <br />
          Teraz ruch należy do Ciebie.
          <br /> Sprzedawaj, rejestruj i zarabiaj!
        </>
      ),
    },
  ];

  return (
    <>
      {onboarding != "false" && user?.onboarding && show != false && (
        <div className="Onboarding">
          <div className="onboarding-overlay">
            <div className="onboarding-inner">
              <Swiper
                loop={false}
                pagination={{
                  clickable: true,
                }}
                navigation={{
                  nextEl: ".swiper-onboarding-next",
                  prevEl: ".swiper-onboarding-prev",
                }}
                modules={[Navigation, Pagination]}
                className="activities-slider"
                breakpoints={{
                  270: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                  },
                }}
                onSlideChange={(e) => setActiveSlide(e.activeIndex)}
              >
                {slidesList.map((slide: any) => (
                  <SwiperSlide key={slide.title}>
                    <div className="onboarding-slide">
                      <div className="image-top">
                        <img src={slide.image} />
                      </div>
                      <div className="content">
                        <h2>{slide.title}</h2>
                        <p>{slide.text}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div
                className="close-btn"
                onClick={() => {
                  setShow(false);
                  sessionStorage.setItem("onboarding", "false");
                }}
              >
                Pomiń
              </div>
              {activeSlide === 8 && (
                <div
                  className="swiper-close"
                  onClick={() => {
                    setShow(false);
                    sessionStorage.setItem("onboarding", "false");
                  }}
                >
                  <ArrowButton color="white" />
                </div>
              )}
              <div
                className={
                  activeSlide < 8
                    ? "swiper-onboarding-next"
                    : "swiper-onboarding-next hide"
                }
              >
                <ArrowButton color="white" />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
