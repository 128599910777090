import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import "./QuizResultScreen.scss";
import { ReactComponent as Correct } from "./img/correct.svg";
import { ReactComponent as Incorrect } from "./img/incorrect.svg";
interface Props {
  doneCorrectly?: boolean;
  loading: boolean;
  //   children: ReactNode;
}

const QuizResultScreen: React.FC<Props> = ({ doneCorrectly, loading }) => {
  const navigate = useNavigate();
  if (loading) {
    return null;
  }
  return (
    <div className="QuizResultScreen">
      {doneCorrectly ? (
        <>
          <div className="heading">
            <div className="icon">
              <Correct />
            </div>
            <h2 className="title">Bezbłednie!</h2>
          </div>
          <div className="body">
            Znasz 4 ważne filary Vuse. Szerz tę wiedzę wśród konsumentów i
            sprzedawaj efektywniej.
          </div>
          <button className="Button" onClick={() => navigate("/aktywnosci", {replace: true})}>
            Wróć do aktywności
          </button>
        </>
      ) : (
        <>
          <div className="heading">
            <div className="icon">
              <Incorrect />
            </div>
            <h2 className="title">Ups, coś poszło nie tak.!</h2>
          </div>
          <div className="body">
            Zapoznaj się z 4 filarami Vuse i wróć za 24h, aby ponownie spróbować
            swoich sił.
          </div>
          <button className="Button" onClick={() => navigate("/aktywnosci", {replace: true})}>
            Wróć do aktywności
          </button>
        </>
      )}
    </div>
  );
};

export default QuizResultScreen;
