import React, { useState, useEffect } from "react";
import "./UserRanking.scss";
import { ReactComponent as BtnIcon } from "./img/btn-icon.svg";
import {RANKING_TYPE} from '../../pages/Rankings/Rankings'
import { useAppSelector } from "../../hooks";
import Arrow from './img/arrow.svg'
interface RankingUser {
  salespoint: string;
  firstName: string;
  counter: string;
  lot: number;
  bp?: string;
  isMe: boolean;
  number?: number;
  partners:any[],
}

interface UserRankingProps {
  rankingList: RankingUser[];
  rankingType: string,
}


const UserRanking: React.FC<UserRankingProps> = ({ rankingList ,rankingType} ) => {

  const [recordToShow, setRecordToShow] = useState(5);
  const userData = rankingList;
  const slicedUserArray = userData.slice(0, recordToShow);
  const userIsOnTop = slicedUserArray.filter((user) => user.isMe !== false);
  const userIsInside = rankingList.filter((user) => Boolean(user.isMe === true));
  const [showSublist,setShowSublist] = useState(false);

  const handleRankingType = () => {
    if (rankingType == RANKING_TYPE.SALESPOINT) {
      return (
        <div className="RankingUserStats salespoint">
          <div className="rankings-wrapper-list">
            <div className="header">
              <span className="bp">Numer punktu</span>
              <span className="score">Wynik</span>
            </div>
          </div>
          <div className="rankings-wrapper-list">
            <ul className="list">
            {slicedUserArray.map((user, idx) => (
                  <li
              key={idx}
              className="list-item list-item--space"
              
            >
              {user.isMe ? (
                <div onClick={() => setShowSublist((prevState) => !prevState)} className={"main-list active"}>
              <div className="list-item-content">
                <span className="list-item-number">{idx + 1}</span>
                <span className="list-item-name">{user?.salespoint} <span className={showSublist ? "arrow active" : "arrow"}>
                  <img src={Arrow} alt="svg" /></span></span>
                
              </div>
              <span className="list-item-score">{user?.counter}</span>
              </div>
              ): (
                <div  className={"main-list"}>
              <div className="list-item-content">
                <span className="list-item-number">{idx + 1}</span>
                <span className="list-item-name">{user?.salespoint} <span className={showSublist ? "arrow active" : "arrow"}>
                  <img src={Arrow} alt="svg" /></span></span>
                
              </div>
              <span className="list-item-score">{user?.counter}</span>
              </div>
              )}
           
              {user.isMe ? (
                      <ul className="sublist-wrapper">
                        {user?.partners?.map((partner,idx) => (
                          <li
                          key={idx}
                          className={showSublist ? "sublist active" : "sublist"}
                          
                        >
                          <div className="list-item-content">
                            <span className="list-item-number"></span>
                            <span className="list-item-name">{partner.firstName}</span>
                          
                          </div>
                          <span className="list-item-score">{partner.counter}</span>
                          
                        </li>
                        ))}
                  </ul>
                  ) : null}
            </li>
              ))}
                    {user.position >= recordToShow ? (
                <li className="list-separator">
                  <button onClick={showMoreHandle} className="dot-btn"></button>
                </li>
              ) : null}
                {allUserAreShow ? null : (
                <button onClick={showAllRecords} className="show-more-btn">
                  Zobacz więcej <BtnIcon />
                </button>
              )}
            </ul>
          </div>
        </div>
      );
    } else {
      return (
        <div className="RankingUserStats">
          <div className="rankings-wrapper-list">
            <div className="header">
              <span className="bp">BAT Partner</span>
              <span className="score">Wynik</span>
            </div>
          </div>
          <div className="rankings-wrapper-list">
            <ul className="list">
              {slicedUserArray.map((user, idx) => (
                <li
                  key={idx}
                  className={
                    user.isMe
                      ? "list-item list-item--space list-item--active "+ rankingType.toLowerCase()
                      : "list-item list-item--space "+ rankingType.toLowerCase()
                  }
                >
                  <div className="list-item-content">
                    <span className="list-item-number">{idx + 1}</span>
                    <span className="list-item-name">{user?.firstName}</span>
                    {/* <span className="list-item-bp">{user?.bp}</span> */}
                  </div>
                  <span className="list-item-score">{user?.counter}</span>
                </li>
              ))}
              {user.number > recordToShow ? (
                <li className="list-separator">
                  <button onClick={showMoreHandle} className="dot-btn"></button>
                </li>
              ) : null}
              {userIsOnTop.length ? null : userIsInside.length > 0 ? (
                <li
                  key={user.number}
                  className={
                    user.isMe ? "list-item list-item--active" : "list-item"
                  }
                >
                  <div className="list-item-content">
                    <span className="list-item-number">{user.number}</span>
                    <span className="list-item-name">{user?.firstName}</span>
                    {/* <span className="list-item-bp">{user?.bp}</span> */}
                  </div>
                  <span className="list-item-score">{user?.counter}</span>
                </li>
              ) : null}
  
              {allUserAreShow ? null : (
                <button onClick={showAllRecords} className="show-more-btn">
                  Zobacz więcej <BtnIcon />
                </button>
              )}
            </ul>
          </div>
        </div>
      );
    }
  };
  
  useEffect(() => {
    const findUser = userData.map((person, idx) => {
      if (person.isMe === true) {
        let myData = {
          ...person,
          number: idx + 1,
        };
        setUser({
          ...myData,
        });
      }
    });
  }, []);

  const showAllRecords = () => {
    setRecordToShow(userData.length);
  };

  const showMoreHandle = () => {
    setRecordToShow(user.number);
  };
  const [user, setUser] = useState<any>({});
  const allUserAreShow = slicedUserArray.length === userData.length;
  return (
    handleRankingType()
  );
};

export default UserRanking;
