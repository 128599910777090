import "./Footer.scss";
import { useAppDispatch } from "../../hooks";
import { showDrawer } from "../../store/actions/ui";

interface Props {
  withDisclaimer?: boolean;
  setOpenCookies:(open:boolean) => void;
}

function Footer({ withDisclaimer ,setOpenCookies}: Props) {
  const dispatch = useAppDispatch();

  const footerLinks = [
    {
      name: "Klauzula informacyjna",
      href: "/pdf/klauzula_informacyjna_bat_partners.pdf",
    },
    {
      name: "Preferencje Cookies",
      action: () => {
        setOpenCookies(true);
      },
    },
    {
      name: "Polityka Cookies",
      href: "/pdf/BATPartners_Polityka_cookies.pdf",
    },
  ];
  return (
    <footer className={withDisclaimer ? "Footer disclaimer" : "Footer"}>
      <ul className="list">
        {footerLinks.map((link) => (
          <li key={link.name} className="list-item">
            {link.href ? (
              <a target="_blank" href={link.href} rel="noreferrer">
                {link.name}
              </a>
            ) : (
              <button onClick={link.action}>{link.name}</button>
            )}
          </li>
        ))}
      </ul>
    </footer>
  );
}

export default Footer;
