import React, { useEffect, useState } from "react";
import PageLayout from "../PageLayout/PageLayout";
import newsBackground from "../../pages/News/img/img.png";
import { useColorPicker, useWindowDimensions } from "../../hooks";
import { ReactComponent as Calendar } from "./img/calendar.svg";
import "./NewsDetails.scss";
import { useOnScreen } from "../../hooks";
import Title from '../Title/Title'
import { API_URL, appFetch } from "../../helpers/fetch";
import { useSelector } from "react-redux";
import { getToken } from "../../store/reducers/user";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import Button from "../Button/Button";

const News = ({ news }: any) => {

  return (
    <>
      <Title><span className="hide-desktop">{ news.content?.title }</span></Title>
      <div className={"news-item"}>
        <span
          className={"news-item-category"}
          style={{ backgroundColor: `${useColorPicker(news?.category)}` }}
        >
          {news?.categoryName}
        </span>

        <div className="news-image">
          <img src={news?.content?.modules?.find((module: any)=> module.type === "hero_image")?.file.url} alt="" />
        </div>
        <div className="news-body mobile">
          { news.content?.modules.map((item: any)=>{
            switch(item.type) {
              case "intro":
                return <div className={"module "+item.type} dangerouslySetInnerHTML={{ __html: item?.custom }}></div>
              case "text":
                return <div className={"module "+item.type} dangerouslySetInnerHTML={{ __html: item?.custom }}></div>
              case "button":
                return <Button className="cta" path={ item.custom }>{ item.metadata.name }</Button>
              default:
                return <div></div>
            }

          })}
          {/*<p className="news-desc">
            6 grudnia pomóż elfom w sprzedaży urządzeń glo™, a otrzymasz
            Mikołajkową Nagrodę Dodatkową.
          </p>
          <p className="news-desc">
            Wypełnij swoją świąteczną skarpetę bonusem od Mikołaja.
          </p>
          <div className="news-table">
            <p>
              1 urzędzenie - <strong>35 zł</strong>
            </p>
            <p>
              2 urzędzenie - <strong>35 zł + 10 zł bonusu</strong>
            </p>
            <p>
              3 urzędzenie - <strong>35 zł + 15 zł bonusu</strong>
            </p>
            <p>
              4 urzędzenie - <strong>35 zł + 20 zł bonusu</strong>
            </p>
            <p>
              5 urzędzenie - <strong>35 zł + 20 zł bonusu</strong>
            </p>

            <p>
              6 i więcej urządzeń - <strong>35 zł + 25 zł bonusu</strong>
            </p>
          </div>
          <p className="news-desc">
            Zdecyduj, jak duży będzie Twój prezent. Im więcej sprzedasz, tym
            więcej dostaniesz.
          </p>
          <p className="news-desc">
            <strong>Ile bonusów pomieści</strong> Twoja świąteczna skarpeta?
          </p>
          <button className="Button cta">Zacznij sprzedawać</button>*/}
        </div>
      </div>
    
      <div className="desktop-news">
        <div className="news-heading">
          <h2 className="news-title">{ news.content?.title}</h2>
          <div className="calendar">
            <Calendar /> <span className="date">{moment(news?.created?.date).format('DD-MM-YYYY')}</span>
          </div>
        </div>
        <div className="news-body">
          { news.content?.modules.map((item: any)=>{
            switch(item.type) {
              case "intro":
                return <div className={"module "+item.type} dangerouslySetInnerHTML={{ __html: item?.custom }}></div>
              case "text":
                return <div className={"module "+item.type} dangerouslySetInnerHTML={{ __html: item?.custom }}></div>
              case "button":
                return <Button className="cta" path={ item.custom }>{ item.metadata.name }</Button>
              default:
                return <div></div>
            }
          })}
          {/*<h2 className="news-subtitle">
            Mikołaj ma dla Ciebie świąteczne wyzwanie!
          </h2>

          <p className="news-desc">
            6 grudnia pomóż elfom w sprzedaży urządzeń glo™, a otrzymasz
            Mikołajkową Nagrodę Dodatkową.
          </p>
          <p className="news-desc">
            Wypełnij swoją świąteczną skarpetę bonusem od Mikołaja.
          </p>
          <div className="news-table">
            <p>
              1 urzędzenie - <strong>35 zł</strong>
            </p>
            <p>
              2 urzędzenie - <strong>35 zł + 10 zł bonusu</strong>
            </p>
            <p>
              3 urzędzenie - <strong>35 zł + 15 zł bonusu</strong>
            </p>
            <p>
              4 urzędzenie - <strong>35 zł + 20 zł bonusu</strong>
            </p>
            <p>
              5 urzędzenie - <strong>35 zł + 20 zł bonusu</strong>
            </p>

            <p>
              6 i więcej urządzeń - <strong>35 zł + 25 zł bonusu</strong>
            </p>
          </div>
          <p className="news-desc">
            Zdecyduj, jak duży będzie Twój prezent. Im więcej sprzedasz, tym
            więcej dostaniesz.
          </p>
          <p className="news-desc">
            <strong>Ile bonusów pomieści</strong> Twoja świąteczna skarpeta?
          </p>
          <button className="Button cta">Zacznij sprzedawać</button>*/}
        </div>
      </div>
    </>
  );
};

function NewsDetails() {
  const { width } = useWindowDimensions();
  const [newsItem, setNewsItem] = useState(false);
  const token = useSelector(getToken);
  const { slug } = useParams();

  useEffect(() => {
    appFetch(
      "POST",
      API_URL,
      "/article/describe",
      { article: { slug: slug} },
      token?.uuid ? token?.uuid : false,
      (result: any) => {
        if (result.status && result.status.success) {
          setNewsItem(result.data);
        } else {
        }
      },
      () => {

      }
    );
  }, []);

  return (
    <PageLayout>
      <div className="page NewsDetails">
        <div>
          { newsItem && <News news={newsItem} /> }
        </div>
      </div>
    </PageLayout>
  );
}

export default NewsDetails;
