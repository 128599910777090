import PageLayout from "../../components/PageLayout/PageLayout";
import Title from "../../components/Title/Title";
import { Link, useNavigate } from "react-router-dom";
import React, { SyntheticEvent, useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import { ReactComponent as IconCamera } from "./img/icon-camera.svg";
import { ReactComponent as WarningIcon } from "./img/warning.svg";
import { ReactComponent as OpenIcon } from "./img/open.svg";
import { ReactComponent as CloseIcon } from "./img/close.svg";
import { ReactComponent as ShapeIcon } from "./img/shape.svg";
import "./NewSale.scss";
import "./NewSale.scss";
import { useDispatch, useSelector } from "react-redux";
import { showDrawer } from "../../store/actions/ui";
import { registerDevice, uploadFile } from "../../store/actions/activations";
import { API_URL, fileFetch } from "../../helpers/fetch";
import { getToken } from "../../store/reducers/user";
import { FieldErrors, FieldValues, useForm } from "react-hook-form";
import Camera from "../../components/PhotoOCR/Camera";
import useDeviceDetect, { useAnalyticsEventTracker, useAppDispatch } from "../../hooks";

function NewSaleDetails() {
  const [showToolTip, setShowToolTip] = useState(false);
  const [step, setStep] = useState(0);
  const dispatch = useAppDispatch();
  const limitSizeInBites = 7090000;
  const [file, setFile] = useState({name:"", size: 0});
  const [showCamera, setShowCamera] = useState(false);
  const [limit, setLimit] = useState(false);
  const [sending, setSending] = useState(false);
  const token = useSelector(getToken);
  const navigate = useNavigate();
  const { isMobile } = useDeviceDetect()
  const { register, setValue, handleSubmit, getValues, formState: { errors } } = useForm();
  const gaEventTracker = useAnalyticsEventTracker('Nowa Sprzedaż Event')

  const onSubmit = ({ serial }: FieldValues) => {
    //console.log(serial, navigate)
    dispatch(registerDevice( serial, navigate ))
  }

  const onError = (errors: FieldErrors) => {
    //console.log(errors)
  }

  useEffect(() => {
    if(file.size >= limitSizeInBites) {
      setLimit(true)
    }else {
      setLimit(false);
    }
  },[file.size])

  const handleToggleTooltip = () => {
    setShowToolTip((prevState) => !prevState);
  };

  const uploadFile = (file: any) => {
    setShowCamera(false);

    if (!sending) {
      setSending(true)
      const fd = new FormData();
      fd.append('files[]', file);

      fileFetch("POST", API_URL, "/ocr/extract-identify", fd, token.uuid, (result: any) => {
          if(result.status && result.status.success) {
            const foundSerial = result.data.words.find((word: string)=> /^(VU|VS|CD|CC|PL|LV)([a-zA-Z0-9]{5,})$/.test(word))

            if (foundSerial) {
              dispatch({ type: 'UPLOAD_FILE_SUCCESS', payload: { words: result.data.words, fileIdent: result.data.file.ident } });
              setStep(1)
              setValue('serial', foundSerial, {
                shouldValidate: true,
                shouldDirty: true
              })
            } else {
              dispatch(showDrawer('error', {name: 'error_zero_results', errorCode: result.meta.ts }))
            }
          } else {
            dispatch(showDrawer('error', {name: result.data.error, errorCode: result.meta.ts }))
            setFile({name:"", size: 0})
          }
          setSending(false)
      }, (error: any) => {
        dispatch(showDrawer('error', {name: 'generic' }))
        setSending(false)
      })
    }

  }

  return (
    <PageLayout>
      <div className="page NewSale">
        <Title></Title>
        { step === 0 &&
          <div className="sale-wrapper">
            <div className="user">
              <div className="header">
                <h2 className="title">
                  Zeskanuj
                  <br /> numer seryjny{" "}
                  <span
                    role="button"
                    onClick={handleToggleTooltip}
                    className="title-icon"
                  >
                    {showToolTip ? <CloseIcon /> : <OpenIcon />}
                  </span>
                </h2>
                <div
                  role="button"
                  onClick={handleToggleTooltip}
                  className={showToolTip ? "tool-tip active" : "tool-tip"}
                >
                  <p className="text">
                  Numer urządzenia umieszczony z tyłu opakowania, oznaczony jako my <span>glo™</span>{" "}number dla urządzeń <span>glo™</span> hyper X2 NHT oraz <span>glo™</span> hyper+ UNIQ NHT
                  </p>
                </div>
              </div>

              <div className="user-pic">
                <label className="ball" htmlFor="userPic" onClick={ ()=>{ if (isMobile ) { setShowCamera(true) }}}>
                  <ShapeIcon className="shape" />
                  <div className="camera">
                    <IconCamera />
                    <span className="camera-desc">{isMobile? "Zeskanuj": "Dodaj zdjęcie"}</span>
                    <p className={limit ? "warning" : ""}>{ file.name }</p>
                  </div>
                </label>
                { !isMobile ?
                  <>
                    {limit ? <p className="limit-warning">Maksymalna waga zdjęcia to: 7 MB</p> : null}
                    <input accept="image/png, image/jpg" type="file" name="userPic" id="userPic" onChange={(e: any)=>{ setFile(e.target.files[0]) }}/>
                  </> : null }
                {file.size > 0 && !limit ?
                  <Button onClick={ () => { 
                    uploadFile(file);
                    gaEventTracker('click', "Upload file")
                  }}>{ sending ? 'Wysyłanie...': 'Wyślij'}</Button> : null}
                <div className="btn" onClick={()=>{ dispatch(showDrawer('device-register-info')); gaEventTracker('click', "Chcę wpisać")}}>Chcę wpisać</div>
              </div>
            </div>
            <div className="info">
              <div className="left-col">
                <span className="warning">
                  <WarningIcon />
                </span>
              </div>
              <div className="right-col">
                <h3 className="title">
                  Zachowaj kopie lub zdjęcie paragonu fiskalnego oraz faktury
                </h3>
                <p className="desc">
                  za sprzedaż urządzeń - mogą się przydać podczas potwierdzenia
                  sprzedaży i zakupu zarejestrowanych urządzeń.
                </p>
              </div>
            </div>
          </div> 
        }

        { step === 1 &&
          <div className="device-wrapper">
            <div className="heading">
              <h2 className="title device-serial-title">Sprawdź czy numer został zeskanowany poprawnie</h2>
            </div>

            <div className="input-item">
              <label className="serial-label" htmlFor="serial">Zeskanowany numer</label>
              <input readOnly className={ `${errors.serial && 'error-input'} read-only-input` } type="text" id="serial" {...register("serial", {required: true, minLength: 6})} placeholder="AJ845076"></input>
              {errors.serial && errors.serial.type === "minLength" && (
                <p className="error">Nieprawidłowa wartość</p>
              )}
              {errors.serial && errors.serial.type === "required" && (
                <p className="error">Wpisz numer produktu</p>
              )}
            </div>
            <Button className="scan-cta" onClick={ () => setStep(0)}>
              Zeskanuj jeszcze raz
            </Button>
            <Button onClick={ handleSubmit(onSubmit, onError) }>
              Potwierdzam
            </Button>
          </div> 
        }
        { showCamera ? 
        <Camera onCapture={(data: any)=>uploadFile(data)} onClear={()=>{}}/> : null }
      </div>
    </PageLayout>
  );
}

export default NewSaleDetails;
