import { forEach } from "lodash";
import {
  ACCOUNT_BALANCE,
  ACCOUNT_PAYMENT_CREATE,
  ACCOUNT_PAYMENT_REQUEST,
  ACCOUNT_PAYMENT_HISTORY,
  ACCOUNT_BALANCE_HISTORY,
  ACCOUNT_BALANCE_SUMMARY,
  GET_SEASONS_SUCCESS,
  GET_PRIZES,
  ACCOUNT_PAYMENT_FAILURE
} from "../actions/types";
import _ from 'lodash'

export function accountReducer(state = {}, action) {
  switch (action.type) {
    case ACCOUNT_BALANCE:
      const countProfit = () => {
        let profit = 0;
        action.payload.balance.history.forEach((item, key) => {
          if (item.value > 0) {
            profit += item.value;
          }
        })
        return profit;
      }

      const payoutHistory = action.payload.balance.history.filter((item, key)=>{
        return item.value < 0;
      })

      const incomeHistorySales = action.payload.balance.history.filter((item, key)=>{
        return item.value > 0 && !/super_seria/.test(item);
      })

      const incomeHistoryRefs = action.payload.balance.history.filter((item, key)=>{
        return item.value > 0 && /super_seria/.test(item);
      })

      const quarters = Object.keys(action.payload.seasons);

      const seasons = quarters.map((name) => {

        const countDevices = (type) => {
          let sold = 0;

          const activations = action.payload.balance.history.filter( (item)=>{
            return item.quarter === name;
          })

          activations.forEach((item, key) => {
            if (item.solution && item.solution.activation.ident === 'device_register') {
              if (type === "hyper" && item.solution.payload.model === "HYPER") {
                sold += 1;
              } else if (type === "vuse" && (!item.solution.payload.model || (item.solution.payload.model && item.solution.payload.model != "HYPER"))) {
                sold += 1;
              } else if ( type === 'all' ) {
                sold += 1;
              } 
            }
          })
          return sold;
        }

        const rankId = (dv) => {
          if (dv <=2) {
            return 0
          } else if ( dv >= 3 && dv < 6) {
            return 1
          } else if ( dv >= 6 && dv < 10) {
            return 2
          } else if ( dv >= 10 && dv < 15) {
            return 3
          } else if ( dv >= 15 && dv < 20) {
            return 4
          } else {
            return 5
          }
        }

        const rankNext = (dv) => {
          const names = ["zero", "jednego", "dwóch", "trzech", "czterech", "pięciu", "sześciu", "siedmiu", "ośmiu"];

          if (dv <=2) {
            const a = 3-dv;
            return names[a]
          } else if ( dv > 2 && dv <= 7) {
            const a = 8-dv;
            return names[a]
          } else if ( dv > 7 && dv <= 14) {
            const a = 15-dv;
            return names[a]
          } else if ( dv > 14 && dv <= 21) {
            const a = 22-dv;
            return names[a]
          } else if ( dv > 21 && dv <= 30) {
            const a = 31-dv;
            return names[a]
          } else {
            return names[0]
          }
        }

        return (
          {
            name: name,
            soldDevices: {
              all: countDevices('all'),
              hyper: countDevices('hyper'),
              vuse: countDevices('vuse')
            },
            rankId: rankId(countDevices('all')),
            rankNext: rankNext(countDevices('all'))
          }
        )
      })

      return {
        ...state,
        data: action.payload.balance,
        balance: action.payload.balance.value,
        profit: action.payload.balance.quarters.history.current,
        payoutHistory: payoutHistory,
        incomeHistorySales: incomeHistorySales,
        incomeHistoryRefs: incomeHistoryRefs,
        seasons: seasons.reverse()
      }
    case ACCOUNT_BALANCE_SUMMARY:
      const rankId = (dv) => {
        if (dv <=2) {
          return 0
        } else if ( dv >= 3 && dv < 6) {
          return 1
        } else if ( dv >= 6 && dv < 10) {
          return 2
        } else if ( dv >= 10 && dv < 15) {
          return 3
        } else if ( dv >= 15 && dv < 20) {
          return 4
        } else {
          return 5
        }
      }

      const nextRank = (dv) => {
        if (dv <=2) {
          return {
            need: 3 - dv,
            nextPrize: "15 zł"
          }
        } else if ( dv >= 3 && dv < 6) {
          return {
            need: 6 - dv,
            nextPrize: "40 zł"
          }
        } else if ( dv >= 6 && dv < 10) {
          return {
            need: 10 - dv,
            nextPrize: "50 zł"
          }
        } else if ( dv >= 10 && dv < 15) {
          return {
            need: 15 - dv,
            nextPrize: "60 zł"
          }
        } else if ( dv >= 15 && dv < 20) {
          return {
            need: 20 - dv,
            nextPrize: "100 zł"
          }
        } else {
          return {
            need: 0,
            nextPrize: "0 zł"
          }
        }
      }

      const rankIdReferral = (dv) => {
        if (dv <=1) {
          return 0
        } else if ( dv >= 2 && dv < 4) {
          return 1
        } else if ( dv >= 4 && dv < 8) {
          return 2
        } else if ( dv >= 8 && dv < 15) {
          return 3
        } else if ( dv >= 15 && dv < 20) {
          return 4
        } else {
          return 5
        }
      }

      const nextRankReferral = (dv) => {
        if (dv <=1) {
          return {
            need: 2 - dv,
            nextPrize: "20 zł" 
          }
        } else if ( dv >= 2 && dv < 4) {
          return {
            need: 4 - dv,
            nextPrize: "30 zł"
          }
        } else if ( dv >= 4 && dv < 8) {
          return {
            need: 8 - dv,
            nextPrize: "50 zł"
          }
        } else if ( dv >= 8 && dv < 15) {
          return {
            need: 15 - dv,
            nextPrize: "80 zł"
          }
        } else if ( dv >= 15 && dv < 20) {
          return {
            need: 20 - dv,
            nextPrize: "100 zł"
          }
        } else {
          return {
            need: 0,
            nextPrize: "0 zł"
          }
        }
      }

      const seasonsHistory = action.payload.balance.history
      let seasonsHistoryWithRanks = [] ;
      _.forEach(seasonsHistory, (s, key) => {
        seasonsHistoryWithRanks.push({
          ...s, 
          sold: s.devices.glo + s.devices.vuse,
          name: key, 
          rankId: rankId(s.devices.glo + s.devices.vuse),
          nextRank: nextRank(s.devices.glo + s.devices.vuse)
        })
      })

      let referralsHistory = [];
      _.forEach(action.payload.referral.history, (v,key) => {
        referralsHistory.push({
          name: key,
          value: v
        })
      })
      return {
        ...state,
        balance: { 
          ...action.payload.balance, 
          history: seasonsHistoryWithRanks, 
          referral: {
            ...action.payload.referral,
            rank: rankIdReferral(action.payload.referral.current),
            nextRank: nextRankReferral(action.payload.referral.current),
            history: referralsHistory
          }
        }
      }
    case ACCOUNT_BALANCE_HISTORY:
      const filteredSales = action.payload.balance.history.filter((item,key) => item.value > 0 && !/referral/.test(item.solution.activation.ident))
      const filteredRefs = action.payload.balance.history.filter((item,key) => item.value > 0 && /referral/.test(item.solution.activation.ident))
      return {
        ...state,
        incomeHistory: action.payload.balance,
        incomeHistorySales: {
          history: filteredSales,
          count: filteredSales.length
        },
        incomeHistoryRefs: {
          history: filteredRefs,
          count: filteredRefs.length
        }
      }
      case ACCOUNT_PAYMENT_HISTORY:
        return {
        ...state,
        payoutHistory: action.payload.balance.history
      }
    case ACCOUNT_PAYMENT_REQUEST:
      return {
        ...state,
        paymentInProgress: true
      }
    case ACCOUNT_PAYMENT_FAILURE:
      return {
        ...state,
        paymentInProgress: false
      }
    case ACCOUNT_PAYMENT_CREATE:
      return {
        ...state,
        balance: {...state, value: 0},
        paymentInProgress: false
      }
    case GET_SEASONS_SUCCESS:
      return {
        ...state,
        seasonsInfo: { current: action.payload.currentSeason, list: action.payload.seasons }
      }
    case GET_PRIZES:
      return {
        ...state,
        prizeList: Array.isArray(action.payload) ? action.payload : [action.payload]
      }
    default:
      return state;
  }
}

export const getAccountBalance = (state) => state.account.balance.value
export const getAccountProfit = (state) => state.account.balance.current
export const getSeasons = (state) => state.account.balance.history
export const getSeasonsInfo = (state) => state.account.seasonsInfo
export const getIncomeHistorySales = (state) => state.account.incomeHistorySales.history
export const getIncomeHistorySalesCount = (state) => state.account.incomeHistorySales.count
export const getIncomeHistoryRefs = (state) => state.account.incomeHistoryRefs.history
export const getIncomeHistoryRefsCount = (state) => state.account.incomeHistoryRefs.count
export const getIncomeHistory = (state) => state.account.incomeHistory.history
export const getIncomeHistoryCount = (state) => state.account.incomeHistory.count
export const getPayoutHistory = (state) => state.account.payoutHistory
export const getReferrals = (state) => state.account.balance.referral
export const getReferralsHistory = (state) => state.account.balance.referral.history
export const getPrizeList = (state) => state.account.prizeList
export const getPayoutInProgress = (state) => state.account.paymentInProgress