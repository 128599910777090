import {
  LOGIN,
  LOGOUT,
  REMIND,
  USER_DETAILS,
  SET_TOKEN,
  SET_OTP_TOKEN,
  REGISTER_SUCCESS,
  REGISTER_REQUEST,
  REGISTER_FAILURE,
  REQUEST_RESET_PASS,
  SET_OTP_GLO_TOKEN,
  REGISTER_GLO_SUCCESS,
  COUPON_LIST,
  DASH_TIMER
} from "../actions/types";

export function userReducer(state = {}, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.payload.token,
        isAuthenticated: true
      }
    case USER_DETAILS:
        return {
          ...state,
          data: action.payload.user,
          isAuthenticated: true
        }
    case LOGOUT:
        return {
          ...state,
          token: null,
          data: null,
          isAuthenticated: false
        }
    case REMIND:
        return {
            ...state,
            remindEmail: action.payload,
        }
    case SET_TOKEN:
      return {
        ...state,
        reset_token: action.payload.token,
      }
    case SET_OTP_TOKEN:
      return {
        ...state,
        otp_token: action.payload.token,
      }
    case REGISTER_REQUEST:
      return {
        ...state,
        submitting: true,
      }
    case REGISTER_SUCCESS:
      return {
        ...state,
        submitting: false,
        registerSuccess: true
      }
    case REGISTER_FAILURE:
      return {
        ...state,
        submitting: false,
      }
    case REGISTER_GLO_SUCCESS:
      return {
        ...state,
        registerGloSuccess: true
      }
    case SET_OTP_GLO_TOKEN:
      return {
        ...state,
        otp_glo_token: action.payload.token,
      }
    case REQUEST_RESET_PASS:
      return {
        ...state,
        remindSuccess: true
      }
    case COUPON_LIST:
      return {
        ...state,
        coupons: action.payload
      }
    case DASH_TIMER:
      return {
        ...state,
        timer: action.payload
      }
    default:
      return state;
  }
}

export const getUser = (state) => state.user.data
export const getToken = (state) => state.user.token
export const getRegisterState = (state) => state.user.registerSuccess
export const getRemindState = (state) => state.user.remindSuccess
export const getAuthState = (state) => state.user.isAuthenticated
export const getRegisterGloState = (state) => state.user.registerGloSuccess
export const getCoupons = (state) => state.user.coupons
export const getTimer = (state) => state.user.timer
export const getSubmitState = (state) => state.user.submitting