import { 
  SHOW_MENU,
  SHOW_DRAWER,
  SHOW_NOTI,
  CONTEST_INFO
} from './types';
import { API_URL, appFetch } from '../../helpers/fetch';
import { errors } from '../../helpers/errors';

export function showMenu() {
  return (dispatch, getState) => { 
    dispatch({type: SHOW_MENU, payload: true})
  }
}

export function closeMenu() {
  return (dispatch, getState) => { 
    dispatch({type: SHOW_MENU, payload: false})
  }
}

export function showDrawer(type, data) {
  return (dispatch, getState) => { 
    dispatch({type: SHOW_DRAWER, payload: {show: true, type: type, data: data }})
  }
}

export function hideDrawer() {
  return (dispatch, getState) => { 
    dispatch({type: SHOW_DRAWER, payload: {show: false}})
  }
}

export function showNoti() {
  return (dispatch, getState) => { 
    dispatch({type: SHOW_NOTI, payload: true})
  }
}

export function hideNoti() {
  return (dispatch, getState) => { 
    dispatch({type: SHOW_NOTI, payload: false})
  }
}

export function setContestInfo(val) {
  return (dispatch, getState) => { 
    dispatch({type: CONTEST_INFO, payload: val})
  }
}
