import React, { useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./Products.scss";
import Title from "../../components/Title/Title";
import DropDownTabs from "../../components/DropDownTabs/DropDownTabs";
import PALLMALL from "./img/pall-mall.jpg";
import KENT from "./img/kent.jpg";
import AIR from "./img/air.jpg";
import HYPER from "./img/hyper.jpg";
import UNIQ from "./img/uniq.jpg";
import VELO from "./img/velo.jpg";
import VUSE from "./img/vuse.jpg";
import VEO from "./img/veo.jpg";
import { Link } from "react-router-dom";
import ArrowButton from "../../components/ArrowButton/ArrowButton";

interface ProductProps {
  item: any;
}

const ProductItem: React.FC<ProductProps> = ({ item }) => {
  return (
    <Link to={item.link} className="product-item">
      <div className="image"><img src={ item.img }/></div>
      <div className="content">
        <div className="product-name">{item.name}</div>
        <div className="product-category"><span>{item.categoryName}</span></div>
        <ArrowButton color="white"/>
      </div>
    </Link>
  );
}

interface DropdownProps {
  categories: any;
  items: any;
  setFilteredItems: any;
}

const Dropdown: React.FC<DropdownProps> = ({ categories, items, setFilteredItems }) => {
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);
  const [dropdownActive, setDropdownActive] = useState(false);

  const handleCategoryChange = (category: any) => {
    setSelectedCategory(category);

    // Filter the items based on the selected category
    if (category.id != 'all') {
      const filteredItems = items.filter((item: any) => item.category === category.id);
      setFilteredItems(filteredItems);
    } else {
      setFilteredItems(items)
    }
  };

  const categoryCount = (selectedCategory:any, items:any) => {
    let count = items.length;
    if (selectedCategory != 'all') {
      const filteredItems = items.filter((item: any) => item.category === selectedCategory);
      return filteredItems.length;
    }
    return count
  }

  return (
    <div className={dropdownActive ? "products-dropdown active" : "products-dropdown"}>
      <div className="products-dropdown-selected" onClick={()=>{setDropdownActive(!dropdownActive)}}>
        { selectedCategory.name }
        <span className="count">{ categoryCount(selectedCategory.id, items) }</span>
        <ArrowButton />
      </div>
      <div className="products-dropdown-options">
        {categories.map((category: any) => (
          <div
            className={selectedCategory.id === category.id ? "products-dropdown-option active" : "products-dropdown-option"}
            key={category.id}
            onClick={() => {handleCategoryChange(category); setDropdownActive(false)}}
          >
            {category.name}
            <span className="count">{ categoryCount(category.id, items) }</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const Products = () => {
  const productItems = [
    {
      name: "veo™",
      categoryName: "Wkłady do glo™",
      category: "wklady",
      img: VEO,
      link: '/produkty-bat/veo'
    },
    {
      name: "Pall Mall",
      categoryName: "Papierosy tradycyjne",
      category: "cigarettes",
      img: PALLMALL,
      link: '/produkty-bat/pall-mall'
    },
    {
      name: "VUSE GO",
      categoryName: "Papierosy jednorazowe",
      category: "vape",
      img: VUSE,
      link: '/produkty-bat/vusego'
    },
    {
      name: "KENT",
      categoryName: "Papierosy tradycyjne",
      category: "cigarettes",
      img: KENT,
      link: '/produkty-bat/kent'
    },
    {
      name: "glo™ hyper X2 Air",
      categoryName: "Podgrzewacz",
      category: "heaters",
      img: AIR,
      link: '/produkty-bat/glo-hyper-x2-air'
    },
    {
      name: "glo™ hyper X2",
      categoryName: "Podgrzewacz",
      category: "heaters",
      img: HYPER,
      link: '/produkty-bat/glo-hyper-x2'
    },
    {
      name: "glo™ hyper+ UNIQ",
      categoryName: "Podgrzewacz",
      category: "heaters",
      img: UNIQ,
      link: '/produkty-bat/glo-hyper-uniq'
    },
    {
      name: "VELO",
      categoryName: "Saszetki nikotynowe",
      category: "gums",
      img: VELO,
      link: '/produkty-bat/velo'
    }
  ]
  const categories = [
    {
      name: "Wszystkie",
      id: 'all'
    },
    {
      name: "Podgrzewacze",
      id: "heaters"
    },
    {
      name: "Wkłady do glo™",
      id: "wklady"
    },
    {
      name: "Papierosy tradycyjne",
      id: "cigarettes"
    },
    {
      name: "Papierosy jednorazowe",
      id: "vape"
    },
    {
      name: "Saszetki nikotynowe",
      id: "gums"
    }
  ]
  const [filteredItems, setFilteredItems] = useState(productItems)

  return (
    <PageLayout>
      <div className={"page products"}>
        <Title>Poznaj produkty BAT</Title>
        <div className="product-page-wrap">
          <Dropdown categories={ categories } items={ productItems } setFilteredItems={setFilteredItems}/>
          <div className="product-items">
            { filteredItems.map((item:any) => (
              <ProductItem item={item} />
              ))}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default Products;