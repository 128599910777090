import { useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./HowToRecommendAlt.scss";
import Title from "../../components/Title/Title";
import mobileHero from "./img/hero-alt.jpg";
import desktopHero from "./img/hero-desktop.jpg";
import icon01 from "./img/icon01.svg";
import icon02 from "./img/icon02.svg";
import icon03 from "./img/icon03.svg";
import icon04 from "./img/icon04.svg";
import iconPrize01 from "./img/icon-25.svg";
import iconPrize02 from "./img/icon-twopacks-flat.svg";
import titleIcon from "./img/title-icon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { useWindowDimensions } from "../../hooks";
import { Link } from "react-router-dom";
import RedeemCode from "./RedeemCode";

function HowToRecommend() {
  const { width } = useWindowDimensions();
  const [redeemShow, setRedeemShow] = useState(false);

  return (
    <PageLayout>
      <section className="HowToRecommendAlt">
        <div className="hero-section">
          <div className="hero-image">
            <picture>
              <source media="(max-width: 1019px)" srcSet={mobileHero} />
              <source media="(min-width: 1020px)" srcSet={desktopHero} />
              <img className="bg" src={desktopHero} alt="A wide image" />
            </picture>
          </div>
          <div className="hero-content">
            <Title>Polecanie rejestracji&nbsp;glo™</Title>
            <div className="ButtonTabs">
              <div className={redeemShow ? "ButtonTab" : "ButtonTab active"} onClick={()=>setRedeemShow(false)}>Jak polecać?</div>
              <div className={redeemShow ? "ButtonTab active" : "ButtonTab"} onClick={()=>setRedeemShow(true)}>Kody na paczki neo™</div>
            </div>
            { !redeemShow ?
              <h2 className="title">
                <strong>Poleć swoim konsumentom zarejestrowanie urządzenia glo™</strong>, a&nbsp;wszyscy otrzymacie wyjątkowe nagrody.
              </h2>
            : 
              <h2 className="title">
                Wpisz promocyjny kod, aby&nbsp;wydać konsumentowi nagrodę: 
                <strong> 2&nbsp;paczki&nbsp;neo™ w&nbsp;cenie jednej</strong>
              </h2>
            }
          </div>
        </div>

        { redeemShow ? 
          <RedeemCode />
        :
        <>
          <Swiper
            slidesPerView={1.2}
            spaceBetween={16}
            centeredSlides={true}
            className="recommend-swiper"
            breakpoints={{
              1020: {
                slidesPerView: 4,
                spaceBetween: 10,
                centeredSlides: false,
              },
              1270: {
                slidesPerView: 4,
                spaceBetween: 20,
                centeredSlides: false,
              },
            }}
          >
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="top">
                  <img
                    className="icon"
                    src={icon01}
                    alt="Sprzedaj glo™ Hyper + Uniq "
                  />
                </div>
                <div className="body">
                  <h3 className="title">
                    Sprzedaj glo™&nbsp;hyper&nbsp;X2&nbsp;Air lub glo™&nbsp;hyper+&nbsp;UNIQ&nbsp;
                  </h3>
                  <p className="desc">
                    Poleć i sprzedaj konsumentowi urządzenie glo™ hyper X2 Air lub glo™ hyper+ UNIQ. Przed przejściem do 2 kroku{" "}
                    <a href="/nowa-sprzedaz">zarejestruj swoją sprzedaż</a>{" "}
                    poprzez zeskanowanie lub ręczne wprowadzenie numeru
                    urządzenia.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="top">
                  <img className="icon" src={icon02} alt="Poinformuj klienta" />
                </div>
                <div className="body">
                  <h3 className="title">
                    Poinformuj <br />
                    klienta
                  </h3>
                  <p className="desc">
                    Powiadom go o możliwości <strong>zarejestrowania zakupionego urządzenia</strong>, aby otrzymać kupon na <strong>2&nbsp;paczki&nbsp;neo™ w&nbsp;cenie jednej</strong> do odebrania w Twoim sklepie.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="top">
                  <img
                    className="icon"
                    src={icon03}
                    alt="Rejestracja urządzenia"
                  />
                </div>
                <div className="body">
                  <h3 className="title title-small">
                    Rejestracja urządzenia
                  </h3>
                  <p className="desc">
                    Teraz czas na ruch klienta. Korzystając z linku <strong><a href="https://batpartners.pl/rejestracjaglo">www.batpartners.pl/rejestracjaglo</a></strong>, klient dokonuje rejestracji urządzenia poprzez podanie numeru seryjnego oraz telefonu, na który chce odebrać kupon.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="recommend-swiper-slide">
                <div className="top">
                  <img className="icon" src={icon04} alt="Pora na nagrody" />
                </div>
                <div className="body">
                  <h3 className="title title-small">Pora na nagrody</h3>
                  <p className="desc">
                    Za pełne skuteczne polecenie, czyli wykonanie wszystkich 3
                    wcześniejszych kroków,{" "}
                    <span className="special">wszyscy otrzymacie nagrody.</span>
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="prize">
            <div className="inner">
              <div className="header">
                <span className="icon">
                  <img src={titleIcon} alt="Pora na nagrody" />
                </span>
                <h2 className="title">Nagrody</h2>
              </div>
              <div className="wrapper">
                <ul className="cards">
                  <li className="card">
                    <div className="top-card">
                      <h3 className="title">
                        Dla Ciebie w&nbsp;Bat&nbsp;Partners
                      </h3>
                    </div>
                      <div className="body">
                        <div className="icon">
                          <img src={iconPrize01} alt="Dla Ciebie w BatPartners" />
                        </div>
                        <p className="desc">
                          <span>25 zł -</span> za każde polecenie konsumenta, który zarejestruje swoje zakupione urządzenie glo™
                        </p>
                      </div>
                    
                  </li>
                  <li className="card">
                    <div className="top-card">
                      <h3 className="title">
                        Dla Klienta w&nbsp;Twoim sklepie
                      </h3>
                    </div>
                    <div className="body">
                      <div className="icon">
                        <img src={iconPrize02} alt="Dla Klienta w Twoim sklepie" />
                      </div>
                      <p className="desc">
                        <span>2 paczki neo™ w cenie jednej -</span>
                        <br /> w postaci kodu do zrealizowania
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </>
        }
      </section>
    </PageLayout>
  );
}

export default HowToRecommend;
