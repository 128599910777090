export function gtagEvent(type, category, label) {
  if (window.gtag) {
    window.gtag('event', type, { event_category: category, event_label: label })
  }
}

export function pageView(pageLocation, pagePath) {
  if (window.gtag) {
    window.gtag('event', 'page_view', {
      page_location: pageLocation,
      page_path: pagePath
    })
  }
}
