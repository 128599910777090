import React, { useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./GloHyper.scss";
import Title from "../../components/Title/Title";
import mobileHero from "./img/hero.png";
import desktopHero from "./img/hero-desktop.png";
import icon01 from "./img/icon01.png";
import icon02 from "./img/icon02.png";
import { ReactComponent as IconBack } from "./img/click-icon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import arctic from "./img/arctic.png";
import arcticDesktop from "./img/arctic-desktop.png";
import summer from "./img/summer.png";
import summerDesktop from "./img/summer-desktop.png";
import { useWindowDimensions } from "../../hooks";
function GloUniq() {
  const { width } = useWindowDimensions();
  const arcticImage = width >= 1020 ? arcticDesktop : arctic;
  const summerImage = width >= 1020 ? summerDesktop : summer;
  return (
    <PageLayout>
      <section className="GloHyper">
        <div className="hero-section">
          <div className="hero-image">
            <picture>
              <source media="(max-width: 1019px)" srcSet={mobileHero} />
              <source media="(min-width: 1020px)" srcSet={desktopHero} />
              <img className="bg" src={desktopHero} />
            </picture>
          </div>
          <div className="glo-wrapper">
            <div className="glo-hero-content">
              <Title>glo™ hyper+</Title>
              <h2 className="sub-title">
                Doświadcz satysfakcji z&nbsp;podgrzewania tytoniu dzięki
                zaawansowanej technologii indukcyjnej. Nowy stylowy design,
                technologia znana z glo™ hyper.
              </h2>
            </div>
          </div>
        </div>

        <Swiper
          pagination={{
            clickable: true,
          }}
          slidesPerView={1.2}
          spaceBetween={16}
          centeredSlides={true}
          className="glo-swiper"
          breakpoints={{
            1020: {
              slidesPerView: 2,
              spaceBetween: 30,
              centeredSlides: false,
            },
          }}
        >
          <SwiperSlide>
            <div className="glo-swiper-slide">
              <div className="top">
                <img className="icon" src={icon02} alt="" />
              </div>
              <div className="body">
                <h3 className="title">Bez dymu, bez popiołu*</h3>
                <p className="desc">
                  Prawdziwy tytoń i zaawansowana technologia w stylowym wydaniu
                </p>
                <p className="disc">
                  *W porównaniu do tradycyjnie palonych papierosów. Nie oznacza
                  to, że produkt ten jest mniej szkodliwy niż inne wyroby
                  tytoniowe
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="glo-swiper-slide">
              <div className="top">
                <img className="icon" src={icon01} alt="" />
              </div>
              <div className="body">
                <h3 className="title">2 tryby pracy</h3>
                <p className="desc">
                  Dzięki dwóm trybom STANDARD i BOOST możesz kontrolować
                  intensywność podgrzewania tytoniu
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="new-taste">
          <h2 className="title">Nowe smaki, więcej opcji wyboru</h2>

          <Swiper
            slidesPerView={1.2}
            centeredSlides={true}
            spaceBetween={10}
            className="cards"
            breakpoints={{
              1020: {
                slidesPerView: 2,
                spaceBetween: 40,
                centeredSlides: false,
              },
            }}
          >
            <SwiperSlide>
              <div
                className="card"
                style={{ backgroundImage: "url(" + arcticImage + ")" }}
              >
                <div className="card-head">
                  <h3>Arctic Blue Click</h3>
                  <p>
                    Zbalansowany smak <br /> tytoniu
                    <br /> i podwójny klik
                  </p>
                </div>
                <div className="card-inner">
                  <IconBack fill="#196BD0" />
                  <p>
                    <strong>
                      doświadczenie
                      <br /> mroźnego mentolu
                    </strong>
                  </p>
                </div>
                <div className="card-inner">
                  <IconBack fill="#93DE4E" />
                  <p>
                    <strong>słodka miętowa nuta</strong>
                  </p>
                </div>
                <div className="power-tobacco">
                  <p>
                    <strong>Intensywność</strong>
                  </p>
                  <div className="power-tobacco-wrapper">
                    <span className="bullet blue"></span>
                    <span className="bullet blue"></span>
                    <span className="bullet blue"></span>
                    <span className="bullet blue"></span>
                    <span className="bullet blue"></span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="card"
                style={{ backgroundImage: "url(" + summerImage + ")" }}
              >
                <div className="card-head">
                  <h3>Summer Yellow Click</h3>
                  <p>
                    Łagodna baza tytoniowa
                    <br /> z dwiema kapsułami do
                    <br /> kliknięcia
                  </p>
                </div>
                <div className="card-inner">
                  <IconBack fill="#7CC12D" />
                  <p>
                    <strong>nuta słodkiego melona</strong>
                  </p>
                </div>
                <div className="card-inner">
                  <IconBack fill="#CEDC0E" />
                  <p>
                    <strong>nuta tropikalnych owoców</strong>
                  </p>
                </div>
                <div className="power-tobacco">
                  <p>
                    <strong>Intensywność</strong>
                  </p>
                  <div className="power-tobacco-wrapper">
                    <span className="bullet green"></span>
                    <span className="bullet green"></span>
                    <span className="bullet green"></span>
                    <span className="bullet green-empty"></span>
                    <span className="bullet green-empty"></span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {/* <div className="quiz">
          <h4 className="title">Rozwiaż quiz i wygraj 10 zł</h4>
          <button className="Button btn">Przejdź do quizu</button>
        </div> */}
      </section>
    </PageLayout>
  );
}

export default GloUniq;
