import React from 'react';
import { NavLink } from 'react-router-dom';
import { ReactComponent as IconHome } from './img/icon-home.svg'
import { ReactComponent as IconNews } from './img/icon-document.svg'
import { ReactComponent as IconActivities } from './img/icon-activities.svg'
import { ReactComponent as IconWallet } from './img/icon-wallet.svg'
import { ReactComponent as IconAdd } from './img/icon-add.svg'
import './Tabs.scss';
import { useAnalyticsEventTracker, useAppSelector } from '../../hooks';
import { getUser } from '../../store/reducers/user';
import classNames from 'classnames';

interface Props {
  withDisclaimer?: boolean;
}

const april = new Date() > new Date("2024-04-01T00:00:00.000")

function Tabs({withDisclaimer}: Props) {
  const gaEventTracker = useAnalyticsEventTracker('Nav Tabs Event')
  const user = useAppSelector(getUser);

  return (
    <div className={withDisclaimer ? "Tabs disclaimer" : "Tabs"}>
      <div className="tab-nav">
        <NavLink to="/dashboard" onClick={ ()=>{gaEventTracker('click', "Dashboard")} }>
          <div className="nav-icon to-home"><IconHome/></div>
          <span>Dashboard</span>
        </NavLink>
        <NavLink to="/news" onClick={ user?.sapIdValidate && !april  ? ()=>{gaEventTracker('click', "News")} : (e)=>e.preventDefault()} className={ user?.sapIdValidate && !april  ? "" : "disabled"}>
          <div className="nav-icon"><IconNews/></div>
          <span>News</span>
        </NavLink>
      </div>
      <div className={user?.sapIdValidate && !april  ? "tab-btn-sale" : "tab-btn-sale disabled"}>
        <NavLink to="/nowa-sprzedaz" onClick={user?.sapIdValidate && !april  ? ()=>{gaEventTracker('click', "Nowa sprzedaż")} : (e)=>e.preventDefault() }>
          <div className="nav-icon"><IconAdd/></div>
          <span>Nowa <br/>sprzedaż</span>
        </NavLink>
      </div>
      <div className="tab-nav">
        <NavLink to="/aktywnosci" onClick={ user?.sapIdValidate && !april  ? ()=>{gaEventTracker('click', "Aktywności")} : (e)=>e.preventDefault()} className={ user?.sapIdValidate && !april  ? "" : "disabled"}>
          <div className="nav-icon"><IconActivities/></div>
          <span>Aktywnosci</span>
        </NavLink>
        <NavLink to="/wyplata" onClick={ ()=>{gaEventTracker('click', "Wypłata")} }>
          <div className="nav-icon"><IconWallet/></div>
          <span>Wypłać</span>
        </NavLink>
      </div>
    </div>
  );
}

export default Tabs
