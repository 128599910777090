import { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import Title from "../../components/Title/Title";
import hyper from "./img/hyper.jpg";
import hyperm from "./img/hyper-m.jpg";
import pdfIcon from "./img/pdf.svg";
import "./Complaint.scss";

function Complaint() {

  return (
    <PageLayout>
      <div className="page Complaint">
        <Title>Nowy, ujednolicony i&nbsp;uproszczony sposób reklamacji!</Title>
        <section className="complaint-top">
          <div className="text">
            <p>Reklamacja to procedura, która ma zastosowanie w przypadku wystąpienia wady jakościowej towaru.</p>
            <p>Konsument ma prawo żądać:
              <ul>
                <li className="color">Wymiany wadliwego towaru na nowy</li>
                <li className="color">Naprawy wadliwego towaru</li>
                <li className="color">Obniżenia ceny towaru</li>
                <li className="color">Odstąpienia od umowy</li>
              </ul>
            </p>
          </div>
          <div className="image">
            <picture>
              <source 
                srcset={hyper}
                media="(min-width: 1000px)"
              />
              <img 
                src={hyperm}
                alt="Glo Hyper X2 Air"
              />
            </picture>
          </div>
        </section>
        <section className="new-process">
          <h2>Nowy proces reklamacji z&nbsp;tytułu gwarancji i rękojmi (krok po kroku)</h2>
          <ul className="process-list">
            <li><p className="color">1. Konsument zgłasza reklamację w punkcie sprzedaży, w którym zakupił produkt.</p></li>
            <li>
              <p className="color">2. Detalista kieruje konsumenta do odpowiedniej infolinii w zależności od reklamowanego produktu:</p>
              <ul>
                <li>Infolinia glo™, Velo: <strong>+48 800 610 610</strong></li>
                <li>Infolinia Vuse: <strong>+48 801 255 255</strong></li>
                <li>Infolinia P1, Frog: <strong>62 738 88 88</strong></li>
              </ul>
              <p>Infolinia udziela informacji dotyczących sposobu złożenia reklamacji.</p>
            </li>
            <li>
              <p className="color">3. Konsument kontaktuje się z infolinią, gdzie zostaje poinformowany o dalszych krokach:</p>
              <p>A) Przekierowanie do najbliższego punktu CHIC/VIS OWN, gdzie reklamacja zostanie rozpatrzona na miejscu, a wadliwy produkt zostanie wymieniony na nowy lub wysłany do naprawy.</p>
              <p>B) Konsument wypełnia Formularz Reklamacyjny dostępny na stronie glo/Vuse/Velo, dołącza paragon lub inny dokument potwierdzający zakup (w zależności od rodzaju reklamacji) oraz produkt i przesyła je na własny koszt do Działu Reklamacji CHIC.</p>
              <p><strong>Jeśli produkt zostanie odesłany do CHIC, dział reklamacji podejmuje decyzję w ciągu 14 dni. Jeśli dział reklamacji nie podejmie decyzji w ciągu 14 dni, reklamacja jest uznawana.</strong></p>
            </li>
            <li>
              <p className="color">4. Jeśli decyzja jest pozytywna, produkt zostanie naprawiony lub wymieniony na nowy w ciągu 14 dni od daty wydania decyzji.</p>
            </li>
            <li>
              <p className="color">5. Konsument otrzymuje naprawiony lub nowy produkt w ciągu 14 dni od daty wydania decyzji:</p>
              <p>A) Konsument odbiera produkt w punkcie CHIC OWN/VIS OWN.</p>
              <p>B) CHIC przesyła naprawiony lub nowy produkt na adres Konsumenta.</p>
            </li>
          </ul>
          <h3>Do pobrania</h3>
          <a className="download-link" href="/pdf/formularz_reklamacji_z_tytulu_gwarancji.pdf" target="_blank"><img src={pdfIcon} /> Formularz reklamacji</a>
        </section>
        <section className="old-process">
          <h2>Proces reklamacyjny (zapasowy) z tytułu rękojmi:</h2>
          <p>Proces ten należy zastosować jedynie w przypadku, <strong>gdy Konsument nalega, że opinią dotyczącą reklamacji powinien być punkt, w którym dokonano zakupu.</strong></p>
          <ul className="process-list">
            <li><p className="color">1. Konsument zgłasza reklamację w punkcie sprzedaży, w którym dokonał zakupu.</p></li>
            <li>
              <p className="color">2. Detalista wypełnia Protokół Reklamacyjny w obecności Konsumenta i wydaje mu potwierdzenie przyjęcia reklamacji.</p>
            </li>
            <li>
              <p className="color">3. Tego samego dnia Detalista wysyła informację na adres e-mail reklamacje@esmokingworld.com w celu zamówienia kuriera.</p>
              <p>Sprzedawca ma obowiązek rozpatrzenia reklamacji <strong>w terminie 14 dni. Po upływie tego terminu nie może odmówić spełnienia żądania Konsumenta.</strong></p>
            </li>
            <li>
              <p className="color">4. Detalista przekazuje wadliwy produkt, dokument potwierdzający zakup oraz wypełniony Protokół Reklamacyjny kurierowi.</p>
              <p>Kurier dostarcza je do Działu Reklamacji CHIC na adres: Chic Sp. z o.o., Aleja Wojska Polskiego 23C, 63-500 Ostrzeszów. Koszty wysyłki ponosi BAT.</p>
            </li>
            <li>
              <p className="color">5. Dział Reklamacji podejmuje decyzję w ciągu 14 dni od daty zgłoszenia reklamacji przez Konsumenta. Następnie Dział Reklamacji odsyła naprawiony lub wymieniony produkt do punktu sprzedaży.</p>
            </li>
          </ul>
          <h3>Do pobrania</h3>
          <a className="download-link" href="/pdf/protokol_reklamacyjny_z_tytulu_rekojmi.pdf" target="_blank"><img src={pdfIcon} /> Protokół reklamacyjny</a>
        </section>
      </div>
    </PageLayout>
  );
}

export default Complaint;
