import React, { useEffect } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/reducers/user";
import { ReactComponent as Smile } from "./img/smile.svg";
import { ReactComponent as Gift } from "./img/gift.svg";
import { ReactComponent as OpenGift } from "./img/gift-open.svg";
import { ReactComponent as IconBack } from "./img/icon-back.svg";
import "./Rank.scss";
import Title from "../../components/Title/Title";
import { getSeasons, getSeasonsInfo } from "../../store/reducers/account";
import { accountSeasons } from "../../store/actions/account";
import { romanize, useAppDispatch } from "../../hooks";

function Ranks() {
  const dispatch = useAppDispatch();
  const user = useSelector(getUser);
  const seasons = useSelector(getSeasons);
  const seasonsInfo = useSelector(getSeasonsInfo)
  const season = seasons.filter((s: any) => s.name === seasonsInfo.current)[0]

  useEffect(()=>{
    if (seasonsInfo.current === '') {
      dispatch(accountSeasons())
    }
  }, [])

  const seasonName = (name:string) => {
    if (name === 'q1_21') {
      return "2021: Wiosna";
    } else if (name === 'q2_21') {
      return "2021: Lato";
    } else if (name === 'q3_21') {
      return "2021: Jesień";
    } else if (name === 'q4_21') {
      return "2021 Zima";
    } else {
      return `20${name.substring(3,5)}: Sezon ${romanize(parseInt(name.substring(1,2)))}`;
    }
  }

  const rankClass = (displayID: number) => {
    if ( season?.rankId === displayID) {
      return "rank-list-item rank-list-item--current"
    } else if ( season?.rankId > displayID ) {
      return "rank-list-item rank-list-item--gainded"
    }

    return "rank-list-item";
  }

  return (
    <PageLayout>
      <div className="page rank">
        <Title>Twoja ranga</Title>
        <div className="ranks-items">
          <h3 className="ranks-items-title">{ seasonName(seasonsInfo.current)}</h3>
          <ul className="rank-list">
            <li className={ rankClass(0) }>
              <div className="rank-list-item-body">
                <div className="rank-list-item-desc">
                  <p className="rank-list-item-desc-text">
                    {" "}
                    Sprzedawaj urządzenia glo™ oraz Vuse, aby awansować na
                    wyższą rangę.
                  </p>
                </div>
                <div className="rank-list-item-role">
                  <h4 className="rank-list-item-role-title">Praktykant</h4>
                  <span className="rank-list-item-icon rank-list-item-icon--absolute">
                    <Smile />
                  </span>
                </div>
              </div>
            </li>
            <li className={ rankClass(1) }>
              <div className="rank-list-item-body">
                <div className="rank-list-item-desc">
                  <p className="rank-list-item-desc-text">
                    Bonus
                    <span className="rank-list-item-value">15 zł</span>
                    za <strong>3</strong> sprzedane urządzenia
                  </p>
                </div>
                <div className="rank-list-item-role">
                  <h4 className="rank-list-item-role-title">Uczestnik</h4>
                  <span className="rank-list-item-icon rank-list-item-icon--absolute">
                   { season?.rankId >= 1 ? <OpenGift /> : <Gift /> }
                  </span>
                </div>
              </div>
            </li>
            <li className={ rankClass(2) }>
              <div className="rank-list-item-body">
                <div className="rank-list-item-desc">
                  <p className="rank-list-item-desc-text">
                    Bonus
                    <span className="rank-list-item-value">40 zł</span>
                    za <strong>6</strong> sprzedanych urządzeń
                  </p>
                </div>
                <div className="rank-list-item-role">
                  <h4 className="rank-list-item-role-title">Dystrybutor</h4>
                  <span className="rank-list-item-icon rank-list-item-icon--absolute">
                   { season?.rankId >= 2 ? <OpenGift /> : <Gift /> }
                  </span>
                </div>
              </div>
            </li>
            <li className={ rankClass(3) }>
              <div className="rank-list-item-body">
                <div className="rank-list-item-desc">
                  <p className="rank-list-item-desc-text">
                    Bonus
                    <span className="rank-list-item-value">50 zł</span>
                    za <strong>10</strong> sprzedanych urządzeń
                  </p>
                </div>
                <div className="rank-list-item-role">
                  <h4 className="rank-list-item-role-title">Ulubieniec </h4>
                  <span className="rank-list-item-icon rank-list-item-icon--absolute">
                   { season?.rankId >= 3 ? <OpenGift /> : <Gift /> }
                  </span>
                </div>
              </div>
            </li>
            <li className={ rankClass(4) }>
              <div className="rank-list-item-body">
                <div className="rank-list-item-desc">
                  <p className="rank-list-item-desc-text">
                    Bonus
                    <span className="rank-list-item-value">60 zł</span>
                    za <strong>15</strong> sprzedanych urządzeń
                  </p>
                </div>
                <div className="rank-list-item-role">
                  <h4 className="rank-list-item-role-title">Gwiazda </h4>
                  <span className="rank-list-item-icon rank-list-item-icon--star">
                   { season?.rankId >= 4 ? <OpenGift /> : <Gift /> }
                  </span>
                  <p className="rank-list-item-role-desc">
                    <strong>+ dodatkowe 5zł </strong> do każdego sprzedanego
                    urządzenia w następnym sezonie
                  </p>
                </div>
              </div>
            </li>
            <li className={ rankClass(5) }>
              <div className="rank-list-item-body">
                <div className="rank-list-item-desc">
                  <p className="rank-list-item-desc-text">
                    Bonus
                    <span className="rank-list-item-value">100 zł</span>
                    za <strong>20</strong> sprzedanych urządzeń
                  </p>
                </div>
                <div className="rank-list-item-role">
                  <h4 className="rank-list-item-role-title">Bohater </h4>
                  <span className="rank-list-item-icon rank-list-item-icon--absolute">
                   { season?.rankId >= 5 ? <OpenGift /> : <Gift /> }
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </PageLayout>
  );
}

export default Ranks;