import { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import Title from "../../components/Title/Title";
import { ReactComponent as ArrowIcon } from "./img/arrow.svg";
import "./Faq.scss";
import { API_URL, appFetch } from "../../helpers/fetch";
import { useAppSelector } from "../../hooks";
import { ADD_TOAST } from "../../store/actions/types";
import { useDispatch } from "react-redux";
import { errors } from "../../helpers/errors";
import { getToken } from "../../store/reducers/user";

const FaqList = ({ questionList, activeQuestion, handleActiveQuestion }: any) => {
  const questionData:any = Object.entries(questionList)

  const isEmpty = Object.keys(questionList).length === 0;
  if(isEmpty) return null;

  return (
      <>
      {questionData.map((questionItem:any) => (
       <ul key={questionItem[0]} className="qa-list">
        <header className="header">
       <h2 className="category-title">{questionItem[0]}</h2> 
      </header> 
        {questionItem[1].map((item:any) => {
            let fakeID = item.uuid + item.category;
            let answer = item.content.modules[0].custom;
          return (
               <li
        key={"question-" + fakeID}
        className={activeQuestion.includes(fakeID)  ? "active list-item" : "list-item"}
      >
        <div
          className="question"
          onClick={() => {
            handleActiveQuestion(fakeID);
          }}
        >
          {item.content.title}
          <span>
            <ArrowIcon className="icon" />
          </span>
        </div>
        <div dangerouslySetInnerHTML={{ __html: answer }} className="answer" />
      </li> 
      )})}
      </ul>
      ))}
    </>
  );

};

interface Faq {
  category: any;
  uuid: string;
}

function Faq() {
  const [activeQuestion, setActiveQuestion] = useState<any[]>([]);
  const [questionList, setQuestionList] = useState<Faq[]>([]);
  const dispatch = useDispatch();
  const token = useAppSelector(getToken);

  const handleActiveQuestion = (item:any) => {
    const itemExist = activeQuestion.includes(item);
    if(activeQuestion.length === 0) {
      setActiveQuestion((prevState) => [...prevState,item])
    }else if (itemExist) {
      setActiveQuestion((prevState) => [...prevState.filter((prev) => prev !== item)])
    }else {
      setActiveQuestion((prevState) => [...prevState,item])
    }
  }

  useEffect(() => {
    function fetchFaq() {
      appFetch(
        "POST",
        API_URL,
        "/faq/list",
        false,
        token?.uuid ? token?.uuid : false,
        (result: any) => {
          if (result.status && result.status.success) {
            const sortCategory = result.data.reduce((obj:any,question:any) => {
              if(obj.hasOwnProperty(`${question.categoryName}`)) {
                obj[question.categoryName] =  [...obj[`${question.categoryName}`],question]
              } else {
                obj[question.categoryName] =  [question];
              }
           
              return obj;
            },{})
            setQuestionList(sortCategory);
          } else {
          }
        },
        () => {
          dispatch({
            type: ADD_TOAST,
            payload: { type: "error", message: errors.generic },
          });
        }
      );
    }




    fetchFaq();
  }, [token]);

  return (
    <PageLayout>
      <div className="page Faq">
        <Title>FAQ</Title>
              <FaqList
                questionList={questionList}
                activeQuestion={activeQuestion}
                handleActiveQuestion={handleActiveQuestion}
              />
      </div>
    </PageLayout>
  );
}

export default Faq;
