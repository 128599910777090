import React, { useEffect, useState } from "react";
import Content from "../../components/PageLayout/Content/Content";
import PageLayout from "../../components/PageLayout/PageLayout";
import { useAnalyticsEventTracker, useAppDispatch, useAppSelector } from "../../hooks";
import { getTimer, getUser } from "../../store/reducers/user";
import openIcon from "./img/open.svg"
import closeIcon from "./img/close.svg"
import "./Dashboard.scss";
import DashboardSlider from "./DashboardSlider/DashboardSlider";
import Button from "../../components/Button/Button";
import { ReactComponent as Arrow } from "../../components/ArrowButton/img/arrow.svg";
import ArrowButton from "../../components/ArrowButton/ArrowButton";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { accountSeasons, balanceSummary } from "../../store/actions/account";
import { getAccountBalance, getAccountProfit, getReferrals, getSeasons, getSeasonsInfo } from '../../store/reducers/account';
import { listNews } from "../../store/actions/news";
import { getOriginalNode } from "typescript";
import CountdownModule from "../../components/CountdownModule/CountdownModule";
import { showDrawer } from "../../store/actions/ui";
import { listRanking } from "../../store/actions/rankings";
import { getUserRanking } from "../../store/reducers/rankings";
import Counter from "../../components/Counter/Counter";
import handshakeIcon from '../Goals/img/handshake.svg';
import DropDownTabs from "../../components/DropDownTabs/DropDownTabs";
import gloIcon from "./img/gloIcon.svg";
import x2Icon from "./img/x2Icon.svg";
import { listActivations } from "../../store/actions/activations";
import { ReactComponent as Hand } from "./img/hand.svg";
import { ReactComponent as Clock } from "./img/clock.svg";
import MobileDetect from "mobile-detect";
//@ts-ignore
import PWAPrompt from 'react-ios-pwa-prompt';
import { dashTimer } from "../../store/actions/user";
import warning from '../Profile/img/warning.svg'

const april = new Date() > new Date("2024-04-01T00:00:00.000")

function Dashboard() {
  const user = useAppSelector(getUser);
  const balance = useAppSelector(getAccountBalance);
  const profit = useAppSelector(getAccountProfit);
  const dispatch = useAppDispatch()
  const seasons = useAppSelector(getSeasons);
  const seasonsInfo = useAppSelector(getSeasonsInfo)
  const season = seasons.filter((s: any) => s.name === seasonsInfo.current)[0]
  const userRank = useAppSelector(getUserRanking);
  const gaEventTracker = useAnalyticsEventTracker('Dashboard Event')
  const timeToShowPopup = localStorage.getItem("POP_MODAL_TIME");
  const referrals = useAppSelector(getReferrals);
  const [dashRank, setDashRank] = useState(0);
  const [device, setDevice] = useState<any>({})
  const [iOSPrompt, setiOSPrompt] = useState(false)
  const countdownTimer = useAppSelector(getTimer);
  const [missingUserData, setMissingUserData] = useState(false);
  const [showPayoutTooltip,setShowPayoutTooltip] = useState(false);

  const handleShowPopup = (modalsArray: any) => {
    if (modalsArray?.length > 0) {
      const date = new Date();
      const time = date.getTime();
      const weekInHours = 24 * 7;
      const serializeTime = timeToShowPopup ? JSON.parse(timeToShowPopup) : 0;
  
      if (serializeTime < time) {
        const diff = date.setTime(date.getTime() + weekInHours * 60 * 60 * 1000);
        window.localStorage.setItem("POP_MODAL_TIME", JSON.stringify(diff));
        dispatch(showDrawer(modalsArray[0]));
      }
    }
  };
  useEffect(() => {
    handleShowPopup(user?.modals)
  },[user?.modals])
  
 
  useEffect(()=>{
    dispatch(accountSeasons())
    dispatch(balanceSummary())
    dispatch(listActivations())
    checkDevice()
    dispatch(dashTimer())
  }, [])

  useEffect(() => {
    if (user?.firstName) {
      if (
        user?.dob != null &&
        user?.streetAddress != null &&
        user?.postalCode != null &&
        user?.cityName != null &&
        user?.taxOfficeName != null &&
        user?.iban != null
      ) {
        setMissingUserData(false);
      } else {
        setMissingUserData(true);
        if (!sessionStorage.getItem("pit-info-viewed")) {
          dispatch(showDrawer("pit-info"));
          sessionStorage.setItem("pit-info-viewed", "true");
        }
      }
    }
  }, [user?.dob]);

  useEffect(() => {
    if (season?.name && !userRank) {
      dispatch(listRanking(season?.name, 'ranking'))
    } else {
      if (dashRank === 0) {
        dispatch(listRanking(season?.name, 'ranking'))
      } else {
        dispatch(listRanking(season?.name, 'ranking-consumer'))
      }
    }
  },[season?.name, dashRank])

  const getRankName = (idx: number) => {
    const i = idx || 0;
    switch(i) {
      case 0:
        return (
          <div className="rank-box rank-box-profile">
            <p>Uzupełnij dane aby zdobyć pierwszą rangę</p>
            <Button path="/profil">Uzupełnij</Button>
          </div>
        )
      case 1:
        return (
          <div className="rank-box">
            <h3 className="rank-box-title">Uczestnik</h3>
            <p>bonus <span className="big">15</span> zł <br/><span className="small">za <strong>3</strong> sprzedane urządzenia</span></p>
          </div>
        )
      case 2:
        return (
          <div className="rank-box">
            <h3 className="rank-box-title">Dystrybutor</h3>
            <p>bonus <span className="big">40</span> zł <br/><span className="small">za <strong>6</strong> sprzedanych urządzeń</span></p>
          </div>
        )
      case 3:
        return (
          <div className="rank-box">
            <h3 className="rank-box-title">Ulubieniec</h3>
            <p>bonus <span className="big">50</span> zł <br/><span className="small">za <strong>10</strong> sprzedanych urządzeń</span></p>
          </div>
        )
      case 4:
        return (
          <div className="rank-box">
            <h3 className="rank-box-title">Gwiazda</h3>
            <p>bonus <span className="big">60</span> zł <br/><span className="small">za <strong>15</strong> sprzedanych urządzeń <br/><strong>+ dodatkowe 5zł </strong> do każdego sprzedanego urządzenia w następnym sezonie</span></p>
          </div>
        )
      case 5:
        return (
          <div className="rank-box">
            <h3 className="rank-box-title">Bohater</h3>
            <p>bonus <span className="big">100</span> zł <br/><span className="small">za <strong>20</strong> sprzedanych urządzeń</span></p>
          </div>
        )
      case 6:
        return <div></div>
    }
  }

  const getPWADisplayMode = () => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (document.referrer.startsWith('android-app://')) {
      return 'twa';
      //@ts-ignore
    } else if (navigator.standalone || isStandalone) {
      return 'standalone';
    }
    return 'browser';
  }

  const checkDevice = () => {
    const md = new MobileDetect(window.navigator.userAgent);
    const dev = {
      isMobile: md.mobile() ? true : false,
      isIos: md.is("iOS") ? true : false,
      isAndroid: md.is("AndroidOS") || md.is("Android") ? true : false,
      isPWA: getPWADisplayMode() === "standalone" ? true : false,
      //@ts-ignore
      installable: window.deferredPrompt ? true : false
    }
    // console.log(dev)
    // console.log(getPWADisplayMode())
    setDevice(dev)
  }

  const installPwa = async () => {
    //@ts-ignore
    window.deferredPrompt?.prompt();
    //eslint-disable-next-line
    //@ts-ignore
    const { outcome } = await window.deferredPrompt.userChoice;
    // console.log(`User response to the install prompt: ${outcome}`);
    //@ts-ignore
    window.deferredPrompt = null;
  }

  const showPrompt = () => {
    setiOSPrompt(true)
  }

  return (
    <PageLayout>
      <div className="page Dashboard">
        <div className="user-info dropshadow-box">
          
          <div className="hello">
            <div className="hello-name">
              <div>Witaj</div>
              <div className="user-name">{user?.firstName}</div>
            </div>
            <div className="hello-number">
              Twój numer BAT&nbsp;Partnera:{" "}<br/>
              <span className="bat-number">{user?.referralCode}</span>
            </div>
          </div>
          <div className={missingUserData ? "earnings-current missing-user-data" : "earnings-current"}>
            <div className="columns">
              <div className="title-column">
                <h3 className="earnings-title">Saldo</h3>
                <div className="sub-text">do wypłacenia</div>
              </div>
              <div className="earnings-column">
                <div className={balance > 999 ? "earnings-current-number smaller" : "earnings-current-number" }>
                  {balance}<span>zł</span>
                </div>
              </div>
            </div>
           <div 
                onClick={() => setShowPayoutTooltip((prev) => !prev)} className="payout-content">
           <div className="link">Jak wypłacić?         <img className="icon" src={showPayoutTooltip ? closeIcon : openIcon} alt="&nbsp;" /></div>
           {showPayoutTooltip && (
            <div className="payout-tooltip desktop">
         <p className="desc">Od 02.04.2024 roku przelewy będą realizowane po uprzednim zgłoszeniu się mailowym na adres: <a className="mail" href="mailto:kontakt@batpartners.pl">kontakt@batpartners.pl</a></p>
      
<p className="list-desc">   W zgłoszeniu należy obowiązkowo podać następujące dane:</p>
            <ul>
              <li>Imię i nazwisko</li>
              <li>Numer BAT Partners ID</li>
              <li>Numer telefonu</li>
              <li>Numer konta bankowego</li>
            </ul>

<p className="disclaimer">Uwaga: Bez pełnych i poprawnych danych zgłoszenia nie będą rozpatrywane.</p>
          </div>
          )}
          
           </div>
            {/* {  balance < 1 ? 
              <Button className="disabled">{'Wypłata od 1 zł'}</Button> : missingUserData ? null :
              <Button path="/wyplata" onClick={ ()=>{gaEventTracker('click', "Wypłać Button")}}>Wypłać</Button> 
            } */}
            { missingUserData && <p className="color-red"><img src={warning} /><strong>Uzupełnij profil w całości, aby odblokować wypłaty</strong></p>}
          </div>
         
        <div className="tile-wrapper">
        {showPayoutTooltip && (
            <div className="payout-tooltip mobile">
         <p className="desc">Od 03.04.2024  roku przelewy będą realizowane po uprzednim zgłoszeniu się mailowym na adres: <a className="mail" href="mailto:kontakt@batpartners.pl">kontakt@batpartners.pl</a>.</p>
      
<p className="list-desc">   W zgłoszeniu należy obowiązkowo podać następujące dane:</p>
            <ul>
              <li>Imię i nazwisko</li>
              <li>Numer BAT Partners ID</li>
              <li>Numer telefonu</li>
              <li>Numer konta bankowego</li>
            </ul>

<p className="disclaimer">Uwaga: Bez pełnych i poprawnych danych zgłoszenia nie będą rozpatrywane.</p>
          </div>
          )}
          <div className="earnings-total" style={countdownTimer?.content?.modules?.find((module: any)=> module.type === "bgColor")?.custom ? { backgroundColor: countdownTimer?.content?.modules?.find((module: any)=> module.type === "bgColor")?.custom } : {}}>
            <div className="title-wrap">
              <h3 className="earnings-title">Zarobki</h3>
              <div className="sub-text">w tym sezonie</div>
            </div>
            <div className={profit > 999 ? "earnings-total-number smaller" : "earnings-total-number" }>
              {profit}<span>zł</span>
            </div>
          </div>
          <div className="dash-countdown">
            { countdownTimer?.endDate &&
              <CountdownModule type={countdownTimer?.bonus ? "bonus": "season"} />
            }
          </div>
        </div>
        </div>

        <div className="dash-rank">
          {user?.sapIdValidate && !april  && <div className="rank-wrap-box dropshadow-box">
            <div className="top-title">
              <h2>Twoje cele w&nbsp;tym sezonie</h2>
              <ArrowButton path={"/cele"} color={"white"} />
            </div>
            {/* <DropDownTabs
              handleChangeTab={(idx) => {setDashRank(idx)}}
              activeTab={dashRank}
              tabList={[{name: "Sprzedaż"}, {name: "Polecenia"}]}
              /> */}
            <div className="counter-wrap">
              <Counter type={ dashRank === 0 ? 'sales' : 'referrals' }/>
              <div className="next-rank">
                { dashRank === 0 ? 

                  season?.nextRank?.need === 0 ?
                    <>
                      <div className="needed">
                        <span className="num">Cel osiągnięty!</span>
                        <span className="text">Sprzedawaj kolejne urządzenia dla kolejnych nagród.</span>
                      </div>
                    </> : 
                    <>
                      <div className="needed">
                        <span className="num">{`+ ${season?.nextRank?.need}`}</span>
                        <img src={x2Icon} /> lub <img src={gloIcon} />
                        <span className="text">do zdobycia bonusu</span>
                      </div>
                      <div className="next-bonus">{season?.nextRank?.nextPrize}</div>
                    </>
                :
                  referrals?.nextRank?.need === 0 ?
                  <>
                    <div className="needed">
                      <span className="num">Cel osiągnięty!</span>
                      <span className="text">Polecaj rejestrację konsumentom dla kolejnych nagród.</span>
                    </div>
                  </> : 
                  <>
                    <div className="needed">
                      <span className="num">{`+ ${referrals?.nextRank?.need}` }</span>
                      <img src={handshakeIcon} />
                      <span className="text">do zdobycia bonusu</span>
                    </div>
                    <div className="next-bonus">{referrals?.nextRank?.nextPrize}</div>
                  </> 
                  }  
              </div>
            </div>
            <div className="bottom-title">
              <h2>{dashRank === 0 ? 'Twoja pozycja w Rankignu Sprzedaży' : 'Twoja pozycja w Rankignu Poleceń'}</h2>
              <Link to="/rankingi" onClick={ ()=>{gaEventTracker('click', "Rangi")} }>Zobacz pełny ranking</Link>
            </div>
            <div className="rank rank-head">
              <div className="position">Pozycja</div>
              <div className="name">BAT Partner</div>
              <div className="score">Wynik</div>
            </div>
            { /*userRank?.position != 1 &&
              <div className="rank rank-before">
                <div className="position">{ userRank?.position - 1 }</div>
                <div className="name">.....</div>
                <div className="score">.....</div>
              </div>
            */}
            <div className="rank">
              <div className="position">{ userRank?.position }</div>
              <div className="name">{ userRank?.firstName }</div>
              <div className="score">{ userRank?.counter }</div>
            </div>
            {/*<div className="rank rank-after">
              <div className="position">{ userRank?.position + 1 }</div>
              <div className="name">.....</div>
              <div className="score">.....</div>
            </div>*/}
          </div>}

        </div>

        {user?.sapIdValidate && !april  && <div className="dash-news">
          <h2>Co nowego?</h2>
          <DashboardSlider />
        </div>}
        {device.isMobile && (device.installable || device.isIos) && !device.isPWA &&
          <div className="dash-shortcut dropshadow-box">
            <div className="dash-shortcut-content">
              <h2>Korzystaj z BAT Partners <br/>w formie aplikacji.</h2>
              <ul>
                <li><Hand /><span>Łatwy dostęp</span></li>
                <li><Clock /><span>Sprawne korzystanie</span></li>
              </ul>
            </div>
            <div className="shortcut-box">
              <p>Zamień stronę internetową <br/> na aplikację webową!</p>
              <Button onClick={() => {
                if (device.isIos) {
                  showPrompt()
                } else if (device.isAndroid) {
                  installPwa()
                }
              }}>Dodaj skrót</Button>
            </div>
          </div>
        }
      </div>
      { iOSPrompt && <PWAPrompt 
          promptOnVisit={0} 
          timesToShow={999} 
          copyClosePrompt="Zamknij" 
          permanentlyHideOnDismiss={false} 
          onClose={ () => { setTimeout(() => { setiOSPrompt(false) }, 500) }}
          copyTitle="Dodaj do ekranu początkowego" 
          copyBody="Wrzuć ikonę na pulpit i odpalaj BAT Partners jednym ruchem - szybciej niż kiedykolwiek." 
          copyShareButtonLabel="1) Naciśnij przycisk udostępniania widoczny na środku dolnego paska nawigacyjnego." 
          copyAddHomeButtonLabel="2) Naciśnij „Do ekranu początkowego”"
        /> }
    </PageLayout>
  );
}

export default Dashboard;
