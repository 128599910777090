import {
  useForm,
  SubmitHandler,
  FieldValues,
  FieldErrors,
} from "react-hook-form"
import moment from "moment"
import Button from "../../../components/Button/Button"
import { getUser } from "../../../store/reducers/user"
import { useSelector } from "react-redux"

function TaxReportStep2({ handleNextStep,handlePrevStep,handleUpdateFormData,formDataValue, dob, businessCode }: any) {
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm()
  const formValues = getValues()
  const onSubmit = (data: FieldValues, event: any) => {
    event.preventDefault()
    console.log(data)
    handleUpdateFormData(data);
    handleNextStep();
  }
  const user = useSelector(getUser);

  const isValidPesel = (pesel: string) => {
    if (typeof pesel !== "string") {
      return false
    }

    let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3]
    let sum = 0
    let controlNumber = parseInt(pesel.substring(10, 11))

    for (let i = 0; i < weight.length; i++) {
      sum += parseInt(pesel.substring(i, i + 1)) * weight[i]
    }
    sum = sum % 10
    console.log((10 - sum) % 10 === controlNumber)
    return (10 - sum) % 10 === controlNumber
  }
  function isValidNip(nip: string) {
    if (typeof nip !== "string") return false

    nip = nip.replace(/[\ \-]/gi, "")

    let weight = [6, 5, 7, 2, 3, 4, 5, 6, 7]
    let sum = 0
    let controlNumber = parseInt(nip.substring(9, 10))
    let weightCount = weight.length
    for (let i = 0; i < weightCount; i++) {
      sum += parseInt(nip.substr(i, 1)) * weight[i]
    }

    return sum % 11 === controlNumber
  }
  return (
    <>
    { !user.dob ? 
    <>
      <div className="input-item">
        <label htmlFor="dob">Data urodzenia</label>
        <input
          className={errors.dob && "error-input"}
          type="date"
          id="dob"
          {...register("dob", {
            required: true,
            validate: {
              checkAge: () =>
                moment(getValues("dob")).isSameOrBefore(
                  moment().subtract(18, "y")
                ),
            },
          })}
          defaultValue={formDataValue.dob}
        />
        {errors.dob && errors.dob.type === "required" && (
          <p className="error">Uzupełnij pole</p>
        )}
        {errors.dob && errors.dob.type === "checkAge" && (
          <p className="error">Musisz mieć ukończone 18 lat!</p>
        )}
      </div>
      {!formValues.foreignTaxResidence ? (
        <>
          <div className="checkbox-item" onClick={() => {}}>
            <div className="label-text">
              Prowadzę jednoosobową działalność gospodarczą
            </div>
            <input
              type="checkbox"
              id="businessOwnership"
              {...register("businessOwnership")}
            />
            <div
              onClick={() => {
                  setValue("businessOwnership", !formValues.businessOwnership, {
                    shouldValidate: true,
                    shouldDirty: true,
                  })
                  handleUpdateFormData({businessOwnership: !formValues.businessOwnership});
                }
              }
              className={
                formDataValue.businessOwnership ? "check active" : "check"
              }
            >
              {formDataValue.businessOwnership ? "Tak" : "Nie"}
            </div>
          </div>
          {formDataValue.businessOwnership ? (
            <>
              <div className="input-item edit-icon">
                <label htmlFor="nip">NIP</label>
                <input
                  className={errors.nip && "error-input"}
                  type="text"
                  id="nip"
                  defaultValue={formDataValue.nip}
                  {...register("nip", {
                    required: true,
                    validate: {
                      validNip: () => isValidNip(getValues("nip")),
                    },
                  })}
                />
                {errors.nip && errors.nip.type === "required" && (
                  <p className="error">Uzupełnij pole</p>
                )}
                {errors.nip && errors.nip.type === "validNip" && (
                  <p className="error">Nieprawidłowa wartość</p>
                )}
              </div>
              <div className="input-item">
                <label htmlFor="businessCode">
                  Numer PKD prowadzonej działalności
                </label>
                <div className="select-item">
              
                  <select
                    id="businessCode"
                    {...register("businessCode", { required: true })}
                    defaultValue={formDataValue.businessCode}
                  >
                    <option value="">-- Wybierz z listy ---</option>
                    <option
                      value={
                        "4635Z 46.35.Z  - Sprzedaż hurtowa wyrobów tytoniowych"
                      }
                    >
                      4635Z 46.35.Z - Sprzedaż hurtowa wyrobów tytoniowych
                    </option>
                    <option value={"Inny"}>Inny</option>
                  </select>
                </div>
                {formValues.businessCode ===
                "4635Z 46.35.Z  - Sprzedaż hurtowa wyrobów tytoniowych" ? (
                  <p>
                    Drogi BAT Partnerze pamiętaj, aby rozliczyć PIT
                    samodzielnie!
                  </p>
                ) : (
                  ""
                )}
              </div>
            </>
          ) : (
            <div className="input-item edit-icon">
              <label htmlFor="pesel">PESEL</label>
              <input
                className={errors.pesel && "error-input"}
                type="text"
                defaultValue={formDataValue.pesel}
                id="pesel"
                {...register("pesel", {
                  required: true,
                  validate: {
                    validPesel: () => isValidPesel(getValues("pesel")),
                  },
                })}
              />
              {errors.pesel && errors.pesel.type === "required" && (
                <p className="error">Uzupełnij pole</p>
              )}
              {errors.pesel && errors.pesel.type === "validPesel" && (
                <p className="error">Nieprawidłowa wartość</p>
              )}
            </div>
          )}
          <div className="btn-group">
            <Button className="back" onClick={() => handlePrevStep()}>Cofnij</Button>
            <Button className="next" onClick={handleSubmit(onSubmit)}>Dalej</Button>
          </div>
        </>
      ) : null}
    </> :
    <>
      <div className="input-item">
        <label htmlFor="dob">Data urodzenia</label>
        <input
          type="date"
          id="dob"
          defaultValue={formDataValue.dob}
          disabled
        />
      </div>

      {formDataValue.businessOwnership ? (
      <>
        <div className="input-item">
          <label htmlFor="nip">NIP</label>
          <input
            className={errors.nip && "error-input"}
            type="text"
            id="nip"
            defaultValue={formDataValue.nip}
            disabled
          />
        </div>
        <div className="input-item">
          <label htmlFor="businessCode">
            Numer PKD prowadzonej działalności
          </label>
          <div className="select-item">
            <select
              id="businessCode"
              defaultValue={formDataValue.businessCode}
              disabled
            >
              <option value="">-- Wybierz z listy ---</option>
              <option
                value={
                  "4635Z 46.35.Z  - Sprzedaż hurtowa wyrobów tytoniowych"
                }
              >
                4635Z 46.35.Z - Sprzedaż hurtowa wyrobów tytoniowych
              </option>
              <option value={"Inny"}>Inny</option>
            </select>
          </div>
          {formValues.businessCode ===
          "4635Z 46.35.Z  - Sprzedaż hurtowa wyrobów tytoniowych" ? (
            <p>
              Drogi BAT Partnerze pamiętaj, aby rozliczyć PIT
              samodzielnie!
            </p>
          ) : (
            ""
          )}
        </div>
      </>
    ) : (
      <div className="input-item">
        <label htmlFor="pesel">PESEL</label>
        <input
          className={errors.pesel && "error-input"}
          type="text"
          defaultValue={formDataValue.pesel}
          id="pesel"
          disabled
        />
      </div>
    )}
    <div className="btn-group">
            <Button className="back" onClick={() => handlePrevStep()}>Cofnij</Button>
            <Button className="next" onClick={handleSubmit(onSubmit)}>Dalej</Button>
          </div>
    </>
    }
    </>
  )
}

export default TaxReportStep2
