import React, { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/reducers/user";
import "./Goals.scss";
import Title from "../../components/Title/Title";
import { getPrizeList, getReferrals, getSeasons, getSeasonsInfo } from "../../store/reducers/account";
import { listPrizes } from "../../store/actions/rankings";
import { accountSeasons, balanceSummary } from "../../store/actions/account";
import phoneIcon from './img/x2-white.svg';
import handshakeIcon from './img/handshake-white.svg';
import Counter from "../../components/Counter/Counter";
import Button from "../../components/Button/Button";
import ButtonCopyToClipboard from "../../components/ButtonCopyToClipboard/ButtonCopyToClipboard";
import { useAppDispatch } from "../../hooks";


function Goals() {
  const dispatch = useAppDispatch();
  const user = useSelector(getUser);
  const seasons = useSelector(getSeasons);
  const seasonsInfo = useSelector(getSeasonsInfo)
  const season = seasons.filter((s: any) => s.name === seasonsInfo.current)[0]
  const referrals = useSelector(getReferrals);
  const prizeList = useSelector(getPrizeList);
  // console.log(season)

  useEffect(()=>{
    dispatch(listPrizes())
    if (seasonsInfo.current === '') {
      dispatch(balanceSummary())
      dispatch(accountSeasons())
    }
  }, [])

  const getVoucherID = (name:string) => {
    if (prizeList.length > 0) {
      const prize = prizeList.filter((p: any) => p.purpose === name+'_'+season?.name)[0]
      return prize?.code ? prize?.code : '...';
    }
    return '...'
  }

  return (
    <PageLayout>
      <div className="page goals">
         <Title>Twoje cele</Title>
        <div className="goals-inner">
          <div className="sales-column">
            <h2>Sprzedaż</h2>
            <Counter />
            <div className="levels-head">
              <span>Sprzedane urządzenia</span>
              <span>Bonus</span>
            </div>
            <div className={season?.rankId >= 1 ? "level pink active" : "level pink"}>
              <div className="number">
                <span>3</span>
                <img src={ phoneIcon } />
              </div>
              <div className="bonus">15 zł</div>
            </div>
            <div className={season?.rankId >= 2 ? "level orange active" : "level orange"}>
              <div className="number">
                <span>6</span>
                <img src={ phoneIcon } />
              </div>
              <div className="bonus">40 zł</div>
            </div>
            <div className={season?.rankId >= 3 ? "level yellow active" : "level yellow"}>
              <div className="number">
                <span>10</span>
                <img src={ phoneIcon } />
              </div>
              <div className="bonus">50 zł</div>
            </div>
            <div className={season?.rankId >= 4 ? "level lightgreen active" : "level lightgreen"}>
              <div className="number">
                <span>15</span>
                <img src={ phoneIcon } />
              </div>
              <div className="bonus">60 zł</div>
            </div>
            <div className={season?.rankId >= 5 ? "level green active" : "level green"}>
              <div className="number">
                <span>20</span>
                <img src={ phoneIcon } />
              </div>
              <div className="bonus">100 zł</div>
            </div>
          </div>
          {/* <div className="sales-column">
            <h2>Polecenia</h2>
            <Counter type="referrals" />
            <div className="levels-head">
              <span>Zarejestrowane urządzenia</span>
              <span>Bonus</span>
            </div>

            <div className={referrals?.rank >= 1 ? "level active" : "level"}>
              <div className="number">
                <span>2</span>
                <img src={ handshakeIcon } />
              </div>
              <div className="bonus"><span>E-voucher Allegro</span> 20 zł</div>
            </div>
            { referrals?.rank >= 1 && <ButtonCopyToClipboard copyText={getVoucherID('referral_lvl1')} /> }

            <div className={referrals?.rank >= 2 ? "level orange active" : "level orange"}>
              <div className="number">
                <span>4</span>
                <img src={ handshakeIcon } />
              </div>
              <div className="bonus">30 zł</div>
            </div>
            <div className={referrals?.rank >= 3 ? "level yellow active" : "level yellow"}>
              <div className="number">
                <span>8</span>
                <img src={ handshakeIcon } />
              </div>
              <div className="bonus"><span>E-voucher Allegro</span> 50 zł</div>
            </div>
            { referrals?.rank >= 3 && <ButtonCopyToClipboard copyText={getVoucherID('referral_lvl3')} /> }

            <div className={referrals?.rank >= 4 ? "level active" : "level"}>
              <div className="number">
                <span>15</span>
                <img src={ handshakeIcon } />
              </div>
              <div className="bonus">80 zł</div>
            </div>

            <div className={referrals?.rank >= 5 ? "level active" : "level"}>
              <div className="number">
                <span>20</span>
                <img src={ handshakeIcon } />
              </div>
              <div className="bonus"><span>E-voucher Allegro</span> 100 zł</div>
            </div>
            { referrals?.rank >= 5 && <ButtonCopyToClipboard copyText={getVoucherID('referral_lvl5')} /> }

          </div> */}
        </div>
      </div>
    </PageLayout>
  );
}

export default Goals;