import React from "react";
import { ReactComponent as Calendar } from "./img/calendar.svg";
import "./NewsListItem.scss";
import ArrowButton from "../../components/ArrowButton/ArrowButton";
import { useNavigate } from "react-router-dom";
import { useColorPicker } from "../../hooks";
import moment from "moment";

const NewsListItem = ({ news }: any) => {
  const navigate = useNavigate();

  return (
    <li
      onClick={() => navigate("/news/"+news?.slug)}
      role="link"
      className="news-list-item"
    >
      <div className="news-list-image">
        <img src={news?.hero_image?.file?.url} alt="" />
      </div>
      <div className="news-list-body dropshadow-box">
        <span
          style={{ backgroundColor: `${useColorPicker(news?.category)}` }}
          className={"news-list-item-category"}
        >
          {news?.categoryName}
        </span>

        <h2
          className="news-list-title"
          dangerouslySetInnerHTML={{ __html: news?.content?.title }}
        ></h2>
        <div className="news-item-footer">
          <span className="news-item-date">
            <Calendar /> <span>{moment(news?.created?.date).format('DD-MM-YYYY')}</span>
          </span>
          <span className="news-item-line"></span>

          <span className="news-item-arrow">
            <ArrowButton path={"/news/"+news?.slug} color={"white"} />
          </span>
        </div>
      </div>
    </li>
  );
};

export default NewsListItem;
