import React, { useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import { Navigation } from "swiper";
import "./PallMall.scss";
import Title from "../../components/Title/Title";
import { Swiper, SwiperSlide } from "swiper/react";
import icon1 from "./img/01.jpg";
import icon2 from "./img/02.jpg";
import icon3 from "./img/03.jpg";
import RED from "./img/red.jpg";
import BLUE from "./img/blue.jpg";
import SILVER from "./img/silver.jpg";
import MARINE from "./img/marine.jpg";
import LOGO from "./img/logo.png";
import FC90 from "./img/fc90.jpg";
import { useWindowDimensions } from "../../hooks";
import Badge from "../../components/Badge/Badge";

function PallMall() {
  const { height, width } = useWindowDimensions();
  const [packageIndex, setPackageIndex] = useState(0);
  const [cardIndex, setCardIndex] = useState(0);

  const phases = [
    {
      icon: icon1,
      name: 'I FAZA',
      date: 'czerwiec - lipiec 2023',
      text: <p>Do papierosów w formacie KS <strong>dodajemy inserty oraz owijkę foliową, które będą stanowić zapowiedź przyszłego formatu FC90</strong>. Te elementy wzbudzą ciekawość i wyczekiwanie, dając konsumentom przedsmak tego, co się szykuje.</p>
    },
    {
      icon: icon2,
      name: 'II FAZA',
      date: 'sierpień 2023',
      text: <p><strong>Nadszedł czas na oficjalne wprowadzenie nowego formatu FC90.</strong> W tym czasie do paczek będą dodawane również specjalne inserty, które pomogą konsumentom poznać najnowsze papierosy.</p>
    },
    {
      icon: icon3,
      name: 'III FAZA',
      date: 'wrzesień 2023',
      text: <p>To moment, od kiedy <strong>Pall Mall FC90 będzie dostępny w nowym opakowaniu i formacie, bez dodatkowych insertów.</strong></p>
    }
  ];

  return (
    <PageLayout>
      <section className="PallMall">
        <div className="hero-section">
          <Badge category="Papierosy tradycyjne"/>
          <div className="glo-wrapper">
            <div className="glo-hero-content">
              <Title></Title>
              <div className="pallmall-logo"><img src={LOGO} /></div>
              <h1 className="Title">Pall Mall</h1>
              <p><strong>Wkrótce</strong> w odświeżonej innowacyjnej odsłonie</p>
            </div>
          </div>
        </div>
        <div className="main-section">
          <div className="content-box">
            <h2 className="pallmall-title">Nowy format <span> FC90</span></h2>
            <p className="pallmall-subtext">Odpowiadając na potrzeby konsumentów papierosy PALL MALL z formatu KS, przejdą na nowoczesny i innowacyjny format FC90.</p>
            <div className="fc90">
              <img src={FC90} />
              <ul>
                <li><strong>Klasyczna jakość,</strong> w nowoczesnym wydaniu.</li>
                <li><strong>Delikatnie dłuższy i cieńszy</strong> od papierosów KS.</li>
                <li>Odświeżony, <strong>nowoczesny i stylowy design.</strong></li>
              </ul>
            </div>
          </div>
          <h2 className="pallmall-title"><span>Nowe opakowanie,</span> nowoczesny format, ta&nbsp;sama&nbsp;jakość</h2>
          <p className="pallmall-subtext">PALL MALL FC90 dostępny jest w czterech wariantach:</p>
          <Swiper
            pagination={{
              clickable: true,
            }}
            slidesPerView={1.2}
            spaceBetween={16}
            centeredSlides={true}
            className="glo-swiper"
            breakpoints={{
              1020: {
                slidesPerView: 4,
                spaceBetween: 30,
                centeredSlides: false,
              },
            }}
          >
            <SwiperSlide>
              <div className="glo-swiper-slide">
                <div className="top">
                  <img className="icon" src={RED} alt="" />
                </div>
                <div className="body">RED</div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="glo-swiper-slide">
                <div className="top">
                  <img className="icon" src={BLUE} alt="" />
                </div>
                <div className="body">BLUE</div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="glo-swiper-slide">
                <div className="top">
                  <img className="icon" src={SILVER} alt="" />
                </div>
                <div className="body">SILVER</div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="glo-swiper-slide">
                <div className="top">
                  <img className="icon" src={MARINE} alt="" />
                </div>
                <div className="body">BLUE MARINE</div>
              </div>
            </SwiperSlide>
          </Swiper>
          <h2 className="pallmall-title">TRZY KROKI WPROWADZENIA FC90</h2>
          { width < 1020 &&
          <Swiper
            pagination={{
              clickable: true,
            }}
            slidesPerView={1.3}
            spaceBetween={30}
            centeredSlides={true}
            className="pallmall-swiper"
            breakpoints={{
              1020: {
                slidesPerView: 2,
                spaceBetween: 30,
                centeredSlides: false,
              },
            }}
          >
            { phases.map((item, key)=> {
              return (
                <SwiperSlide>
                  <div className="pallmall-swiper-slide">
                    <div className="top">
                      <h3>{ item.name }</h3>
                      <p className="date">{ item.date }</p>
                    </div>
                    <div className="body">
                      { item.text }
                      <img className="icon" src={item.icon} alt="" />
                    </div>
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
          }
          { width >= 1020 &&
            <div className="pallmall-slides">
              { phases.map((item, key)=> {
              return (
                  <div className="pallmall-slide">
                    <div className="top">
                      <img className="icon" src={item.icon} alt="" />
                    </div>
                    <div className="body">
                      <h3>{ item.name }</h3>
                      <p className="date">{ item.date }</p>
                      { item.text }
                    </div>
                  </div>
                )
              })}
            </div>
          }
        </div>
      </section>
    </PageLayout>
  );
}

export default PallMall;
