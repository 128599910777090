import "./Cookies.scss";
import { useState, useEffect } from "react";
import { ReactComponent as CookieIcon } from "./img/icon-cookie.svg";
import Button from "../Button/Button";
import ButtonTab from "../ButtonTab/ButtonTab";
import Modal from "react-modal";
import { useLocalStorage } from "../../hooks";
Modal.setAppElement("#root");
// Hook
interface Window {
  zgoda?: (f: boolean | undefined) => string;
}

declare global {
  interface Window {
    zgoda?: (f: boolean | undefined) => string;
  }
}

function consentGrantedUpdate(consent: boolean | undefined) {
  window.zgoda = function zgoda(f: boolean | undefined) {
    switch (f) {
      case true:
        return "granted";
      case false:
        return "denied";
      default:
        return "denied";
    }
  };
  // @ts-expect-error need fix
  window.gtag("consent", "update", {
    // @ts-expect-error need fix
    ad_storage: window.zgoda(consent.communication),
    // @ts-expect-error need fix
    analytics_storage: window.zgoda(consent.performance),
    // @ts-expect-error need fix
    functionality_storage: window.zgoda(consent.technical),
    // @ts-expect-error need fix
    ad_user_data: window.zgoda(consent.communication),
    // @ts-expect-error need fix
    ad_personalization: window.zgoda(consent.communication),
  });
  // @ts-expect-error need fix
  window.dataLayer.push({ event: "consentUpdate" });
}
// @ts-expect-error need fix
function consentGranted(callback) {
  var consentTimer = setInterval(function () {
    // @ts-expect-error need fix
    var consent = JSON.parse(window.localStorage.getItem("cookieOptions"));
    if (consent) {
      clearInterval(consentTimer);
      callback(consent);
    }
  }, 100);
}

const initialCookieState = {
  technical: true,
  performance: false,
  communication: false,
  ad_user_data: false,
  ad_personalization: false,
};

type CookiesType = "technical" | "performance" | "communication";

type CookiesTypeState = {
  [key in CookiesType]: boolean;
};
interface CookiesProps {
  handleCloseCookies: () => void;
}

function Cookies({ handleCloseCookies }: CookiesProps) {
  const tabs = ["technical", "performance", "communication"];

  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [expandOptions, setExpandOptions] = useState(false);
  const [value, setValue] = useLocalStorage(
    "cookieOptions",
    initialCookieState
  );
  const currentTabName = tabs[currentTabIndex];
  const [cookieOptions, setCookieOptions] = useState(
    value ?? initialCookieState
  );

  const toggleCookie = (name: string) => {
    const tabName = tabs[currentTabIndex];
    if (name !== "technical") {
      setCookieOptions((opts: CookiesTypeState) => ({
        ...opts,
        // @ts-expect-error need fix
        [tabName]: !opts[tabName as keyof typeof initialCookieState],
      }));
    }
  };

  const closeModalAndSaveCookies = () => {
    consentGranted(consentGrantedUpdate);
    handleCloseCookies();
  };

  // useEffect(() => {
  //   setValue(cookieOptions);
  //   if (cookieOptions.communication) {
  //     // @ts-expect-error need fix
  //     window.gtag("consent", "update", {
  //       ad_storage: "granted",
  //     });
  //   }
  //   if (cookieOptions.performance) {
  //     // @ts-expect-error need fix
  //     window.gtag("consent", "update", {
  //       analytics_storage: "granted",
  //     });
  //   }
  // }, [cookieOptions, setValue]);
  useEffect(() => {
    setValue(cookieOptions);
    // @ts-expect-error need fix
    if (cookieOptions.communication && window.dataLayer) {
      // @ts-expect-error need fix
      window.dataLayer.push({
        event: "GTMcookieOK",
      });
    }
  }, [cookieOptions, setValue]);

  const handleAcceptAllCookies = () => {
    setValue({
      technical: true,
      performance: true,
      communication: true,
      ad_user_data: true,
      ad_personalization: true,
    });

    consentGranted(consentGrantedUpdate);

    handleCloseCookies();
  };

  const handleChangeTabIndex = (index: number) => {
    setCurrentTabIndex(index);
  };

  return (
    <Modal
      isOpen={true}
      contentLabel="Modal"
      className={{
        base: "cookies",
        afterOpen: "cookies_after-open",
        beforeClose: "cookies_before-close",
      }}
      overlayClassName={{
        base: "overlay",
        afterOpen: "overlay_after-open",
        beforeClose: "overlay_before-close",
      }}
      onRequestClose={() => {
        handleCloseCookies();
      }}
      closeTimeoutMS={500}
    >
      <div className="Cookies">
        <CookieIcon />
        <button
          onClick={() => closeModalAndSaveCookies()}
          className="btn-close"
        >
          <div className="icon"></div>
        </button>
        {expandOptions ? (
          <>
            <div className="steps">
              <div className="buttons-tabs">
                <ButtonTab
                  active={currentTabIndex === 0}
                  onClick={() => handleChangeTabIndex(0)}
                >
                  Techniczne
                </ButtonTab>
                <ButtonTab
                  active={currentTabIndex === 1}
                  onClick={() => handleChangeTabIndex(1)}
                >
                  Wydajnościowe
                </ButtonTab>
                <ButtonTab
                  active={currentTabIndex === 2}
                  onClick={() => handleChangeTabIndex(2)}
                >
                  Marketingowe
                </ButtonTab>
              </div>

              {currentTabIndex === 0 && (
                <div>
                  <h2 className="title">Techniczne pliki cookies</h2>
                  <p>
                    Są to pliki niezbędne do prawidłowego funkcjonowania strony.
                    Techniczne pliki cookies, pamiętają o tym kim jesteś, jakie
                    masz preferencje (język, ustawienia regionalne), dzięki
                    czemu możesz w wygodny sposób korzystać ze strony i jej
                    ustawień. Nie możesz z nich zrezygnować, ale możesz ustawić
                    w preferencjach przeglądarki odrzucanie wszystkich cookies.
                    Może to jednak spowodować, że strona nie będzie wyświetlana
                    prawidłowo albo niektóre funkcje nie będą działać.
                  </p>
                </div>
              )}
              {currentTabIndex === 1 && (
                <div>
                  <h2 className="title">Wydajnościowe pliki cookies</h2>
                  <p>
                    Używając tych cookies zbieramy wyłącznie anonimowe
                    informacje. Dzięki nim ustalamy, jak użytkownicy korzystają
                    z naszej strony   dowiadujemy się, czy nie ma w niej błędów
                    i możemy poprawić jej wydajność.
                  </p>
                </div>
              )}
              {currentTabIndex === 2 && (
                <div>
                  <h2 className="title">Marketingowe pliki cookies</h2>
                  <p>
                    Te pliki cookies (i inne technologie do nich podobne)
                    pozwalają śledzić historię Twojego przeglądania na tej
                    stronie, co pozwala nam przekazywać ukierunkowane informacje
                    do odbiorcy. Na ich podstawie możemy na przykład przestawiać
                    Ci informacje o charakterze promocyjnym.
                  </p>
                </div>
              )}
            </div>
            <div className="cta-group">
              <div
                className="switch-outer"
                onClick={() => toggleCookie(currentTabName)}
              >
                {currentTabIndex === 0 ? (
                  <p>Zawsze aktywne</p>
                ) : (
                  <p>
                    {cookieOptions[tabs[currentTabIndex]]
                      ? "Zaakceptowane"
                      : "Niezaakceptowane"}
                  </p>
                )}
                <div
                  className={
                    cookieOptions[tabs[currentTabIndex]]
                      ? "switch active"
                      : "switch"
                  }
                ></div>
              </div>
              <Button color="white" onClick={() => closeModalAndSaveCookies()}>
                Zapisz i zamknij
              </Button>
            </div>
          </>
        ) : (
          <div className="intro">
            <div className="intro">
              <h2 className="title title-cookies">
                Zanim przejdziesz do BAT Partners
              </h2>
              <p>
                Korzystamy z plików cookie (i innych podobnych technologii) w
                celu zapewnienia prawidłowego funkcjonowania oraz ulepszenia
                obsługi serwisu. Możemy również wykorzystywać pliki cookie
                w celach analitycznych i marketingowych, aby przeprowadzać
                analizę zachowań użytkowników i na tej podstawie oferować
                spersonalizowaną komunikację marketingową.
                <br />
                Klikając „Zaakceptuj”, zgadzasz się abyśmy korzystali ze
                wszystkich ww. rodzajów cookie. Aby samemu zdecydować, które
                rodzaje cookie akceptujesz, wybierz „Więcej opcji”. Możesz
                zmienić te ustawienia w dowolnym momencie, klikając w link
                widoczny na dole strony.
              </p>
              <p>
                Więcej informacji w{" "}
                <a
                  href="/pdf/BATPartners_Polityka_cookies.pdf"
                  target="_blank"
                  className="link"
                >
                  Polityce cookies
                </a>
              </p>

              <div className="cta-group">
                <Button onClick={() => handleAcceptAllCookies()} color="white">
                  Zaakceptuj
                </Button>
                <Button
                  onClick={() => setExpandOptions(true)}
                  className="transparent"
                >
                  Więcej opcji
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default Cookies;
