import React, { useEffect, useState } from "react";
import Bg from "./img/bg.png";
import { useAppSelector, useWindowDimensions } from "../../../hooks";
import { ReactComponent as Calendar } from "./img/calendar.svg";
import { ReactComponent as Play } from "./img/play.svg";
import "./ActivitiesDetails.scss";
import { useOnScreen } from "../../../hooks";
import Title from "../../../components/Title/Title";
import { API_URL, appFetch } from "../../../helpers/fetch";
import { useSelector } from "react-redux";
import { getToken } from "../../../store/reducers/user";
import { ReactComponent as OpenIcon } from "./img/open.svg";
import { ReactComponent as CloseIcon } from "./img/close.svg";
import moment from "moment";
import { ReactComponent as Gift } from "./img/gift.svg";
import { Link, useLocation, useParams } from "react-router-dom";
import Button from "../../../components/Button/Button";
import PageLayout from "../../../components/PageLayout/PageLayout";
import { getActivations } from "../../../store/reducers/activations";

const Activity = ({ completed, activity }: any) => {
  const [showToolTip, setShowToolTip] = useState(false);
  const { width } = useWindowDimensions();
  const activations = useAppSelector(getActivations);
  console.log(activations)

  const handleToggleTooltip = () => {
    setShowToolTip((prevState) => !prevState);
  };

  const termsModule = activity?.content?.modules?.find((module: any)=> module.type === "terms_pdf");

  let hyperTwoStep = true;

  if (activity?.ident === 'quiz_glo_x2_air_2') {
    const first = activations.filter((item:any)=> item.ident === 'quiz_glo_x2_air_1')[0]
    console.log(first)
    if (first && !first?.completed && !first?.expired) {
      hyperTwoStep = false;
    }
  }

  return (
    <>
      <div className="heading">
        {width <= 1020 ? <Title>{ activity?.content?.title}</Title> : <Title></Title>}
        <div className="prize">
          <div className="value">
            { activity?.payout } zł{" "}
            <span className="icon">
              <Gift />
            </span>
          </div>
        </div>
        <div className="calendar">
          <span className="date">
            <Calendar /> {moment(activity?.created?.date).format('DD-MM-YYYY')}
          </span>
          <span className="line"></span>
        </div>
      </div>
      <div></div>
      <div className={"activity-item"}>
        <div className="activity-image">
          <img src={activity?.content?.modules?.find((module: any)=> module.type === "hero_image")?.file.url} alt="" />
        </div>
        <div className="activity-body mobile">
          <div dangerouslySetInnerHTML={{__html: activity?.content?.modules?.find((module: any)=> module.type === "intro")?.custom}}></div>

          {/*<div className="activity-help">
            <h4 className="title">Chcesz skorzystać z małej pomocy?</h4>
            <div className="link">
              <Link to="/dashboard">
                Zajrzyj do zakładki edukacyjnej <Play />
              </Link>
            </div>
  </div>*/}

          <div className="tutorial">
            <div className="how-to">
              <div className={showToolTip ? "body active" : "body"} dangerouslySetInnerHTML={{__html: activity?.content?.modules?.find((module: any)=> module.type === "how_to_use_activity")?.custom}}></div>
              <div className="header">
                <h3 className="title">Jak wykonać?</h3>
                <div onClick={handleToggleTooltip} className="icon">
                  {showToolTip ? <CloseIcon /> : <OpenIcon />}
                </div>
              </div>
            </div>
          </div>
          { (!completed && hyperTwoStep) &&
            <>
              <Button className="cta" path={`/aktywnosci/${activity?.ident}/quiz`}>Rozpocznij</Button>
              {termsModule && <a className="terms-link" href={termsModule?.file?.url} target="_blank">{ termsModule?.metadata?.desc }</a>}
            </>
          }
          { !hyperTwoStep &&
            <>
              <p><strong>Najpierw ukończ pierwszy etap.</strong></p>
              <Button className="cta" path={`/aktywnosci/quiz_glo_x2_air_1`}>Przejdź do I etapu</Button>
            </>
          }
        </div>
      </div>

      <div className="desktop-activities">
      <div className="heading">
         <h2 className="title">{ activity?.content?.title}</h2>
          <div className="prize">
            <div className="value">
            { activity?.payout } zł{" "}
              <span className="icon">
                <Gift />
              </span>
            </div>
          </div>
          <div className="calendar">
            <span className="date">
              <Calendar /> {moment(activity?.created?.date).format('DD-MM-YYYY')}
            </span>
            <span className="line"></span>
          </div>
        </div>

        <div className="activity-body">
          <div dangerouslySetInnerHTML={{__html: activity?.content?.modules?.find((module: any)=> module.type === "intro")?.custom}}></div>
          {/*<div className="activity-help">
            <h4 className="title">Chcesz skorzystać z małej pomocy?</h4>
            <div className="link">
              <Link to="/dashboard">
                Zajrzyj do zakładki edukacyjnej <Play />
              </Link>
            </div>
          </div>*/}

          <div className="tutorial">
            <div className="how-to">
              <div className={showToolTip ? "body active" : "body"} dangerouslySetInnerHTML={{__html: activity?.content?.modules?.find((module: any)=> module.type === "how_to_use_activity")?.custom}}></div>
              <div className="header">
                <h3 className="title">Jak wykonać?</h3>
                <div onClick={handleToggleTooltip} className="icon">
                  {showToolTip ? <CloseIcon /> : <OpenIcon />}
                </div>
              </div>
            </div>
          </div>
          { (!completed && hyperTwoStep) &&
            <>
              <Button className="cta" path={`/aktywnosci/${activity?.ident}/quiz`}>Rozpocznij</Button>
              {termsModule && <a className="terms-link" href={termsModule?.file?.url} target="_blank">{ termsModule?.metadata?.desc }</a>}
            </>
          }
          { !hyperTwoStep &&
            <>
              <p>Najpierw ukończ pierwszy etap.</p>
              <Button className="cta" path={"/aktywnosci/quiz_glo_x2_air_1"}>Przejdź do I etapu</Button>
            </>
          }
        </div>
      </div>
    </>
  );
};

function ActivitiesDetails() {
  const [activityItem, setActivityItem] = useState({next_attempt: {date: null}, completed: null, solutions: []});
  const token = useSelector(getToken);
  const { slug } = useParams();
  const [completed, setCompleted] = useState(true)

  const checkCompleted = (item: any) => {
    if (item?.next_attempt > 0) {
      if (item?.completed || moment(item?.next_attempt?.date).isAfter(moment())) {
        return true;
      }
    }
    return false;
  }


  useEffect(() => {
    appFetch(
      "POST",
      API_URL,
      "/activation/describe",
      { activation: { ident: slug, withSolutions: true} },
      token?.uuid ? token?.uuid : false,
      (result: any) => {
        if (result.status && result.status.success) {
          setActivityItem(result.data);
          setCompleted(checkCompleted(result.data));
        } else {
        }
      },
      () => {

      }
    );
  }, [slug]);

  return (
    <PageLayout>
      <div className="page ActivitiesDetails">
        { activityItem && <Activity completed={completed} activity={activityItem} /> }
      </div>
    </PageLayout>
  );
}

export default ActivitiesDetails;
