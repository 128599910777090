import React, { useState, useEffect } from "react";
import PageLayout from "../../../../components/PageLayout/PageLayout";
import Title from "../../../../components/Title/Title";
import { useWindowDimensions } from "../../../../hooks";
import "../Quiz.scss";
import Bg01 from "./img/1.png";
import Bg02 from "./img/2.png";
import Bg03 from "./img/3.png";
import Bg04 from "./img/4.png";
import Bg05 from "./img/5.png";
import QuizQuestionCounter from "../../../../components/QuizQuestionCounter/QuizQuestionCounter";
import QuizCta from "../../../../components/QuizCta/QuizCta";
import Button from "../../../../components/Button/Button";
import QuizResultScreen from "../../../../components/QuizResultScreen/QuizResultScreen";
import { getToken } from "../../../../store/reducers/user";
import { API_URL, appFetch } from "../../../../helpers/fetch";
import { useAppSelector } from "../../../../hooks";
import { ADD_TOAST } from "../../../../store/actions/types";
import { useDispatch } from "react-redux";
import { errors } from "../../../../helpers/errors";

const questionBackgrounds = [Bg01, Bg02, Bg03, Bg04, Bg05];

interface QuizProps {
  questionNumber: number;
  indexActiveQuestion: number;
  handleNextQuestion: () => void;
  handleAnswer: (answer: any) => void;
  questionAnswers: any;
}

const Quiz: React.FC<QuizProps> = ({
  questionAnswers,
  questionNumber,
  indexActiveQuestion,
  handleNextQuestion,
  handleAnswer,
}) => {
  const itsActive = (answer: string) =>
    Boolean(questionAnswers[indexActiveQuestion]?.answer_ident === answer);

  return (
    <div className="quiz-wrapper">
      {indexActiveQuestion === 0 && (
        <div className="quiz-item">
          <h2 className="title">Fakty o Vuse ePod 2</h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />
          <div className="image">
            <img src={questionBackgrounds[indexActiveQuestion]} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">Prawda czy fałsz?</span>
              </p>
              <p className="desc small">
                Oceń prawdziwość podanego stwierdzenia.{" "}
                <span className="italic">
                  Zaznacz Prawda, jeśli zdanie jest prawdziwe albo fałsz
                </span>
                , jeśli jest fałszywe.
              </p>
              <p className="desc">
                <strong>Vuse ePod 2 jest dostępnyw 3 kolorach urządzeń (Matt Black, Silver, Rosegold).</strong>
              </p>
            </div>
            <div className="cta-group">
              <QuizCta
                active={itsActive("a")}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q0",
                    answer_ident: "a",
                  })
                }
              >
                Prawda
              </QuizCta>
              <QuizCta
                active={itsActive("b")}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q0",
                    answer_ident: "b",
                  })
                }
              >
                Fałsz
              </QuizCta>
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 1 && (
        <div className="quiz-item">
          <h2 className="title">Fakty o Vuse ePod 2</h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />
          <div className="image">
            <img src={questionBackgrounds[indexActiveQuestion]} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">Prawda czy fałsz?</span>
              </p>
              <p className="desc small">
                Oceń prawdziwość podanego stwierdzenia.{" "}
                <span className="italic">
                  Zaznacz Prawda, jeśli zdanie jest prawdziwe albo fałsz
                </span>
                , jeśli jest fałszywe.
              </p>
              <p className="desc">
                <strong>Nowa wersja Vuse ePod 2 jest odporna na zachlapania wody.</strong>
              </p>
            </div>
            <div className="cta-group">
              <QuizCta
                active={itsActive("a")}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q1",
                    answer_ident: "a",
                  })
                }
              >
                Prawda
              </QuizCta>
              <QuizCta
                active={itsActive("b")}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q1",
                    answer_ident: "b",
                  })
                }
              >
                Fałsz
              </QuizCta>
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 2 && (
        <div className="quiz-item">
          <h2 className="title">Fakty o Vuse ePod 2</h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />
          <div className="image">
            <img src={questionBackgrounds[indexActiveQuestion]} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">Prawda czy fałsz?</span>
              </p>
              <p className="desc small">
                Oceń prawdziwość podanego stwierdzenia.{" "}
                <span className="italic">
                  Zaznacz Prawda, jeśli zdanie jest prawdziwe albo fałsz
                </span>
                , jeśli jest fałszywe.
              </p>
              <p className="desc">
                <strong>Vuse ePod 2 to innowacyjne urządzenie bez przycisków, uruchamiane przez zaciąganie. Dodatkową zaletą jest możliwość korzystania w trakcie ładowania.</strong>
              </p>
            </div>
            <div className="cta-group">
              <QuizCta
                active={itsActive("a")}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q2",
                    answer_ident: "a",
                  })
                }
              >
                Prawda
              </QuizCta>
              <QuizCta
                active={itsActive("b")}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q2",
                    answer_ident: "b",
                  })
                }
              >
                Fałsz
              </QuizCta>
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 3 && (
        <div className="quiz-item">
          <h2 className="title">Fakty o Vuse ePod 2</h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />
          <div className="image">
            <img src={questionBackgrounds[indexActiveQuestion]} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">Prawda czy fałsz?</span>
              </p>
              <p className="desc small">
                Oceń prawdziwość podanego stwierdzenia.{" "}
                <span className="italic">
                  Zaznacz Prawda, jeśli zdanie jest prawdziwe albo fałsz
                </span>
                , jeśli jest fałszywe.
              </p>
              <p className="desc">
                <strong>E-papieros Vuse ePod 2 jest kompatybilny ze wszystkimi wkładami ePod oraz ePen.</strong>
              </p>
            </div>
            <div className="cta-group">
              <QuizCta
                active={itsActive("a")}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q3",
                    answer_ident: "a",
                  })
                }
              >
                Prawda
              </QuizCta>
              <QuizCta
                active={itsActive("b")}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q3",
                    answer_ident: "b",
                  })
                }
              >
                Fałsz
              </QuizCta>
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 4 && (
        <div className="quiz-item">
          <h2 className="title">Fakty o Vuse ePod 2</h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />
          <div className="image">
            <img src={questionBackgrounds[indexActiveQuestion]} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">Prawda czy fałsz?</span>
              </p>
              <p className="desc small">
                Oceń prawdziwość podanego stwierdzenia.{" "}
                <span className="italic">
                  Zaznacz Prawda, jeśli zdanie jest prawdziwe albo fałsz
                </span>
                , jeśli jest fałszywe.
              </p>
              <p className="desc">
              <strong>Bateria w Vuse ePod 2 jestw pełni naładowana w 60 minut (w porównaniu do 80 minut dla urządzenia Vuse ePod) oraz osiąga 80% poziomu naładowania już w 35 minut.</strong>
              </p>
            </div>
            <div className="cta-group">
              <QuizCta
                active={itsActive("a")}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q4",
                    answer_ident: "a",
                  })
                }
              >
                Prawda
              </QuizCta>
              <QuizCta
                active={itsActive("b")}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q4",
                    answer_ident: "b",
                  })
                }
              >
                Fałsz
              </QuizCta>
            </div>
          </div>
        </div>
      )}

      <Button
        onClick={handleNextQuestion}
        className={
          questionAnswers[indexActiveQuestion] === null
            ? "next-cta disabled"
            : "next-cta"
        }
      >
        Dalej
      </Button>
    </div>
  );
};

function VuseEpodEtap1() {
  const { width } = useWindowDimensions();
  const token = useAppSelector(getToken);
  const quizIdent = "quiz_epod2_1";
  const questionQuantity = 5;
  const [questionNumber, setQuestionNumber] = useState(questionQuantity);
  const [indexActiveQuestion, setIndexActiveQuestion] = useState(0);
  const [isFinish, setIsFinish] = useState(false);
  const [doneCorrectly, setDoneCorrectly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [questionAnswers, setQuestionAnswers] = useState(
    new Array(questionQuantity).fill(null)
  );
  const dispatch = useDispatch();

  // const quizFetch = () => {
  //   setLoading(true);
  //   const data = {
  //     activation: {
  //       ident: quizIdent,
  //       payload: questionAnswers,
  //     },
  //   };
  // };

  useEffect(() => {
    function fetchQuiz() {
      const data = {
        activation: {
          ident: quizIdent,
          payload: questionAnswers,
        },
      };

      appFetch(
        "POST",
        API_URL,
        "/activation/solution/create",
        data,
        token?.uuid ? token?.uuid : false,
        (result: any) => {
          if (result.status && result.status.success) {
            if (result.data.payout != 0) {
              setDoneCorrectly(true);
            } else {
              setDoneCorrectly(false);
            }
            setLoading(false);
            setIsFinish(true);
          }
          setLoading(false);
          setIsFinish(true);
        },
        () => {
          dispatch({
            type: ADD_TOAST,
            payload: { type: "error", message: errors.generic },
          });
        }
      );
    }

    if (questionQuantity === indexActiveQuestion) {
      fetchQuiz();
    }
  }, [indexActiveQuestion]);

  const handleAnswer = (answer: any) => {
    let arrayWithQuestion = [...questionAnswers];
    arrayWithQuestion[indexActiveQuestion] = answer;
    setQuestionAnswers(arrayWithQuestion.slice(0, questionQuantity));
  };

  const handleNextQuestion = () => {
    if (indexActiveQuestion <= questionQuantity - 1) {
      setIndexActiveQuestion((prevState) => prevState + 1);
    }
  };

  return (
    <PageLayout>
      <div className="Quiz-Wrapper">
        {isFinish ? (
          <QuizResultScreen loading={loading} doneCorrectly={doneCorrectly} />
        ) : (
          <div className="page Quiz Thr-Quiz">
            <div className="heading">
              {width <= 1020 ? <Title>Fakty o Vuse ePod 2</Title> : <Title></Title>}
              <div className="image-desktop">
                <img src={questionBackgrounds[indexActiveQuestion]} alt="" />
              </div>
            </div>
            <Quiz
              questionAnswers={questionAnswers}
              handleAnswer={handleAnswer}
              handleNextQuestion={handleNextQuestion}
              indexActiveQuestion={indexActiveQuestion}
              questionNumber={questionNumber}
            />
          </div>
        )}
      </div>
    </PageLayout>
  );
}

export default VuseEpodEtap1;
