// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useState, useEffect } from "react";
import PageLayout from "../../../../components/PageLayout/PageLayout";
import Title from "../../../../components/Title/Title";
import { useWindowDimensions } from "../../../../hooks";
import { ReactComponent as Correct } from "./img/correct.svg";
import { ReactComponent as Incorrect } from "./img/incorrect.svg";
import "../Quiz.scss";
import _ from "lodash";
import Bg01 from "./img/01.jpg";
import Bg02 from "./img/02.jpg";
import Bg03 from "./img/03.jpg";
import Bg04 from "./img/04.jpg";
import Bg05 from "./img/05.jpg";
import VUSE from "./img/product.png";
import QuizQuestionCounter from "../../../../components/QuizQuestionCounter/QuizQuestionCounter";
import QuizCta from "../../../../components/QuizCta/QuizCta";
import Button from "../../../../components/Button/Button";
import { getToken } from "../../../../store/reducers/user";
import { API_URL, appFetch } from "../../../../helpers/fetch";
import { useAppSelector } from "../../../../hooks";
import { ADD_TOAST } from "../../../../store/actions/types";
import { useDispatch } from "react-redux";
import { errors } from "../../../../helpers/errors";
import { useNavigate } from "react-router-dom";

const ResultScreen = ({ loading, doneCorrectly }) => {
  const navigate = useNavigate();
  if (loading) return null;

  return doneCorrectly ? (
    <div className="QuizResultScreen">
      <div className="heading">
        <div className="icon">{<Correct />}</div>
        <h2 className="title">Bezbłędnie!</h2>
      </div>
      <div className="body">
        <p>Jesteśmy pod wrażeniem!<br/>
Wykorzystaj swoją wiedzę podczas rozmów z klientami</p>
        <p>
          <strong>
            W nagrodę dorzucamy do Twojego konta: <br />
            5&nbsp;zł
          </strong>
        </p>
      </div>

      <button className="Button" onClick={() => navigate("/aktywnosci")}>
        Super!
      </button>
    </div>
  ) : (
    <div className="QuizResultScreen">
      <div className="heading">
        <div className="icon">{<Incorrect />}</div>
        <h2 className="title">Małe potknięcie.</h2>
      </div>
      <div className="body">
        <p>
          <strong>Zajrzyj do zakładki edukacyjnej raz jeszcze</strong> i&nbsp;spotkaj się z&nbsp;Vuse&nbsp;Go ponownie za 24h.
        </p>
      </div>

      <button className="Button" onClick={() => navigate("/produkty-bat/vusego")}>
        Dowiedz się więcej
      </button>
    </div>
  );
};

const questionBackgrounds = [Bg01, Bg02, Bg03, Bg04, Bg05];
const questionBackgroundsMobile = [
  Bg01,
  Bg02,
  Bg03,
  Bg04,
  Bg05
];

interface QuizProps {
  questionNumber: number;
  indexActiveQuestion: number;
  handleNextQuestion: () => void;
  handleAnswer: (answer: any) => void;
  questionAnswers: any;
}

const Quiz: React.FC<QuizProps> = ({
  questionAnswers,
  questionNumber,
  indexActiveQuestion,
  handleAnswer,
  handlePickBg,
  handleNextQuestion,
}) => {
  const itsActive = (answer: string, idx: any) => {
    if (typeof idx === "number") {
      return Boolean(
        questionAnswers[indexActiveQuestion]?.answer_ident[idx] === answer
      );
    } else {
      return Boolean(
        questionAnswers[indexActiveQuestion]?.answer_ident === answer
      );
    }
  };

  const [firstOption, setFirstOption] = useState('');
  const [secondOption, setSecondOption] = useState('');

  return (
    <div className="quiz-wrapper kent-quiz">
      {indexActiveQuestion === 0 && (
        <div className="quiz-item">
          <h2 className="title">
            Spotkanie z&nbsp;Vuse&nbsp;GO
          </h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />
          <div className="image">
            <img src={handlePickBg()} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">
                  Vuse GO to e-papieros:
                </span>
              </p>
            </div>
            <div className="cta-group">
              <QuizCta
                active={itsActive("a", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q0",
                    answer_ident: "a",
                  })
                }
              >
                W systemie zamkniętym
              </QuizCta>
              <QuizCta
                active={itsActive("b", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q0",
                    answer_ident: "b",
                  })
                }
              >
                W systemie otwartym
              </QuizCta>
              <QuizCta
                active={itsActive("c", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q0",
                    answer_ident: "c",
                  })
                }
              >
                Jednorazowy
              </QuizCta>
              <QuizCta
                active={itsActive("d", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q0",
                    answer_ident: "d",
                  })
                }
              >
                Żadne z powyższych
              </QuizCta>
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 1 && (
        <div className="quiz-item">
          <h2 className="title">
            Spotkanie z&nbsp;Vuse&nbsp;GO
          </h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />
          <div className="image">
            <img src={handlePickBg()} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">
                Jakie są zalety Vuse GO?
                </span>
              </p>
            </div>
            <div className="cta-group">
            <QuizCta
                active={itsActive("a", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q1",
                    answer_ident: "a",
                  })
                }
              >
                Kompaktowy rozmiar
              </QuizCta>
              <QuizCta
                active={itsActive("b", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q1",
                    answer_ident: "b",
                  })
                }
              >
                Zawsze gotowy do użycia (bez ładowania)
              </QuizCta>
              <QuizCta
                active={itsActive("c", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q1",
                    answer_ident: "c",
                  })
                }
              >
                Różnorodność aromatów
              </QuizCta>
              <QuizCta
                active={itsActive("d", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q1",
                    answer_ident: "d",
                  })
                }
              >
                Wszystkie odpowiedzi są poprawne
              </QuizCta>
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 2 && (
        <div className="quiz-item">
          <h2 className="title">
            Spotkanie z&nbsp;Vuse&nbsp;GO
          </h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />
          <div className="image">
            <img src={handlePickBg()} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">
                Na ile zaciągnięć pozwala wbudowana bateria oraz liquid (2ml)?
                </span>
              </p>
            </div>
            <div className="cta-group">
            <QuizCta
                active={itsActive("a", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q2",
                    answer_ident: "a",
                  })
                }
              >
                200
              </QuizCta>
              <QuizCta
                active={itsActive("b", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q2",
                    answer_ident: "b",
                  })
                }
              >
                500
              </QuizCta>
              <QuizCta
                active={itsActive("c", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q2",
                    answer_ident: "c",
                  })
                }
              >
                950
              </QuizCta>
              <QuizCta
                active={itsActive("d", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q2",
                    answer_ident: "d",
                  })
                }
              >
                1050
              </QuizCta>
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 3 && (
        <div className="quiz-item">
          <h2 className="title">
            Spotkanie z&nbsp;Vuse&nbsp;GO
          </h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />
          <div className="image">
            <img src={handlePickBg()} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">
                Ile aromatów Vuse GO jest dostępnych na polskim rynku?
                </span>
              </p>
            </div>
            <div className="cta-group">
            <QuizCta
                active={itsActive("a", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q3",
                    answer_ident: "a",
                  })
                }
              >
                2
              </QuizCta>
              <QuizCta
                active={itsActive("b", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q3",
                    answer_ident: "b",
                  })
                }
              >
                6
              </QuizCta>
              <QuizCta
                active={itsActive("c", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q3",
                    answer_ident: "c",
                  })
                }
              >
                13
              </QuizCta>
              <QuizCta
                active={itsActive("d", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q3",
                    answer_ident: "d",
                  })
                }
              >
                15
              </QuizCta>
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 4 && (
        <div className="quiz-item">
          <h2 className="title">
            Spotkanie z&nbsp;Vuse&nbsp;GO
          </h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />
          <div className="image">
            <img src={handlePickBg()} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">
                Uzupełnij schemat e-papierosa Vuse GO, wybierając odpowiednie elementy.
                </span>
              </p>
            </div>
            <div className="vuse-question">
              <img src={VUSE}/>
              <div className="selects">
                <div className="vuse-select first-select">
                  <select onChange={(e)=>{
                      setFirstOption(e.target.value);
                      let answer = "b"
                      if (secondOption === 'a') {
                        answer = 'e'
                      }
                      handleAnswer({
                        question_ident: "q4",
                        answer_ident: answer,
                      })
                    }}>
                    <option value="" selected disabled hidden>Wybierz z listy</option>
                    <option value="a">Urządzenie z e-liquidem i baterią</option>
                    <option value="b">Wkład Vuse ePod</option>
                    <option value="c">Ustnik</option>
                    <option value="d">Dioda LED</option>
                  </select>
                </div>
                <div className="vuse-select second-select">
                  <select onChange={(e)=>{
                      setSecondOption(e.target.value)
                      let answer = "b"
                      if (firstOption === 'c') {
                        answer = 'e'
                      }
                      handleAnswer({
                        question_ident: "q4",
                        answer_ident: answer,
                      })
                    }}>
                    <option value="" selected disabled hidden>Wybierz z listy</option>
                    <option value="a">Urządzenie z e-liquidem i baterią</option>
                    <option value="b">Wkład Vuse ePod</option>
                    <option value="c">Ustnik</option>
                    <option value="d">Dioda LED</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      
      <Button
        onClick={handleNextQuestion}
        className={
          questionAnswers[indexActiveQuestion] === null
            ? "next-cta disabled"
            : "next-cta"
        }
      >
        Dalej
      </Button>
    </div>
  );
};

function QuizVuse() {
  const { width } = useWindowDimensions();
  const token = useAppSelector(getToken);
  const quizIdent = "quiz_vusego";
  const questionQuantity = 5;
  const [questionNumber, setQuestionNumber] = useState(questionQuantity);
  const [indexActiveQuestion, setIndexActiveQuestion] = useState(0);
  const [isFinish, setIsFinish] = useState(false);
  const [doneCorrectly, setDoneCorrectly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [questionAnswers, setQuestionAnswers] = useState(
    new Array(questionQuantity).fill(null)
  );
  const dispatch = useDispatch();

  const handlePickBg = () =>
    width >= 1020
      ? questionBackgrounds[indexActiveQuestion]
      : questionBackgroundsMobile[indexActiveQuestion];

  useEffect(() => {
    function fetchQuiz() {
      const data = {
        activation: {
          ident: quizIdent,
          payload: questionAnswers,
        },
      };
      appFetch(
        "POST",
        API_URL,
        "/activation/solution/create",
        data,
        token?.uuid ? token?.uuid : false,
        (result: any) => {
          if (result.status && result.status.success) {
            if (result.data.payout != 0) {
              setDoneCorrectly(true);
            } else {
              setDoneCorrectly(false);
            }
            setLoading(false);
            setIsFinish(true);
          }
          setLoading(false);
          setIsFinish(true);
        },
        () => {
          dispatch({
            type: ADD_TOAST,
            payload: { type: "error", message: errors.generic },
          });
        }
      );
    }
    if (questionQuantity === indexActiveQuestion) {
      fetchQuiz();
    }
  }, [indexActiveQuestion]);

  const handleAnswer = (answer: any) => {
    console.log(answer);
    let arrayWithQuestion = [...questionAnswers];
    arrayWithQuestion[indexActiveQuestion] = answer;
    setQuestionAnswers(arrayWithQuestion.slice(0, questionQuantity));
  };

  const handleNextQuestion = () => {
    if (indexActiveQuestion <= questionQuantity - 1) {
      setIndexActiveQuestion((prevState) => prevState + 1);
    }
  };

  return (
    <PageLayout>
      <div className="Quiz-Wrapper">
        {isFinish ? (
          <ResultScreen loading={loading} doneCorrectly={doneCorrectly} />
        ) : (
          <div className="page Quiz VuseQuiz">
            <div className="heading">
              {width <= 1020 ? (
                <Title>
                  Spotkanie z&nbsp;Vuse&nbsp;GO
                </Title>
              ) : (
                <Title></Title>
              )}

              <div className="image-desktop">
                <img src={handlePickBg()} alt="" />
              </div>
            </div>
            <Quiz
              handlePickBg={handlePickBg}
              questionAnswers={questionAnswers}
              handleAnswer={handleAnswer}
              handleNextQuestion={handleNextQuestion}
              indexActiveQuestion={indexActiveQuestion}
              questionNumber={questionNumber}
            />
          </div>
        )}
      </div>
    </PageLayout>
  );
}

export default QuizVuse;
