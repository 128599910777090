import React from "react";

import "./QuizCta.scss";

interface QuizCtaProps {
  children: React.ReactNode;
  onClick: () => void;
  active: boolean;
}
const QuizCta: React.FC<QuizCtaProps> = ({ children, onClick, active }) => {
  return (
    <button onClick={onClick} className={active ? "QuizCta active" : "QuizCta"}>
      {children}
    </button>
  );
};

export default QuizCta;
