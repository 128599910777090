import React, { useState } from 'react';
import {
  useLocation,
  NavLink,
  Link
} from 'react-router-dom'
import { useWindowDimensions, useScrollPosition, useAppDispatch, useAppSelector} from '../../hooks';
import { ReactComponent as IconMenu } from './img/icon-menu.svg';
import { ReactComponent as IconBell } from './img/icon-bell.svg';
import { ReactComponent as Disclamer } from './img/disclamer.svg';
import { ReactComponent as Logo } from './img/logo.svg';
import ButtonBack from '../Button/ButtonBack/ButtonBack';
import './Header.scss';
import { showMenu } from '../../store/actions/ui'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { getBellNoti } from '../../store/reducers/notifications';
import { getUI } from '../../store/reducers/ui'
import { ReactComponent as ColorLine } from "../../../img/kreski.svg";
import OutageInfo from './OutageInfo';
import ContestInfo from './ContestInfo';
import { getUser } from '../../store/reducers/user';

interface Props {
  castrated?: boolean
}
const april = new Date() > new Date("2024-04-01T00:00:00.000")

function Header({castrated}: Props) {
  const dispatch = useAppDispatch()
  const location = useLocation();
  const {width, height} = useWindowDimensions();
  const navigate = useNavigate();
  const noti = useSelector(getBellNoti);
  const ui = useSelector(getUI)
  const scrollPosition = useScrollPosition();
  const changeHeaderToFixed = scrollPosition > 28 && width < 1270;
  const [outageInfoViewed, setOutageInfo] = useState(localStorage.getItem("outageInfo04052023Viewed"));
  const user = useAppSelector(getUser);


  const messageNumber = () => 0
  return (
    <>
    {/* !castrated && <div className="header-info">GLO New Heating Technology.<br/> <span>|</span> <strong>Wkłady tytoniowe NEO dedykowane do GLO New Heating Technology.</strong></div> */}
    <header className={changeHeaderToFixed ? "Header fixed" : "Header"}>
      { castrated ? 
        <>
          <ColorLine className="color-line" preserveAspectRatio="none" />
          <div className="header-inner">
            <div className="logo"><Logo/></div>
          </div>
        </>
        :
        <>
          {/* <div className="header-info">GLO New Heating Technology.<br/> <span>|</span> <strong>Wkłady tytoniowe NEO dedykowane do GLO New Heating Technology.</strong></div> */}
          <ColorLine className="color-line" preserveAspectRatio="none" />
          <div className="header-inner">
            <div className="nav-trigger" onClick={ ()=>{ dispatch(showMenu()) }}><IconMenu /></div>
            <div className={user?.sapIdValidate && !april  ? "bell-icon" : "bell-icon disabled"}>
              <NavLink to="/notyfikacje">
                <IconBell />
                { noti.length > 0 && !ui.showNoti && user?.sapIdValidate && !april  ? <div className="unseen">{ noti.length }</div> : null }
              </NavLink>
            </div>
            { width < 1270 ?
                location.pathname != '/dashboard' ? 
                <ButtonBack/> : <div className="logo"><Logo/></div>
              : <div className="logo"><Link to="/dashboard"><Logo role="link"/></Link></div>
            }
          </div>
          {/* { outageInfoViewed === 'true' ? null : <OutageInfo hide={()=>setOutageInfo('true')} /> } */}
          {/* { ui.contestInfo === 'true' || ui.contestInfo === true ? null : <ContestInfo /> } */}
        </>
      }
      {/* <div className='technical-break'> <Disclamer /> Z uwagi na planowane prace, 5 kwietnia w godz. 9:00-14:00 nastąpi przerwa w dostępie do wypłat.</div> */}
    </header>
    </>
  );
}

export default Header
