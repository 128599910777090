import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Remind from "./pages/Remind/Remind";
import NewPass from "./pages/NewPass/NewPass";
import Dashboard from "./pages/Dashboard/Dashboard";
import Rank from "./pages/Rank/Rank";
import Rankings from "./pages/Rankings/Rankings";
import Stats from "./pages/Stats/Stats";
import Page404 from "./pages/404/Page404";
import Activities from "./pages/Activities/Activities";
import ActivitiesDetails from "./pages/Activities/ActivitiesDetails/ActivitiesDetails";
import ProfileFill from "./pages/Activities/perm/ProfileFill";
import StaticActivity01 from "./pages/Activities/perm/StaticActivity01";
import StaticActivity02 from "./pages/Activities/perm/StaticActivity02";
import StaticActivity03 from "./pages/Activities/perm/StaticActivity03";
import StaticActivity04 from "./pages/Activities/perm/StaticActivity04";
import StaticActivity05 from "./pages/Activities/perm/StaticActivity05";
import ThrQuiz from "./pages/Activities/quiz/ThrQuiz/ThrQuiz";
import News from "./pages/News/News";
import NewsDetails from "./components/NewsDetails/NewsDetails";
import Payout from "./pages/Payout/Payout";
import Profile from "./pages/Profile/Profile";
import Contact from "./pages/Contact/Contact";
import About from "./pages/About/About";
import ShortcutPage from "./pages/ShortcutPage/ShortcutPage";
import Nav from "./components/Nav/Nav";
import TokenRefresh from "./components/TokenRefresh/TokenRefresh";
import GloUniq from "./pages/GloUniq/GloUniq";
import GloHyper from "./pages/GloHyper/GloHyper";
import HyperX2 from "./pages/HyperX2/HyperX2";
import HyperX2Air from "./pages/HyperX2Air/HyperX2Air";
import HowToRecommend from "./pages/HowToRecommend/HowToRecommend";
import HowToRecommendAlt from "./pages/HowToRecommendAlt/HowToRecommend";
import Velo from "./pages/Velo/Velo";
import VuseEpod from "./pages/VuseEpod/VuseEpod";
import VuseEpen from "./pages/VuseEpen/VuseEpen";
import Faq from "./pages/Faq/Faq";
import Regulations from "./pages/Regulations/Regulations";
import NewSale from "./pages/NewSale/NewSale";
import NewSaleDetails from "./pages/NewSale/NewSaleDetails/NewSaleDetails";
import NewSaleSerialNumber from "./pages/NewSale/NewSaleSerialNumber/NewSaleSerialNumber";
import Notifications from "./pages/Notifications/Notifications";
import Drawer from "./components/Drawer/Drawer";
import NotificationToasts from "./components/NotificationToasts/NotificationToasts";
import VuseEpodEtap1 from "./pages/Activities/quiz/VuseEpodEtap1/VuseEpodEtap1";
import VuseEpodEtap2 from "./pages/Activities/quiz/VuseEpodEtap2/VuseEpodEtap2";
import VeloFreeze from "./pages/Activities/quiz/VeloFreezeQuiz/VeloFreeze";
import QuizKent from "./pages/Activities/quiz/KentQuiz/KentQuiz";
import QuizGlo from "./pages/Activities/quiz/QuizGlo/QuizGlo";
import QuizVuseGo from "./pages/Activities/quiz/QuizVuseGo/QuizVuseGo";
import QuizX2AirOne from "./pages/Activities/quiz/QuizX2AirOne/QuizX2AirOne";
import QuizX2AirTwo from "./pages/Activities/quiz/QuizX2AirTwo/QuizX2AirTwo";
import QuizPallMall from "./pages/Activities/quiz/QuizPallMall/QuizPallMall";
import QuizVeo from "./pages/Activities/quiz/QuizVeo/QuizVeo";
import SaleRules from "./pages/SaleRules/SaleRules";
import Logout from "./pages/Logout/Logout";
import Goals from "./pages/Goals/Goals";
import Onboarding from "./components/Onboarding/Onboarding";
import Kent from "./pages/Kent/Kent";
import VuseGo from "./pages/VuseGo/VuseGo";
import PallMall from "./pages/PallMall/PallMall";
import Veo from "./pages/Veo/Veo";
import RegisterGlo from "./pages/RegisterGlo/RegisterGlo";
import DetalistInfo from "./pages/DetalistInfo/DetalistInfo";
import Products from "./pages/Products/Products";
import Complaint from "./pages/Complaint/Complaint";
import TechBreak from "./pages/TechBreak/TechBreak";
import { getUser } from './store/reducers/user';
import { useAppSelector } from './hooks';

const april = new Date() > new Date("2024-04-01T00:00:00.000")

function AppRoutes() {
  const user = useAppSelector(getUser);
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/wylogowanie" element={<Logout />} />
        <Route path="/rejestracja" element={<Register />} />
        <Route path="/przypomnienie-hasla" element={<Remind />} />
        <Route path="/nowe-haslo" element={<NewPass />} />
        <Route path="/przerwa-techniczna" element={<TechBreak />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/wyplata"
          element={
            <ProtectedRoute>
              <Payout />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profil"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rangi"
          element={
            <ProtectedRoute>
              <Rank />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rankingi"
          element={<Navigate to="/rankingi/sprzedaz" />}
        />
        <Route
          path="/rankingi/sprzedaz"
          element={
            <ProtectedRoute>
              <Rankings rankingType="SELL" />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/rankingi/polecenia"
          element={
            <ProtectedRoute>
              <Rankings rankingType="REF" />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/rankingi/konkurs"
          element={
            <ProtectedRoute>
              <Rankings rankingType="KONKURS" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rankingi/salespoint"
          element={
            <ProtectedRoute>
              <Rankings rankingType="SALESPOINT" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rankingi/wehikul-czasu"
          element={
            <ProtectedRoute>
              <Rankings rankingType="TIMETRAVEL" />
            </ProtectedRoute>
          }
        />
        {user?.sapIdValidate && !april  && <Route
          path="/statystyki"
          element={<Navigate to="/statystyki/sprzedaz" />}
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/statystyki/sprzedaz"
          element={
            <ProtectedRoute>
              <Stats statsType="SALES" />
            </ProtectedRoute>
          }
        />}
        <Route
          path="/statystyki/polecenia"
          element={
            <ProtectedRoute>
              <Stats statsType="REF" />
            </ProtectedRoute>
          }
        />
        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci"
          element={
            <ProtectedRoute>
              <Activities />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci/quiz_vuse_thr/quiz"
          element={
            <ProtectedRoute>
              <ThrQuiz />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci/quiz_epod2_1/quiz"
          element={
            <ProtectedRoute>
              <VuseEpodEtap1 />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci/quiz_epod2_2/quiz"
          element={
            <ProtectedRoute>
              <VuseEpodEtap2 />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci/quiz_velo2/quiz"
          element={
            <ProtectedRoute>
              <VeloFreeze />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci/quiz_kent/quiz"
          element={
            <ProtectedRoute>
              <QuizKent />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci/quiz_glo/quiz"
          element={
            <ProtectedRoute>
              <QuizGlo />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci/quiz_vusego/quiz"
          element={
            <ProtectedRoute>
              <QuizVuseGo />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci/quiz_glo_x2_air_1/quiz"
          element={
            <ProtectedRoute>
              <QuizX2AirOne />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci/quiz_glo_x2_air_2/quiz"
          element={
            <ProtectedRoute>
              <QuizX2AirTwo />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci/quiz_pallmall/quiz"
          element={
            <ProtectedRoute>
              <QuizPallMall />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci/quiz_veo/quiz"
          element={
            <ProtectedRoute>
              <QuizVeo />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci/:slug"
          element={
            <ProtectedRoute>
              <ActivitiesDetails />
            </ProtectedRoute>
          }
        />}

        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci/uzupelnij_profil"
          element={
            <ProtectedRoute>
              <ProfileFill />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci/happy_days_sierpien_2022"
          element={
            <ProtectedRoute>
              <StaticActivity01 />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci/happy_days_wrzesien_2022"
          element={
            <ProtectedRoute>
              <StaticActivity02 />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci/happy_days_pazdziernik_2022"
          element={
            <ProtectedRoute>
              <StaticActivity03 />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci/black_week_2022"
          element={
            <ProtectedRoute>
              <StaticActivity04 />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/aktywnosci/mikolajki_2022"
          element={
            <ProtectedRoute>
              <StaticActivity05 />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/news"
          element={
            <ProtectedRoute>
              <News />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/news/:slug"
          element={
            <ProtectedRoute>
              <NewsDetails />
            </ProtectedRoute>
          }
        />}
        <Route
          path="/kontakt"
          element={
            <ProtectedRoute>
              <Contact />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/poznaj-bat-partners"
          element={
            <ProtectedRoute>
              <About />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/utworz-skrot"
          element={
            <ProtectedRoute>
              <ShortcutPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produkty-bat/velo"
          element={
            <ProtectedRoute>
              <Velo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produkty-bat/glo-hyper-uniq"
          element={
            <ProtectedRoute>
              <GloUniq />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produkty-bat/glo-hyper"
          element={
            <ProtectedRoute>
              <GloHyper />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produkty-bat/glo-hyper-x2"
          element={
            <ProtectedRoute>
              <HyperX2 />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produkty-bat/glo-hyper-x2-air"
          element={
            <ProtectedRoute>
              <HyperX2Air />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path="/produkty-bat/vuse-epod-2"
          element={
            <ProtectedRoute>
              <VuseEpod />
            </ProtectedRoute>
          }
        /> */}
        <Route
          path="/jak-polecac"
          element={
            <ProtectedRoute>
              <HowToRecommend />
            </ProtectedRoute>
          }
        />
        <Route
          path="/jak-rozmawiac-o-glo"
          element={
            <ProtectedRoute>
              <DetalistInfo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/faq"
          element={
            <ProtectedRoute>
              <Faq />
            </ProtectedRoute>
          }
        />
        <Route
          path="/poznaj-zasady-sprzedazy"
          element={
            <ProtectedRoute>
              <SaleRules />
            </ProtectedRoute>
          }
        />
        <Route
          path="/regulaminy"
          element={
            <ProtectedRoute>
              <Regulations />
            </ProtectedRoute>
          }
        />
        {user?.sapIdValidate && !april  && <Route
          path="/nowa-sprzedaz"
          element={
            <ProtectedRoute>
              <NewSale />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/nowa-sprzedaz/numer-seryjny"
          element={
            <ProtectedRoute>
              <NewSaleSerialNumber />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/nowa-sprzedaz/success/:device"
          element={
            <ProtectedRoute>
              <NewSaleDetails />
            </ProtectedRoute>
          }
        />}
        {user?.sapIdValidate && !april  && <Route
          path="/notyfikacje"
          element={
            <ProtectedRoute>
              <Notifications />
            </ProtectedRoute>
          }
        />}
        <Route
          path="/cele"
          element={
            <ProtectedRoute>
              <Goals />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produkty-bat/kent"
          element={
            <ProtectedRoute>
              <Kent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produkty-bat/vusego"
          element={
            <ProtectedRoute>
              <VuseGo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produkty-bat/pall-mall"
          element={
            <ProtectedRoute>
              <PallMall />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produkty-bat/veo"
          element={
            <ProtectedRoute>
              <Veo />
            </ProtectedRoute>
          }
        />
        <Route
          path="/jak-polecac-alt"
          element={
            <ProtectedRoute>
              <HowToRecommendAlt />
            </ProtectedRoute>
          }
        />
        <Route
          path="/produkty-bat"
          element={
            <ProtectedRoute>
              <Products />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reklamacje"
          element={
            <ProtectedRoute>
              <Complaint />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/kent" element={<Navigate to="/produkty-bat/kent" />} />
        <Route path="/404" element={<Page404 />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
      <Nav />
      <Drawer />
      {user?.sapIdValidate && !april  && <NotificationToasts />}
      <TokenRefresh />
    </Router>
  );
}

export default AppRoutes;
