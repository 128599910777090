import { 
  GET_NEWS,
  ADD_TOAST
} from './types';
import { API_URL, appFetch, fileFetch } from '../../helpers/fetch';
import { errors } from '../../helpers/errors';

export function listNews() {
  return (dispatch, getState) => {
    const currentState = getState();
    appFetch("POST", API_URL, "/article/list", false, currentState.user?.token?.uuid ? currentState.user?.token?.uuid : false, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type: GET_NEWS, payload: result.data });
        } else {
            //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}