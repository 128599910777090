import React from "react";
import { ReactComponent as Gift } from "./img/gift.svg";

import "./QuizQuestionCounter.scss";

interface QuestionCounterProps {
  questionNumber: number;
  indexActiveQuestion: number;
  prize?: boolean;
  value?: number;
}

const QuizQuestionCounter: React.FC<QuestionCounterProps> = ({
  questionNumber,
  indexActiveQuestion,
  value,
  prize,
}) => {
  const bullets = new Array(questionNumber).fill(null);

  return (
    <div className="QuizQuestionCounter">
      {prize && (
        <div className="prize">
          <span className="value">{value} zł</span>
          <span className="icon">
            <Gift />
          </span>
        </div>
      )}
      <div className="bullets-wrapper">
        <div className="bullets">
          {bullets.map((_, i) => {
            return (
              <div
                key={i}
                className={
                  indexActiveQuestion === i ? "bullet active" : "bullet"
                }
              >
                {i + 1}
              </div>
            );
          })}
          <div className="line"></div>
        </div>
      </div>
    </div>
  );
};

export default QuizQuestionCounter;
