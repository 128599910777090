import React, { useEffect } from 'react';
import PageLayout from "../../components/PageLayout/PageLayout";
import { SubmitHandler, useForm, FieldValues, FieldErrors } from 'react-hook-form';
import '../../theme/forms.scss';
import { Link } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { useNavigate  } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { requestResetPass } from '../../store/actions/user';
import { getRemindState } from '../../store/reducers/user';
import { useAppDispatch } from '../../hooks';
import './Remind.scss';

interface remindProps {
  emailAddress: 'string';
}

function RemindPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const remindSuccess = useSelector(getRemindState)
  const { register, setValue, handleSubmit, getValues, formState: { errors } } = useForm();

  const onSubmit = ({email}: FieldValues) => {
    dispatch(requestResetPass(email))
  }

  const onError = (errors: FieldErrors) => {
    //console.log(errors)
  }

  return (
    <PageLayout>
      <div className="page Remind">
        <h1>Zapomniałeś hasła?</h1>
        <p className="color-secondary text-center"><strong>Nic nie szkodzi.</strong></p>
        <form>
          <section className={ remindSuccess ? "form-step": "form-step active" }>
            <div className="input-item">
              <label htmlFor="email">E-mail</label>
              <input className={ errors.email && 'error-input' } type="text" id="email" {...register("email", {required: true, pattern:/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/})} ></input>
              {errors.email && errors.email.type === "pattern" && (
                <p className="error">Nieprawidłowa wartość</p>
              )}
              {errors.email && errors.email.type === "required" && (
                <p className="error">Pole wymagane</p>
              )}
            </div>
            <Button onClick={ handleSubmit(onSubmit, onError) }>Dalej</Button>
          </section>
          <section className={ remindSuccess ? "form-step active": "form-step" }>
            <p>{"Wszystko jest na dobrej drodze. Na Twoją skrzynkę mailową ("+getValues('email')+") wysłaliśmy instrukcję resetowania hasła."} </p>
            <div className="center">
              {/*<IconCheck />*/}
            </div>
          </section>
          <div className="text-center margin-20">
            <Link to="/">Powrót do logowania</Link>
          </div>
        </form>
      </div>
    </PageLayout>
  );
}

export default RemindPage
