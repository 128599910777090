import { useEffect, useState } from "react";
import { SubmitHandler, useForm, FieldValues, FieldErrors } from 'react-hook-form';
import Button from "../../components/Button/Button";
import "./RedeemCode.scss";
import {ReactComponent as Chevron} from '../../theme/img/chevron.svg';
import { couponList, couponRegister } from "../../store/actions/user";
import { useAppDispatch } from "../../hooks";
import { useSelector } from "react-redux";
import { getCoupons } from "../../store/reducers/user";
import moment from "moment";

function RedeemCode() {
  const dispatch = useAppDispatch();
  const coupons = useSelector(getCoupons);
  const { register, setValue, handleSubmit, getValues, reset, formState: { errors } } = useForm();
  const [showRedeemed, setShowRedeemed] = useState(false)

  const onSubmit = ({coupon}: FieldValues) => {
    dispatch(couponRegister({
      couponCode: coupon
    }))
    reset({coupon: ""})
  }

  const onError = (errors: FieldErrors) => {
    console.log(errors)
  }

  useEffect(()=>{
    dispatch(couponList())
  },[])

  return (
    <div className="RedeemCode">
      <div className="redeem-inner">
        <form>
          <div className="input-item">
            <label htmlFor="coupon">Kod rabatowy</label>
            <input placeholder='np. AJ845' className={ errors.coupon && 'error-input' } type="text" id="coupon" {...register("coupon", {pattern:/^[a-zA-Z0-9]{5}$/})} ></input>
            {errors.coupon && errors.coupon.type === "pattern" && (
              <p className="error">Nieprawidłowy kod rabatowy</p>
            )}
          </div>
          <Button color="white" onClick={ handleSubmit(onSubmit, onError) }>Wyślij</Button>
          <div className={showRedeemed ? "show-redeemed-text active" : "show-redeemed-text"} onClick={()=>setShowRedeemed(!showRedeemed)}>Zrealizowane kody <Chevron /></div>
        </form>
        <ul className={showRedeemed ? "redeemed-codes active" : "redeemed-codes"}>
          { coupons &&
            coupons.map((item: any, key: number)=>
            <li>
              <div className="date">{moment(item?.registered).format('DD.MM.YYYY')}</div>
              <div className="code">{item?.code}</div>
            </li>
            )
          }
        </ul>
      </div>
    </div>
  );
}
export default RedeemCode;
