// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React, { useState, useEffect } from "react";
import PageLayout from "../../../../components/PageLayout/PageLayout";
import Title from "../../../../components/Title/Title";
import { useWindowDimensions } from "../../../../hooks";
import { ReactComponent as Correct } from "./img/correct.svg";
import { ReactComponent as Incorrect } from "./img/incorrect.svg";
import "../Quiz.scss";
import _ from "lodash";
import Bg01 from "./img/01.png";
import Bg02 from "./img/02.png";
import Bg03 from "./img/03.png";
import Bg04 from "./img/04.png";
import Bg05 from "./img/05.png";
import Bg06 from "./img/06.png";
import Bg01mobile from "./img/01-mobile.png";
import Bg02mobile from "./img/02-mobile.png";
import Bg03mobile from "./img/03-mobile.png";
import Bg04mobile from "./img/04-mobile.png";
import Bg05mobile from "./img/05-mobile.png";
import Bg06mobile from "./img/06-mobile.png";
import QuizQuestionCounter from "../../../../components/QuizQuestionCounter/QuizQuestionCounter";
import QuizCta from "../../../../components/QuizCta/QuizCta";
import Button from "../../../../components/Button/Button";
import { getToken } from "../../../../store/reducers/user";
import { API_URL, appFetch } from "../../../../helpers/fetch";
import { useAppSelector } from "../../../../hooks";
import { ADD_TOAST } from "../../../../store/actions/types";
import { useDispatch } from "react-redux";
import { errors } from "../../../../helpers/errors";
import { ukladanka_glo } from "./data/ukladanka";
import { useNavigate } from "react-router-dom";

const ResultScreen = ({ loading, doneCorrectly }) => {
  const navigate = useNavigate();
  if (loading) return null;

  return doneCorrectly ? (
    <div className="QuizResultScreen">
      <div className="heading">
        <div className="icon">{<Correct />}</div>
        <h2 className="title">Bezbłednie!</h2>
      </div>
      <div className="body">
        <p>
          Znasz saszetki nikotynowe VELO i masz świadomość ich różnorodności. Na
          Twoje konto wpadło: <strong>10 złotych</strong>.
        </p>
      </div>

      <button className="Button" onClick={() => navigate("/aktywnosci")}>
        Wróć do aktywności
      </button>
    </div>
  ) : (
    <div className="QuizResultScreen">
      <div className="heading">
        <div className="icon">{<Incorrect />}</div>
        <h2 className="title">Ups, coś poszło nie tak.</h2>
      </div>
      <div className="body">
        <p>
          Zapoznaj się raz jeszcze z naszą edukacyjną zakładką i wróć za 24h,
          aby ponownie spróbować swoich sił.
        </p>
      </div>

      <button className="Button" onClick={() => navigate("/aktywnosci")}>
        Wróć do aktywności
      </button>
    </div>
  );
};

const correctPuzzels = [
  "43d10a48-e945-4ba1-8b44-2867fba77026",
  "2e8c7022-445b-4be8-bb66-4af23e66ec4b",
  "ad0c9e80-28cb-43f6-abb1-b212ebc163a0",
  "d6885344-90ce-4a87-bfb7-62d022cbbe03",
  "bf03f153-0c44-40ed-8f4d-ae55186cbcdb",
  "8c05a602-9aac-41f9-af2e-e820a30bc0af",
  "e75d6f7b-b1cd-413d-b2f0-fc3b3d2efe95",
  "94c465c4-54dc-4e97-ba97-7ba8fa5488a6",
  "cd3f497b-528f-45ac-a940-56cd917b9e2e",
];
const questionBackgrounds = [Bg01, Bg02, Bg03, "", Bg04, Bg05, Bg06];
const questionBackgroundsMobile = [
  Bg01mobile,
  Bg02mobile,
  Bg03mobile,
  "",
  Bg04mobile,
  Bg05mobile,
  Bg06mobile,
];

interface QuizProps {
  questionNumber: number;
  indexActiveQuestion: number;
  handleNextQuestion: () => void;
  handleAnswer: (answer: any) => void;
  questionAnswers: any;
}

const Quiz: React.FC<QuizProps> = ({
  questionAnswers,
  questionNumber,
  indexActiveQuestion,
  handleNextQuestion,
  handleAnswer,
  handlePickBg,
}) => {
  const itsActive = (answer: string, idx: any) => {
    if (typeof idx === "number") {
      return Boolean(
        questionAnswers[indexActiveQuestion]?.answer_ident[idx] === answer
      );
    } else {
      return Boolean(
        questionAnswers[indexActiveQuestion]?.answer_ident === answer
      );
    }
  };
  const getQuestion = (idx) =>
    questionAnswers[indexActiveQuestion]?.answer_ident[idx]
      ? questionAnswers[indexActiveQuestion]?.answer_ident[idx]
      : null;
  let puzzels = _.cloneDeep(ukladanka_glo.glo[0]);
  const [answersArray, setAnswersArray] = useState(puzzels);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [finishPuzzels, setFinishPuzzels] = useState(false);
  const multipleAnswers =
    indexActiveQuestion === 4 || indexActiveQuestion === 5;

  const findWithAttr = (array: any, attr: any, value: any) => {
    for (var i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  };
  const selectElement = (item: any, i: any, x: any, y: any) => {
    let selectedI = [...selectedIndex];
    let selectedItm = [...selectedItems];

    if (selectedIndex.includes(i)) {
      let index = selectedI.indexOf(i);
      if (index > -1) {
        selectedI.splice(index, 1);
      }
    } else {
      if (
        selectedItm.length === 0 ||
        (selectedItm.length === 1 &&
          (((selectedItm[0].x === x - 1 || selectedItm[0].x === x + 1) &&
            selectedItm[0].y === y) ||
            ((selectedItm[0].y === y - 1 || selectedItm[0].y === y + 1) &&
              selectedItm[0].x === x)))
      )
        selectedI.push(i);
    }
    setSelectedIndex(selectedI);

    if (selectedItems.includes({ ...item, ...{ x: x, y: y } })) {
      let index = selectedItm.indexOf({ ...item, ...{ x: x, y: y } });
      if (index > -1) {
        selectedItm.splice(index, 1);
      }
    } else {
      if (
        selectedItm.length === 0 ||
        (selectedItm.length === 1 &&
          (((selectedItm[0].x === x - 1 || selectedItm[0].x === x + 1) &&
            selectedItm[0].y === y) ||
            ((selectedItm[0].y === y - 1 || selectedItm[0].y === y + 1) &&
              selectedItm[0].x === x)))
      )
        selectedItm.push({ ...item, ...{ x: x, y: y } });
    }
    setSelectedItems(selectedItm);
    if (selectedItm.length === 2) {
      swapItems(selectedItm);
    }
  };

  const swapItems = (items: any) => {
    if (finishPuzzels) return;
    let tempArray = answersArray;
    let firstItem = findWithAttr(tempArray.blocks, "ident", items[0].ident);
    let secondItem = findWithAttr(tempArray.blocks, "ident", items[1].ident);
    [tempArray.blocks[firstItem], tempArray.blocks[secondItem]] = [
      tempArray.blocks[secondItem],
      tempArray.blocks[firstItem],
    ];
    setAnswersArray(tempArray);
    setSelectedIndex([]);
    setSelectedItems([]);
  };

  const checkIfPuzzleAreFinish = () => {
    const userPuzzels = answersArray.blocks.map((item) => item.ident);
    const correctAnswers = correctPuzzels;
    const correct = userPuzzels.join("") === correctAnswers.join("");

    if (correct) {
      setFinishPuzzels(true);
      handleAnswer({
        question_ident: "q3",
        answer_ident: correctAnswers,
      });
    }
  };

  useEffect(() => {
    checkIfPuzzleAreFinish();
  }, [setSelectedItems, selectedIndex]);

  return (
    <div className="quiz-wrapper">
      {indexActiveQuestion === 0 && (
        <div className="quiz-item">
          <h2 className="title">Odkryj VELO X-FREEZE</h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />
          <div className="top-desc">
            VELO dopasowane jest do potrzeb każdego użytkownika.
          </div>
          <div className="image">
            <img src={handlePickBg()} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">
                  Dopasuj pokazany format saszetek do poziomu zaawansowania
                  konsumentów.{" "}
                </span>
              </p>
            </div>
            <div className="cta-group">
              <QuizCta
                active={itsActive("a", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q0",
                    answer_ident: ["a"],
                  })
                }
              >
                Dla początkujących
              </QuizCta>
              <QuizCta
                active={itsActive("b", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q0",
                    answer_ident: ["b"],
                  })
                }
              >
                Dla zaawansowanych
              </QuizCta>
              <QuizCta
                active={itsActive("c", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q0",
                    answer_ident: ["c"],
                  })
                }
              >
                Dla ekspertów
              </QuizCta>
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 1 && (
        <div className="quiz-item">
          <h2 className="title">Odkryj VELO X-FREEZE</h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />
          <div className="top-desc">
            VELO dopasowane jest do potrzeb każdego użytkownika.
          </div>
          <div className="image">
            <img src={handlePickBg()} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">
                  Dopasuj pokazany format saszetek do poziomu zaawansowania
                  konsumentów.{" "}
                </span>
              </p>
            </div>
            <div className="cta-group">
              <QuizCta
                active={itsActive("a", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q1",
                    answer_ident: ["a"],
                  })
                }
              >
                Dla ekspertów
              </QuizCta>
              <QuizCta
                active={itsActive("b", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q1",
                    answer_ident: ["b"],
                  })
                }
              >
                Dla początkujących
              </QuizCta>
              <QuizCta
                active={itsActive("c", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q1",
                    answer_ident: ["c"],
                  })
                }
              >
                Dla zaawansowanych
              </QuizCta>
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 2 && (
        <div className="quiz-item">
          <h2 className="title">Odkryj VELO X-FREEZE</h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />
          <div className="top-desc">
            VELO dopasowane jest do potrzeb każdego użytkownika.
          </div>
          <div className="image">
            <img src={handlePickBg()} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">
                  Dopasuj pokazany format saszetek do poziomu zaawansowania
                  konsumentów.{" "}
                </span>
              </p>
            </div>
            <div className="cta-group">
              <QuizCta
                active={itsActive("a", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q2",
                    answer_ident: ["a"],
                  })
                }
              >
                Dla zaawansowanych
              </QuizCta>
              <QuizCta
                active={itsActive("b", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q2",
                    answer_ident: ["b"],
                  })
                }
              >
                Dla ekspertów
              </QuizCta>
              <QuizCta
                active={itsActive("c", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q2",
                    answer_ident: ["c"],
                  })
                }
              >
                Dla początkujących
              </QuizCta>
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 3 && (
        <div className="quiz-item">
          <h2 className="title">Odkryj VELO X-FREEZE</h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />
          <div className="body">
            <div className="question">
              <p className="desc puzzle-desc">
                <span className="bold subtitle">
                  Zaznacz dwa sąsiadujące fragmenty, aby je przesunąć. Kafelki
                  mogą poruszać się tylko w pionie i poziomie.
                </span>
              </p>
            </div>
            <div className="board">
              {answersArray.blocks.map((item, i) => {
                return (
                  <li
                    className={
                      selectedIndex.includes(i) ? "square active" : "square"
                    }
                    key={i}
                    onClick={() => {
                      selectElement(item, i, i % 3, parseInt(i / 3));
                    }}
                  >
                    <img src={item.part} alt="square" />
                  </li>
                );
              })}
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 4 && (
        <div className="quiz-item">
          <h2 className="title">Odkryj VELO X-FREEZE</h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />
          <div className="image">
            <img src={handlePickBg()} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">
                  Dopasuj pokazany format saszetek do poziomu zaawansowania
                  konsumentów.{" "}
                </span>
              </p>
            </div>
            <div className="tabs">
              <p className="copy">Stężenie nikotyny:</p>
              <div className="cta-tabs">
                <QuizCta
                  active={itsActive("a", 0)}
                  onClick={() =>
                    handleAnswer({
                      question_ident: "q4",
                      answer_ident: ["a", getQuestion(1)],
                    })
                  }
                >
                  <span>4 mg</span>
                </QuizCta>
                <QuizCta
                  active={itsActive("b", 0)}
                  onClick={() =>
                    handleAnswer({
                      question_ident: "q4",
                      answer_ident: ["b", getQuestion(1)],
                    })
                  }
                >
                  <span>6 mg</span>
                </QuizCta>
                <QuizCta
                  active={itsActive("c", 0)}
                  onClick={() =>
                    handleAnswer({
                      question_ident: "q4",
                      answer_ident: ["c", getQuestion(1)],
                    })
                  }
                >
                  <span>10 mg</span>
                </QuizCta>
                <QuizCta
                  active={itsActive("d", 0)}
                  onClick={() =>
                    handleAnswer({
                      question_ident: "q4",
                      answer_ident: ["d", getQuestion(1)],
                    })
                  }
                >
                  <span>15 mg</span>
                </QuizCta>
              </div>
              <p className="copy">Liczba saszetek:</p>
              <div className="cta-tabs">
                <QuizCta
                  active={itsActive("a", 1)}
                  onClick={() =>
                    handleAnswer({
                      question_ident: "q4",
                      answer_ident: [getQuestion(0), "a"],
                    })
                  }
                >
                  18
                </QuizCta>
                <QuizCta
                  active={itsActive("b", 1)}
                  onClick={() =>
                    handleAnswer({
                      question_ident: "q4",
                      answer_ident: [getQuestion(0), "b"],
                    })
                  }
                >
                  20
                </QuizCta>
                <QuizCta
                  active={itsActive("c", 1)}
                  onClick={() =>
                    handleAnswer({
                      question_ident: "q4",
                      answer_ident: [getQuestion(0), "c"],
                    })
                  }
                >
                  22
                </QuizCta>
                <QuizCta
                  active={itsActive("d", 1)}
                  onClick={() =>
                    handleAnswer({
                      question_ident: "q4",
                      answer_ident: [getQuestion(0), "d"],
                    })
                  }
                >
                  24
                </QuizCta>
              </div>
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 5 && (
        <div className="quiz-item">
          <h2 className="title">Odkryj VELO X-FREEZE</h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />
          <div className="image">
            <img src={handlePickBg()} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">
                  Dopasuj pokazany format saszetek do poziomu zaawansowania
                  konsumentów.{" "}
                </span>
              </p>
            </div>
            <div className="tabs">
              <p className="copy">Stężenie nikotyny:</p>
              <div className="cta-tabs">
                <QuizCta
                  active={itsActive("a", 0)}
                  onClick={() =>
                    handleAnswer({
                      question_ident: "q5",
                      answer_ident: ["a", getQuestion(1)],
                    })
                  }
                >
                  <span>10 mg</span>
                </QuizCta>
                <QuizCta
                  active={itsActive("b", 0)}
                  onClick={() =>
                    handleAnswer({
                      question_ident: "q5",
                      answer_ident: ["b", getQuestion(1)],
                    })
                  }
                >
                  <span>15 mg</span>
                </QuizCta>
                <QuizCta
                  active={itsActive("c", 0)}
                  onClick={() =>
                    handleAnswer({
                      question_ident: "q5",
                      answer_ident: ["c", getQuestion(1)],
                    })
                  }
                >
                  <span>20 mg</span>
                </QuizCta>
                <QuizCta
                  active={itsActive("d", 0)}
                  onClick={() =>
                    handleAnswer({
                      question_ident: "q5",
                      answer_ident: ["d", getQuestion(1)],
                    })
                  }
                >
                  <span>25 mg</span>
                </QuizCta>
              </div>
              <p className="copy">Liczba saszetek:</p>
              <div className="cta-tabs">
                <QuizCta
                  active={itsActive("a", 1)}
                  onClick={() =>
                    handleAnswer({
                      question_ident: "q5",
                      answer_ident: [getQuestion(0), "a"],
                    })
                  }
                >
                  18
                </QuizCta>
                <QuizCta
                  active={itsActive("b", 1)}
                  onClick={() =>
                    handleAnswer({
                      question_ident: "q5",
                      answer_ident: [getQuestion(0), "b"],
                    })
                  }
                >
                  20
                </QuizCta>
                <QuizCta
                  active={itsActive("c", 1)}
                  onClick={() =>
                    handleAnswer({
                      question_ident: "q5",
                      answer_ident: [getQuestion(0), "c"],
                    })
                  }
                >
                  22
                </QuizCta>
                <QuizCta
                  active={itsActive("d", 1)}
                  onClick={() =>
                    handleAnswer({
                      question_ident: "q5",
                      answer_ident: [getQuestion(0), "d"],
                    })
                  }
                >
                  24
                </QuizCta>
              </div>
            </div>
          </div>
        </div>
      )}
      {indexActiveQuestion === 6 && (
        <div className="quiz-item">
          <h2 className="title">Odkryj VELO X-FREEZE</h2>
          <QuizQuestionCounter
            questionNumber={questionNumber}
            indexActiveQuestion={indexActiveQuestion}
          />
          <div className="image">
            <img src={handlePickBg()} alt="" />
          </div>
          <div className="body">
            <div className="question">
              <p className="desc">
                <span className="bold subtitle">
                  Jaka jest <span className="hilight">główna różnica</span>{" "}
                  między nowymi wariantami VELO X-Freeze a dotychczasowymi
                  wariantami Velo?
                </span>
              </p>
            </div>
            <div className="cta-group">
              <QuizCta
                active={itsActive("a", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q6",
                    answer_ident: ["a"],
                  })
                }
              >
                Opakowanie podlega recyklingowi
              </QuizCta>

              <QuizCta
                active={itsActive("b", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q6",
                    answer_ident: ["b"],
                  })
                }
              >
                Mogą być używane gdziekolwiek chcesz
              </QuizCta>
              <QuizCta
                active={itsActive("c", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q6",
                    answer_ident: ["c"],
                  })
                }
              >
                Oferuje doświadczenie nikotyny bez dymu i&nbsp;popiołu
              </QuizCta>
              <QuizCta
                active={itsActive("d", 0)}
                onClick={() =>
                  handleAnswer({
                    question_ident: "q6",
                    answer_ident: ["d"],
                  })
                }
              >
                Oferuje doświadczenie najwyższej mocy nikotyny
                <br/> <span className="footer">w portfolio Velo</span>
              </QuizCta>
            </div>
          </div>
        </div>
      )}
      {multipleAnswers ? (
        <Button
          onClick={handleNextQuestion}
          className={
            Boolean(questionAnswers[indexActiveQuestion]?.answer_ident[0]) &&
            Boolean(questionAnswers[indexActiveQuestion]?.answer_ident[1])
              ? "next-cta"
              : "next-cta disabled"
          }
        >
          Dalej
        </Button>
      ) : (
        <Button
          onClick={handleNextQuestion}
          className={
            questionAnswers[indexActiveQuestion] === null
              ? "next-cta disabled"
              : "next-cta"
          }
        >
          Dalej
        </Button>
      )}
    </div>
  );
};

function VeloFreeze() {
  const { width } = useWindowDimensions();
  const token = useAppSelector(getToken);
  const quizIdent = "quiz_velo2";
  const questionQuantity = 7;
  const [questionNumber, setQuestionNumber] = useState(questionQuantity);
  const [indexActiveQuestion, setIndexActiveQuestion] = useState(0);
  const [isFinish, setIsFinish] = useState(false);
  const [doneCorrectly, setDoneCorrectly] = useState(false);
  const [loading, setLoading] = useState(false);
  const [questionAnswers, setQuestionAnswers] = useState(
    new Array(questionQuantity).fill(null)
  );
  const dispatch = useDispatch();

  const topDesc =
    indexActiveQuestion === 0 ||
    indexActiveQuestion === 1 ||
    indexActiveQuestion === 2;

  const handlePickBg = () =>
    width >= 1020
      ? questionBackgrounds[indexActiveQuestion]
      : questionBackgroundsMobile[indexActiveQuestion];
  useEffect(() => {
    function fetchQuiz() {
      const data = {
        activation: {
          ident: quizIdent,
          payload: questionAnswers,
        },
      };
      appFetch(
        "POST",
        API_URL,
        "/activation/solution/create",
        data,
        token?.uuid ? token?.uuid : false,
        (result: any) => {
          if (result.status && result.status.success) {
            if (result.data.payout != 0) {
              setDoneCorrectly(true);
            } else {
              setDoneCorrectly(false);
            }
            setLoading(false);
            setIsFinish(true);
          }
          setLoading(false);
          setIsFinish(true);
        },
        () => {
          dispatch({
            type: ADD_TOAST,
            payload: { type: "error", message: errors.generic },
          });
        }
      );
    }
    if (questionQuantity === indexActiveQuestion) {
      fetchQuiz();
    }
  }, [indexActiveQuestion]);

  const handleAnswer = (answer: any) => {
    let arrayWithQuestion = [...questionAnswers];
    arrayWithQuestion[indexActiveQuestion] = answer;
    setQuestionAnswers(arrayWithQuestion.slice(0, questionQuantity));
  };

  const handleNextQuestion = () => {
    if (indexActiveQuestion <= questionQuantity - 1) {
      setIndexActiveQuestion((prevState) => prevState + 1);
    }
  };

  return (
    <PageLayout>
      <div className="Quiz-Wrapper">
        {console.log(questionAnswers)}
        {isFinish ? (
          <ResultScreen loading={loading} doneCorrectly={doneCorrectly} />
        ) : (
          <div className="page Quiz VeloFreeze">
            <div className="heading">
              {width <= 1020 ? (
                <Title>
                  Odkryj
                  <br /> VELO X-FREEZE
                </Title>
              ) : (
                <Title></Title>
              )}
              {topDesc ? (
                <div className="image-desktop">
                  <div className="top-desc">
                    VELO dopasowane jest do potrzeb
                    <br /> każdego użytkownika.
                  </div>
                  <img src={handlePickBg()} alt="" />
                </div>
              ) : (
                <div className="image-desktop">
                  <img src={handlePickBg()} alt="" />
                </div>
              )}
            </div>
            <Quiz
              handlePickBg={handlePickBg}
              questionAnswers={questionAnswers}
              handleAnswer={handleAnswer}
              handleNextQuestion={handleNextQuestion}
              indexActiveQuestion={indexActiveQuestion}
              questionNumber={questionNumber}
            />
          </div>
        )}
      </div>
    </PageLayout>
  );
}

export default VeloFreeze;
