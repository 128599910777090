import React, { useEffect, useState } from "react";
import Routes from "./app/Routes";
import "./App.scss";
// swiper
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useAppDispatch, useAppSelector, useWindowDimensions } from "./app/hooks";
import { getUI } from "./app/store/reducers/ui";
import { getUserDetails } from "./app/store/actions/user";
import moment from "moment";
import Loading from "./app/components/Loading/LoadingScreen/Loading";

function App() {
  const dispatch = useAppDispatch()
  const ui = useAppSelector(getUI);
  const [loading, setLoading] = useState(true)
  const token = localStorage.getItem("token");
  const {height} = useWindowDimensions();


  useEffect(()=>{
    const tkn = token && JSON.parse(token);
    if (tkn && moment(tkn.expires.date).isAfter(moment())) {
      dispatch({ type: 'LOGIN', payload: { token: tkn } });
      dispatch(getUserDetails(false, false, tkn.uuid))
      setLoading(false)
    } else {
      setLoading(false)
    }
  },[])

  

  

  return (
    <div className={ui.showMenu ? "app menu-open" : "app"} style={ui.showMenu ? {height: height} : {height: 'auto'}}>
      { loading ? <div className="app-loader"><Loading/></div> : <Routes /> }
    </div>
  );
}

export default App;
