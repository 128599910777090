import React, { useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import { Navigation } from "swiper";
import "./Kent.scss";
import Title from "../../components/Title/Title";
import { Swiper, SwiperSlide } from "swiper/react";
import icon1 from "./img/icon1.svg";
import icon2 from "./img/icon2.svg";
import icon3 from "./img/icon3.svg";
import icon4 from "./img/icon4.svg";
import icon5 from "./img/icon5.svg";
import icon6 from "./img/icon6.svg";
import kentLogo from "./img/kent-logo.png";
import footerImageDesktop from "./img/footer-packs.jpg";
import pack1 from "./img/kent-blue.png";
import pack2 from "./img/kent-silver.png";
import pack3 from "./img/kent-ssl.png";
import pack4 from "./img/kent-red.png";
import pack5 from "./img/kent-ssl-blue.png";
import kentLogoBg from "./img/logo_bg.png";
import { useWindowDimensions } from "../../hooks";
import Badge from "../../components/Badge/Badge";

function Kent() {
  const { height, width } = useWindowDimensions();
  const [packageIndex, setPackageIndex] = useState(0);
  const [cardIndex, setCardIndex] = useState(0);

  const panels = [
    {
      icon: icon1,
      text: (
        <p>
          <strong>Kent to nowoczesna marka premium</strong> dostępna w ponad 50
          krajach na świecie.
        </p>
      ),
    },
    {
      icon: icon2,
      text: (
        <p>
          Skierowana dla konsumentów takich marek jak
          <strong>
            {" "}
            L&M First Cut, Winston, Chesterfield, Parliament, Davidoff.
          </strong>
        </p>
      ),
    },
    {
      icon: icon3,
      text: (
        <p>
          Dla konsumentów podążających za trendami,
          <strong>
            {" "}
            którzy cenią sobie nie tylko jakość, ale i stylowy design.
          </strong>
        </p>
      ),
    },
    {
      icon: icon4,
      text: (
        <p>
          Dostarcza tytoń najlepszej jakości <strong>od ponad 70 lat.</strong>
        </p>
      ),
    },
    {
      icon: icon5,
      text: (
        <p>
          Papierosy Kent aktualnie produkowane są{" "}
          <strong>
            w Polsce, w fabryce w Augustowie - jednej z największych fabryk BAT
            na świecie.
          </strong>
        </p>
      ),
    },
    {
      icon: icon6,
      text: (
        <p>
          Kent <strong>to silna marka w Ukrainie</strong> (szczególną
          popularnością cieszy się Kent KS Silver).
        </p>
      ),
    },
  ];

  const packages = [
    {
      title: "Kent KS",
      info: [
        <p>
          <strong>Jeden format</strong> - dwa produkty o różnych mieszankach
          tytoniu
        </p>,
        <p>
          <strong>Stylowe</strong>, wyróżniające się opakowanie
        </p>,
        <p>
          <strong>Nowoczesny</strong> filtr z tubą
        </p>,
        <p>
          <strong>Atrakcyjna</strong> cena
        </p>,
      ],
    },
    {
      title: "Kent SSL",
      info: [
        <p>
          <strong>Blend z chłodzącym efektem</strong> odczuwalnym podczas
          palenia
        </p>,
        <p>
          <strong>Eleganckie i wyróżniające</strong> się opakowanie
        </p>,
        <p>
          <strong>Nowoczesny</strong> filtr z tubą
        </p>,
        <p>
          <strong>Atrakcyjna</strong> cena
        </p>,
      ],
    },
  ];

  return (
    <PageLayout>
      <section className="Kent">
        <div className="hero-section">
          <Badge category="Papierosy tradycyjne" />
          <div className="glo-wrapper">
            <div className="glo-hero-content">
              <Title></Title>
              <div className="kent-logo">
                <img src={kentLogo} />
              </div>
              <h1 className="Title">
                Poznaj nową <br />
                <span>markę papierosów</span>
              </h1>
            </div>
          </div>
        </div>
        <div className="content-box">
          <div className="card-control">
            {packages.map((item, key) => {
              return (
                <div
                  className={
                    cardIndex === key ? "control-btn active" : "control-btn"
                  }
                  onClick={() => setCardIndex(key)}
                >
                  {item.title}
                </div>
              );
            })}
          </div>
          {cardIndex === 0 ? (
            <div className="kent-boxes">
              <div className="kent-box kent-blue">
                <h3>
                  Kent <br />
                  KS&nbsp;Blue
                </h3>
                <p className="desc">20 sztuk</p>
                <img src={pack1} />
                <div className="price">13,99 zł</div>
              </div>
              <div className="kent-box kent-silver">
                <h3>
                  Kent <br />
                  KS&nbsp;Silver
                </h3>
                <p className="desc">20 sztuk</p>
                <img src={pack2} />
                <div className="price">13,99 zł</div>
              </div>
              <div className="kent-box kent-red">
                <h3>
                  Kent <br />
                  KS&nbsp;Red
                </h3>
                <p className="desc">20 sztuk</p>
                <img src={pack4} />
                <div className="price">13,99 zł</div>
              </div>
            </div>
          ) : (
            <div className="kent-boxes">
              <div className="kent-box kent-ssl">
                <h3>
                  Kent <br />
                  SSL&nbsp;Blue <br />
                  Marine
                </h3>
                <p className="desc">20 sztuk</p>
                <img src={pack3} />
                <div className="price">13,99 zł</div>
              </div>
              <div className="kent-box kent-blue">
                <h3>
                  Kent <br />
                  SSL&nbsp;Blue
                </h3>
                <p className="desc">20 sztuk</p>
                <img src={pack5} />
                <div className="price">13,99 zł</div>
              </div>
            </div>
          )}
          <div className="info-boxes">
            {packages[cardIndex].info.map((item, key) => {
              return <div className="info-box">{item}</div>;
            })}
          </div>
        </div>
        <h2 className="kent-title">
          <span>Co wyróżnia</span>
          <img src={kentLogoBg} />
          <span>?</span>
        </h2>
        {width < 1020 && (
          <Swiper
            pagination={{
              clickable: true,
            }}
            slidesPerView={1.2}
            spaceBetween={16}
            centeredSlides={true}
            className="glo-swiper"
            breakpoints={{
              1020: {
                slidesPerView: 2,
                spaceBetween: 30,
                centeredSlides: false,
              },
            }}
          >
            {panels.map((item, key) => {
              return (
                <SwiperSlide>
                  <div className="glo-swiper-slide">
                    <div className="top">
                      <img className="icon" src={item.icon} alt="" />
                    </div>
                    <div className="body">{item.text}</div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        )}
        {width >= 1020 && (
          <div className="kent-slides">
            {panels.map((item, key) => {
              return (
                <div className="kent-slide">
                  <div className="top">
                    <img className="icon" src={item.icon} alt="" />
                  </div>
                  <div className="body">{item.text}</div>
                </div>
              );
            })}
          </div>
        )}
        <div className="footer-image">
          <img src={footerImageDesktop} />
        </div>
        <div className="footer-info">
          <p>
            Informacja używana do celów handlowych skierowana wyłącznie do
            podmiotów zajmujących się produkcją, dystrybucją i handlem wyrobami
            tytoniowymi, papierosami elektronicznymi, pojemnikami zapasowymi lub
            rekwizytami tytoniowymi.
          </p>
        </div>
      </section>
    </PageLayout>
  );
}

export default Kent;
