import React from 'react';
import './ButtonTab.scss';

interface Props  {
  children:React.ReactNode,
  active?:boolean,
  onClick:() => void,
}

const ButtonTab: React.FC<Props> = ({children,active,onClick}) => {

  
  return (
    <button onClick={onClick} className={active ? "ButtonTab active" : "ButtonTab"}>{children}</button> 
  );
}

export default ButtonTab;
