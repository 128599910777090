import {useState} from 'react'
import Select from 'react-select'
import {
  useForm,
  SubmitHandler,
  FieldValues,
  FieldErrors,
} from "react-hook-form"
import {region} from '../region'
import { URZEDY_SKARBOWE } from "../../../data/urzedy_skarbowe"
import {Controller} from 'react-hook-form';
import Button from '../../../components/Button/Button'
function TaxReportStep3({handleNextStep, handlePrevStep,handleUpdateFormData,formDataValue, setOpenTab, taxOfficeName,user }: any) {
  const {
    register,
    setValue,
    clearErrors,
    handleSubmit,
    getValues,
    control,
    formState: { errors },
  } = useForm()
  const [regionLocation,setRegionLocation] = useState("");
  const onSubmit = (data: FieldValues, event: any) => {
    event.preventDefault()
    //wysyłamy otp
    console.log(data,'forma')
    handleUpdateFormData(data);
    handleNextStep()
  }

  const handleRegionChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setRegionLocation(e.target.value);  

  }

  const filterRegion = regionLocation ? region.filter(({wojewodztwo}) => wojewodztwo === regionLocation): region
  const allRegion = [...new Set(region.map(({wojewodztwo}) => wojewodztwo).sort(function (a, b) {
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  }))]
  const options = filterRegion.map((region) => ({  value: region.title,label:region.title }))


  return (
    <>
         
         <div className="input-item">
                    <label htmlFor="stateName">Województwo</label>
                    <div className="select-item">
                      <select  id="stateName"  defaultValue={formDataValue.stateName} {...register("stateName", { onChange:(e)=>handleRegionChange(e), required: true })}>
                        <option value="">-- Wybierz z listy ---</option>
                        { allRegion.map((item:any, key:any)=>{
                          return <option key={'option-'+key} value={item}>{ item }</option>
                        })}
                      </select>
                    </div>
                  </div>
      <div className="input-item">
      <label htmlFor="taxOfficeName">Nazwa Urzędu Skarbowego</label>
      <Controller
          control={control}
          name="taxOfficeName"
          rules={{
            required:true
          }}
          defaultValue={formDataValue.taxOfficeName}
          render={({ field: { onChange, value, ref, name }}) => (
            <Select 
              className="react-select-container"
              classNamePrefix="react-select"
              noOptionsMessage={() => "brak wyników"}
              placeholder={formDataValue.taxOfficeName ? formDataValue.taxOfficeName : "-- Wybierz z listy ---"} 
              options={options}  
              onChange={value => {
                  console.log(value)
              // @ts-ignore: Unreachable code error
                  setValue("taxOfficeName",value.label)
                  clearErrors("taxOfficeName")
              }} 
            />
          )}
      /> 
      {errors.taxOfficeName && (
        <p className="error">Uzupełnij pole</p>
      )}
      </div>
      <div className="btn-group">
        <Button className="back" onClick={() => handlePrevStep()}>Cofnij</Button>
        <Button className="next" onClick={handleSubmit(onSubmit)}>Dalej</Button>
      </div>
    </>
  )
}

export default TaxReportStep3
