import { useState, useEffect } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import Title from "../../components/Title/Title";
import { ReactComponent as IconReg } from "./img/reg-icon.svg";
import "./SaleRules.scss";
import { API_URL, appFetch } from "../../helpers/fetch";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ADD_TOAST } from "../../store/actions/types";
import { useDispatch } from "react-redux";
import { errors } from "../../helpers/errors";
import { getToken } from "../../store/reducers/user";
import moment from "moment";

interface Reg {
  title: string;
  validFrom: any;
  file: any;
  uuid: string;
}
function SaleRules() {
  const [regs, setRegs] = useState<Reg[]>([]);
  const dispatch = useAppDispatch();
  const token = useAppSelector(getToken);

  useEffect(() => {
    function fetchRegs() {
      const data = {
        limit: 100,
        offset: 0,
        status: ["new", "current", "archive"],
      };
      appFetch(
        "POST",
        API_URL,
        "/rules/list",
        data,
        token?.uuid ? token?.uuid : false,
        (result: any) => {
          if (result.status && result.status.success) {
            setRegs(result.data);
          } else {
          }
        },
        () => {
          dispatch({
            type: ADD_TOAST,
            payload: { type: "error", message: errors.generic },
          });
        }
      );
    }
    fetchRegs();
  }, [token]);

  return (
    <PageLayout>
      <div className="page SaleRules">
        <Title>
        Poznaj zasady sprzedaży{" "}
          <span>
            <IconReg />
          </span>
        </Title>
        <ul className="regs-list">
          {regs.map((reg) => (
            <li key={reg.uuid} className="reg-item">
              <h3>{reg.title}</h3>
              <div className="reg-item-footer">
                <a target="_blank" href={reg.file.url}>
                  Sprawdź
                </a>
                <div className="date">
                  {moment(reg.validFrom.date).format("DD.MM.YYYY")}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </PageLayout>
  );
}

export default SaleRules;
