
import React, { FC } from "react";
import "./Badge.scss";

interface Props {
  category?: string;
}

const Badge: FC<Props> = ({ category }) => {

  return (
    <div className="ProductBadge">
      <span className="category">{category}</span>
    </div>
  );
};

export default Badge;
