import React from "react";
import { Navigate, Route } from "react-router-dom";
import { useAppSelector } from "./hooks"; 
import { getAuthState } from './store/reducers/user'

function ProtectedRoute({ children }) {
  const isAuthenticated = useAppSelector(getAuthState);
  return isAuthenticated ? children : <Navigate to="/login" />;
}

export default ProtectedRoute;