import PageLayout from "../../../components/PageLayout/PageLayout";
import Title from "../../../components/Title/Title";
import "./NewSaleDetails.scss";
import gloImg from "./img/glo.jpg";
import glox2Img from "./img/glox2.jpg";
import gloAirImg from "./img/air.jpg";
import { ReactComponent as GiftIcon } from "./img/gift.svg";
import Button from "../../../components/Button/Button";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { showDrawer } from "../../../store/actions/ui";
import { getPayout } from "../../../store/reducers/activations";
import { useAppDispatch } from "../../../hooks";

function NewSaleDetails() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { device } = useParams()
  const payout = useSelector(getPayout);

  return (
    <PageLayout>
      <div className="page NewSaleDetails">
        <div className="card">
          <div className="photo">
          <img src={ device === 'hyper' ? gloImg : device === 'x2air' ? gloAirImg : glox2Img } alt="" />
          </div>
          <div className="details">
            <p className="copy">
              {" "}
              <strong>{`Super! ${payout} zł`}</strong> jest Twoje
            </p>
            <GiftIcon className="icon" />
          </div>
        </div>
        <div className="info">
          <h2 className="title">
            Chcesz, aby Twoja sprzedaż odbiła się szerokim echem?
          </h2>
          <p className="desc">
            Poinformuj klienta o możliwości rejestracji zakupionego urządzenia
            na stronie www.batpartners.pl/konsument podając Twój numer BAT
            Partnera.
          </p>
          <button className="cta" onClick={ ()=> { dispatch(showDrawer('batcode'))} }>Wyświetl numer BAT Partnera</button>
          <div className="footer">
            <h3 className="title">
              Chcesz zobaczyć czy w tym sezonie
              <br /> nie masz sobie równych?
            </h3>

            <Button onClick={() => navigate("/rankingi")} color="white">Sprawdź rankingi</Button>
            <Link to="/dashboard" className="cta">Wróć na stronę główną</Link>
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export default NewSaleDetails;
