import React, { FC, useState, useRef, useEffect } from "react";
import { ReactComponent as Calendar } from "./img/calendar.svg";
import { ReactComponent as Cross } from "./img/cross.svg";
import "./NotificationToasts.scss";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { closeNotification } from "../../../app/store/actions/notifications"
import { fetchNotifications } from "../../store/actions/notifications"
import { getBellNoti ,getNOTI} from "../../store/reducers/notifications";
import { useSelector } from "react-redux";
import { getUI } from "../../store/reducers/ui";
import { hideNoti } from "../../store/actions/ui";
import { useAppDispatch } from "../../hooks";

// interface Toast {
//   path: string;
//   date: string;
//   id: number;
//   title: string;
// }
interface Props {
  handleRemoveNotification: (id: number) => void;
  notification: any;
}


const NotificationListItem: React.FC<Props> = ({ handleRemoveNotification, notification }) => {
  const [startXposs, setStartXposs] = useState(0);
  const notiRef = useRef<HTMLLIElement>(null);

  const handleCloseToast = () => {
    const noti = notiRef?.current;
    if (noti) {
      noti.classList.add("remove");
    }
  };

  const handlePointerUp = (e: React.PointerEvent<HTMLLIElement>) => {
    setStartXposs(e.clientX);
    if (startXposs < e.clientX) {
      handleCloseToast();
    }
  };
  const handlePointerDown = (e: React.PointerEvent<HTMLLIElement>) => {
    setStartXposs(e.clientX);
  };

  return (
    <li
      onPointerDown={(e) => handlePointerDown(e)}
      onPointerUp={(e) => handlePointerUp(e)}
      onTransitionEnd={() => handleRemoveNotification(notification.uuid)}
      className="item"
      ref={notiRef}
    >
      <div className="inner">
        <button onClick={() => handleRemoveNotification(notification.uuid)} className="cta">
          <Cross />
        </button>
        <div className="title">{notification.content.title}</div>
        <div className="footer">
          <span className="date">
            <Calendar /> <span>{moment(notification.content.date).format('DD.MM.YYYY')}</span>
          </span>
          <span className="line"></span>
          <Link to={notification.content.description} className="copy">Zobacz więcej</Link>
        </div>
      </div>
    </li>
  );
};

const Notifications = () => {
  const dispatch = useAppDispatch()
  const notifications = useSelector(getBellNoti);
  const ui = useSelector(getUI)
  const location = useLocation();

  useEffect(() => {
    if (location.pathname != '/dashboard') {
      dispatch(hideNoti());
    }
  },[location.pathname])

  useEffect(() => {
    setTimeout(()=>{
      dispatch(hideNoti());
    }, 15000)
  },[])

  const handleRemoveNotification = (id: number) => {
    dispatch(closeNotification(id))
  };
  
  return (
    <div className={notifications.length > 0 && ui.showNoti ? "NotificationToasts" : "NotificationToasts hidden"}>
      {notifications ? (
        <ul className="list">
        {notifications?.map((notification: any) => (
          <NotificationListItem
            key={notification.uuid}
            notification={notification}
            handleRemoveNotification={handleRemoveNotification}
          />
        ))}
      </ul>
      ) : null}
    </div>
  );
};

export default Notifications;

