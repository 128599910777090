import React, { useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./HowToRecommend.scss";
import Title from "../../components/Title/Title";
import mobileHero from "./img/hero.png";
import desktopHero from "./img/hero-desktop.png";
import icon01 from "./img/icon01.svg";
import icon02 from "./img/icon02.svg";
import icon03 from "./img/icon03.svg";
import icon04 from "./img/icon04.svg";
import iconPrize01 from "./img/01-prize.svg";
import iconPrize02 from "./img/02-prize.svg";
import iconPrize03 from "./img/03-prize.svg";
import iconPrize04 from "./img/04-prize.svg";
import iconPrize05 from "./img/05-prize.svg";
import azIcon01 from "./img/01-az.svg";
import azIcon02 from "./img/02-az.svg";
import azIcon03 from "./img/03-az.svg";
import azIcon04 from "./img/04-az.svg";
import az from "./img/az.svg";
import qrIcon from "./img/qr-icon.svg";
import titleIcon from "./img/title-icon.svg";
import { ReactComponent as IconBack } from "./img/click-icon.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { useWindowDimensions } from "../../hooks";
import { Link } from "react-router-dom";
function HowToRecommend() {
  const { width } = useWindowDimensions();

  return (
    <PageLayout>
      <section className="HowToRecommend">
        <div className="hero-section">
          <div className="hero-image">
            <picture>
              <source media="(max-width: 1019px)" srcSet={mobileHero} />
              <source media="(min-width: 1020px)" srcSet={desktopHero} />
              <img className="bg" src={desktopHero} alt="A wide image" />
            </picture>
          </div>
          <div className="wrapper">
            <div className="hero-content">
              <Title></Title>
              <h1 className="title">
                <span className="bold">
                  Poleć swoim
                  <br className="br" /> konsumentom zarejestrowanie{" "}
                  <br className="br" />
                  urządzenia glo™ hyper+ <br className="br" />
                  UNIQ  lub glo™ hyper X2 Air,
                </span>{" "}
                a wszyscy otrzymacie wyjątkowe nagrody.
              </h1>
            </div>
          </div>
        </div>

        <Swiper
          slidesPerView={1.2}
          spaceBetween={16}
          centeredSlides={true}
          className="recommend-swiper"
          breakpoints={{
            1020: {
              slidesPerView: 4,
              spaceBetween: 10,
              centeredSlides: false,
            },
            1270: {
              slidesPerView: 4,
              spaceBetween: 20,
              centeredSlides: false,
            },
          }}
        >
          <SwiperSlide>
            <div className="recommend-swiper-slide">
              <div className="top">
                <img
                  className="icon"
                  src={icon01}
                  alt="Sprzedaj glo™ hyper X2 Air lub glo™ hyper+ UNIQ "
                />
              </div>
              <div className="body">
                <h3 className="title">
                  Sprzedaj glo™&nbsp;hyper&nbsp;X2&nbsp;Air lub glo™&nbsp;hyper+&nbsp;UNIQ&nbsp;
                </h3>
                <p className="desc">
                  Poleć i sprzedaj konsumentowi urządzenie glo™ hyper X2 Air lub glo™ hyper+ UNIQ. Przed przejściem do 2 kroku{" "}
                  <a href="/nowa-sprzedaz">zarejestruj swoją sprzedaż</a>{" "}
                  poprzez zeskanowanie lub ręczne wprowadzenie numeru
                  urządzenia.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="recommend-swiper-slide">
              <div className="top">
                <img className="icon" src={icon02} alt="Poinformuj klienta" />
              </div>
              <div className="body">
                <h3 className="title">
                  Poinformuj <br />
                  klienta
                </h3>
                <p className="desc">
                  <span className="special">
                    Opowiedz o możliwości zarejestrowania zakupionego glo™ hyper+ Uniq lub glo™ hyper X2 Air
                  </span>{" "}
                  oraz dołączenia do programu lojalnościowego, aby otrzymać
                  darmowe nagrody w postaci wkładów neo™ i&nbsp;punktów w
                  programie do wydania na inne rabaty.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="recommend-swiper-slide">
              <div className="top">
                <img
                  className="icon"
                  src={icon03}
                  alt="Rejestracja urządzenia i dołączenie do Alter Zone"
                />
              </div>
              <div className="body">
                <h3 className="title title-small">
                  Rejestracja urządzenia i dołączenie do Alter Zone
                </h3>
                <p className="desc">
                  Teraz czas na ruch klienta. Korzystając z linku
                  <a href={process.env.REACT_APP_REGISTER_DEVICE_URL}>
                    {" "}
                    www.batpartners.pl/konsument{" "}
                  </a>
                  lub kodu QR, dokonuje on rejestracji glo™ Hyper+ UNIQ  lub glo™ hyper X2 Air poprzez podanie numeru urządzenia oraz
                  Twojego kodu BAT Partnera. Aby odebrać nagrody, musi również
                  zarejestrować się do programu Alter Zone.
                </p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="recommend-swiper-slide">
              <div className="top">
                <img className="icon" src={icon04} alt="Pora na nagrody" />
              </div>
              <div className="body">
                <h3 className="title title-small">Pora na nagrody</h3>
                <p className="desc">
                  Za pełne skuteczne polecenie, czyli wykonanie wszystkich 3
                  wcześniejszych kroków,{" "}
                  <span className="special">wszyscy otrzymacie nagrody.</span>
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        <div className="prize">
          <div className="inner">
            <header className="header">
              <span className="icon">
                <img src={titleIcon} alt="Pora na nagrody" />
              </span>
              <h2 className="title">Nagrody</h2>
            </header>
            <div className="wrapper">
              <ul className="cards">
                <li className="card">
                  <div className="top-card">
                    <h3 className="title">
                      Dla Ciebie
                      <br /> w BatPartners
                    </h3>
                  </div>
                  <div className="body-wrapper">
                    <div className="body">
                      <div className="icon">
                        <img src={iconPrize01} alt="Dla Ciebie w BatPartners" />
                      </div>
                      <p className="desc">
                        <span>5 zł</span> (za każde polecenie konsumenta, który
                        zarejestruje glo™ hyper X2 Air lub glo™ hyper+ UNIQ z
                        Twoim kodem BAT Partnera oraz dołączy do
                        Alter&nbsp;Zone)
                      </p>
                    </div>
                    <div className="body">
                      <div className="icon">
                        <img
                          src={iconPrize02}
                          alt="Karta podarunkowa Allegro lub nagroda pieniężna "
                        />
                      </div>
                      <p className="desc">
                        <span>
                          Karta podarunkowa Allegro lub nagroda pieniężna
                        </span>{" "}
                        (za osiągnięcie konkretnej liczby poleceń w każdym
                        sezonie)
                      </p>
                    </div>
                  </div>
                </li>
                <li className="card">
                  <div className="top-card">
                    <h3 className="title">
                      Dla Klienta w <br />
                      AlterZone
                    </h3>
                  </div>

                  <div className="body">
                    <div className="icon">
                      <img src={iconPrize03} alt="Dla Ciebie w BatPartners" />
                    </div>
                    <p className="desc">
                      <span>Pakiet powitalny</span>
                      <br /> (do zakupionego urządzenia)
                    </p>
                  </div>
                  <div className="body">
                    <div className="icon">
                      <img
                        src={iconPrize04}
                        alt="Karta podarunkowa Allegro lub nagroda pieniężna "
                      />
                    </div>
                    <p className="desc">
                      <span> Dodatkowa niespodzianka</span>
                      <br />
                      (w postaci atrakcyjnej oferty)
                    </p>
                  </div>
                  <div className="body">
                    <div className="icon">
                      <img src={iconPrize05} alt="Dla Ciebie w BatPartners" />
                    </div>
                    <p className="desc">
                      <span>45 punktów na start </span>
                      <br /> (do wydania na inne oferty i&nbsp;rabaty
                      w&nbsp;katalogu nagród)
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="qr">
          <div className="wrapper">
            <header className="header">
              <span className="icon">
                <img src={qrIcon} alt="QR" />
              </span>
              <h2 className="title">
                Skorzystaj <br />z naklejki QR
              </h2>
            </header>
            <div className="cards">
              <div className="card">
                <div className="top">
                  <span className="count">1</span>
                </div>
                <div className="body">
                  <p className="desc">
                    <span>
                      Przyklej naklejkę z kodem QR oraz Twoim numerem BAT
                      Partners
                    </span>{" "}
                    w widocznym miejscu.
                  </p>
                </div>
              </div>
              <div className="card">
                <div className="top">
                  <span className="count">2</span>
                </div>
                <div className="body">
                  <p className="desc">
                    Kod po zeskanowaniu przeniesie automatycznie klienta na
                    stronę:{" "}
                    <a href="https://batpartners.pl/konsument">
                      {" "}
                      www.batpartners.pl/
                      <br />
                      konsument{" "}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="alter-zone">
          <div className="wrapper">
            <header className="header">
              <span className="logo">
                <img src={az} alt="alter zone" />
              </span>
              <h2 className="title">Co oferuje Alter Zone?</h2>
              <p className="desc">
                Alter Zone to program lojalnościowy przeznaczony dla
                pełnoletnich i&nbsp;palących konsumentów glo™ hyper X2 Air lub glo™ Hyper+ UNIQ, który nagradza zaangażowanych użytkowników
                licznymi ekskluzywnymi ofertami.
              </p>
              <p className="footer-desc">Użytkownicy mogą:</p>
            </header>
            <div className="cards">
              <div className="card">
                <img src={azIcon01} alt="az-icon" />
                <p className="desc">
                  <span>Być na bieżąco</span> z promocjami
                </p>
              </div>
              <div className="card">
                <img src={azIcon02} alt="az-icon" />
                <p className="desc">
                  <span>Brać udział w licznych</span>{" "}
                  aktywnościach&nbsp;i&nbsp;konkursach
                </p>
              </div>
              <div className="card">
                <img src={azIcon03} alt="az-icon" />
                <p className="desc">
                  <span>Zbierać punkty </span> i wymieniać je na wartościowe
                  nagrody
                </p>
              </div>
              <div className="card">
                <img src={azIcon04} alt="az-icon" />
                <p className="desc">
                  <span>
                    Polecać zakup urządzenia glo™
                  </span>{" "}
                  swoim znajomym
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
}

export default HowToRecommend;
