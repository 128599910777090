//toasts
export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";

// screen
export const SCREEN_DIMENSIONS_SAVE = "SCREEN_DIMENSIONS_SAVE";

// routing
export const NAVIGATE_HOME = "NAVIGATE_HOME";

// user
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REMIND = "REMIND";
export const REMIND_OTP = "REMIND_OTP";
export const USER_DETAILS = "USER_DETAILS";
export const RESET_PASS = "RESET_PASS";
export const REQUEST_RESET_PASS = "REQUEST_RESET_PASS";
export const SET_TOKEN = "SET_TOKEN";
export const SET_OTP_TOKEN = "SET_OTP_TOKEN";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const REGISTER_SUCCESS_CLEAR = "REGISTER_SUCCESS_CLEAR";
export const REMIND_SUCCESS = "REMIND_SUCCES";

export const ACCOUNT_BALANCE = "ACCOUNT_BALANCE";
export const ACCOUNT_BALANCE_HISTORY = "ACCOUNT_BALANCE_HISTORY";
export const ACCOUNT_PAYMENT_HISTORY = "ACCOUNT_PAYMENT_HISTORY";
export const ACCOUNT_PAYMENT_REQUEST = "ACCOUNT_PAYMENT_REQUEST";
export const ACCOUNT_PAYMENT_CREATE = "ACCOUNT_PAYMENT_CREATE";
export const ACCOUNT_PAYMENT_FAILURE = "ACCOUNT_PAYMENT_FAILURE";
export const ACCOUNT_BALANCE_SUMMARY = "ACCOUNT_BALANCE_SUMMARY";

export const GET_ACTIVATION_LIST = "GET_ACTIVATION_LIST";
export const REGISTER_DEVICE = "REGISTER_DEVICE";
export const REGISTER_DEVICE_FAILURE = "REGISTER_DEVICE_FAILURE";
export const REGISTER_DEVICE_REQUEST = "REGISTER_DEVICE_REQUEST";
export const REGISTER_DEVICE_LIMIT = "REGISTER_DEVICE_LIMIT";
export const SUBMIT_QUIZ = "SUBMIT_QUIZ";
export const QUIZ_END = "QUIZ_END";
export const UPLOAD_FILE_REQUEST = "UPLOAD_FILE_REQUEST";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";
export const BOTTOM_ERROR = "BOTTOM_ERROR";
export const BOTTOM_INFO = "BOTTOM_INFO";

export const GET_RANKING = "GET_RANKING";
export const GET_RANKING_GLO = "GET_RANKING_GLO";
export const GET_RANKING_ACTIVE = "GET_RANKING_ACTIVE";
export const GET_RANKING_TIGER = "GET_RANKING_TIGER";
export const GET_RANKING_CONSUMER = "GET_RANKING_CONSUMER";

export const GET_PRIZES = "GET_PRIZES";

export const GET_SEASONS = "GET_SEASONS";
export const GET_SEASONS_SUCCESS = "GET_SEASONS_SUCCESS";

// pages ///////////////////////////////////

export const WELCOME_SCREEN_SHOWN = "WELCOME_SCREEN_SHOWN";
export const ON_LOGIN_POPUP_OPEN = "ON_LOGIN_POPUP_OPEN";
export const ON_LOGIN_POPUP_CLOSE = "ON_LOGIN_POPUP_CLOSE";

export const SET_STEP = "SET_STEP";

// UI /////////////////////////////////////////////////

export const SHOW_MENU = "SHOW_MENU";
export const SHOW_DRAWER = "SHOW_DRAWER";
export const SHOW_NOTI = "SHOW_NOTI";

export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";

export const GET_NEWS = "GET_NEWS";
export const CONTEST_INFO = "CONTEST_INFO";

export const REGISTER_GLO_SUCCESS = 'REGISTER_GLO_SUCCESS'
export const SET_OTP_GLO_TOKEN = 'SET_OTP_GLO_TOKEN'

export const COUPON_REGISTER_SUCCESS = 'COUPON_REGISTER_SUCCESS'
export const COUPON_LIST = 'COUPON_LIST'

export const DASH_TIMER = 'DASH_TIMER'