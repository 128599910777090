import React from "react";
import Content from "../../components/PageLayout/Content/Content";
import PageLayout from "../../components/PageLayout/PageLayout";
import Title from "../../components/Title/Title";
import "./About.scss";
import VideoPlaceHolder from "./img/desktop-placeholder.jpg";
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';


function About() {

  return (
    <PageLayout>
      <div className="page About">
        <Title>O programie BAT Partners</Title>
        <div className="about-wrapper">
          <VideoPlayer url="https://www.w3schools.com/html/mov_bbb.mp4" placeholder={VideoPlaceHolder}/>
          <h2 className="about-title">
            Sprawdź wszystkie <span className="strong">korzyści</span>{" "}
            <span className="strong">płynące</span> z BAT Partners
            i&nbsp;wykorzystaj swój potencjał w pełni.
          </h2>
        </div>
      </div>
    </PageLayout>
  );
}

export default About;
