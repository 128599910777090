import React, { useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import { Navigation } from "swiper";
import "./GloUniq.scss";
import Title from "../../components/Title/Title";
import mobileHero from "./img/hero.jpg";
import desktopHero from "./img/hero-desktop.jpg";
import featureBG from "./img/feature-bg.jpg";
import DesktopFeatureBg from "./img/desktop-feature-bg.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import videoPlaceholder from "./img/video-placeholder.jpg";
import ButtonTab from "../../components/ButtonTab/ButtonTab";
import ArrowButton from "../../components/ArrowButton/ArrowButton";

import deviceImage01 from "./img/uniq-slider/01.png";
import deviceImage02 from "./img/uniq-slider/02.png";
import deviceImage03 from "./img/uniq-slider/03.png";
import deviceImage04 from "./img/uniq-slider/04.png";

import uniqCore01 from "./img/uniq-slider-case/core01.png";
import uniqCore02 from "./img/uniq-slider-case/core02.png";
import uniqCore03 from "./img/uniq-slider-case/core03.png";
import uniqCore04 from "./img/uniq-slider-case/core04.png";
import uniqCore05 from "./img/uniq-slider-case/core05.png";
import uniqCore06 from "./img/uniq-slider-case/core06.png";
import uniqCore07 from "./img/uniq-slider-case/core07.png";
import uniqCore08 from "./img/uniq-slider-case/core08.png";
import uniqCore09 from "./img/uniq-slider-case/core09.png";

import satinCore01 from "./img/uniq-slider-case/satin01.png";
import satinCore02 from "./img/uniq-slider-case/satin02.png";
import satinCore03 from "./img/uniq-slider-case/satin03.png";

import matteCore01 from "./img/uniq-slider-case/mate01.png";
import matteCore02 from "./img/uniq-slider-case/mate02.png";
import matteCore03 from "./img/uniq-slider-case/mate03.png";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import Badge from "../../components/Badge/Badge";

const panels = {
  core: [
    {
      bg: uniqCore01,
      name: "Sakura pink",
    },
    {
      bg: uniqCore02,
      name: "Powder blue",
    },
    {
      bg: uniqCore03,
      name: "Volt yellow",
    },
    {
      bg: uniqCore04,
      name: "Coral orange",
    },
    {
      bg: uniqCore05,
      name: "Pearl white",
    },
    {
      bg: uniqCore06,
      name: "Mirror gold",
    },
    {
      bg: uniqCore07,
      name: "chrome black",
    },
    {
      bg: uniqCore08,
      name: "ENERGETIC blue",
    },
    {
      bg: uniqCore09,
      name: "FINE MAHOGANY",
    },
  ],
  satin: [
    {
      bg: satinCore01,
      name: "RUBELLITE",
    },
    {
      bg: satinCore02,
      name: "BRONZE",
    },
    {
      bg: satinCore03,
      name: "RHODIUM",
    },
  ],
  matte: [
    {
      bg: matteCore01,
      name: "BLUSH GREEN",
    },
    {
      bg: matteCore02,
      name: "SAND BEIGE",
    },
    {
      bg: matteCore03,
      name: "SAGE GREEN",
    },
  ],
};

const PANELS_VARIANT = {
  SATIN: "satin",
  CORE: "core",
  MATTE: "matte",
};

function GloUniq() {
  const [coreSlider, setCoreSlider] = useState(PANELS_VARIANT.CORE);
  return (
    <PageLayout>
      <section className="GloUniq">
        <div className="hero-section">
          <Badge category="Podgrzewacz"/>
          <div className="hero-image">
            <picture>
              <source media="(max-width: 1019px)" srcSet={mobileHero} />
              <source media="(min-width: 1020px)" srcSet={desktopHero} />
              <img className="bg" src={desktopHero} alt="A wide image" />
            </picture>
          </div>
          <div className="glo-wrapper">
            <div className="glo-hero-content">
              <Title></Title>
              <h1 className="Title">glo™ Hyper+&nbsp;UNIQ </h1>
              <h2 className="sub-title">
                Nowy, kolorowy, różnorodny. Dopasuj urządzenie do Twojego stylu
                życia i okazji.
              </h2>
            </div>
            <p className="hero-desc">
              Urządzenie glo™ Hyper+&nbsp;UNIQ  dostępne jest w 4 wariantach
              kolorystycznych:
            </p>
          </div>
        </div>
        <div className="static-items">
          <div className="glo-wrapper">
            <div className="static-item">
              <img className="item-img" src={deviceImage01} alt="" />
            </div>
            <div className="static-item">
              <img className="item-img" src={deviceImage02} alt="" />
            </div>
            <div className="static-item">
              <img className="item-img" src={deviceImage03} alt="" />
            </div>
            <div className="static-item">
              <img className="item-img" src={deviceImage04} alt="" />
            </div>
          </div>
        </div>

        <Swiper
          pagination={{
            clickable: true,
          }}
          slidesPerView={1.2}
          spaceBetween={16}
          centeredSlides={true}
          navigation={{
            nextEl: ".swiper-cta-next",
            prevEl: ".swiper-cta-prev",
          }}
          modules={[Navigation]}
          className="uniq-swiper"
          breakpoints={{
            1020: {
              slidesPerView: 4,
              spaceBetween: 10,
              centeredSlides: false,
            },
          }}
        >
          <SwiperSlide>
            <div className="uniq-swiper-slide">
              <img className="slide-img" src={deviceImage01} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="uniq-swiper-slide">
              <img className="slide-img" src={deviceImage02} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="uniq-swiper-slide">
              <img className="slide-img" src={deviceImage03} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="uniq-swiper-slide">
              <img className="slide-img" src={deviceImage04} alt="" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="uniq-swiper-slide">
              <img className="slide-img" src={deviceImage01} alt="" />
            </div>
          </SwiperSlide>
        </Swiper>
        <p className="slider-desc">
          W skład zestawu startowego wchodzi urządzenie z&nbsp;teksturowanym
          panelem i dodatkowy wymienny panel połyskujący.
        </p>
        <div className="core-section">
          <section className="top">
            <h2 className="title">
              Personalizacja przeniesiona na nowy poziom
            </h2>
            <p className="top-desc">
              15 kolorowych wymiennych paneli to jeszcze więcej możliwości
              ekspresji.
            </p>
            <div className="navigation">
              <ButtonTab active={coreSlider === PANELS_VARIANT.CORE} onClick={() => setCoreSlider(PANELS_VARIANT.CORE)}>Core Range</ButtonTab>
              <ButtonTab active={coreSlider === PANELS_VARIANT.MATTE} onClick={() => setCoreSlider(PANELS_VARIANT.MATTE)}>Matte Panels</ButtonTab>
              <ButtonTab active={coreSlider === PANELS_VARIANT.SATIN} onClick={() => setCoreSlider(PANELS_VARIANT.SATIN)}>Satin Panels</ButtonTab>
            </div>
          </section>

          <div className="uniq-slider-case">
            <Swiper
              pagination={{
                clickable: true,
              }}
              slidesPerView={1.4}
              spaceBetween={16}
              centeredSlides={true}
              navigation={{
                nextEl: ".swiper-cta-next",
                prevEl: ".swiper-cta-prev",
              }}
              modules={[Navigation]}
              className="core-swiper"
              breakpoints={{
                1020: {
                  slidesPerView: 3,
                  spaceBetween: 10,
                  centeredSlides: false,
                  loop: false,
                },
              }}
            >
              {coreSlider === PANELS_VARIANT.CORE &&
                panels.core.map((item) => (
                  <SwiperSlide key={item.name}>
                    <div className="card">
                      <img src={item.bg} />
                      <p className="card-desc">{item.name}</p>
                    </div>
                  </SwiperSlide>
                ))}
              {coreSlider === PANELS_VARIANT.SATIN &&
                panels.satin.map((item) => (
                  <SwiperSlide key={item.name}>
                    <div className="card">
                      <img src={item.bg} />
                      <p className="card-desc">{item.name}</p>
                    </div>
                  </SwiperSlide>
                ))}
              {coreSlider === PANELS_VARIANT.MATTE &&
                panels.matte.map((item) => (
                  <SwiperSlide key={item.name}>
                    <div className="card">
                      <img src={item.bg} />
                      <p className="card-desc">{item.name}</p>
                    </div>
                  </SwiperSlide>
                ))}
              <p className="footer">
                Dodatkowe panele są sprzedawane w punktach eSmokingWorld i na
                www.discoverglo.com
              </p>
            </Swiper>
            <div className="swiper-cta swiper-cta-next">
              <ArrowButton color="orange" />
            </div>
            <div className="swiper-cta swiper-cta-prev">
              <ArrowButton color="orange" />
            </div>
          </div>
          <div className="uniq-feature-wrapper">
            <section className="uniq-feature">
              <div className="uniq-feature-heading">
                <h2 className="feature-title">
                  Czym się różni glo™ Hyper+&nbsp;UNIQ  od glo™ hyper+ ?
                </h2>
                <p className="feature-desc">
                  Nowy design, ta sama jakość i znana funkcjonalność.
                </p>
              </div>
              <div className="uniq-feature-box">
                <div className="box-header">
                  <span className="half-box">glo™ Hyper+&nbsp;UNIQ </span>
                  <span className="half-box">glo™ hyper+</span>
                </div>
                <div className="box-wrapper">
                  <div className="box-body">
                    Możliwość <br />
                    personalizacji:
                    <div className="box-body-content">
                      <span className="half-box">Tak</span>{" "}
                      <span className="half-box">Nie</span>
                    </div>
                  </div>
                  <div className="box-body">
                    Technologia <br /> podgrzewania:
                    <div className="box-body-content">
                      <span className="full-box">indukcja</span>
                    </div>
                  </div>
                  <div className="box-body">
                    Format <br /> wkładów:
                    <div className="box-body-content">
                      <span className="full-box"> Wkłady neo™</span>
                    </div>
                  </div>
                  <div className="box-body">
                    Liczba sesji na jednym
                    <br /> ładowaniu:
                    <div className="box-body-content">
                      <span className="full-box"> 20</span>
                    </div>
                  </div>
                  <div className="box-body">
                    Tryby <br />
                    podgrzewania:
                    <div className="box-body-content">
                      <span className="full-box">
                        {" "}
                        2 tryby (Standard & Boost)
                      </span>
                    </div>
                  </div>
                  <div className="box-body">
                    Szybkość podgrzania
                    <br /> wkładu:
                    <div className="box-body-content">
                      <span className="full-box">
                        {" "}
                        Standard: 20 s., Boost: 15 s
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <picture>
              <source media="(max-width: 1019px)" srcSet={featureBG} />
              <source media="(min-width: 1020px)" srcSet={DesktopFeatureBg} />
              <img className="uniq-bg" src={DesktopFeatureBg} />
            </picture>
          </div>
          <div className="uniq-video">
            <div className="wrapper">
              <h2 className="title">
                Rzuć okiem i zobacz,
                <span>jak prezentuje się najnowsze urządzenie</span>{" "}
              </h2>
              <div className="video-wrapper">
              

                <VideoPlayer url="https://batcontent.www.dev.tdy.pl/bp-assets/glo_uniq-movie-1-2-1-news-alterzone720_9MB.mp4" placeholder={videoPlaceholder}/>

                
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
}

export default GloUniq;
