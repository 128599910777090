import React, { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getUser } from "../../store/reducers/user";
import Title from "../../components/Title/Title";
import { useOnScreen } from "../../hooks";

import { ReactComponent as BtnIcon } from "./img/btn-icon.svg";
// import ArrowButton from "../../components/ArrowButton/ArrowButton";
import newsBackground from "./img/img.png";
import NewsListItem from "../../components/NewsListItem/NewsListItem";
import NewsBigListItem from "../../components/NewsBigListItem/NewsBigListItem";

import "./News.scss";
import { useDispatch, useSelector } from "react-redux";
import { getNews } from "../../store/reducers/news";
import { listNews } from "../../store/actions/news";

function News() {
  const user = useAppSelector(getUser);
  const showItem = 4;
  const [itemToShow, setItemToShow] = useState(showItem);
  const [ref, visable] = useOnScreen({ rootMargin: "-240px" });
  const newsList = useSelector(getNews);
  const filterItems = newsList.slice(1, itemToShow);
  const showMoreCta = filterItems.length < newsList.length -1;
  const dispatch = useAppDispatch()

  useEffect(()=>{
    if (newsList.length === 0) {
      dispatch(listNews())
    }
  }, [])
  
  const handleShowMore = () => {
    setItemToShow((prevState) => prevState + showItem);
  };

  return (
    <PageLayout>
      <div className="page News">
        <Title>Co nowego?</Title>
        <div className="news-wrapper">
          <ul className={visable ? "news-list" : "news-list fixed"}>
            <>
              <div ref={ref}></div>
              <NewsBigListItem news={newsList[0]} />
            </>

            {filterItems.map((news: any) => (
              <NewsListItem key={news.title} news={news} />
            ))}
            {showMoreCta && (
              <button className="show-more-cta" onClick={handleShowMore}>
                Zobacz więcej <BtnIcon />
              </button>
            )}
          </ul>
        </div>
      </div>
    </PageLayout>
  );
}

export default News;
