export const API_URL = process.env.REACT_APP_API_URL;
export const API_WEBFORM_URL = process.env.REACT_APP_API_WEBFORM_URL;

// "https://api-dev.batpartners.pl" // https://api-dev.batpartners.pl //https://api.batpartners.pl


export function appFetch(method, api_url, endpoint, data, token, success, failed) {
    fetch(api_url+endpoint, {
        method: method,
        headers: (token ? {
            "Content-Type": "application/json; charset=utf-8",
            "Authorization": "Bearer " + token
        } : {
            "Content-Type": "application/json; charset=utf-8"
        }),
        body: method === "GET" ? null : data ? JSON.stringify(data) : null
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
        success(result)
        if ( result?.data?.error === "error_unauthorized") {
            localStorage.removeItem("token");
            window.location.replace('/')
        }
    }, (error) => {
        failed(error)
    })
}

export function fileFetch(method, api_url, endpoint, data, token, success, failed) {
    fetch(api_url+endpoint, {
        method: method,
        headers: (token ? {
            "Authorization": "Bearer " + token
        } : {
        }),
        body: data
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
        success(result)
    }, (error) => {
        failed(error)
    })
}