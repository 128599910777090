import {
  GET_NEWS
} from "../actions/types";

export function newsReducer(state = {}, action) {
  switch (action.type) {
    case GET_NEWS:
      return {
        ...state,
        list: action.payload
      }

    default:
      return state;
  }
}

export const getNews = (state) => state.news.list;