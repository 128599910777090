import PageLayout from "../../../components/PageLayout/PageLayout";

import Button from "../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import "./NewSaleSerialNumber.scss";
import { useEffect, useState } from "react";
import { FieldErrors, FieldValues, useForm } from "react-hook-form";
import { loginUser } from "../../../store/actions/user";
import { registerDevice } from "../../../store/actions/activations";
import { useDispatch, useSelector } from "react-redux";
import { getRegisterProgress } from "../../../store/reducers/activations";
import { useAppDispatch } from "../../../hooks";

function NewSaleSerialNumber() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { register, setValue, handleSubmit, getValues, formState: { errors } } = useForm();
  const registerInProgress = useSelector(getRegisterProgress)

  const onSubmit = ({ serial }: FieldValues) => {
    if (!registerInProgress) {
      dispatch(registerDevice( serial, navigate ))
    }
  }

  const onError = (errors: FieldErrors) => {
    //console.log(errors)
  }
  
  return (
    <PageLayout>
      <div className="page NewSaleSerialNumber">
        <div className="device-wrapper">
          <div className="heading">
            <h2 className="title">Wpisz numer seryjny urządzenia</h2>
            <p className="desc">
              Numer seryjny znajdziesz na spodzie opakowania obok kodu
              kreskowego.
            </p>
          </div>

          <div className="input-item">
            <label htmlFor="serial">Numer seryjny produktu</label>
            <input className={ errors.serial && 'error-input' } type="text" id="serial" {...register("serial", {required: true, minLength: 6})} placeholder="AJ845076"></input>
            {errors.serial && errors.serial.type === "minLength" && (
              <p className="error">Nieprawidłowa wartość</p>
            )}
            {errors.serial && errors.serial.type === "required" && (
              <p className="error">Wpisz numer seryjny produktu</p>
            )}
          </div>
          <Button onClick={ handleSubmit(onSubmit, onError) }>
            Potwierdzam
          </Button>
        </div>
      </div>
    </PageLayout>
  );
}

export default NewSaleSerialNumber;