import { 
  GET_NOTIFICATIONS,
  CLOSE_NOTIFICATION,
  ADD_TOAST,
} from './types';
import { API_URL, appFetch } from '../../helpers/fetch';
import { errors } from '../../helpers/errors';

export function fetchNotifications() {
  return (dispatch, getState) => { 
    const currentState = getState();
    appFetch("POST", API_URL, "/message/list", false, currentState.user?.token?.uuid ? currentState.user.token?.uuid : false, (result) => {
        if(result.status && result.status.success) {
            dispatch({ type:  GET_NOTIFICATIONS, payload: result.data  });
            
        } else {
            //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
    }, (error) => {
      dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
    })
  }
}

export function closeNotification(id) {
      const data = {
        "message": {
          "uuid": id,
        }
    }
  
  return (dispatch, getState) => { 
    const currentState = getState();
    appFetch("POST", API_URL, "/message/close",data ,currentState.user?.token?.uuid ? currentState.user.token?.uuid : false, (result) => {
        if(result.status && result.status.success) {
          dispatch({ type:  CLOSE_NOTIFICATION , payload: result.data  });
        } else {
            //dispatch({ type: ADD_TOAST, payload: { type: "error", message: result.data ? errors[result.data.error] : errors.generic } });
        }
      }, (error) => {
            dispatch({ type: ADD_TOAST, payload: { type: "error", message: errors.generic } });
      })
  }
 
}
