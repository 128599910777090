import React, { useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import { Navigation } from "swiper";
import "./Veo.scss";
import Title from "../../components/Title/Title";
import { Swiper, SwiperSlide } from "swiper/react";
import icon1 from "./img/nose.svg";
import icon2 from "./img/plant.svg";
import icon3 from "./img/capsule.svg";
import icon4 from "./img/leaf.svg";
import icon5 from "./img/glo.svg";
import RED from "./img/veo-red.png";
import BLUE from "./img/veo-blue.png";
import YELLOW from "./img/veo-yellow.png";
import GREEN from "./img/veo-green.png";
import PLUM from "./img/veo-plum.png";
import NEO from "./img/twopacks.png";
import ROIBOS from "./img/roiboos.jpg";
import { useWindowDimensions } from "../../hooks";
import Badge from "../../components/Badge/Badge";

interface CardProps {
  icon: string;
  title: string;
  desc: string;
  intensivity: any;
  effect: any;
  capsule: string;
}

const VeoCard: React.FC<CardProps> = ({icon, title, desc, intensivity, effect, capsule}:CardProps) => {
  const [active, setActive] = useState(false)

  return (
    <div className={active ? "VeoCard active" : "VeoCard"} onClick={()=>{setActive(!active)}}>
      <div className="pack">
        <div className="top">
          <img className="icon" src={icon} alt="" />
        </div>
      </div>
      <div className="info">
        Kliknij na paczkę <br/>i&nbsp;dowiedz się więcej
      </div>
      <div className="more">
        <h3>{title}</h3>
        <p className="desc">{desc}</p>
        <div className="level">
          <div className="name">Intensywność aromatu: </div>
          <div className="power">
            {intensivity.map((item:any)=>{
              return (
                <div className={item === 1 ? "dot active" : "dot"}></div>
              )
            })}
          </div>
        </div>
        <div className="level">
          <div className="name">Efekt chłodzenia: </div>
          <div className="power">
            {effect.map((item:any)=>{
              return (
                <div className={item === 1 ? "dot active" : "dot"}></div>
              )
            })}
          </div>
        </div>
        <div className="capsule">Kapsuła: <strong>{capsule}</strong></div>
      </div>
    </div>
  )
}

function Veo() {
  const { height, width } = useWindowDimensions();
  const [packageIndex, setPackageIndex] = useState(0);
  const [cardIndex, setCardIndex] = useState(0);

  return (
    <PageLayout>
      <section className="Veo">
        <div className="hero-section">
          <Badge category="Wkłady do glo™"/>
          <div className="glo-wrapper">
            <div className="glo-hero-content">
              <Title></Title>
              <h1 className="Title-veo">Nowe veo™</h1>
              <p>Technologia bez tytoniu, stworzone z rooibos</p>
            </div>
          </div>
        </div>
        <div className="main-section">
          <Swiper
            pagination={{
              clickable: true,
            }}
            slidesPerView={1.4}
            spaceBetween={16}
            centeredSlides={true}
            className="icon-swiper"
            breakpoints={{
              1020: {
                slidesPerView: 5,
                spaceBetween: 30,
                centeredSlides: false,
              },
            }}
          >
            <SwiperSlide>
              <div className="icon-swiper-slide">
                <div className="top">
                  <img className="icon" src={icon1} alt="" />
                </div>
                <div className="body">Nasycone bogatym aromatem</div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="icon-swiper-slide">
                <div className="top">
                  <img className="icon" src={icon2} alt="" />
                </div>
                <div className="body">Zawierają nikotynę</div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="icon-swiper-slide">
                <div className="top">
                  <img className="icon" src={icon3} alt="" />
                </div>
                <div className="body">Wzmocnione kapsułą</div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="icon-swiper-slide">
                <div className="top">
                  <img className="icon" src={icon4} alt="" />
                </div>
                <div className="body">Stworzone z&nbsp;rooibos</div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="icon-swiper-slide">
                <div className="top">
                  <img className="icon" src={icon5} alt="" />
                </div>
                <div className="body">Do użytku z&nbsp;podgrzewaczem glo™</div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="section-veo">
          <h2>5 wariantów <br/>smakowych</h2>
          <p>Pełne smaku, wzmocnione kapsułami</p>
          { width < 1020 ?
          <Swiper
            pagination={{
              clickable: true,
            }}
            slidesPerView={1.4}
            spaceBetween={16}
            centeredSlides={true}
            className="veo-swiper"
            breakpoints={{
              1020: {
                slidesPerView: 5,
                spaceBetween: 30,
                centeredSlides: false,
              },
            }}
          >
            <SwiperSlide>
              <VeoCard
                icon={RED}
                title="SCARLET CLICK"
                desc="Rześki aromat czerwonych owoców"
                intensivity={[1,1,1,0,0]}
                effect={[1,1,0,0,0]}
                capsule="Aromat czerwonych owoców" 
                />
            </SwiperSlide>
            <SwiperSlide>
              <VeoCard
                icon={PLUM}
                title="VIOLET CLICK"
                desc="Kremowo-jagodowy aromat"
                intensivity={[1,1,0,0,0]}
                effect={[1,1,1,1,0]}
                capsule="Kremowy aromat" 
                />
            </SwiperSlide>
            <SwiperSlide>
              <VeoCard
                icon={YELLOW}
                title="SUNSET CLICK"
                desc="Rześki aromat tropikalnego ananasa"
                intensivity={[1,1,1,1,0]}
                effect={[1,1,0,0,0]}
                capsule="Aromat tropikalnego ananasa" 
                />
            </SwiperSlide>
            <SwiperSlide>
              <VeoCard
                icon={BLUE}
                title="ARCTIC CLICK"
                desc="Chłodzący aromat zielonej mięty"
                intensivity={[1,1,1,0,0]}
                effect={[1,1,1,1,0]}
                capsule="Aromat zielonej mięty" 
                />
            </SwiperSlide>
            <SwiperSlide>
              <VeoCard
                icon={GREEN}
                title="GREEN CLICK"
                desc="Rześki i kremowy aromat miętowy"
                intensivity={[1,1,1,0,0]}
                effect={[1,1,0,0,0]}
                capsule="Kremowy aromat" 
                />
            </SwiperSlide>
          </Swiper>
          :
          <div className="veo-products">
            <VeoCard
              icon={RED}
              title="SCARLET CLICK"
              desc="Rześki aromat czerwonych owoców"
              intensivity={[1,1,1,0,0]}
              effect={[1,1,0,0,0]}
              capsule="Aromat czerwonych owoców" 
              />
            <VeoCard
              icon={PLUM}
              title="VIOLET CLICK"
              desc="Kremowo-jagodowy aromat"
              intensivity={[1,1,0,0,0]}
              effect={[1,1,1,1,0]}
              capsule="Kremowy aromat" 
              />
            <VeoCard
              icon={YELLOW}
              title="SUNSET CLICK"
              desc="Rześki aromat tropikalnego ananasa"
              intensivity={[1,1,1,1,0]}
              effect={[1,1,0,0,0]}
              capsule="Aromat tropikalnego ananasa" 
              />
            <VeoCard
              icon={BLUE}
              title="ARCTIC CLICK"
              desc="Chłodzący aromat zielonej mięty"
              intensivity={[1,1,1,0,0]}
              effect={[1,1,1,1,0]}
              capsule="Aromat zielonej mięty" 
              />
            <VeoCard
              icon={GREEN}
              title="GREEN CLICK"
              desc="Rześki i kremowy aromat miętowy"
              intensivity={[1,1,1,0,0]}
              effect={[1,1,0,0,0]}
              capsule="Kremowy aromat" 
              />
          </div>
        }
        </div>
        <div className="section-blue">
          <h2>Innowacja</h2>
          <p><strong>Nowatorska technologia wolna od tytoniu*</strong><br/>Zastąpiliśmy tytoń roślinną bazą rooibos <br/>i dodaliśmy do niej nikotynę oraz aromaty</p>
          <div className="wklady">
            <div className="wklady-item">
              <div className="column">
                <h3>Wkłady tytoniowe neo™ i kent do glo™</h3>
                <p>
                  Tytoń <br/>+ aromaty
                </p>
              </div>
              <div className="column column-img">
                <img src={NEO} />
              </div>
            </div>
            <div className="wklady-item">
              <div className="column">
                <h3>Wkłady veo™</h3>
                <p>
                  Rooibos <br/>+ nikotyna <br/>+ aromaty
                </p>
              </div>
              <div className="column column-img">
                <img src={RED} className="single" />
              </div>
            </div>
          </div>
          <p className="disclaimer">*Ten produkt jest wykonany z roślinnej mieszanki rooibos z dodatkiem nikotyny, która jest substancją uzależniającą. Jest przeznaczony do podgrzewania, a nie spalania. Ten produkt nie jest pozbawiony ryzyka.</p>
        </div>
        <div className="section-roibos">
          <h2>Czym jest rooibos?</h2>
          <div className="columns">
            <div className="column">
              <img src={ROIBOS}/>
            </div>
            <div className="column">
            <strong>Rooibos (wymawiane jako rojbos; co oznacza "czerwony krzew")</strong> to roślina Aspalathus linearis, uprawiana w prowincji Western Cape w Republice Południowej Afryki.<br/><br/>
            Roślina ta posiada subtelny, niemal neutralny smak po podgrzaniu, dzięki czemu doskonale uwydatnia aromaty. Zapewnia łagodniejszy posmak i mniej zapachu.
            </div>
          </div>
        </div>
      </section>
    </PageLayout>
  );
}

export default Veo;
