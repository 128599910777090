import React, { useEffect, useState } from "react";
import "./Nav.scss";
import { NavLink, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAnalyticsEventTracker, useAppDispatch, useAppSelector, useWindowDimensions } from "../../hooks";
import { getUI } from "../../store/reducers/ui";
import { ReactComponent as IconX } from "./img/icon-x.svg";
import { ReactComponent as IconArrow } from "./img/arrow-right.svg";
import { ReactComponent as IconChevronTop } from "./img/chevron-top.svg";
import { ReactComponent as IconChevronBot } from "./img/chevron-bot.svg";
import { closeMenu } from "../../store/actions/ui";
import { ReactComponent as Logo } from "./img/logo-white.svg";
import { useClickOutside } from "../../hooks";
import { pageView } from "../../helpers/gtag"
import { getUser } from "../../store/reducers/user";
import { logout } from "../../store/actions/user";

const april = new Date() > new Date("2024-04-01T00:00:00.000")

function Nav() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const ui = useAppSelector(getUI);
  const [selectedItem, setSelectedItem] = useState(0);
  const [subnavOpen, setSubnavOpen] = useState(false);
  const {height} = useWindowDimensions();
  const gaEventTracker = useAnalyticsEventTracker('Nav Event')
  let node = useClickOutside(() => closeNav());
  const user = useAppSelector(getUser)

  // let recommendLink = {
  //   name: "Jak polecać?",
  //   link: "/jak-polecac",
  // };

  // if (user?.recommendationGlo) {
  //   recommendLink = {
  //     name: "Polecanie rejestracji glo™",
  //     link: "/jak-polecac-alt",
  //   };
  // }

  useEffect(() => {
    /*ReactGA.set({
      location: `${window.location.origin}${location.pathname}${location.search}`,
    });
    ReactGA.pageview(location.pathname);*/
    pageView(`${window.location.origin}${location.pathname}${location.search}`, location.pathname)
  }, [location]);

  const navItems = [
    {
      name: "Twoje dane",
      link: "/profil",
    },
    {
      name: "Twoje osiągnięcia",
      sapIdVisibility:  user?.sapIdValidate && !april,
      subnav: [
        {
          name: "Rankingi",
          link: "/rankingi",
        },
        {
          name: "Statystyki",
          link: "/statystyki",
        },
        {
          name: "Cele",
          link: "/cele",
        },
      ],
    },
    // {
    //   name: recommendLink.name,
    //   link: recommendLink.link,
    // },
    {
      name: "Jak rozmawiać o glo™ z klientami?",
      link: "/jak-rozmawiac-o-glo",
    },
    {
      name: "Poznaj Produkty BAT",
      link: "/produkty-bat",
    },
  
    {
      name: "Regulaminy",
      link: "/regulaminy",
    },
    {
      name: "Poznaj zasady sprzedaży",
      link: "/poznaj-zasady-sprzedazy",
    },
    {
      name: "Pomoc",
      sapIdVisibility: true,
      subnav: [
        {
          name: "Reklamacje",
          link: "/reklamacje",
        },
        {
          name: "Utwórz skrót BAT Partners",
          link: "/utworz-skrot",
        },
        {
          name: "FAQ",
          link: "/faq",
        },
        {
          name: "Kontakt",
          link: "/kontakt",
        },
      ],
    },
  ];

  const toggleSubnav = (idx: number) => {
    setSubnavOpen(() => true);
    setSelectedItem(() => idx);
    if (idx === selectedItem) {
      setSubnavOpen(() => !subnavOpen);
    }
  };

  const closeNav = () => {
    dispatch(closeMenu());
    setSubnavOpen(false);
  };

  return (
    <div ref={node} className={ui.showMenu ? "nav active" : "nav"} style={{height: height}}>
      <div className="nav-inner">
        <div className="logo">
          <Logo />
        </div>
        <div className="close" onClick={() => closeNav()}>
          <IconX />
        </div>
        <div className="nav-links">
          {navItems.map((item, key) => {
            if (item.link) {
              return (
                <NavLink
                  key={`nav-item-${key}`}
                  className="nav-item"
                  to={item.link}
                  onClick={() => {closeNav(); gaEventTracker('click subnav', item.name) }}
                >
                  <span>{item.name}</span>
                  <IconArrow />
                </NavLink>
              );
            } else if (item.subnav) {
              return (
                <div key={`nav-item-${key}`} className={item.sapIdVisibility ? "" : "disabled"}>
                  <div
                    className="nav-item nav-dropdown"
                    onClick={() => {
                      toggleSubnav(key);
                      gaEventTracker('click subnav-toggle', item.name)
                    }}
                  >
                    <span>{item.name}</span>
                    {selectedItem === key && subnavOpen ? (
                      <IconChevronBot />
                    ) : (
                      <IconChevronTop />
                    )}
                  </div>
                  <div
                    className={
                      selectedItem === key && subnavOpen
                        ? "subnav active"
                        : "subnav"
                    }
                  >
                    {item.subnav.map((subitem, subkey) => {
                      return (
                        <NavLink
                          key={`nav-item-${key}-${subkey}`}
                          className="subnav-item"
                          to={subitem.link}
                          onClick={() => {closeNav(); gaEventTracker('click nav', item.name) }}
                        >
                          {subitem.name}
                        </NavLink>
                      );
                    })}
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div className="nav-footer">
          <span
            className="nav-item"
            onClick={() => {
              dispatch(logout(navigate));
              closeNav();
            }}
          >
            <span>Wyloguj</span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default Nav;
