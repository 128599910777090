import React, { useEffect, useState } from 'react';
import Content from '../../components/PageLayout/Content/Content';
import PageLayout from '../../components/PageLayout/PageLayout';
import Title from '../../components/Title/Title';
import { getUser } from "../../store/reducers/user";
import { useAnalyticsEventTracker, useAppDispatch, useAppSelector, useWindowDimensions } from '../../hooks';
import Button from '../../components/Button/Button';
import "./Payout.scss";
import { useDispatch, useSelector } from 'react-redux';
import { accountBalance, accountPaymentHistory, accountSeasons, balanceSummary, payout } from '../../store/actions/account';
import { getAccountBalance, getAccountProfit, getPayoutHistory, getPayoutInProgress, getSeasonsInfo } from '../../store/reducers/account';
import moment from 'moment';
import { Link } from 'react-router-dom';
import warning from '../Profile/img/warning.svg'

function Payout() {
  const user = useAppSelector(getUser);
  const balance = useAppSelector(getAccountBalance);
  const profit = useAppSelector(getAccountProfit);
  const {width, height} = useWindowDimensions();
  const dispatch = useAppDispatch();
  const payoutHistory = useSelector(getPayoutHistory);
  const seasonsInfo = useSelector(getSeasonsInfo);
  const gaEventTracker = useAnalyticsEventTracker('Payout Event')
  const [missingUserData, setMissingUserData] = useState(false);
  const [dateDisabled, setDateDisabled] = useState(false);
  const payoutInProgress = useSelector(getPayoutInProgress);

  useEffect(()=>{
    dispatch(accountPaymentHistory())
    dispatch(balanceSummary())
    if (seasonsInfo.current === '') {
      dispatch(accountSeasons())
    }
    if (moment().isAfter('01.29.2023 00:00:01') && moment().isBefore('02.06.2023 23:59:59')) {
      setDateDisabled(true)
    }
  }, [])

  useEffect(() => {
    if (user?.firstName) {
      if (
        user?.dob != null &&
        user?.streetAddress != null &&
        user?.postalCode != null &&
        user?.cityName != null &&
        user?.taxOfficeName != null &&
        user?.iban != null
      ) {
        setMissingUserData(false);
      } else {
        setMissingUserData(true);
      }
    }
  }, [user?.dob]);

  console.log((user?.blacklisted || dateDisabled || payoutInProgress || balance < 1) && missingUserData)

  const april = new Date() > new Date("2024-04-01T00:00:00.000")

  return (
    <PageLayout withBottom>
      <div className="page Payout">
        <Title>Wypłać</Title>
        <div className="earnings-wrap">
        <div className={missingUserData ? "earnings-box dropshadow-box missing-user-data" : "earnings-box dropshadow-box" }>
            <div className="earnings-top">
              <div className="earnings-total">
                <h3 className="earnings-title">Twoje zarobki</h3>
                <div className={profit > 999 ? "earnings-total-number smaller" : "earnings-total-number" }>
                  {profit}<span> zł</span>
                </div>
                <div className="sub-text">w tym sezonie</div>
              </div>
              <div className="earnings-current">
                <div className="columns">
                  <div className="title-column">
                    <h3 className="earnings-title">Twoje saldo</h3>

                    <div className={balance > 999 ? "earnings-current-number smaller" : "earnings-current-number" }>
                      {balance}<span> zł</span>
                    </div>
                   
                    <div className="sub-text sub-text-2">do wypłacenia</div>
                  </div>
                  {/* <div className="earnings-column">
                    <div className={balance > 999 ? "earnings-current-number smaller" : "earnings-current-number" }>
                      {balance}<span> zł</span>
                    </div>
                  </div> */}
                </div>
                {/* { missingUserData && <Button path="/profil">Uzupełnij profil</Button>}
                { (user?.blacklisted || dateDisabled || payoutInProgress || balance < 1) && !missingUserData ? <Button className="disabled">{balance < 1 ? 'Wypłata od 1 zł' : 'Wypłać'}</Button> : missingUserData ? null : <Button onClick={()=>{ dispatch(payout()); gaEventTracker('click', "Payout Button")}}>Wypłać</Button> } */}
              </div>
            </div>
            <div className="earnings-bot">
              { missingUserData ? 
              <p className="color-red attention"><img src={warning} /><strong>Uzupełnij profil w całości, aby odblokować wypłaty</strong></p> :
              <>
                <p className="color-primary">od 03.04.2024 roku przelewy będą realizowane po uprzednim zgłoszeniu się mailowym na adres:  <strong><a href="mailto:kontakt@batpartners.pl">kontakt@batpartners.pl</a></strong></p>
                <p className="color-primary">W zgłoszeniu należy obowiązkowo podać następujące dane:</p>
                 <ul>
                  <li>Imię i nazwisko</li>
                  <li>Numer BAT Partners ID</li>
                  <li>Numer telefonu</li>
                  <li>Numer konta bankowego</li>
                 </ul>
                 <p className="color-primary"><strong>Uwaga:</strong> Bez pełnych i poprawnych danych zgłoszenia nie będą rozpatrywane.</p>
              </> }
            </div>
          </div>
          <p className={user?.sapIdValidate && !april  ? "stats-link" : "stats-link disabled"}>Porównaj swoją sprzedaż z poprzednimi sezonami. <strong><Link to="/statystyki">Zajrzyj do statystyk</Link></strong></p>
        </div>
      </div>
      <div className="history-section bottom-section">
        <div className="stats-wrapper">
          <h3>Historia wypłat</h3>
          <table>
            <tbody>
              {payoutHistory.map((item: any, key: any) => {
                return (
                  <tr key={key}>
                    <td className="date">{ moment(item.created.date).format('DD.MM.YYYY') }</td>
                    <td className="name">{  }</td>
                    <td className="score">{ `${Math.abs(item.value)} zł` }</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </PageLayout>
  );
}

export default Payout
