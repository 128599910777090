import React, { useRef, useState } from 'react';
import Measure from 'react-measure';
import { useUserMedia } from './useUserMedia';
import { useCardRatio } from './useCardRatio';
import { useOffsets } from './useOffsets';
import './PhotoOCR.scss'
import Button from '../Button/Button';
import { useWindowDimensions } from '../../hooks';

const CAPTURE_OPTIONS = {
    audio: false,
    video: { facingMode: "environment" },
};

function Camera({ onCapture, onClear }) {
  const canvasRef = useRef();
  const videoRef = useRef();
  const screen = useWindowDimensions();
  const [container, setContainer] = useState({ width: 0, height: 0 });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [isFlashing, setIsFlashing] = useState(false);

  const mediaStream = useUserMedia(CAPTURE_OPTIONS);
  const [aspectRatio, calculateRatio] = useCardRatio(0.66666666);
  const offsets = useOffsets(
    videoRef.current && videoRef.current.videoWidth,
    videoRef.current && videoRef.current.videoHeight,
    container.width,
    container.height
  );

  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    videoRef.current.srcObject = mediaStream;
  }

  function handleResize(contentRect) {
    setContainer({
      width: contentRect.bounds.width,
      height: Math.round(contentRect.bounds.width / aspectRatio)
    });
  }

  function handleCanPlay() {
    calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
    setIsVideoPlaying(true);
    videoRef.current.play();
  }

  function handleCapture() {
    const context = canvasRef.current.getContext("2d");

    context.drawImage(
      videoRef.current,
      offsets.x,
      offsets.y,
      container.width,
      container.height,
      0,
      0,
      container.width,
      container.height
    );

    canvasRef.current.toBlob((data)=>onCapture(data),"image/jpeg", 1);
    setIsCanvasEmpty(false);
    setIsFlashing(true);
  }

  function handleClear() {
    const context = canvasRef.current.getContext("2d");
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setIsCanvasEmpty(true);
    onClear();
  }

  if (!mediaStream) {
    return null;
  }

  return (
      <Measure bounds onResize={handleResize}>
        {({ measureRef }) => (
    <div className="Camera" style={{ height: `${screen.height}px` }}>
          <div className="wrapper">
            <div className="container" ref={measureRef} style={{ height: `${container.height}px` }}>
              <video 
                className="video"
                ref={videoRef}
                onCanPlay={handleCanPlay}
                style={{ top: `-${offsets.y}px`, left: `-${offsets.x}px` }}
                autoPlay 
                playsInline 
                muted
              />
            </div>
            <div className="overlay" hidden={!isVideoPlaying} style={{ height: `${container.width*0.8}px`, top: `${container.width*0.8*0.5 - 20}px` }}></div>
            <canvas 
              className="canvas"
              ref={canvasRef}
              width={container.width}
              height={container.height}
            />
            <div className="controls" style={{ height: `${screen.height - container.height}px` }}>
              {isVideoPlaying && (
                <Button onClick={isCanvasEmpty ? handleCapture : handleClear}>
                  {isCanvasEmpty ? "Zrób zdjęcie" : "Wyczyść"}
                </Button>
              )}
            </div>
          </div>
    </div>
        )}
      </Measure>
  );
};

export default Camera;