import React from "react";
import ArrowButton from "../ArrowButton/ArrowButton";
import { ReactComponent as Gift } from "./img/gift.svg";
import { ReactComponent as Calendar } from "./img/calendar.svg";
import checked from "./img/checked.svg";
import "./ActivitiesListItem.scss";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ActivitiesListItem = ({ activity, rewriteCompleted }: any) => {
  const navigate = useNavigate();

  const completed = activity?.completed || rewriteCompleted;

  const checkCompleted = () => {
    if (activity?.next_attempt) {
      if (!activity?.completed && moment(activity?.next_attempt?.date).isAfter(moment())) {
        return true;
      }
    }
    return false;
  }
  const incorrectlyCompleted = checkCompleted()

  return (
    <div className="Activities-box">
      <div
        role="link"
        onClick={() => navigate(`/aktywnosci/${activity?.ident}`)}
        className={
          incorrectlyCompleted
            ? "box-item wrong"
            : completed
            ? "box-item done"
            : "box-item"
        }
      >
        <div className="box-bg-wrapper">
          <img className="bg" src={activity?.hero_image?.file?.url} alt="" />
          <div className="checked">
            <img className="checked-img" src={checked} alt="" />
          </div>
        </div>
        <div
          className={
            incorrectlyCompleted
              ? "box-item-body dropshadow-box incorrectly-completed"
              : "box-item-body dropshadow-box"
          }
        >
          <div className="box-item-top">
            <h3 className="box-item-title">{activity?.content?.title}</h3>
            <div className="box-item-prize">
              <span className="box-item-value">{activity?.payout} zł</span>
              <span className="box-item-icon">
                <Gift />
              </span>
            </div>
          </div>
          <div className="box-item-footer">
            <span className="box-item-date">
              <Calendar /> <span>{moment(activity?.created?.date).format('DD-MM-YYYY')}</span>
            </span>
            <span className="box-item-line"></span>
            <span className="box-item-line-desc">Wróć jutro!</span>
            <span className="box-item-arrow">
              <ArrowButton path={`/aktywnosci/${activity?.ident}`} color={"white"} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivitiesListItem;
