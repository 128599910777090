import React, { Component, ReactComponentElement } from 'react'
import Modal from 'react-modal'
import { getUI } from '../../store/reducers/ui'
import { hideDrawer, showDrawer } from '../../store/actions/ui'
import { getUser } from '../../store/reducers/user'
import { ReactComponent as IconExclamation } from './img/icon-exclamation.svg';
import { ReactComponent as IconLocked } from './img/icon-locked.svg';
import { ReactComponent as IconPrize } from './img/icon-prize.svg';
import { ReactComponent as IconHourglass } from './img/icon-hourglass.svg';
import { ReactComponent as IconChecked } from './img/icon-checked.svg';
import { ReactComponent as IconCompleted } from './img/icon-completed.svg';
import Button from '../Button/Button';
import { errors, infos } from './messages';
import './Drawer.scss';
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks'

Modal.setAppElement('#root')

export default function Drawer() {
  const dispatch = useAppDispatch()
  const ui = useAppSelector(getUI)
  const user = useAppSelector(getUser)
  const navigate = useNavigate();

  const icons: { [icon: string]: any} = {
    'exclamation': <IconExclamation />,
    'locked': <IconLocked />,
    'hourglass': <IconHourglass />,
    'completed': <IconCompleted />
  }

  const handleSwipe = (e: any) => {
    if (e.direction === 16) {
      dispatch(hideDrawer())
    }
  }

  const drawerContent = () => {
    switch(ui.drawerType) {
      case 'batcode':
        return (
          <div className="drawer-inner drawer-batcode">
            <p>Twój numer BAT Partnera</p>
            <p className="bat-number">{ user?.referralCode }</p>
          </div>
        )
      case 'error':
        let errorName: string = ui.drawerData.name;
        const errorCode: string = ui.drawerData.errorCode;
         if (!errors[errorName]) errorName = "generic"
        return (
          <div className="drawer-inner drawer-error">
            { errors[errorName].icon && icons[errors[errorName].icon] }
            <h2>{ errors[errorName].title }</h2>
            <p className="color-red"><strong>Kod błędu: { errorCode }</strong></p>
            { errors[errorName].description && <p dangerouslySetInnerHTML={{ __html: errors[errorName].description }}></p> }
            <Button onClick={ () => { dispatch(hideDrawer()) }} >{ errors[errorName].buttonText }</Button>
          </div>
        )
      case 'info':
        const infoName: string = ui.drawerData.name;
        return (
          <div className="drawer-inner drawer-info">
            <h2>{ infos[infoName].title }</h2>
            { infos[infoName].description && <p>{ infos[infoName].description }</p> }
            <Button onClick={ () => { dispatch(hideDrawer()) }} >{ infos[infoName].buttonText }</Button>
          </div>
        )
      case 'device-register-info':
        return (
          <div className="drawer-inner drawer-info">
            <h2>Chcesz sam wpisać <br/>numer seryjny?</h2>
            <p>Pamiętaj, że przy wpisywaniu numeru seryjnego konieczne jest zachowanie dokumentu potwierdzającego sprzedaż.</p>
            <Button onClick={ () => { dispatch(hideDrawer()); navigate("/nowa-sprzedaz/numer-seryjny") }} >Tak, chcę wpisać</Button>
          </div>
        )
      case 'czechy2022':
        return (
          <div className="drawer-inner drawer-pop">
            <h2>Ahoj!</h2>
            <h3>Marka glo™ jest dostępna w wielu krajach<br/> Europy m.in. w Czechach.</h3>
            <p>Dlatego bez obaw sprzedawaj urządzenia glo™ naszym czeskim sąsiadom i zdobywaj kolejne nagrody pieniężne.</p>
            <Button onClick={ () => { dispatch(hideDrawer()) }} >Rozumiem</Button>
          </div>
        )
      case 'coupon-registered':
          return (
            <div className="drawer-inner drawer-info drawer-success">
              { icons['completed']}
              <h2>Kod rabatowy zarejestrowany</h2>
              <p>Możesz wydać konsumentowi nagrodę: <strong>2&nbsp;paczki neo™ w cenie jednej</strong></p>
              <Button onClick={ () => { dispatch(hideDrawer()) }} >Rozumiem</Button>
            </div>
          )
      case 'sap-info':
        return (
          <div className="drawer-inner drawer-error">
            { icons['exclamation']}
            <h2>Już niedługo pojawi się istotna aktualizacja danych w programie BAT Partners!</h2>
            <p><strong>Twój kod punktu (POP ID) zostanie zastąpiony przez numer klienta (SAP ID).</strong></p>
            <p>Dowiedz się więcej o tej zmianie, klikając poniższy przycisk:</p>
            <Button onClick={ () => { dispatch(hideDrawer()); navigate("/news/zmiana-w-bat-partners-od-teraz-korzystamy-z-numeru-klienta-sap-id") }} >Przeczytaj news</Button>
          </div>
        )
        case 'non-sap-login':
        return (
          <div className="drawer-inner drawer-error">
            { icons['exclamation']}
            <h2>Brak numeru SAP ID</h2>
            <p>Obecnie Twoje konto jest zablokowane z uwagi na brak przypisania poprawnego numeru SAP ID. Aby odblokować konto, prosimy o przesłanie numeru na adres: <strong>kontakt@batpartners.pl</strong>.</p>
            <p>Numer SAP ID można uzyskać, kontaktując się ze swoim przedstawicielem handlowym.</p>
            <p><strong>Jeśli nie zostanie on przesłany oraz nie będzie żadnej aktywności sprzedażowej w serwisie w tym okresie, konto zostanie usunięte zgodnie z regulaminem.</strong></p>
            <p><strong>Dodatkowo, do dnia 20 stycznia 2024 roku</strong>, masz możliwość odebrania zarobionych środków, w przeciwnym razie środki te zostaną wygaszone.</p>
            <Button onClick={ () => { dispatch(hideDrawer()) }} >Zamknij</Button>
          </div>
        )
        case 'pit-info':
          return (
            <div className="drawer-inner drawer-info drawer-blue">
              <h2>Tylko krok dzieli Cię od wypłaty środków!</h2>
              <p>Pamiętaj, że pełne uzupełnienie profilu jest konieczne, aby bezproblemowo wypłacić środki po zakończeniu  aktywności! Twoje dane są niezbędne do prawidłowego rozliczenia podatkowego z uzyskanej nagrody.</p>
              <Button className={"white"} onClick={ () => { dispatch(hideDrawer()); navigate("/profil") }} >Uzupełnij dane</Button>
            </div>
          )
      default:
        return <div></div>
    }
  }

  return (
    <Modal
      isOpen={ ui.showDrawer } 
      contentLabel="Modal" 
      className={{
        base: 'drawer',
        afterOpen: 'drawer_after-open',
        beforeClose: 'drawer_before-close'
      }}
      overlayClassName={{
        base: 'overlay',
        afterOpen: 'overlay_after-open',
        beforeClose: 'overlay_before-close'
      }} 
      onRequestClose={ () => { dispatch(hideDrawer()) } } 
      closeTimeoutMS={ 500 }
    >
      <div className="swipe-close">
        { drawerContent() }
        <button className="btn-close" onClick={ () => { dispatch(hideDrawer()) } }><div className={ui.drawerType === "batcode" ? "icon white": "icon"}></div></button>
      </div>
    </Modal>
  )
}
