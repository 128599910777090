import React, { useEffect, useState } from "react";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./Stats.scss";
import Title from "../../components/Title/Title";
import DropDownTabs from "../../components/DropDownTabs/DropDownTabs";
import { useDispatch, useSelector } from "react-redux";
import {
  accountSeasons,
  balanceHistory,
  balanceSummary,
} from "../../store/actions/account";
import {
  getSeasons,
  getSeasonsInfo,
  getIncomeHistorySales,
  getIncomeHistorySalesCount,
  getIncomeHistoryRefs,
  getIncomeHistoryRefsCount,
  getReferrals,
  getReferralsHistory,
  getPrizeList,
} from "../../store/reducers/account";
import moment from "moment";
import { romanize, useAppDispatch } from "../../hooks";
import ReactPaginate from "react-paginate";
import { NavLink } from "react-router-dom";
import phoneIcon from "../Goals/img/devices.svg";
import handshakeIcon from "../Goals/img/handshake.svg";
import { listPrizes } from "../../store/actions/rankings";

export const STATS_TYPE = {
  SELL: "SALES",
  REF: "REF",
};

interface StatsProps {
  statsType: string;
}

const Stats: React.FC<StatsProps> = ({ statsType }) => {
  const seasons = useSelector(getSeasons);
  const seasonsInfo = useSelector(getSeasonsInfo);
  const incomeHistorySales = useSelector(getIncomeHistorySales);
  const incomeHistorySalesCount = useSelector(getIncomeHistorySalesCount);
  const incomeHistoryRefs = useSelector(getIncomeHistoryRefs);
  const incomeHistoryRefsCount = useSelector(getIncomeHistoryRefsCount);
  const incomeHistory =
    statsType === "SALES" ? incomeHistorySales : incomeHistoryRefs;
  const incomeHistoryCount =
    statsType === "SALES" ? incomeHistorySalesCount : incomeHistoryRefsCount;
  const [season, setSeason] = useState(0);
  const dispatch = useAppDispatch();
  const [hyperDevices, setHyperDevices] = useState(0);
  const [vuseDevices, setVuseDevices] = useState(0);
  const [allDevices, setAllDevices] = useState(0);
  const referralsHistory = useSelector(getReferralsHistory);
  const [referralsCount, setReferralsCount] = useState(0);
  const prizeList = useSelector(getPrizeList);
  const [voucherHistoryActive, setVocuherHistoryActive] = useState(false);
  console.log(season, seasonsInfo);

  useEffect(() => {
    if (seasonsInfo.current === "") {
      dispatch(accountSeasons());
    } else {
      dispatch(balanceHistory(0, seasonsInfo?.current));
    }
    dispatch(balanceSummary());
    dispatch(listPrizes());
  }, []);

  useEffect(() => {
    dispatch(balanceHistory(0, seasonsInfo?.current));
  }, [seasonsInfo?.current]);

  useEffect(() => {
    const currentSeasonData = seasons.filter(
      (s: any) => s.name === Object.keys(seasonsInfo.list).reverse()[season]
    );
    const currentSeasonRefs = referralsHistory.filter(
      (s: any) => s.name === Object.keys(seasonsInfo.list).reverse()[season]
    );

    setReferralsCount(currentSeasonRefs[0]?.value);
    setHyperDevices(currentSeasonData[0]?.devices.glo);
    setVuseDevices(currentSeasonData[0]?.devices.vuse);
    setAllDevices(
      currentSeasonData[0]?.devices.glo + currentSeasonData[0]?.devices.vuse
    );
    dispatch(balanceHistory(0, seasonKey(season)));
  }, [season]);

  const handleChangeSeason = (season: number) => {
    setSeason(season);
  };

  const seasonKey = (idx: number) => {
    const seasonKeys = Object.keys(seasonsInfo.list);
    return seasonKeys.reverse()[idx];
  };

  const seasonNames = () => {
    const seasonKeys = Object.keys(seasonsInfo.list);
    const names = seasonKeys.map((item) => {
      if (item === "q1_21") {
        return { name: "2021 - Wiosna" };
      } else if (item === "q2_21") {
        return { name: "2021 - Lato" };
      } else if (item === "q3_21") {
        return { name: "2021 - Jesień" };
      } else if (item === "q4_21") {
        return { name: "2021 - Zima" };
      } else {
        return {
          name: `20${item.substring(3, 5)} - Sezon ${romanize(
            parseInt(item.substring(1, 2))
          )}`,
        };
      }
    });
    return names.reverse();
  };

  const activationName = (solution: any) => {
    let name = "";
    let ident = solution?.activation?.ident;
    if (solution.purpose) {
      ident = solution.purpose;
    }
    if (/super_seria/.test(ident)) {
      ident = "super_seria";
    }
    if (/quiz/.test(ident)) {
      ident = "quiz";
    }
    if (/happy_days/.test(ident)) {
      ident = "bonus";
    }
    if (/boost_/.test(ident)) {
      ident = "bonus";
    }
    if (/comeback_/.test(ident)) {
      ident = "bonus";
    }
    if (/new_/.test(ident)) {
      ident = "bonus";
    }
    if (/bonus_referral/.test(ident)) {
      ident = "end";
    }
    if (/bonus_registration/.test(ident)) {
      ident = "end";
    }
    switch (ident) {
      case "device_register":
        let deviceName = solution.payload.model;
        name = "Rejestracja urządzenia - " + deviceName;
        break;
      case "quiz":
        name = "Bonus za quiz";
        break;
      case "fill_profile":
        name = "Uzupełniony Profil";
        break;
      case "super_seria":
      case "referral_lvl2_q3_22":
      case "referral_lvl2_q4_22":
      case "referral_lvl4_q3_22":
      case "referral_lvl4_q4_22":
        name = "Bonus za osiągnięcie rangi";
        break;
      case "glo_boost":
        name = "Happy Days";
        break;
      case "glo_boost_2":
        name = "Happy Days";
        break;
      case "boost_inactivity_lipiec_1":
        name = "ComeBack Bonus 1";
        break;
      case "boost_inactivity_lipiec_2":
        name = "ComeBack Bonus 2";
        break;
      case "boost_inactivity_sierpien_1":
        name = "ComeBack 2 Bonus 1";
        break;
      case "boost_inactivity_sierpien_2":
        name = "ComeBack 2 Bonus 2";
        break;
      case "consumer_register":
        name = "Rejestracja Konsumenta";
        break;
      case "glo_boost_3":
        name = "Happy Days";
        break;
      case "ankieta_grudzien_2021":
        name = "Bonus za wypełnienie ankiety";
        break;
      case "birthday_bonus":
        name = "Bonus urodzinowy";
        break;
      case "bonus_referral_q4_21":
        name = "Bonus za polecenia";
        break;
      case "end":
        name = "Bonus za koniec sezonu";
        break;
      case "rok_tygrysa":
        name = "Wygrana w konkursie Rok Tygrysa";
        break;
      case "quiz_vuse_thr":
        name = "Bonus za quiz 4 filary Vuse";
        break;
      case "bonus":
      case "mikolajki_2022":
      case "black_week_2022":
      case "device_first_registration_bonus":
      case "new_january_2023":
        name = "Bonus za sprzedaż";
        break;
      case "referral_lvl1_q3_22":
      case "referral_lvl1_q4_22":
      case "referral_lvl1_q1_23":
        name = "Voucher Allegro 20 zł";
        break;
      case "referral_lvl3_q3_22":
      case "referral_lvl3_q4_22":
      case "referral_lvl3_q1_23":
        name = "Voucher Allegro 50 zł";
        break;
      case "referral_lvl5_q3_22":
      case "referral_lvl5_q4_22":
      case "referral_lvl5_q1_23":
        name = "Voucher Allegro 100 zł";
        break;
      case "update_profile":
        name = "Bonus za uzupełnienie profilu";
        break;
      default:
        name = "";
        break;
    }

    return name;
  };

  const handlePageChange = (event: any) => {
    dispatch(balanceHistory((event.selected * 100) % incomeHistoryCount));
  };

  const num = (value: number, numerals: any, wovalue: boolean = true) => {
    var t0 = value % 10,
      t1 = value % 100,
      vo = [];
    if (wovalue !== true) vo.push(value);
    if (value === 1 && numerals[1]) vo.push(numerals[1]);
    else if (
      (value == 0 ||
        (t0 >= 0 && t0 <= 1) ||
        (t0 >= 5 && t0 <= 9) ||
        (t1 > 10 && t1 < 20)) &&
      numerals[0]
    )
      vo.push(numerals[0]);
    else if ((t1 < 10 || t1 > 20) && t0 >= 2 && t0 <= 4 && numerals[2])
      vo.push(numerals[2]);
    return vo.join(" ");
  };

  const title = (score: number) => {
    if (statsType === "SALES") {
      return num(score, ["sprzedanych", "sprzedane", "sprzedane"]);
    } else {
      return num(score, [
        "zarejstrowanych konsumentów",
        "zarejestrowany konsument",
        "zarejestrowanych konsumentów",
      ]);
    }
  };

  return (
    <PageLayout withBottom>
      <section className="page Stats">
        <Title>Moje statystyki</Title>
        <div className="button-group stats-button-group-top">
          <NavLink
            className={statsType === "SALES" ? "ButtonTab active" : "ButtonTab"}
            to="/statystyki/sprzedaz"
          >
            Sprzedane urządzenia
          </NavLink>
          <NavLink
            className={statsType != "SALES" ? "ButtonTab active" : "ButtonTab"}
            to="/statystyki/polecenia"
          >
            Poleceni konsumenci
          </NavLink>
        </div>
        <div className="stats-dashboard dropshadow-box">
          {seasonsInfo.current != "" && (
            <DropDownTabs
              handleChangeTab={handleChangeSeason}
              tabList={seasonNames()}
              activeTab={season}
            />
          )}
          <div className="sales-score">
            {statsType === "SALES" ? (
              <>
                <div className="score">
                  {hyperDevices} <img src={phoneIcon} />
                </div>
                <div className="score-title">
                  {title(hyperDevices) +
                    " glo™ hyper+ Uniq lub glo™ hyper X2 Air"}
                </div>
              </>
            ) : (
              <>
                <div className="score">
                  {referralsCount} <img src={handshakeIcon} />
                </div>
                <div className="score-title">{title(referralsCount)}</div>
              </>
            )}
          </div>
        </div>
        <div className="button-group">
          <div
            className={!voucherHistoryActive ? "ButtonTab active" : "ButtonTab"}
            onClick={() => setVocuherHistoryActive(false)}
          >
            Historia wpływów
          </div>
          {statsType != "SALES" && (
            <div
              className={
                voucherHistoryActive ? "ButtonTab active" : "ButtonTab"
              }
              onClick={() => setVocuherHistoryActive(true)}
            >
              Vouchery
            </div>
          )}
        </div>
      </section>
      <div className="history-section bottom-section">
        {statsType === "SALES" || !voucherHistoryActive ? (
          <div className="stats-wrapper">
            <h3>Historia wpływów</h3>
            <table>
              <tbody>
                {incomeHistory.map((item: any, key: any) => {
                  return (
                    <tr key={key}>
                      <td className="date">
                        {moment(item?.created?.date).format("DD.MM.YYYY")}
                      </td>
                      <td className="name">
                        {activationName(item?.solution) ||
                          item?.solution?.activation?.ident}
                      </td>
                      <td className="score">{`${item?.value} zł`}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {incomeHistoryCount <= 100 ? null : (
              <div className="pagination">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=">"
                  onPageChange={handlePageChange}
                  pageRangeDisplayed={5}
                  pageCount={incomeHistoryCount / 100}
                  previousLabel="<"
                />
              </div>
            )}
          </div>
        ) : (
          <div className="stats-wrapper">
            <h3>Twoje Vouchery do wykorzystania</h3>
            <table>
              <tbody>
                {prizeList.map((item: any, key: any) => {
                  return (
                    <tr key={key}>
                      <td className="date">
                        {moment(item?.created?.date).format("DD.MM.YYYY")}
                      </td>
                      <td className="name">{activationName(item)}</td>
                      <td className="score">{item?.code}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default Stats;
