import React, { useEffect } from "react";
import BackgroundImage01 from "../../pages/Activities/img/01.jpg";
import { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import NewsBigListItem from "../../../components/NewsBigListItem/NewsBigListItem";
import "./DashboardSlider.scss";
import { useDispatch, useSelector } from "react-redux";
import { getNews } from "../../../store/reducers/news";
import { listNews } from "../../../store/actions/news";
import ArrowButton from "../../../components/ArrowButton/ArrowButton";
import { useAppDispatch } from "../../../hooks";

const DashboardSlider: React.FC<any> = () => {
  const newsList = useSelector(getNews);
  const dispatch = useAppDispatch()

  useEffect(()=>{
    if (newsList.length === 0) {
      dispatch(listNews())
    }
  }, [])

  return (
    <div className="DashboardSlider">
      <Swiper
        loop={true}
        loopedSlides={5}
        pagination={{
          clickable: true,
        }}
        navigation={{
          nextEl: ".swiper-cta-next",
          prevEl: ".swiper-cta-prev",
        }}
        modules={[Navigation, Pagination]}
        className="activities-slider"
        breakpoints={{
          320: {
            slidesPerView: 1.1,
            spaceBetween: 20,
          },
          1020: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        }}
      >
        {newsList.slice(0,5).map((news: any) => (
          <SwiperSlide key={news.title}>
            <NewsBigListItem news={news} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="swiper-cta swiper-cta-next">
        <ArrowButton color="blue" />
      </div>
      <div className="swiper-cta swiper-cta-prev">
        <ArrowButton color="blue" />
      </div>
    </div>
  );
};

export default DashboardSlider;
