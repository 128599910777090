import React from "react";
import { useSelector } from "react-redux";
import { getReferrals, getSeasons, getSeasonsInfo } from "../../store/reducers/account";
import "./Counter.scss";
import counterBackground from "./img/meter.svg";

interface Props {
  type?: "sales" | "referrals";
}

const Counter: React.FC<Props> = ({type = 'sales'}) => {
  const seasons = useSelector(getSeasons);
  const seasonsInfo = useSelector(getSeasonsInfo)
  const season = seasons.filter((s: any) => s.name === seasonsInfo.current)[0]
  const referrals = useSelector(getReferrals);

  const score = type === "sales" ? season?.sold : referrals?.current

  const num = (value: number, numerals:any, wovalue:boolean = true) => {
    var t0 = value % 10,
        t1 = value % 100,
        vo = [];
    if (wovalue !== true)
        vo.push(value);
    if (value === 1 && numerals[1])
        vo.push(numerals[1]);
    else if ((value == 0 || (t0 >= 0 && t0 <= 1) || (t0 >= 5 && t0 <= 9) || (t1 > 10 && t1 < 20)) && numerals[0])
        vo.push(numerals[0]);
    else if (((t1 < 10 || t1 > 20) && t0 >= 2 && t0 <= 4) && numerals[2])
        vo.push(numerals[2]);
    return vo.join(' ');
  };

  const title = () => {
    if (type === "sales") {
      return num(score, ["Sprzedanych urządzeń", "Sprzedane urządzenie", "Sprzedane urządzenia"])
    } else {
      return num(score, ["Poleconych konsumentów", "Polecony konsument", "Poleconych konsumentów"])
    }
  }

  const pointPosition = () => {
    const s = Math.min(Math.max(score,0.01),20);
    const r = 110;
    if (s) {
      const deg = (180 / 20 * s)* Math.PI / 180;
      const x = r * Math.sin(deg);
      const y = r * Math.cos(deg);

      return { top: x, left: y}
    }
    return { top: 0, left: 0}
  }

  return (
    <div className="Counter">
      <div className="counter-meter">
        <div className="counter-point-position">
          <div className="counter-point" style={pointPosition()}></div>
        </div>
        <img className="counter-background" src={counterBackground} />
        <div className="counter-numbers">
          <div className="counter-number">0</div>
          <div className="counter-number">3</div>
          <div className="counter-number">6</div>
          <div className="counter-number">10</div>
          <div className="counter-number">15</div>
          <div className="counter-number">20</div>
        </div>
      </div>
      <div className="sales-number">
        <span className="number">{ score }</span>
        <span className="title">{ title() }</span>
      </div>
    </div>
  )
};

export default Counter;
